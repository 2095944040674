import { Nav, Card } from "react-bootstrap";
import { useEffect, useState } from "react";
import profile from "../../../assets/images/profile.jpg";
import { HiLocationMarker } from "react-icons/hi";
const Groups = ({ groups, activeUserId, setActiveUserId, activeChatType, setActiveChatType, setChatId, handleEventKey, updateReadStatus }) => {
    return (<><div className="bg-white mt-2">{groups && groups.length > 0 ? groups.map((chat, i) => (<Nav.Item
        className={`userchathov ${activeUserId === chat.id && activeChatType === "group" ? "bl_msg_list" : ""
            }`}
        key={i}
    >
        <Nav.Link
            onClick={(event) => {
                setActiveUserId(chat.id);
                setActiveChatType("group");
                setChatId(chat.id);
                handleEventKey(
                    chat.id,
                    chat.name,
                    chat.imagePath,
                    chat.city,
                    chat.state,
                    chat.description,
                    "group"
                );
                updateReadStatus(chat);
                console.log(" chat.description", chat.description)
            }}
        >
            <div className="userchat">
                <div>
                    <img src={chat.imagePath ? chat.imagePath : profile} />
                    {/* {chat.isActive == 1 ? (
                        <span className="act_grn"></span>
                    ) : (
                        <span className="act_red"></span>
                    )} */}
                </div>
                <div className="userchatInfo">
                    <span>
                        <p className="fz_14">{chat.name}</p>
                    </span>
                    {chat.message ? (
                        <p>
                        </p>
                    ) : (
                        ""
                    )}
                    {chat.isRead == false ? (
                        <span className="unread-text">Unread</span>
                    ) : null}
                </div>
            </div>
        </Nav.Link>
    </Nav.Item>
    )) : <Card className="bg-white fz_14 text-center">No groups were found</Card>}</div></>);
}

export default Groups;