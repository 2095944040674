import { Card, Container, Row, Col } from "reactstrap";
import { useEffect, useState } from "react";
import HeaderMenu from "../headerMenu";
import SideBar from "../SideBar";
import BreadCrumb from "../../plugins/Breadcrumb";
import { useLocation } from "react-router-dom";
import { useToast } from "../../Toast";
import { useSubscription } from '../../SubscriptionContext';
const ScoreSettings = () => {
  const [enableOption, setEnableOption] = useState(false);
  const [enablePSOption, setEnablePSOption] = useState(false);
  const [enableCSOption, setEnableCSOption] = useState(false);
  const location = useLocation();
  const userId = location.state?.userId;
  const settingsData = location.state?.settingsData;
  const toast = useToast();
  const { allFeatures, isFeatureEnabled } = useSubscription();
  const onToggle = (e) => {
    setEnableOption(e.target.checked);
    UpdateAuth(e.target.checked, 'BS');
  };
  const onPSScoreToggle = (e) => {
    setEnablePSOption(e.target.checked);
    UpdateAuth(e.target.checked, 'PS');
  };
  const onCSScoreToggle = (e) => {
    setEnableCSOption(e.target.checked);
    UpdateAuth(e.target.checked, 'CS');
  };
  useEffect(() => {
    setEnableOption(settingsData.businessScore);
    setEnablePSOption(settingsData.peopleScore);
    setEnableCSOption(settingsData.cultureScore);
  }, [settingsData]);
  const UpdateAuth = async (value, type) => {
    const params = {
      businessScore: (type === "BS" ? value : enableOption),
      peopleScore: (type === "PS" ? value : enablePSOption),
      cultureScore: (type === "CS" ? value : enableCSOption)
    };
    try {
      const response = await window.apiService.settingsBusnScore(
        userId,
        params
      );
      if (response.data.status === "OK") {
        toast.success(response.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <HeaderMenu />
      <SideBar />
      <div className="main-content ml_200">
        {/* <BreadCrumb /> */}
        <Card className="shade h-500">
          <Container fluid>
            <div className="header-body  pt-3">
              <Row>
                {isFeatureEnabled(allFeatures["BS,-PS%"]?.key, allFeatures["BS,-PS%"]?.isDeleted) && <><div className="d-flex mt-2 toggle_gap align-items-center">
                  <Col sm={2} md={2} lg={2}>
                    <label className="font-weight-500 fz_14">
                      Show Business Score
                    </label></Col>
                  <Col sm={10} md={10} lg={10}>
                    <label className="toggle-switch">
                      <input
                        type="checkbox"
                        checked={enableOption}
                        onChange={onToggle}
                      />
                      <span className="switch" />
                    </label>
                  </Col>
                </div>
                  <div className="d-flex mt-2 toggle_gap">
                    <Col sm={2} md={2} lg={2}>
                      <label className="font-weight-500 fz_14">
                        Show People Score
                      </label></Col>
                    <Col sm={10} md={10} lg={10}>
                      <label className="toggle-switch">
                        <input
                          type="checkbox"
                          checked={enablePSOption}
                          onChange={onPSScoreToggle}
                        />
                        <span className="switch" />
                      </label>
                    </Col>
                  </div></>}
                {isFeatureEnabled(allFeatures["Culture-Score%"]?.key, allFeatures["Culture-Score%"]?.isDeleted) && <div className="d-flex mt-2 toggle_gap">
                  <Col sm={2} md={2} lg={2}>
                    <label className="font-weight-500 fz_14">
                      Show Culture Score
                    </label></Col>
                  <Col sm={10} md={10} lg={10}>
                    <label className="toggle-switch">
                      <input
                        type="checkbox"
                        checked={enableCSOption}
                        onChange={onCSScoreToggle}
                      />
                      <span className="switch" />
                    </label>
                  </Col>
                </div>}
              </Row>
            </div>
          </Container>
        </Card>
      </div>
    </>
  );
};

export default ScoreSettings;
