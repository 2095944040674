import axios from 'axios';
import store from './Redux/store';

const axiosInstance = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
});

const waitForAuthentication = () => {
  return new Promise((resolve) => {
    const checkAuth = () => {
      const state = store.getState();
      if (state.user.isAuthenticating) {
        resolve();
      } else {
        setTimeout(checkAuth, 100);
      }
    };
    checkAuth();
  });
};

axiosInstance.interceptors.request.use(
  async (config) => {
    await waitForAuthentication();

    const token = store.getState().user.token;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      window.location.href = '/login';
      store.dispatch({ type: 'LOGOUT' });

    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
