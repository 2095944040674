import React, { useState, useEffect } from "react";
import { Container, Modal, Navbar, Nav, Button } from "react-bootstrap";
import profile from "../../assets/images/profile.jpg";
import { NavLink, Link, Navigate } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";
import { FaSearch, FaUser } from "react-icons/fa";
import { TbLogout } from "react-icons/tb";
import { IoIosArrowDown } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import "../Dashboard/headerMenu.css";
import { commonUtils } from "../CommonUtils";
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
} from "reactstrap";
import { connect } from "react-redux";
import { logoutAction } from "../../Redux/Actions/userAction";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
function HeaderMenu(props) {
  const location = useLocation();
  const [isNavbarFixed, setIsNavbarFixed] = useState(false);
  const [activeIcon, setActiveIcon] = useState(null);
  const [bannerName, setBannerName] = useState("");
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const userId = useSelector((state) => state.user.userId);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const Logout = async () => {
    dispatch(logoutAction());
    // sessionStorage.removeItem("rememberMeToken");
    localStorage.removeItem("userdetail");
    navigate("/login");
    if (userId) {
      try {
        const response = await window.apiService.logout(userId, {
          isActive: 0,
        });
        console.log("response", response);
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    console.log("hh", location.pathname);
    handleDynamicName();
  }, []);
  const handleDynamicName = () => {
    setBannerName(commonUtils.getBannerName(location.pathname));
  };
  const handleEvent = (key) => {
    props.onEventKey(key);
    setActiveIcon(key === activeIcon ? null : key);
  };

  return (
    <>
      <div className="headerMenu">
        <Navbar expand="lg" className="menu-navbar header-menu">
          <Container className="container_width bg-white">
            <Navbar.Brand as={NavLink} to="/dashboard" className="navBrand">
              <div>
                <h1 className="tiipstr">{bannerName}</h1>
              </div>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="navbar-nav" />
            <Navbar.Collapse id="navbar-nav" className="flex-end">
              <Nav className="src/App.css">
                <Nav.Link className="p-0">
                  <UncontrolledDropdown nav className="top_5">
                    <DropdownToggle nav className="p-0">
                      <Media className="align-items-center">
                        <span className="avatar avatar-sm rounded-circle">
                          <img
                            src={props.imagePath ? props.imagePath : profile}
                          />
                        </span>
                        <span className="logo_name">
                          {props.userName}
                          <span>
                            <IoIosArrowDown />
                          </span>
                        </span>
                      </Media>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-arrow" right>
                      <DropdownItem className="noti-title" header tag="div">
                        <h6 className="text-overflow drop_header m-0">
                          Welcome!
                        </h6>
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => navigate("/dashboard/support_profile")}
                      >
                        <FaUser className="ni ni-single-02" />
                        <span>My profile</span>
                      </DropdownItem>
                      <DropdownItem divider />
                      <DropdownItem
                        href="#pablo"
                        onClick={() => setShowConfirmPopup(true)}
                      >
                        <TbLogout className="ni ni-user-run" />
                        <span>Logout</span>
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
      <div className="header pt-0"></div>
      {/* ----------- Logout Confirmation Popup ---------- */}
      <Modal
        size="sm"
        show={showConfirmPopup}
        onHide={() => setShowConfirmPopup(false)}
      >
        <Modal.Header>
          <Modal.Title
            id="example-custom-modal-styling-title"
            style={{ fontSize: "15px", fontWeight: 600 }}
          >
            Logout Confirmation
          </Modal.Title>
          <span onClick={() => setShowConfirmPopup(false)} title="Close">
            <AiOutlineClose />
          </span>
        </Modal.Header>
        <Modal.Body className="bordr">
          <span>Are you sure you want to Logout?</span>
        </Modal.Body>
        <Modal.Footer className="bordr pad_13">
          <Button variant="primary" className="del_bttn" onClick={Logout}>
            Yes
          </Button>
          <Button
            variant="danger"
            className="del_bttn"
            onClick={() => setShowConfirmPopup(false)}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    userName: state.user.userName,
    imagePath: state.user.imagePath,
  };
};
export default connect(mapStateToProps)(HeaderMenu);
