import { Col, Row, Button, Card } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { useToast } from "../../../Toast";
import "../../../../assets/css/ToggleSwitch.css";
const PlanFeatureMapping = (props) => {
    const roleName = props.name;
    const [roleId, setRoleId] = useState(null);
    const [featureList, setFeatureList] = useState([]);
    const [checkedList, setCheckedList] = useState([]);
    const [planList, setPlanList] = useState([]);
    const toast = useToast();
    useEffect(() => {
        const loadData = async () => {
            if (roleId) {
                await loadAllPlanFeatures();
                await loadFeatureById(roleId);
            }
        };
        loadData();
    }, [roleId]);
    useEffect(() => {
        loadPlans();
    }, []);
    const loadPlans = async () => {
        try {
            const response = await window.apiService.loadPlans();
            setPlanList(response.data.recordInfo);
            setRoleId(response.data.recordInfo[0]?.id);
        } catch (error) {
            console.log(error);
        }
    };

    const loadAllPlanFeatures = async () => {
        try {
            const response = await window.apiService.loadAllFeatures();
            const allFeatures = response.data.recordInfo;
            const features = allFeatures.filter(item => item.isDeleted === 0);
            const checkedFeatureIds = new Set(checkedList.map(feature => feature.featureId));
            const updatedFeatureList = features.map((feature) => ({
                ...feature,
                isChecked: checkedFeatureIds.has(feature.id)
            }));

            setFeatureList(updatedFeatureList);
        } catch (error) {
            console.log(error);
        }
    };
    const loadFeatureById = async (id) => {
        if (id) {
            try {
                const response = await window.apiService.loadFeatureById(id);
                const mappedFeatures = response.data.recordInfo || [];
                const mappedFeatureIds = new Set(mappedFeatures.map((mf) => mf.id));
                setFeatureList((prevList) =>
                    prevList.map((feature) => ({
                        ...feature,
                        isChecked: mappedFeatureIds.has(feature.id)
                    }))
                );
                setCheckedList(mappedFeatures.map((mf) => ({ planId: roleId, featureId: mf.id })));
            } catch (error) {
                console.error("Error loading features by plan ID:", error);
            }
        }
    };

    const handleCheckboxChange = (event, featureId) => {
        const isChecked = event.target.checked;
        setFeatureList((prevList) =>
            prevList.map((feature) =>
                feature.id === featureId ? { ...feature, isChecked } : feature
            )
        );
        setCheckedList((prevCheckedList) => {
            if (isChecked) {
                return [...prevCheckedList, { planId: roleId, featureId }];
            } else {
                return prevCheckedList.filter((item) => item.featureId !== featureId);
            }
        });
    };

    const updateFeatureMapping = async () => {
        const selectedFeatures = featureList
            .filter(feature => feature.isChecked)
            .map(feature => ({
                planId: roleId,
                featureId: feature.id
            }));

        try {
            const response = await window.apiService.updateFeatureMapping(selectedFeatures);
            if (response.data.status === "OK") {
                toast.success(response.data.message);
                loadAllPlanFeatures();
                loadFeatureById(roleId);
            }
        } catch (error) {
            console.log("Error updating feature mapping:", error);
        }
    };

    return (
        <>
            <div className="chat container h-100">
                <Row>
                    <Col sm={12} md={12} lg={10} xl={10}>
                        <div className="role_container">
                            <Row className="m_b18 title_txt">
                                <Col sm={12} md={12} lg={12} xl={12}>
                                    Choose Plan Features
                                </Col>
                            </Row>
                            <Row className="mb-2">
                                <Col sm={8} md={5} lg={5} xl={5}>
                                    <select
                                        className="select_clr form-control input_select fz_14"
                                        value={roleId}
                                        onChange={(e) => setRoleId(e.target.value)}
                                    >
                                        <option value="">Select Plan</option>
                                        {planList &&
                                            planList.map((plan) => (
                                                <option key={plan.id} value={plan.id}>
                                                    {plan.servicePlan + " / " + plan.billingCycle}
                                                </option>
                                            ))}
                                    </select>
                                </Col>
                            </Row>
                            <div className="h_218 overflow-x-hidden">
                                <div className="qr_image rounded">
                                    {featureList && featureList.length > 0 ? (
                                        <Row>
                                            {featureList.map((feature, index) => (
                                                <Col key={feature.id} sm={12} md={4} lg={4} xl={4} className="mt-2">
                                                    <div className="d-flex align-items-center">
                                                        <div className="form-check form-switch">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id={`feature${feature.id}`}
                                                                checked={feature.isChecked || false}
                                                                onChange={(event) => handleCheckboxChange(event, feature.id)}
                                                            />
                                                            <label
                                                                className="form-check-label"
                                                                htmlFor={`feature${feature.id}`}
                                                            >
                                                                {feature.featureName}
                                                            </label>
                                                        </div>
                                                    </div>
                                                </Col>
                                            ))}
                                        </Row>
                                    ) : (
                                        <Row>
                                            <Col sm={12}>
                                                <div className="text-center fz_13 mt-2">
                                                    No Plan Features Were Found
                                                </div>
                                            </Col>
                                        </Row>
                                    )}
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className="m_b18 title_txt">
                    <Col sm={8} md={6} lg={6} xl={6}>
                        <Button variant="primary" className="del_bttn ml-3" onClick={() => updateFeatureMapping()}>
                            Save
                        </Button>
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default PlanFeatureMapping;
