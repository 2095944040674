import React, { useState, useEffect } from "react";
import { useToast } from "../../Toast";
import HeaderMenu from "../headerMenu";
import SideBar from "../SideBar";
import { Card, Container, Row, Col } from "reactstrap";
import BreadCrumb from "../../plugins/Breadcrumb";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

const NotifySettings = (props) => {
  const location = useLocation();
  const settingsData = location.state?.settingsData;
  const userId = location.state?.userId;
  const [emailNotify, setEmailNotify] = useState(false);
  const [smsNotify, setSmsNotify] = useState(false);
  const [pushNotify, setPushNotify] = useState(false);
  const navigate = useNavigate();
  const toast = useToast();
  useEffect(() => {
    setEmailNotify(settingsData.emailNotification);
    setSmsNotify(settingsData.smsNotification);
    setPushNotify(settingsData.pushNotification);
  }, [settingsData, userId]);

  const handleEmailChange = () => {
    setEmailNotify(!emailNotify);
  };
  const handlePhoneChange = () => {
    setSmsNotify(!smsNotify);
  };
  const IsValid = () => {
    return emailNotify || smsNotify || pushNotify;
  };
  const handleSubmit = async () => {
    const params = {
      emailNotification: emailNotify,
      smsNotification: smsNotify,
      pushNotification: pushNotify,
    };
    if (IsValid()) {
      try {
        const response = await window.apiService.settingsNotfyUpdate(
          userId,
          params
        );
        if (response.data.status == "OK") {
          toast.success(response.data.message);
        } else {
          toast.error("Error Occured");
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      toast.error("Please choose an option before proceeding");
    }
  };

  return (
    <>
      <HeaderMenu />
      <SideBar />
      <div className="main-content ml_200">
        {/* <BreadCrumb /> */}
        <Card className="shade h-500">
          {props.emailVerified || props.phoneVerified ? (
            <Container fluid>
              <div className="header-body  pt-3">
                <Row>
                  <Col lg="12" xl="12">
                    <div className="d-flex mt-2 toggle_gap">
                      <h6 className="font-weight-500">
                        Choose the notification preference
                      </h6>
                    </div>
                    <div style={{ marginLeft: "20px" }}>
                      <input
                        type="checkbox"
                        name="contactMethod"
                        value="email"
                        checked={emailNotify}
                        onChange={handleEmailChange}
                        disabled={!props.emailVerified}
                      />
                      <label className="marL">
                        Email {props.email && <span>({props.email})</span>}
                      </label>

                      <br />

                      <input
                        type="checkbox"
                        name="contactMethod"
                        value="phone"
                        checked={smsNotify}
                        onChange={handlePhoneChange}
                        disabled={!props.phoneVerified}
                      />
                      <label className="marL">
                        Phone {props.phone && <span>({props.phone})</span>}
                      </label>
                      <br />
                      <button
                        type="submit"
                        className="btn btn-primary rev_btn"
                        onClick={handleSubmit}
                      >
                        Update
                      </button>
                    </div>
                  </Col>
                </Row>
              </div>
            </Container>
          ) : (
            <div className="centered-card">
              <Card className="infoCard">
                <p className="txtInf">
                  To enhance the security of your account, please complete the
                  email or phone verification process before proceeding. Visit
                  your{" "}
                  <span
                    className="name_blue c_pointer"
                    onClick={() => navigate("/dashboard/profile")}
                  >
                    profile
                  </span>{" "}
                  to verify your email or phone Thank you for prioritizing the
                  security of your account.
                </p>
              </Card>
            </div>
          )}
        </Card>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    emailVerified: state.user.emailVerified,
    phoneVerified: state.user.phoneVerified,
    email: state.user.email,
    phone: state.user.phone,
  };
};
export default connect(mapStateToProps)(NotifySettings);
