import React, { useState, useEffect } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
export function Header() {
  const [isNavbarFixed, setIsNavbarFixed] = useState(false);
  const location = useLocation();
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsNavbarFixed(true);
      } else {
        setIsNavbarFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Navbar
      expand="lg"
      className={`custom-navbar ${isNavbarFixed ? "fixed-navbar" : ""}`}
    >
      <Container>
        {/* <Navbar.Brand as={NavLink} to="/">
          <div><h1 className='tiipstr'>tiipstr</h1></div>
        </Navbar.Brand> */}
        <Navbar.Toggle aria-controls="navbar-nav" />
        <Navbar.Collapse id="navbar-nav">
          <Nav className="src/App.css">
            <Nav.Link
              className="fontW"
              as={NavLink}
              exact
              to="https://tiipstr.com/home"
            >
              Home
            </Nav.Link>
            <Nav.Link
              className="fontW"
              as={NavLink}
              to="https://tiipstr.com/about"
            >
              About
            </Nav.Link>
            <Nav.Link className="fontW" as={NavLink} to="/terms-of-service">
              Terms of Service
            </Nav.Link>
            <Nav.Link className="fontW" as={NavLink} to="/privacy-policy">
              Privacy Policy
            </Nav.Link>
            {location.pathname !== "/login" ? (
              <Nav.Link className="fontW" as={NavLink} to="/login">
                Login
              </Nav.Link>
            ) : (
              ""
            )}
            {location.pathname !== "/registration" ? (
              <Nav.Link className="fontW" as={NavLink} to="/registration">
                Sign Up
              </Nav.Link>
            ) : (
              ""
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
