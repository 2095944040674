import { Button, Card, Form, Container, Row, Col } from "react-bootstrap";
import PhoneControl from "../../CustomPhone/PhoneControl";
import profile from "../../../assets/images/profile.jpg";
import Modal from "react-bootstrap/Modal";
import "../../ProfileUpdate/Profile.css";
import "../../../components/Register/register.css";
import { useState, useEffect, useRef } from "react";
import { HiCheckCircle } from "react-icons/hi";
import { AiOutlineClose } from "react-icons/ai";
import { TiCamera } from "react-icons/ti";
import { BsExclamationCircle } from "react-icons/bs";
import { useToast } from "../../Toast";
import HeaderMenu from "../HeaderMenu";
import SideBar from "../SupportSideBar";
import "../../../assets/css/ToggleSwitch.css";
import { connect } from "react-redux";
import { useDispatch, useSelector } from "react-redux";
import loader from "../../../assets/images/loader.gif";
import { phoneNumber } from "../../../Redux/Actions/userAction";
const STATUS = {
  start: 1,
  default: 2,
};
const Profile = (props) => {
  const [status, setStatus] = useState(STATUS.default);
  const intervalRef = useRef();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [state, setState] = useState("");
  const [year, setYear] = useState("");
  const [city, setCity] = useState("");
  const [professionInfo, setprofessionInfo] = useState([]);
  const [businessInfo, setbusinessInfo] = useState([]);
  const [emptyErr, setemptyErr] = useState(false);
  const [mobileVerified, setmobileVerified] = useState(false);
  const [isEmailCodeSend, setIsEmailCodeSend] = useState(false);
  const [isMobileCodeSend, setIsMobileCodeSend] = useState(false);
  const [mobilePopupShow, setMobilePopupShow] = useState(false);
  const [emailPopupShow, setEmailPopupShow] = useState(false);
  const [imagePath, setImagePath] = useState("");
  const [verificationCode, setVerifcationCode] = useState("");
  const [minutes, setMinutes] = useState(5);
  const [seconds, setSeconds] = useState(0);
  const [config, setConfig] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isPhoneChanged, setisPhoneChanged] = useState(false);
  const [emailVerified, setemailVerified] = useState(false);
  const [isPublic, setIsPublic] = useState(false);
  const dispatch = useDispatch();
  const [displayContent, setdisplayContent] = useState(false);
  const timerMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const timerSeconds = seconds < 10 ? `0${seconds}` : seconds;
  const [phoneValidation, setPhoneValidation] = useState("");
  const [deletePopupShow, setDeletePopupShow] = useState(false);
  const toast = useToast();
  useEffect(() => {
    loadsprofileInfo();
    fetch("/config.json")
      .then((res) => res.json())
      .then((res) => {
        setConfig(res);
      });
  }, [props]);
  useEffect(() => {
    if (status === STATUS.start) {
      intervalRef.current = setInterval(() => {
        countDown();
      }, 1000);
    }
    return () => {
      clearInterval(intervalRef.current);
    };
  }, [minutes, seconds, status]);
  const start = () => {
    setMinutes(5);
    setSeconds(0);
    setStatus(STATUS.start);
  };
  const userId = useSelector((state) => state.user.userId);
  const authToken = useSelector((state) => state.user.token);
  const oldPhoneNo = useSelector((state) => state.user.phNumber);
  const loadsprofileInfo = async () => {
    setdisplayContent(false);
    const params = {
      id: props.userId,
      userType: props.userType,
    };
    try {
      const data = await window.apiService.loadprofileInfo(params);
      let result = data.data;
      if (data.status == "OK") {
        setFirstName(result.firstName);
        setPhone(result.phone);
        dispatch(phoneNumber(result.phone));
        setLastName(result.lastName);
        setEmail(result.email);
        setState(result.state);
        setYear(result.birthYear);
        setCity(result.city);
        setImagePath(result.imagePath);
        setbusinessInfo(result.businessList);
        setprofessionInfo(result.professionalList);
        setemailVerified(result.emailVerified);
        setmobileVerified(result.phoneVerified);
        setIsPublic(result.isPublic);
        dispatch({
          type: "SAVE_USER_DATA",
          payload: {
            token: authToken,
            userId: result.id,
            userType: result.userType,
            userName: result.name,
            imagePath: result.imagePath != null ? result.imagePath : "",
          },
        });
        setdisplayContent(true);
      }
    } catch (error) {
      setdisplayContent(true);
      toast.error("Error Occured");
    }
  };
  function countDown() {
    if (seconds === 0) {
      if (minutes !== 0) {
        setSeconds(59);
        setMinutes((min) => min - 1);
      } else {
        let mins = 0;
        let sec = 0;
        setSeconds(sec);
        setMinutes(mins);
      }
    } else {
      setSeconds((sec) => sec - 1);
    }
  }
  const phoneChange = (value) => {
    const result = value.indexOf(" ");
    const ph = value.substring(result + 1);
    if (value != "" && ph.length === 14) {
      setPhoneValidation("");
    } else {
      setPhoneValidation("Phone No is required");
    }
    setPhone(value);
    if (value == oldPhoneNo) {
      setisPhoneChanged(false);
      setmobileVerified(true);
    } else {
      setisPhoneChanged(true);
      setmobileVerified(false);
    }
  };
  const mobileverifyset = (value) => {
    setMobilePopupShow(value);
  };
  const onSubmit = async () => {
    if (year < new Date().getFullYear() && year >= 1900) {
      if (
        firstName &&
        city &&
        state &&
        year &&
        lastName &&
        phone != "" &&
        phone != "+1 undefined" &&
        phone != "+91 undefined" &&
        !phoneValidation
      ) {
        setLoading(true);
        let userInfo = {
          firstName: firstName,
          lastName: lastName,
          email: email,
          phone: phone,
          state: state,
          city: city,
          birthYear: year,
          imagePath: imagePath,
          business: businessInfo,
          professional: professionInfo,
          isPublic: isPublic,
          isPhoneModified: isPhoneChanged,
        };
        try {
          const response = await window.apiService.profileUpdate(
            props.userId,
            userInfo
          );
          if (response.data.status == "OK") {
            toast.success(response.data.message);
            loadsprofileInfo();
            setLoading(false);
          } else {
            toast.error(response.data.message);
            setLoading(false);
          }
        } catch (error) {
          console.log(error);
          setLoading(false);
          toast.error("Error Occured");
        }
      } else {
        toast.error("Please fill out all required fields.");
      }
    } else {
      toast.error("Invalid Birth Year");
    }
  };
  const verifyMobileCode = async () => {
    if (verificationCode) {
      try {
        const response = await window.apiService.verifyCode({
          id: props.userId,
          type: "phone",
          verificationCode: verificationCode,
        });
        if (response.data.status == "OK") {
          toast.success(response.data.message);
          loadsprofileInfo();
          setVerifcationCode("");
          setMobilePopupShow(false);
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        toast.error("Error Occured");
      }
    } else {
      setemptyErr(true);
    }
  };
  const verifyEmailCode = async () => {
    if (verificationCode) {
      try {
        const response = await window.apiService.verifyCode({
          id: props.userId,
          type: "email",
          verificationCode: verificationCode,
        });
        if (response.data.status == "OK") {
          toast.success(response.data.message);
          loadsprofileInfo();
          setVerifcationCode("");
          setEmailPopupShow(false);
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        console.log(error);
        toast.error("Error Occured");
      }
    } else {
      setemptyErr(true);
    }
  };
  const verifyMobile = async () => {
    try {
      const response = await window.apiService.sendCode({
        id: props.userId,
        type: "phone",
      });
      if (response.data.status == "OK") {
        toast.success(response.data.message);
        start();
        setIsMobileCodeSend(true);
        setisPhoneChanged(false);
        setVerifcationCode("");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log(error);
      toast.error("Error Occured");
    }
  };
  const verifyEmail = async () => {
    try {
      const response = await window.apiService.sendCode({
        id: props.userId,
        type: "email",
      });
      if (response.data.status == "OK") {
        toast.success(response.data.message);
        start();
        setIsEmailCodeSend(true);
        setVerifcationCode("");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log(error);
      toast.error("Error Occured");
    }
  };
  const createImage = (image) => {
    var reader = new FileReader();
    reader.onload = (e) => {
      setImagePath(e.target.result);
    };
    reader.readAsDataURL(image);
  };
  const uploadImage = async (event) => {
    if (event.target.files[0]) {
      if (
        !/\.(jpg|svg|jpeg|png|bmp|gif|jfif)$/i.test(event.target.files[0].name)
      ) {
        toast.error(config.FileFormatErr);
        return false;
      }
      setLoading(true);
      console.log("event.target.files[0].name", event.target.files[0].name);
      const myNewFile = new File(
        [event.target.files[0]],
        event.target.files[0].name.replace(/\s/g, ""),
        { type: event.target.files[0].type }
      );
      console.log("event.target.files[0].name55", myNewFile.name);
      createImage(myNewFile);
      let formData = new FormData();
      formData.append("file", myNewFile);
      formData.append("id", userId);
      try {
        const response = await window.apiService.imgUpload(formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        if (response.data.status == "OK") {
          loadsprofileInfo();
          setLoading(false);
          toast.success(config.ImageUpSuc);
        } else if (response.data.status == "EXPECTATION_FAILED") {
          setLoading(false);
          toast.error(response.data.message);
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
  };
  const deleteImage = async () => {
    if (imagePath) {
      setLoading(true);
      try {
        const response = await window.apiService.deleteImage(userId);
        if (response.data.status == "OK") {
          loadsprofileInfo();
          setLoading(false);
          toast.success(response.data.message);
          setDeletePopupShow(false);
        } else if (response.data.status == "EXPECTATION_FAILED") {
          setLoading(false);
          toast.error(response.data.message);
          setDeletePopupShow(false);
        }
      } catch (error) {
        setLoading(false);
        setDeletePopupShow(false);
        console.log(error);
      }
    } else {
      toast.warning(config.DelImageErr);
    }
  };
  return (
    <>
      <HeaderMenu />
      <div className="">
        <SideBar />
        {displayContent ? (
          <div className="main-content ml_200">
            {loading ? (
              <div className="pro-loader-center">
                <img className="loader-image" src={loader} alt="Loading..." />
              </div>
            ) : (
              ""
            )}
            {/* <BreadCrumb/> */}
            <div
              className="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
              style={{
                minHeight: "600px",
                backgroundSize: "cover",
                backgroundPosition: "center top",
              }}
            >
              <span className="mask" />
              <Container className="d-flex align-items-center" fluid>
                <Row>
                  <Col lg="7" md="10"></Col>
                </Row>
              </Container>
            </div>
            <Container className="mt--30 mb-4" fluid>
              <Row>
                <Col className="order-xl-1" xl="12">
                  <Card className="bg-secondary profile_card">
                    <Card.Header className="bg-white border-0">
                      <Row className="align-items-center">
                        <Col xs="12">
                          <h3 className="mb-0 text-center fz-22 alert-link">
                            Profile Information
                          </h3>
                        </Col>
                        <Col className="text-right" xs="4"></Col>
                      </Row>
                    </Card.Header>
                    <Card.Body>
                      <Row>
                        <Col lg="4" md="4" sm="12">
                          <Row className="justify-content-center">
                            <div className="card-profile-image">
                              <div
                                className="imagePreview"
                                style={{
                                  backgroundImage:
                                    "url(" +
                                    `${imagePath ? imagePath : profile}` +
                                    ")",
                                }}
                              ></div>
                              <label className="ad_upload_icon">
                                <TiCamera className="uploadfont" />
                                <input
                                  id="interviewer_profile_photo"
                                  type="file"
                                  accept="image/*"
                                  class="uploadFile img"
                                  style={{
                                    width: "0px",
                                    height: "0px",
                                    overflow: "hidden",
                                  }}
                                  onChange={uploadImage}
                                />
                              </label>
                              <label className="ad_delete_icon">
                                <AiOutlineClose
                                  className="closefont"
                                  onClick={() => setDeletePopupShow(true)}
                                />
                              </label>
                            </div>
                          </Row>
                          <Row className="justify-content-center">
                            <div className="text-center fz-22 alert-link">
                              {firstName} {lastName}
                            </div>
                            {/* <div className="text-center fz-12 font-weight-normal">followers:&nbsp;{}</div> */}
                          </Row>
                        </Col>
                        <Col lg="8" md="8" sm="12">
                          <Form>
                            <div className="pl-lg-4">
                              <Row>
                                <Col lg="6">
                                  <Form.Group className="form-group">
                                    <Form.Label className="mb-1 profile_label">
                                      First Name
                                    </Form.Label>
                                    <Form.Control
                                      type="text"
                                      className={
                                        !firstName
                                          ? "form-control input_ctrl error"
                                          : "form-control input_ctrl"
                                      }
                                      onChange={(e) => {
                                        const value = e.target.value;
                                        const regex = /^[a-zA-Z\s]*$/;
                                        if (regex.test(value)) {
                                          setFirstName(value);
                                        }
                                      }}
                                      value={firstName}
                                      placeholder="first name"
                                    />
                                    {!firstName && (
                                      <p className="fz-13">
                                        First Name is required
                                      </p>
                                    )}
                                  </Form.Group>
                                </Col>
                                <Col lg="6">
                                  <Form.Group className="form-group">
                                    <Form.Label className="mb-1 profile_label">
                                      Last Name
                                    </Form.Label>
                                    <Form.Control
                                      type="text"
                                      className={
                                        !lastName
                                          ? "form-control input_ctrl error"
                                          : "form-control input_ctrl"
                                      }
                                      onChange={(e) => {
                                        const value = e.target.value;
                                        const regex = /^[a-zA-Z\s]*$/;
                                        if (regex.test(value)) {
                                          setLastName(value);
                                        }
                                      }}
                                      value={lastName}
                                      placeholder="last name"
                                    />
                                    {!lastName && (
                                      <p className="fz-13">
                                        Last Name is required
                                      </p>
                                    )}
                                  </Form.Group>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="6">
                                  {phone ? (
                                    <PhoneControl
                                      phoneNo={phone}
                                      isProfile={true}
                                      mandatory={true}
                                      mobileVerify={true}
                                      PopupShow={mobileverifyset}
                                      isverified={mobileVerified}
                                      isPhoneChanged={isPhoneChanged}
                                      onPhoneChange={phoneChange}
                                      phoneValidation={phoneValidation}
                                    />
                                  ) : (
                                    ""
                                  )}
                                </Col>
                                <Col lg="6">
                                  <Form.Group class="form-group required-field-block">
                                    <Form.Label className="mb-1 profile_label">
                                      Email Address
                                    </Form.Label>
                                    <Form.Control
                                      type="email"
                                      className="form-control input_ctrl"
                                      value={email}
                                      disabled
                                      placeholder="email"
                                      name="email"
                                      maxlength="80"
                                    />
                                    {email != "" && (
                                      <span class="required-icon">
                                        {!emailVerified ? (
                                          <a
                                            href="#"
                                            onClick={() =>
                                              setEmailPopupShow(true)
                                            }
                                          >
                                            <BsExclamationCircle
                                              className={
                                                email != "" && emailVerified
                                                  ? ""
                                                  : "text-danger"
                                              }
                                              style={{ fontSize: "20px" }}
                                            />
                                          </a>
                                        ) : (
                                          <a href="#">
                                            <HiCheckCircle
                                              style={{
                                                fontSize: "20px",
                                                color: "green",
                                              }}
                                            />
                                          </a>
                                        )}
                                      </span>
                                    )}
                                  </Form.Group>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="6">
                                  <Form.Group className="form-group">
                                    <Form.Label className="mb-1 profile_label">
                                      City
                                    </Form.Label>
                                    <Form.Control
                                      type="text"
                                      className={
                                        !city
                                          ? "form-control input_ctrl error"
                                          : "form-control input_ctrl"
                                      }
                                      onChange={(e) => setCity(e.target.value)}
                                      value={city}
                                      placeholder="city"
                                    />
                                    {!city && (
                                      <p className="fz-13">City is required</p>
                                    )}
                                  </Form.Group>
                                </Col>
                                <Col lg="6">
                                  <Form.Group className="form-group">
                                    <Form.Label className="mb-1 profile_label">
                                      State
                                    </Form.Label>
                                    <Form.Control
                                      type="text"
                                      className={
                                        !state
                                          ? "form-control input_ctrl error"
                                          : "form-control input_ctrl"
                                      }
                                      onChange={(e) => setState(e.target.value)}
                                      value={state}
                                      placeholder="state"
                                    />
                                    {!state && (
                                      <p className="fz-13">State is required</p>
                                    )}
                                  </Form.Group>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg="6">
                                  <Form.Group
                                    className="form-group"
                                    onKeyPress={(e) =>
                                      !/[0-9]/.test(e.key) && e.preventDefault()
                                    }
                                  >
                                    <Form.Label className="mb-1 profile_label">
                                      Birth Year
                                    </Form.Label>
                                    <Form.Control
                                      type="text"
                                      className={
                                        !year
                                          ? "form-control input_ctrl error"
                                          : "form-control input_ctrl"
                                      }
                                      onChange={(e) => setYear(e.target.value)}
                                      value={year}
                                      placeholder="birth year"
                                      maxLength={4}
                                    />
                                    {!year && (
                                      <p className="fz-13">
                                        Birth Year is required
                                      </p>
                                    )}
                                  </Form.Group>
                                </Col>
                              </Row>
                            </div>
                            <div className="pl-lg-4 pt-3 text-center">
                              <Button
                                onClick={onSubmit}
                                className="btn btn-default update_btn btn-primary float-left"
                              >
                                Update
                              </Button>
                            </div>
                          </Form>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Container>
            {/* ----------- Mobile verification Popup ---------- */}
            {!isMobileCodeSend || isPhoneChanged ? (
              <Modal
                size="sm"
                show={mobilePopupShow}
                onHide={() => setMobilePopupShow(false)}
              >
                <Modal.Header>
                  <Modal.Title
                    id="example-custom-modal-styling-title"
                    style={{ fontSize: "15px", fontWeight: 600 }}
                  >
                    Verify Mobile Number
                  </Modal.Title>
                  <span
                    onClick={() => {
                      setMobilePopupShow(false);
                    }}
                    title="Close"
                  >
                    <AiOutlineClose />
                  </span>
                </Modal.Header>
                <Modal.Body>
                  <Button onClick={verifyMobile}>Send Verification Code</Button>
                </Modal.Body>
              </Modal>
            ) : (
              <Modal
                size="sm"
                show={mobilePopupShow}
                onHide={() => setMobilePopupShow(false)}
              >
                <Modal.Header>
                  <Modal.Title
                    id="example-custom-modal-styling-title"
                    style={{ fontSize: "15px", fontWeight: 600 }}
                  >
                    Verify Mobile Number
                  </Modal.Title>
                  <span
                    onClick={() => {
                      setMobilePopupShow(false);
                    }}
                    title="Close"
                  >
                    <AiOutlineClose />
                  </span>
                </Modal.Header>
                <Modal.Body>
                  <div className="fz_11">
                    Enter the OTP send to Mobile No {phone}
                  </div>
                  <div className="input-group code_Input">
                    <input
                      className="form-control py-2 border border-top-0 fz_13"
                      placeholder="Enter OTP"
                      onChange={(e) => setVerifcationCode(e.target.value)}
                      value={verificationCode}
                      maxLength="6"
                      onKeyPress={(e) =>
                        !/[0-9]/.test(e.key) && e.preventDefault()
                      }
                    />
                  </div>
                  {emptyErr ? <span className="fz-13">Enter OTP</span> : ""}
                  <div className="d-flex justify-content-between mb-2">
                    <div>
                      <span className="fz-12">
                        Didn't receive an OTP?&nbsp;
                        <a
                          href="#"
                          classsName="a_clr fz-12"
                          onClick={verifyMobile}
                        >
                          Resend Code
                        </a>
                      </span>
                    </div>
                    {timerMinutes === "00" && timerSeconds === "00" ? (
                      ""
                    ) : (
                      <div className="float-right fz_13">
                        {timerMinutes === "00" ? (
                          <span className="">
                            {timerMinutes}:{timerSeconds}
                          </span>
                        ) : (
                          <span>
                            {timerMinutes}:{timerSeconds}
                          </span>
                        )}
                      </div>
                    )}
                    {timerMinutes === "00" && timerSeconds === "00" ? (
                      <div className="float-right text-warning fz-12">
                        Code Expired
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <Button onClick={verifyMobileCode} className="fz-12">
                    Verify
                  </Button>
                </Modal.Body>
              </Modal>
            )}
            <Modal
              size="sm"
              show={deletePopupShow}
              onHide={() => setDeletePopupShow(false)}
            >
              <Modal.Header>
                <Modal.Title
                  id="example-custom-modal-styling-title"
                  style={{ fontSize: "15px", fontWeight: 600 }}
                >
                  Delete Confirmation
                </Modal.Title>
                <span onClick={() => setDeletePopupShow(false)} title="Close">
                  <AiOutlineClose />
                </span>
              </Modal.Header>
              <Modal.Body className="bordr">
                <span>Are you sure to delete this profile image?</span>
              </Modal.Body>
              <Modal.Footer className="bordr">
                <Button
                  variant="primary"
                  className="del_bttn"
                  onClick={deleteImage}
                >
                  Yes
                </Button>
                <Button
                  variant="danger"
                  className="del_bttn"
                  onClick={() => setDeletePopupShow(false)}
                >
                  No
                </Button>
              </Modal.Footer>
            </Modal>
            {/* -----------Email verification Popup ---------- */}
            {!isEmailCodeSend ? (
              <Modal
                size="sm"
                show={emailPopupShow}
                onHide={() => setEmailPopupShow(false)}
              >
                <Modal.Header>
                  <Modal.Title
                    id="example-custom-modal-styling-title"
                    style={{ fontSize: "15px", fontWeight: 600 }}
                  >
                    Verify Email Id
                  </Modal.Title>
                  <span
                    onClick={() => {
                      setEmailPopupShow(false);
                    }}
                    title="Close"
                  >
                    <AiOutlineClose />
                  </span>
                </Modal.Header>
                <Modal.Body>
                  <Button onClick={verifyEmail}>Send Verification Code</Button>
                </Modal.Body>
              </Modal>
            ) : (
              <Modal
                size="sm"
                show={emailPopupShow}
                onHide={() => setEmailPopupShow(false)}
              >
                <Modal.Header className="p-3">
                  <Modal.Title
                    id="example-custom-modal-styling-title"
                    className="mb-4"
                    style={{ fontSize: "15px", fontWeight: 600 }}
                  >
                    Verify Email Id
                  </Modal.Title>
                  <span
                    onClick={() => {
                      setEmailPopupShow(false);
                    }}
                    title="Close"
                  >
                    <AiOutlineClose />
                  </span>
                </Modal.Header>
                <Modal.Body className="p-3">
                  <div className="fz_11">
                    Enter the OTP send to Email id {email}
                  </div>
                  <div className="input-group code_Input">
                    <input
                      className="form-control py-2 border border-top-0 fz_13"
                      placeholder="Enter OTP"
                      onChange={(e) => setVerifcationCode(e.target.value)}
                      value={verificationCode}
                      maxLength="6"
                      onKeyPress={(e) =>
                        !/[0-9]/.test(e.key) && e.preventDefault()
                      }
                    />
                  </div>
                  {emptyErr ? <span className="fz-13">Enter OTP</span> : ""}
                  <div className="d-flex justify-content-between mb-2">
                    <div>
                      <span className="fz-12">
                        Didn't receive an OTP?&nbsp;
                        <a
                          href="#"
                          classsName="a_clr fz-12"
                          onClick={verifyEmail}
                        >
                          Resend Code
                        </a>
                      </span>
                    </div>
                    {timerMinutes === "00" && timerSeconds === "00" ? (
                      ""
                    ) : (
                      <div className="float-right fz_13">
                        {timerMinutes === "00" ? (
                          <span className="">
                            {timerMinutes}:{timerSeconds}
                          </span>
                        ) : (
                          <span>
                            {timerMinutes}:{timerSeconds}
                          </span>
                        )}
                      </div>
                    )}
                    {timerMinutes === "00" && timerSeconds === "00" ? (
                      <div className="float-right text-warning fz-12">
                        Code Expired
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <Button onClick={verifyEmailCode} className="fz-12">
                    Verify
                  </Button>
                </Modal.Body>
              </Modal>
            )}
          </div>
        ) : (
          <div className="pro-loader-center">
            <img className="loader-image" src={loader} alt="Loading..." />
          </div>
        )}
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    userType: state.user.userType,
  };
};
export default connect(mapStateToProps)(Profile);
