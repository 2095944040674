import React, { useState, useRef } from "react";
import CustomModal from "../../plugins/CustomModal";
import profile from "../../../assets/images/profile.jpg";
import { useForm } from "react-hook-form";
import { BsImages } from "react-icons/bs";
import { BiImageAdd } from "react-icons/bi";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { Button, Card } from "react-bootstrap";
import { useToast } from "../../Toast";
import { useLocation } from "react-router-dom";
import loader from "../../../assets/images/loader.gif";

const AddPost = ({ props, userData }) => {
    const [showModal, setShowModal] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [imageDivShow, setImageDivShow] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const location = useLocation();
    const fileInputRef = useRef(null);
    const toast = useToast();

    const { register, handleSubmit, reset, formState: { errors, isValid } } = useForm();

    const handleImageChange = (e) => {
        const files = Array.from(e.target.files);
        const validFormats = /\.(jpg|jpeg|png|svg|mp4)$/i;

        for (const file of files) {
            if (!validFormats.test(file.name)) {
                toast.error("Invalid file format");
                return false;
            }
        }

        setSelectedFiles(files);
    };

    const rmvFile = (index) => {
        const remainingFiles = selectedFiles.filter((file, idx) => idx !== index);
        setSelectedFiles(remainingFiles);
    };

    const handleButtonClick = (e) => {
        e.preventDefault();
        fileInputRef.current.click();
    };

    const validateContent = (value) => {
        const areFilesSelected = selectedFiles.length > 0;
        if (!(value && value.trim()) && !areFilesSelected) {
            return false;
        }
        return true;
    };

    const uploadPostFiles = async (id) => {
        if (selectedFiles.length > 0) {
            let formData = new FormData();
            for (const row of Object.keys(selectedFiles)) {
                let file = selectedFiles[row];
                formData.append("files", file);
                let maxSizeInBytes;
                if (file.type.startsWith("video/")) {
                    maxSizeInBytes = 100 * 1024 * 1024;
                } else {
                    maxSizeInBytes = 10 * 1024 * 1024;
                }
                if (file.size > maxSizeInBytes) {
                    toast.info(
                        `File size exceeds the limit (${maxSizeInBytes / (1024 * 1024)} MB)`
                    );
                    continue;
                }
            }
            formData.append("postedBy", props?.userId);
            formData.append("id", id);
            try {
                const response = await window.apiService.uploadPostFile(formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                });

                if (response.data.status == "OK") {
                    setSelectedFiles([]);
                    setIsLoading(false);
                } else if (response.data.status == "EXPECTATION_FAILED") {
                    setIsLoading(false);
                }
            } catch (error) {
                console.log(error);
            } finally {
                setIsLoading(false);
            }
        }
    };


    const onSubmit = async (data, e) => {
        e.preventDefault();
        setIsLoading(true);
        const postInfo = {
            comment: data.content,
            postedBy: props?.userId,
            postedTo: userData?.id ? userData?.id : props?.userId,
            userType: props?.userType,
            businessId: props?.userType === "Business" ? props?.profileId : null,
            toBusinessId: userData?.userType === "Business" ? userData?.profileId : null,
            filePath: null,
            isDeleted: 0,
        };
        try {
            const response = await window.apiService.addPost(postInfo);
            if (response.data.status == "OK") {
                if (selectedFiles.length > 0) await uploadPostFiles(response.data.id);
                toast.success(response.data.message);
                reset();
                setIsLoading(false);
                setShowModal(false);
                setImageDivShow(false);
                window.dispatchEvent(new Event("reloadPostList"));
                setSelectedFiles([]);
            }
        } catch (error) {
            setIsLoading(false);
            toast.error("Error Occured");
        }
    };


    const handleDrop = (e) => {
        e.preventDefault();
        const files = Array.from(e.dataTransfer.files);
        const validFormats = /\.(jpg|jpeg|png|svg)$/i;
        for (const file of files) {
            if (!validFormats.test(file.name)) {
                toast.error("Invalid file format");
                return false;
            }
        }
        setSelectedFiles(files);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleCloseClick = (e) => {
        e.stopPropagation();
        setImageDivShow(false);
    };

    return (
        <>
            <div className={location.pathname === "/dashboard" ? "d-flex" : "d-flex post"} >
                <div className="d-flex add_post_div gp_10 m-2">
                    <form onSubmit={handleSubmit(onSubmit)} className="w-100">
                        {imageDivShow && (
                            <div className="mb-2">
                                {selectedFiles.length === 0 && (
                                    <div className="w-100">
                                        <Card className="p-2">
                                            <div className="text-end">
                                                <AiOutlineCloseCircle
                                                    className="social_div fz-22 c_pointer"
                                                    onClick={handleCloseClick}
                                                />
                                            </div>
                                            <div className="d-flex justify-content-center align-items-center App h_100 p-2">
                                                <div
                                                    className="text-center c_pointer"
                                                    onClick={handleButtonClick}
                                                    onDrop={handleDrop}
                                                    onDragOver={handleDragOver}
                                                >
                                                    <BiImageAdd className="display-3 rounded p-1 gble_bg_clr rounded-circle" />
                                                    <h6>Add photos/videos</h6>
                                                    <p className="mb-0 fz_13 font-weight-400 gray_clr">
                                                        or drag and drop
                                                    </p>
                                                </div>
                                            </div>
                                        </Card>
                                        <input
                                            ref={fileInputRef}
                                            type="file"
                                            multiple
                                            accept="image/jpeg, image/png, image/jpg, video/*"
                                            className="uploadFile img"
                                            style={{ display: "none" }}
                                            onChange={handleImageChange}
                                        />
                                    </div>
                                )}
                                {selectedFiles.length > 0 &&
                                    selectedFiles.map((file, i) => (
                                        <div className="image-preview m-2" key={i}>
                                            <div className="text-end m_r23">
                                                <AiOutlineCloseCircle
                                                    className="position-absolute z_idx_1 Bs_cam gray_clr"
                                                    onClick={() => rmvFile(i)}
                                                />
                                            </div>
                                            {file.type.startsWith("video/") ? (
                                                <video controls width="100%" height="200">
                                                    <source
                                                        src={URL.createObjectURL(file)}
                                                        type={file.type}
                                                    />
                                                    Your browser does not support the video tag.
                                                </video>
                                            ) : (
                                                <img
                                                    src={URL.createObjectURL(file)}
                                                    alt={`Selected ${i}`}
                                                />
                                            )}
                                        </div>
                                    ))}
                            </div>
                        )}

                        <div className="d-flex align-items-center gp_10">
                            <img
                                src={props?.imagePath ? props?.imagePath : profile}
                                height={35}
                                width={35}
                                className="rounded-circle"
                            />
                            <textarea
                                placeholder="Type something..."
                                className="post-textarea"
                                {...register("content", { validate: validateContent })}
                            />
                            {!imageDivShow && (
                                <BsImages
                                    className="c_pointer"
                                    style={{ color: "#02788b", fontSize: "35px" }}
                                    onClick={() => setImageDivShow(true)}
                                />
                            )}
                            <Button
                                disabled={!isValid && selectedFiles.length === 0}
                                type="submit"
                                className="btn btn-default"
                            >
                                Post
                            </Button>
                        </div>
                    </form>

                    {isLoading && (
                        <div className="pro-loader-center">
                            <img className="loader-image" src={loader} alt="Loading..." />
                        </div>
                    )}

                </div>
            </div>

        </>
    );
};

export default AddPost;
