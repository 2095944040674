import { Sidebar } from "./sidebar";
import { Chat } from "./chat";
import "./inbox.css";
export function Inbox() {
  return (
    <div className="inbox">
      <div className="container pl-0 pr-0">
        <Sidebar />
      </div>
    </div>
  );
}

export default Inbox;
