import Inbox from "../inbox/index";
import HeaderMenu from "../headerMenu";
import AdminHeaderMenu from "../../Admin/HeaderMenu";
import SupportHeaderMenu from "../../Support/HeaderMenu";
import SideBar from "../SideBar";

import AdminSideBar from "../../Admin/AdminSideBar";
import SupportSideBar from "../../Support/SupportSideBar";
import BreadCrumb from "../../plugins/Breadcrumb";
import { useParams } from "react-router-dom";
const InboxTab = () => {
  const { typeId } = useParams();
  return (
    <>
      {typeId == 3 ? (
        <HeaderMenu />
      ) : typeId == 1 ? (
        <AdminHeaderMenu />
      ) : (
        <SupportHeaderMenu />
      )}
      <div className="">
        {typeId == 3 ? (
          <SideBar />
        ) : typeId == 1 ? (
          <AdminSideBar />
        ) : (
          <SupportSideBar />
        )}
        <div className="ml_200">
          <Inbox />
        </div>
      </div>
    </>
  );
};

export default InboxTab;
