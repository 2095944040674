import React, { useState } from "react";
import { googleLogout, useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { useToast } from "../../Toast";
import { userAction } from "../../../Redux/Actions/userAction";
import LoaderOverlay from "../../plugins/LoaderOverlay";

import googleimg from '../../../assets/images/googleimg.png'

function GoogleLoginButton() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();
  const [profile, setProfile] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const login = useGoogleLogin({
    onSuccess: (response) => onGoogleResponse(response),
    onError: (error) => console.log("Login Failed:", error),
  });

  const onGoogleResponse = async (response) => {
    setIsLoading(true);
    console.log("Google response received:", response);

    const token = response;

    if (token.access_token) {
      try {
        const { data: userInfo } = await axios.get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${token.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${token.access_token}`,
              Accept: "application/json",
            },
          }
        );

        setProfile(userInfo);

        const socialLoginData = {
          socialLoginId: userInfo.id,
          email: userInfo.email,
          firstName: userInfo.given_name,
          lastName: userInfo.family_name,
          socialLoginProvider: "google",
        };

        await GoogleAuth(socialLoginData);
      } catch (error) {
        console.log("Error fetching user info:", error);
        toast.error("Failed to fetch user information.");
      }
    }
  };

  const GoogleAuth = async (socialLoginData) => {
    console.log("Starting GoogleAuth");

    try {
      const response = await window.apiService.socialAuth(socialLoginData);
      console.log("Response received:", response);

      if (response.data.result === true) {

        console.log("Response result is true");
        const googlelogs = response.data.data;
        console.log("Google logs:", googlelogs);

        // Dispatch user action after successful login
        dispatch(userAction(googlelogs));

        // Navigate to the dashboard
        navigate("/dashboard");
        console.log("Navigating to dashboard");
      } else {
        console.log("Response result is false");
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Error caught:", error);
      toast.error("Error Occurred during authentication.");
    }
  };

  const logOut = () => {
    googleLogout();
    setProfile(null);
    toast.success("Logged out successfully.");
  };

  return (
    <div>
      <LoaderOverlay isLoading={isLoading} />

      <div class="google-btn" onClick={login}>
        <img
          src={googleimg}
          alt="Google icon"
          class="google-icon"
        />
        Login with Google
      </div>
      {/* You can uncomment the following code to display user info after login */}
      {/* 
        {profile ? (
          <div>
            <img src={profile.picture} alt="user" />
            <h3>User Logged in</h3>
            <p>Name: {profile.name}</p>
            <p>Email Address: {profile.email}</p>
            <br />
            <button onClick={logOut}>Log out</button>
          </div>
        ) : (
          <button onClick={login}>Sign in with Google</button>
        )} 
      */}
    </div>
  );
}

export default GoogleLoginButton;
