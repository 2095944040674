import React from "react";
import "../Register/register.css";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useToast } from "../Toast";
import { Form, InputGroup } from "react-bootstrap";
import Header from "../../layout/header";
import { useState, useRef, useEffect } from "react";
import LoaderOverlay from "../plugins/LoaderOverlay";
import { GrMail } from "react-icons/gr";
import { BiSolidLockOpen } from "react-icons/bi";
import { Button, Row, Col } from "reactstrap";
import { useDispatch } from "react-redux";
import { userAction } from "../../Redux/Actions/userAction";
import GoogleLoginButton from "../Login/sociallogin/Googlelogin";
import { IoEye, IoEyeOff } from "react-icons/io5";
import { commonUtils } from "../CommonUtils";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { useAuth } from '../AuthContext';
import { useSubscription } from '../SubscriptionContext';
const Login = () => {
  const dispatch = useDispatch();
  const { login } = useAuth();
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue
  } = useForm();
  const navigate = useNavigate();
  const [ischecked, setIschecked] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const clientid = process.env.REACT_APP_GOOGLE_CLIENT_ID
  const { refreshSubscriptions } = useSubscription();
  useEffect(() => {
    const savedEmail = localStorage.getItem("rememberMeEmail");
    const savedPassword = localStorage.getItem("rememberMePassword");
    if (savedEmail && savedPassword) {
      setValue("email", savedEmail);
      setValue("password", savedPassword);
      setIschecked(true);
    }
  }, [setValue]);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      const response = await window.apiService.userAuthentication(data);
      setIsLoading(false);
      if (response.data.result) {
        dispatch(userAction(response.data.data));
        login(response.data.data.userId);
        refreshSubscriptions();
        if (response.data.data.userSettings.twoFactorEnabled) {
          localStorage.removeItem("remainingTime");
          navigate("/two-factor-authentication", {
            state: {
              userId: response.data.data.userId,
              phone: response.data.data.phone ? response.data.data.phone : "",
              email: response.data.data.email,
              method: response.data.data.userSettings.twoFactorMethod,
            },
          });
        } else {
          navigate("/dashboard");
        }
        if (ischecked) {
          // Save email and password in local storage if "Remember Me" is checked
          localStorage.setItem("rememberMeEmail", data.email);
          localStorage.setItem("rememberMePassword", data.password);
        } else {
          // Remove email and password from local storage if unchecked
          localStorage.removeItem("rememberMeEmail");
          localStorage.removeItem("rememberMePassword");
        }
      } else {
        if (response.data.message == "Invalid Password.") {
          toast.error(response.data.message);
        } else {
          if (response.data.message == "User not Exists.") {
            toast.info(response.data.message);
          }
        }
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      toast.error("Error Occured");
    }
  };
  return (
    <>
      <LoaderOverlay isLoading={isLoading} />
      <div>
        <Row>
          <Col lg="12" md="12" sm="12" xl="12">
            <Row>
              <Col lg="5" md="5" sm="12" xl="5" className="log_bg log_text">
                {commonUtils.LeftDiv()}
              </Col>
              <Col
                lg="7"
                md="7"
                sm="12"
                xl="7"
                style={{ backgroundColor: "white" }}
              >
                <Header />
                <div className="d-flex justify-content-center align-items-center copyright bg-content">
                  <Col lg="7" md="7" sm="12" xl="5">
                    <div className="mt-2">
                      <h4>Login</h4>
                    </div>
                    <div style={{ paddingTop: "0px!important" }}>
                      <Form onSubmit={handleSubmit(onSubmit)}>
                        <Form.Group
                          controlId="email"
                          className="log_form form-group"
                        >
                          <div className="inputContainer">
                            <label className="fz_14">Email</label>
                            <InputGroup
                              className={`${errors.email ? "is-invalid" : "inputBox"
                                }`}
                            >
                              <InputGroup.Text className="border-0">
                                <GrMail />
                              </InputGroup.Text>
                              <Form.Control
                                className="formControl fz_13"
                                type="text"
                                placeholder="Email"
                                name="email"
                                id="log_mail"
                                {...register("email", {
                                  required: "Email is required",
                                  pattern: {
                                    value:
                                      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                    message: "Invalid email address",
                                  },
                                })}
                              />
                            </InputGroup>
                            {errors.email && (
                              <Form.Text className="text-danger ">
                                {errors.email.message}
                              </Form.Text>
                            )}
                          </div>
                        </Form.Group>
                        <Form.Group
                          controlId="password"
                          className="log_form form-group"
                        >
                          <div className="inputContainer">
                            <label className="fz_14">Password</label>
                            <InputGroup
                              className={` ${errors.password ? "is-invalid" : "inputBox"
                                }`}
                            >
                              <InputGroup.Text className="border-0">
                                <BiSolidLockOpen />
                              </InputGroup.Text>
                              <Form.Control
                                className="formControl fz_13"
                                type={showPassword ? "text" : "password"}
                                placeholder="Password"
                                name="password"
                                id="log_pass"
                                {...register("password", {
                                  required: "Password is required",
                                  minLength: {
                                    value: 6,
                                    message:
                                      "Password must be at least 6 characters!",
                                  },
                                })}
                              />
                              <InputGroup.Text className="border-0">
                                {!showPassword ? (
                                  <IoEye
                                    className="c_pointer"
                                    onClick={togglePasswordVisibility}
                                  />
                                ) : (
                                  <IoEyeOff
                                    className="c_pointer"
                                    onClick={togglePasswordVisibility}
                                  />
                                )}
                              </InputGroup.Text>
                            </InputGroup>
                            {errors.password && (
                              <Form.Text className="text-danger">
                                {errors.password.message}
                              </Form.Text>
                            )}
                          </div>
                        </Form.Group>
                        <div className="d-flex justify-content-between">
                          <div>
                            <input
                              type="checkbox"
                              checked={ischecked}
                              className="lsRememberMe"
                              onChange={() => setIschecked(!ischecked)}
                            />
                            <small className="text-light">
                              &nbsp;Remember me
                            </small>
                          </div>
                          <div>
                            {" "}
                            <a
                              href="#"
                              onClick={() => navigate("/forgotpassword")}
                            >
                              <div
                                className="Acc_sign"
                                style={{ color: "#000!important" }}
                              >
                                Forgot password?
                              </div>
                            </a>
                          </div>
                        </div>
                        <div className="text-center">
                          <Button
                            variant="primary"
                            type="submit"
                            className="login"
                          >
                            Login
                          </Button>
                        </div>
                        <div className="text-center">
                          <hr class="hr-text gradient" data-content="OR" />
                          <GoogleOAuthProvider clientId={clientid}>
                            <GoogleLoginButton />

                          </GoogleOAuthProvider>
                        </div>
                        <div className="Acc_sign">
                          Don't have an account?{" "}
                          <a href="#" onClick={() => navigate("/registration")}>
                            Sign Up
                          </a>
                        </div>
                        <div className="text-center pdb_8">
                          {/* <Button onClick={() => navigate('/registration')} variant="secondary" type="button" className="signupButton">
                    Sign Up
                  </Button> */}
                        </div>
                      </Form>
                    </div>
                  </Col>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Login;