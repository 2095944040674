const GroupsTab = () => {
  return (
    <div className="row col-md-12 p-0 d-align mx-0">
      <div className="no-data w-100 font-weight-600 text-center fz_14">
        Work In Progress...
      </div>
    </div>
  );
};

export default GroupsTab;
