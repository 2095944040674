import { Card, Col, Row, Modal, Button } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { useToast } from "../../Toast";
import { BsPlusLg } from "react-icons/bs";
import { FaTimes } from "react-icons/fa";
import { TiTick } from "react-icons/ti";
import SideBar from "../AdminSideBar";
import HeaderMenu from "../HeaderMenu";
import BreadCrumb from "../../plugins/Breadcrumb";
import { Input } from "reactstrap";
import editRole from "../../../assets/images/usr_edit.png";
import ProfessionalTypeAccess from "./ProfessionalTypeAccess";
import { HiOutlineTrash } from "react-icons/hi";
import { AiOutlineClose } from "react-icons/ai";
import roleAccess from "../../../assets/images/usr_ques.png";
import accessActive from "../../../assets/images/ques_active.png";

const ProfessionalTypes = () => {
  const [roleList, setRoleList] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [editedType, setEditedType] = useState("");
  const [newProfType, setNewProfType] = useState("");
  const [profTypeError, setProfTypeError] = useState("");
  const [deletePopupShow, setDeletePopupShow] = useState(false);
  const [id, setId] = useState(null);
  const [activeRoleId, setActiveRoleId] = useState(null);
  const [roleName, setRoleName] = useState("");
  const handleClose = () => setDeletePopupShow(false);
  const toast = useToast();
  useEffect(() => {
    loadProfessionalList();
  }, []);
  const loadProfessionalList = async () => {
    try {
      const response = await window.apiService.professionalList({
        userTypeId: 2,
      });
      setRoleList(response.data.recordInfo);
    } catch (error) {
      console.log(error);
    }
  };
  const addUserRole = async () => {
    setProfTypeError("");
    if (!newProfType) {
      setProfTypeError("Professional Type is Required.");
      return;
    }
    const params = {
      userRole: newProfType,
      userTypeId: 2,
    };
    try {
      const response = await window.apiService.userRoleAdd(params);
      if (
        response.data.status == "OK" &&
        response.data.message != "Profession already exists."
      ) {
        toast.success(response.data.message);
        setNewProfType("");
        loadProfessionalList();
      } else if (response.data.message == "Profession already exists.") {
        toast.info(response.data.message);
        setNewProfType("");
        loadProfessionalList();
      } else {
        toast.error("Error Occured");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const updateUserRole = async () => {
    const params = {
      userRole: editedType.name,
      userTypeId: 2,
    };
    try {
      const response = await window.apiService.editUserRole(
        editedType.id,
        params
      );
      if (
        response.data.status == "OK" &&
        response.data.message != "Profession already exists."
      ) {
        toast.success(response.data.message);
        setEditMode(false);
        setEditedType("");
        loadProfessionalList();
      } else if (response.data.message == "Profession already exists.") {
        toast.info(response.data.message);
        setEditMode(false);
        setEditedType("");
        loadProfessionalList();
      } else {
        toast.error("Error Occured");
      }
    } catch (error) {
      console.log(error);
      toast.error("Error Occured");
    }
  };
  const showDeletePopup = (id) => {
    setId(id);
    setDeletePopupShow(true);
  };
  const deleteProfessionalType = async () => {
    if (id) {
      try {
        const response = await window.apiService.deleteUserRole(id);
        if (response.data.status == "OK") {
          toast.success(response.data.message);
          loadProfessionalList();
          setDeletePopupShow(false);
        }
      } catch (error) {
        console.log(error);
        setDeletePopupShow(false);
      }
    }
  };

  return (
    <>
      <HeaderMenu />
      <SideBar />
      <div className="main-content ml_200">
        {/* <BreadCrumb /> */}
        <Card className="shade h-500">
          <Row>
            <Col sm={12} md={12} lg={5} xl={5}>
              <div className="role_container">
                <Row className="m_b18 title_txt">
                  <Col sm={12} md={12} lg={12} xl={12}>
                    Manage Professional Types
                  </Col>
                </Row>
                <Row className="m_b8">
                  <Col sm={6} md={9} lg={9} xl={9} className="custom-col">
                    <Input
                      className="inptRole rle_place"
                      placeholder={
                        editMode
                          ? "Edit Professional Type"
                          : "Enter a new Professional Type"
                      }
                      type="text"
                      value={editMode ? editedType.name : newProfType}
                      onChange={(e) => {
                        if (editMode) {
                          setEditedType({
                            ...editedType,
                            name: e.target.value,
                          });
                        } else {
                          setNewProfType(e.target.value);
                          setProfTypeError("");
                        }
                      }}
                    />
                    {profTypeError && (
                      <div className="text-danger">{profTypeError}</div>
                    )}
                  </Col>
                  <Col
                    sm={6}
                    md={3}
                    lg={3}
                    xl={3}
                    className="align-items-center custom-col-icn d-flex p-0"
                  >
                    {editMode ? (
                      <>
                        <TiTick
                          className="c_pointer plus_icn m_r15"
                          onClick={updateUserRole}
                        />
                        <FaTimes
                          className="c_pointer close_icn"
                          onClick={() => {
                            setEditMode(false);
                            setEditedType("");
                          }}
                        />
                      </>
                    ) : (
                      <BsPlusLg onClick={addUserRole} className="c_pointer " />
                    )}
                  </Col>
                </Row>
                {roleList &&
                  roleList.map((role) => (
                    <Row>
                      <Col sm={6} md={9} lg={9} xl={9} className="custom-col">
                        <Input
                          placeholder="Enter a new Role"
                          className="rle_place m_b8 inptRole"
                          type="text"
                          value={role.name}
                        ></Input>
                      </Col>
                      <Col
                        sm={6}
                        md={3}
                        lg={3}
                        xl={3}
                        className="align-items-center custom-col-icn p-0"
                      >
                        <div className="d-flex">
                          <div className="m_r15">
                            <span
                              onClick={() => {
                                setEditMode(true);
                                setEditedType(role);
                                setProfTypeError("");
                              }}
                              title="Edit"
                              className="c_pointer"
                            >
                              <img src={editRole} />
                            </span>
                          </div>
                          {/* <div className='m_r15'><FaTrashCan title="Delete" style={{ color: '#f16969' }}  className="c_pointer icon_usrRole"/></div>                     */}
                          <div className="m_r15">
                            <HiOutlineTrash
                              onClick={() => showDeletePopup(role.id)}
                              title="Delete"
                              className="ques_trash c_pointer"
                            />
                          </div>
                          <div>
                            <span
                              title="Mapping"
                              className="c_pointer"
                              onClick={() => {
                                if (activeRoleId === role.id) {
                                  setActiveRoleId(null);
                                } else {
                                  setActiveRoleId(role.id);
                                }
                                setRoleName(role.name);
                              }}
                            >
                              {activeRoleId === role.id ? (
                                <img src={accessActive} />
                              ) : (
                                <img src={roleAccess} />
                              )}
                            </span>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  ))}
              </div>
            </Col>
            <Col sm={12} md={12} lg={7} xl={7} className="mt_53 p-0">
              {activeRoleId && (
                <ProfessionalTypeAccess id={activeRoleId} name={roleName} />
              )}
            </Col>
          </Row>
        </Card>
      </div>
      {/* ----------- Delete Confirmation Popup ---------- */}
      <Modal
        size="sm"
        show={deletePopupShow}
        onHide={() => setDeletePopupShow(false)}
      >
        <Modal.Header>
          <Modal.Title
            id="example-custom-modal-styling-title"
            style={{ fontSize: "15px", fontWeight: 600 }}
          >
            Delete Confirmation
          </Modal.Title>
          <span onClick={handleClose} title="Close">
            <AiOutlineClose />
          </span>
        </Modal.Header>
        <Modal.Body className="bordr">
          <span>Are you sure to delete?</span>
        </Modal.Body>
        <Modal.Footer className="bordr">
          <Button
            variant="primary"
            className="del_bttn"
            onClick={deleteProfessionalType}
          >
            Yes
          </Button>
          <Button
            variant="danger"
            className="del_bttn"
            onClick={() => setDeletePopupShow(false)}
          >
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ProfessionalTypes;
