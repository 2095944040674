import HeaderMenu from "../headerMenu";
import SideBar from "../SideBar";
import { Button, Card, Col, Row, Container } from "react-bootstrap";
import { useEffect, useState } from "react";
import loader from "../../../assets/images/loader.gif";
import { useToast } from "../../Toast";
import profile from "../../../assets/images/profile.jpg";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import BreadCrumb from "../../plugins/Breadcrumb";
import { useDispatch } from "react-redux";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import moment from "moment";
import { userAction } from "../../../Redux/Actions/userAction";
const Notification = (props) => {
  const [reviewList, setReviewList] = useState([]);
  const [cmtList, setCmtList] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();
  const loadRevNotifyList = async () => {
    setLoading(true);
    try {
      const response = await window.apiService.revMentionListById({
        userId: props.userId,
        professionalId:
          props.userType == "Professional" ? props.profileId : null,
        businessId: props.userType == "Business" ? props.profileId : null,
      });
      let result = response.data.recordInfo;
      setReviewList(result);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Error occurred");
    }
  };
  const updateReadStatus = async (id) => {
    const params = {
      id: id,
    };
    try {
      const response = await window.apiService.updateRevMentionSts(params);
      if (response.data.status == "OK") {
        loadRevNotifyList();
        Refresh();
      }
    } catch (error) {
      toast.error("Error Occured");
    }
  };
  const updatecmtReadStatus = async (id) => {
    const params = {
      id: id,
    };
    try {
      const response = await window.apiService.updateCmtMentionSts(params);
      if (response.data.status == "OK") {
        loadCmtNotifyList();
        Refresh();
      }
    } catch (error) {
      toast.error("Error Occured");
    }
  };
  const handleViewClick = (review) => {
    navigate("/dashboard/notification/view", {
      state: { type: "review", revId: review.id },
    });
    updateReadStatus(review.reviewMentionId);
  };
  const handleCmtClick = (cmt) => {
    navigate("/dashboard/notification/view", {
      state: { type: "comment", status: cmt.status, revId: cmt.id },
    });
    updatecmtReadStatus(cmt.commentMentionId);
  };
  const loadCmtNotifyList = async () => {
    try {
      const response = await window.apiService.cmtMentionListById({
        userId: props.userId,
        professionalId:
          props.userType == "Professional" ? props.profileId : null,
        businessId: props.userType == "Business" ? props.profileId : null,
      });
      let result = response.data.recordInfo;
      setCmtList(result);
    } catch (error) {
      toast.error("Error Occured");
    }
  };
  useEffect(() => {
    loadRevNotifyList();
    loadCmtNotifyList();
  }, [props]);

  const Refresh = async () => {
    if (props.authToken) {
      const switchAccount = localStorage.getItem("switchAccount");
      const params = {
        profileId: props.profileId,
        authToken: props.authToken,
        userType: props.userType ? props.userType : "Personal",
        switchAccount: switchAccount ? switchAccount : false,
      };
      try {
        const data = await window.apiService.refresh(params);
        let result = data.data;
        if (data) {
          dispatch(userAction(result));
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <>
      <HeaderMenu />
      <SideBar />
      <div className="main-content ml_200">
        {/* <BreadCrumb /> */}
        <Card className="shade h-500">
          <Container>
            <Tabs defaultActiveKey="review" className="mb-3 notify_tab">
              <Tab eventKey="review" title="Reviews">
                {reviewList.length > 0 ? (
                  reviewList.map((review, i) => (
                    <Card
                      className={`notify_card_rev ${review.isRead == true
                          ? "not_crd_bg_rd crd_shd"
                          : "not_crd_bg shadow"
                        }`}
                      onClick={() => {
                        handleViewClick(review);
                      }}
                    >
                      <Card.Body className="p-0">
                        <Row>
                          <span className="noty_bar_men p-0"></span>
                          <Col sm={2} md={2} lg={1} xl={1}>
                            <div className="follow_profile m-2">
                              <img
                                src={
                                  review.reviewerImagePath
                                    ? review.reviewerImagePath
                                    : profile
                                }
                                className="rounded-circle"
                                width="50"
                                height="50"
                              />
                            </div>
                          </Col>
                          <Col sm={6} md={7} lg={8} xl={8} className="m-2">
                            <p className="fz_15 font-weight-normal mb-0">
                              you have a mentioned review by{" "}
                              <b style={{ color: "#ac3737" }}>
                                {review.reviewerName}
                              </b>
                            </p>
                            <Row>
                              <div className="d-flex">
                                <div
                                  style={{ fontSize: "11px", color: "#959595" }}
                                >
                                  {moment
                                    .utc(review.createdAt)
                                    .local()
                                    .startOf("seconds")
                                    .fromNow()}
                                </div>
                              </div>
                            </Row>
                          </Col>
                          <Col sm={2} md={2} lg={2} xl={2}>
                            <span className="float-right text-center mt-2 notify_btn_men fz_13">
                              View
                            </span>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  ))
                ) : loading ? (
                  <div className="text-center">
                    <img
                      className="loader-image"
                      src={loader}
                      alt="Loading..."
                    />
                  </div>
                ) : (
                  <div className="row col-md-12 d-align mx-0">
                    <div className="no-data w-100 text-center font-weight-600 fz_14">
                      Sorry, there is no data to be displayed
                    </div>
                  </div>
                )}
              </Tab>
              <Tab eventKey="mention" title="Comments">
                {cmtList.length > 0 ? (
                  cmtList.map((cmt, i) => (
                    <Card
                      className={`notify_card_men ${cmt.isRead == true
                          ? "not_crd_bg_rd crd_shd"
                          : "not_crd_bg shadow"
                        }`}
                      onClick={() => {
                        handleCmtClick(cmt);
                      }}
                    >
                      {/* {cmtList.length>0 ? cmtList.map((cmt, i) => (<Card className="shadow notify_card_men"> */}
                      <Card.Body className="p-0">
                        <Row>
                          <span className="noty_bar_rev p-0"></span>
                          <Col sm={2} md={2} lg={1} xl={1}>
                            <div className="follow_profile m-2">
                              <img
                                src={
                                  cmt.userImagePath
                                    ? cmt.userImagePath
                                    : profile
                                }
                                className="rounded-circle"
                                width="50"
                                height="50"
                              />
                            </div>
                          </Col>
                          <Col sm={6} md={7} lg={8} xl={8} className="m-2">
                            <p className="fz_15 font-weight-normal mb-0">
                              you have a mentioned comment by{" "}
                              <b style={{ color: "#a0ac37" }}>{cmt.userName}</b>
                            </p>
                            <Row>
                              <div className="d-flex">
                                <div
                                  style={{ fontSize: "11px", color: "#959595" }}
                                >
                                  {moment
                                    .utc(cmt.createdAt)
                                    .local()
                                    .startOf("seconds")
                                    .fromNow()}
                                </div>
                              </div>
                            </Row>
                          </Col>
                          <Col sm={2} md={2} lg={2} xl={2}>
                            <span className="float-right text-center mt-2 notify_btn_rev fz_13">
                              View
                            </span>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  ))
                ) : (
                  <div className="row col-md-12 d-align mx-0">
                    <div className="no-data w-100 text-center font-weight-600 fz_14">
                      Sorry, there is no data to be displayed
                    </div>
                  </div>
                )}
              </Tab>
            </Tabs>
          </Container>
        </Card>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    authToken: state.user.token,
    profileId: state.user.profileId,
    userType: state.user.userType,
  };
};

export default connect(mapStateToProps)(Notification);
