import { Col, Container, Image, Nav, Row } from "react-bootstrap";
export default function Footer() {
  return (
    <div className="footer_div">
      <div className="copyright">
        <p>Copyright © 2023 tiipstr - All Rights Reserved.</p>
      </div>
    </div>
  );
}
