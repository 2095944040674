import React, { useState, useEffect } from "react";
import { Button, Card, Container, Row, Col, ToggleButton, ButtonGroup } from "react-bootstrap";
import { TiTick } from "react-icons/ti";
import { FaCircle } from "react-icons/fa";
import { LuRocket } from "react-icons/lu";
import { FaArrowRight } from "react-icons/fa6";
import { connect } from "react-redux";
import { useToast } from "../Toast";
import { format } from "date-fns";
import { useSubscription } from '../SubscriptionContext';
import { isBefore } from "date-fns";
import { useNavigate } from "react-router-dom";
const PlanDetails = ({ back, ...props }) => {
    const [billingCycle, setBillingCycle] = useState("monthly");
    const [planList, setPlanList] = useState([]);
    const [activePlanId, setActivePlanId] = useState(null);
    const [validity, setValidity] = useState(null);
    const [trialPlan, setTrialPlan] = useState("");
    const [trialStatus, setTrialStatus] = useState("");
    const [trialStartDate, setTrialStartDate] = useState(null);
    const [trialEndDate, setTrialEndDate] = useState("");
    const [planStatus, setPlanStatus] = useState("");
    const navigate = useNavigate();
    const toast = useToast();
    const { refreshSubscriptions } = useSubscription();
    useEffect(() => {
        loadPlans();
        getPlansById();
    }, []);

    const goBack = () => {
        back(false);
    };

    const loadPlans = async () => {
        try {
            const response = await window.apiService.loadPlans();
            const allPlans = response.data.recordInfo;
            setPlanList(allPlans);
            const trialPlan = allPlans.find(plan => plan.isTrial === 1);
            setTrialPlan(trialPlan.servicePlan);
        } catch (error) {
            console.log(error);
        }
    };

    const getPlansById = async () => {
        if (props) {
            try {
                const response = await window.apiService.getPlanById(props.userId);
                if (response.data.status === "OK") {
                    setActivePlanId(response.data.recordInfo.planId);
                    setValidity(response.data.recordInfo.endDate);
                    setTrialStatus(response.data.recordInfo.trialStatus);
                    setTrialStartDate(response.data.recordInfo.trialStartDate);
                    setTrialEndDate(response.data.recordInfo.trialEndDate);
                    setPlanStatus(response.data.recordInfo.status);
                }
            } catch (error) {
                console.log(error);
            }
        }
    };

    const filteredPlans = planList.filter(plan => plan.billingCycle === billingCycle);
    const colors = ["#3498db", "#28a745", "#6f42c1", "#f1c40f", "#9b59b6"];

    const addUserPlans = async (plan, status) => {
        console.log("Plan selected:", status);

        const startDate = new Date();
        const endDate = new Date(startDate);
        endDate.setDate(startDate.getDate() + plan.planPeriod);

        const formattedStartDate = format(startDate, "yyyy-MM-dd");
        const formattedEndDate = format(endDate, "yyyy-MM-dd");

        const currentPlan = planList.find((p) => p.id === activePlanId);
        const currentPrice = currentPlan ? currentPlan.price : 0;

        const action = plan.price > currentPrice ? "upgraded" : "downgraded";

        const params = {
            userId: props.userId,
            planId: plan.id,
            startDate: formattedStartDate,
            endDate: formattedEndDate,
            status: status == "trial" ? "none" : "active",
            action: status == "trial" ? status : action,
        };

        if (plan.trialPeriod && status) {
            const trialStart = new Date();
            const trialEnd = new Date(trialStart);
            trialEnd.setDate(trialStart.getDate() + plan.trialPeriod);

            params.trialStartDate = format(trialStart, "yyyy-MM-dd");
            params.trialEndDate = format(trialEnd, "yyyy-MM-dd");
            params.trialStatus = "active";
        }

        try {
            const response = await window.apiService.addUserPlan(params);
            if (response.status === "OK") {
                toast.success(response.message);
                getPlansById();
                loadPlans();
                refreshSubscriptions();
            }
        } catch (error) {
            console.log("Error adding user plan:", error);
        }
    };

    const hasPlanExpired = () => {
        const validityDate = new Date(validity);
        const today = new Date();
        validityDate.setHours(0, 0, 0, 0);
        today.setHours(0, 0, 0, 0);

        return validityDate < today;
    };

    const getDiscountedPrice = (plan) => {
        const today = new Date();

        const applicableDiscount = plan.discount.find(discount => {
            const startDate = new Date(discount.startDate);
            const endDate = new Date(discount.endDate);
            return today >= startDate && today <= endDate;
        });

        if (applicableDiscount) {
            const { discountType, discountValue } = applicableDiscount;

            if (discountType === "percentage") {
                return plan.price * (1 - discountValue / 100);
            } else if (discountType === "fixed_amount") {
                return plan.price - discountValue;
            }
        }

        return plan.price;
    };


    return (
        <div className="profile_div pln">
            <Container className="pb-0" fluid>
                <Row>
                    <Col className="order-xl-1" xl="12">
                        <Card className="bg-secondary profile_card pad_50">
                            <Row className="mb-4">
                                <Col lg="10">
                                    <h4>Choose your plan</h4>
                                    <p>Get the right plan for your business. Plans can be upgraded in the future.</p>
                                    {trialPlan && <span className="text-muted">
                                        <LuRocket /> Free {trialPlan} Plan
                                    </span>}
                                </Col>
                                <Col lg="2" className="text-end c_pointer">
                                    <span className="text-muted" onClick={goBack}>Back <FaArrowRight /> </span>
                                </Col>
                            </Row>

                            <Row className="justify-content-end">
                                <ButtonGroup className="mb-4" style={{ width: "20%" }}>
                                    <ToggleButton
                                        type="radio"
                                        name="billingCycle"
                                        value="monthly"
                                        checked={billingCycle === "monthly"}
                                        onClick={() => setBillingCycle("monthly")}
                                        className={billingCycle === "monthly" ? "btn_brown_checked" : "btn_brown_unchecked"}
                                    >
                                        Monthly
                                    </ToggleButton>
                                    <ToggleButton
                                        type="radio"
                                        name="billingCycle"
                                        value="yearly"
                                        checked={billingCycle === "yearly"}
                                        onClick={() => setBillingCycle("yearly")}
                                        className={billingCycle === "yearly" ? "btn_brown_checked" : "btn_brown_unchecked"}
                                    >
                                        Yearly
                                    </ToggleButton>
                                </ButtonGroup>
                            </Row>

                            <Row className="justify-content-center">
                                {filteredPlans && filteredPlans.length > 0 ? (
                                    (() => {
                                        const sortedPlans = [...filteredPlans].sort((a, b) => a.price - b.price);

                                        const displayedFeatures = new Set();
                                        let previousPlan = null;

                                        return sortedPlans.map((plan, idx) => {
                                            const includesPreviousPlan = previousPlan ? previousPlan.servicePlan : null;

                                            const renderedCard = (
                                                <Col key={idx} md="4" className="mb-4">
                                                    <Card className="plan-card">
                                                        {plan.id === activePlanId && (
                                                            <div className="ribbon">
                                                                {hasPlanExpired() && plan.isTrial == 0 ? "Expired" : "Active"}
                                                            </div>
                                                        )}
                                                        <Card.Header
                                                            className="bg-light"
                                                            style={{ color: colors[idx % colors.length] }}
                                                        >
                                                            <span>
                                                                <FaCircle className="icn_blur" />
                                                            </span>{" "}
                                                            {plan.servicePlan}
                                                            {plan.isTrial == 1 && <span className="freePln">Free</span>}
                                                        </Card.Header>
                                                        <Card.Body
                                                            className={
                                                                (plan.id === activePlanId && plan.isTrial == 0) || plan.trialPeriod && trialStartDate == null ? "pad_tp" : ""
                                                            }
                                                        >
                                                            <div className="d-flex justify-content-end">
                                                                {plan.trialPeriod && (plan.id != activePlanId || planStatus == "none") && (
                                                                    <div className={trialStartDate == null ? "freePln" : ""}>
                                                                        {trialStatus === "none" && trialStartDate == null ? (
                                                                            `${plan.trialPeriod} day${plan.trialPeriod > 1 ? "s" : ""} trial`
                                                                        ) : trialEndDate && plan.id === activePlanId ? (
                                                                            <div className="freePln">
                                                                                {new Date(trialEndDate).toDateString() === new Date().toDateString() ? (
                                                                                    "Trial expires today"
                                                                                ) : (
                                                                                    `Trial expires on ${format(new Date(trialEndDate), "dd MMM yyyy")}`
                                                                                )}
                                                                            </div>
                                                                        ) : ""}
                                                                    </div>
                                                                )}

                                                                {plan.id === activePlanId && plan.isTrial == 0 && planStatus == "active" ? (

                                                                    <div className="text-end fs_12 text-muted">
                                                                        {new Date(validity).toDateString() === new Date().toDateString() ? (
                                                                            <div className="freePln">Plan expires today</div>
                                                                        ) : hasPlanExpired() ? (
                                                                            "Plan Expired"
                                                                        ) : (
                                                                            `Valid till: ${format(new Date(validity), "dd MMM yyyy")}`
                                                                        )}
                                                                    </div>
                                                                ) : (
                                                                    <div></div>
                                                                )}
                                                            </div>
                                                            <h4>
                                                                {plan.isTrial === 1 ? (
                                                                    <s>${plan.price}</s>
                                                                ) : (
                                                                    <>
                                                                        {getDiscountedPrice(plan) < plan.price && (
                                                                            <>
                                                                                <s className="">${plan.price}</s> {" "}
                                                                                <span className="text-success">${getDiscountedPrice(plan).toFixed(2)}</span>

                                                                            </>
                                                                        )}
                                                                        {getDiscountedPrice(plan) === plan.price && (
                                                                            <span>${plan.price}</span>
                                                                        )}
                                                                    </>
                                                                )}
                                                                <small> / {billingCycle === "monthly" ? "month" : "year"}</small>
                                                            </h4>

                                                            <div className="text-muted fs_12">
                                                                {includesPreviousPlan && (
                                                                    <div>
                                                                        <strong>(Includes {includesPreviousPlan} plan)</strong>
                                                                    </div>
                                                                )}
                                                                <strong>Subscription Duration:</strong>{" "}
                                                                {plan.isTrial == 0 ? `${plan.planPeriod} days` : "Unlimited"}
                                                            </div>
                                                            <div>{plan.description}</div>

                                                            <ul className="list-unstyled mt-3 mb-4 feat">
                                                                {plan.features
                                                                    .filter((feature) => {
                                                                        const isDisplayed = displayedFeatures.has(
                                                                            feature.featureName
                                                                        );
                                                                        if (!isDisplayed) {
                                                                            displayedFeatures.add(feature.featureName);
                                                                            return true;
                                                                        }
                                                                        return false;
                                                                    })
                                                                    .map((feature, featureIdx) => (
                                                                        <li key={featureIdx}>
                                                                            <TiTick className="tck" /> {feature.featureName}{" "}
                                                                            <span className="text-muted">
                                                                                ({feature.description})
                                                                            </span>
                                                                        </li>
                                                                    ))}
                                                            </ul>
                                                            {plan.isTrial == 0 &&
                                                                (!hasPlanExpired() || plan.id !== activePlanId) ? (

                                                                <>
                                                                    <div className="d-flex mt-auto">
                                                                        <Button
                                                                            onClick={() => addUserPlans(plan)}
                                                                            disabled={plan.id === activePlanId}
                                                                            className={
                                                                                plan.id === activePlanId
                                                                                    ? "btn btn_plan_act w_100"
                                                                                    : "btn_plan w_100"
                                                                            }
                                                                        >
                                                                            {plan.id === activePlanId ? "Active Plan" : "Get Plan"}
                                                                        </Button>
                                                                        {plan.trialPeriod && (plan.id != activePlanId || planStatus == "none") && trialStartDate == null && trialEndDate == null && <Button onClick={() => addUserPlans(plan, "trial")}
                                                                            disabled={trialStatus !== "none"}
                                                                            className={
                                                                                trialStatus == "active"
                                                                                    ? "btn btn_plan_act w_100"
                                                                                    : "btn btn_plan w_100"
                                                                            }
                                                                        >
                                                                            {trialStatus == "active" && plan.id === activePlanId && trialStartDate && trialEndDate ? (
                                                                                <>
                                                                                    Trial Activated
                                                                                </>
                                                                            ) : (
                                                                                "Get Trial"
                                                                            )}
                                                                        </Button>}
                                                                    </div>
                                                                </>
                                                            ) : plan.isTrial == 0 ? (
                                                                <Button
                                                                    onClick={() =>
                                                                        navigate("/dashboard/user_settings/plans_billings")
                                                                    }
                                                                    className={
                                                                        plan.id === activePlanId
                                                                            ? "btn btn_plan_act w_100"
                                                                            : "btn_plan w_100"
                                                                    }
                                                                >
                                                                    Renew plan
                                                                </Button>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                            );

                                            previousPlan = plan;
                                            return renderedCard;
                                        });
                                    })()
                                ) : (
                                    <div className="row d-align mx-0 pb-4">
                                        <div className="no-data font-weight-600 w-100 text-center fz_14">
                                            Sorry, there is no data to be displayed
                                        </div>
                                    </div>
                                )}
                            </Row>


                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        userId: state.user.userId,
    };
};
export default connect(mapStateToProps)(PlanDetails);
