import {
  Button,
  Card,
  Form,
  Col,
  Row,
  Container,
  InputGroup,
} from "react-bootstrap";
import "../Register/register.css";
import Header from "../../layout/header";
import Footer from "../../layout/footer";
import { useEffect, useState } from "react";

const TermsOfService = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    fetch("/modckdata/termsOfService.json")
      .then((res) => res.json())
      .then((res) => setData(res[0].TermsOfService));
  }, []);
  return (
    <>
      <div className="body-content">
        <Header />
        <div style={{ backgroundColor: "#fff" }}>
          <Row className="justify-content-center">
            <div>
              <h2 className="terms_h2">See what ther're saying…</h2>
            </div>
            <Col lg="11" md="11">
              <Card className="term_card border-0">
                <Card.Body className="px-lg-5 py-lg-5 pt-0">
                  <div className="profileForm">
                    <div className="container about">
                      <div className="mt-4 mb-4 b-btm">
                        <h4 className="terms_h4">tiipstr Terms of Service</h4>
                      </div>
                      <h5>
                        If you live outside the European Union, EFTA States, or
                        the United Kingdom, including if you live in the United
                        States:{" "}
                      </h5>
                      <div className="d-flex justify-content-center">
                        <Col lg="10" md="10">
                          {data.map((item, index) => (
                            <div key={index}>
                              <p className="m-0">
                                <span>{item.content}</span>
                              </p>
                              <br />
                              {item.list && (
                                <ol>
                                  {item.list.map((listItem, idx) => (
                                    <li key={idx}>{listItem}</li>
                                  ))}
                                </ol>
                              )}
                              {item.title && (
                                <h6>
                                  <span className="font-weight-bold">
                                    {item.title}
                                  </span>
                                </h6>
                              )}
                              {item.content1 && (
                                <p className="m-0">
                                  <span>{item.content1}</span>
                                </p>
                              )}
                              <br />
                              {item.content2 && (
                                <p className="m-0">
                                  <span>{item.content2}</span>
                                </p>
                              )}
                              <br />
                              {item.content3 && (
                                <p className="m-0">
                                  <span>{item.content3}</span>
                                </p>
                              )}
                              {item.content4 && (
                                <p className="m-0">
                                  <span>{item.content4}</span>
                                </p>
                              )}
                            </div>
                          ))}
                        </Col>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default TermsOfService;
