import React, { useState, useEffect } from "react";
import { useToast } from "../../Toast";
import HeaderMenu from "../headerMenu";
import SideBar from "../SideBar";
import { Card, Container, Row, Col } from "reactstrap";
import BreadCrumb from "../../plugins/Breadcrumb";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

const AuthSettings = (props) => {
  const location = useLocation();
  const settingsData = location.state?.settingsData;
  const userId = location.state?.userId;
  const [enableOption, setEnableOption] = useState(false);
  const toast = useToast();
  const onToggle = () => {
    setEnableOption((prevEnableOption) => {
      return !prevEnableOption;
    });
  };
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    contactMethod: "",
  });

  useEffect(() => {
    setEnableOption((prevEnableOption) => {
      return settingsData.twoFactorEnabled;
    });
    setFormData({
      ...formData,
      contactMethod: settingsData.twoFactorMethod,
    });
  }, [settingsData, userId]);

  useEffect(() => {
    if (!enableOption) {
      const params = {
        twoFactorEnabled: enableOption,
        twoFactorMethod: null,
      };
      if (
        formData.contactMethod != "" &&
        formData.contactMethod != null &&
        !enableOption &&
        settingsData.twoFactorEnabled
      ) {
        UpdateAuth(params);
        setFormData({
          ...formData,
          contactMethod: null,
        });
      }
    }
  }, [enableOption]);
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const UpdateAuth = async (params) => {
    try {
      const response = await window.apiService.settingsAuthUpdate(
        userId,
        params
      );
      if (response.data.status == "OK") {
        toast.success(response.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    const params = {
      twoFactorEnabled: enableOption,
      twoFactorMethod: formData.contactMethod,
    };
    if (formData.contactMethod != "" && formData.contactMethod != null) {
      UpdateAuth(params);
    } else {
      toast.error("Please choose an option before proceeding");
    }
  };

  return (
    <>
      <HeaderMenu />
      <SideBar />
      <div className="main-content ml_200">
        {/* <BreadCrumb /> */}
        <Card className="shade h-500">
          {props.emailVerified || props.phoneVerified ? (
            <Container fluid>
              <div className="header-body  pt-3">
                <Row>
                  <Col lg="12" xl="12">
                    {/* <h6>Two Factor Authentication</h6> */}
                    <div className="d-flex mt-2 toggle_gap">
                      <label className="font-weight-500 fz_14">
                        Two Factor Authentication
                      </label>
                      <label className="toggle-switch">
                        <input
                          type="checkbox"
                          checked={enableOption}
                          onChange={onToggle}
                        />
                        <span className="switch" />
                      </label>
                    </div>
                    {enableOption && (
                      <div style={{ marginLeft: "20px" }}>
                        <form onSubmit={handleSubmit}>
                          <input
                            type="radio"
                            name="contactMethod"
                            value="email"
                            checked={formData.contactMethod === "email"}
                            onChange={handleInputChange}
                            disabled={!props.emailVerified}
                          />
                          <label className="marL">
                            Email {props.email && <span>({props.email})</span>}
                          </label>

                          <br />

                          <input
                            type="radio"
                            name="contactMethod"
                            value="phone"
                            checked={formData.contactMethod === "phone"}
                            onChange={handleInputChange}
                            disabled={!props.phoneVerified}
                          />
                          <label className="marL">
                            Phone {props.phone && <span>({props.phone})</span>}
                          </label>
                          <br />

                          <input
                            type="radio"
                            name="contactMethod"
                            value="both"
                            checked={formData.contactMethod === "both"}
                            onChange={handleInputChange}
                            disabled={
                              !props.phoneVerified || !props.emailVerified
                            }
                          />
                          <label className="marL">Both</label>
                          <br />

                          <button
                            type="submit"
                            className="btn btn-primary rev_btn"
                          >
                            Update
                          </button>
                        </form>
                      </div>
                    )}
                  </Col>
                </Row>
              </div>
            </Container>
          ) : (
            <div className="centered-card">
              <Card className="infoCard">
                <p className="txtInf">
                  To enhance the security of your account, please complete the
                  email or phone verification process before proceeding. Visit
                  your{" "}
                  <span
                    className="name_blue c_pointer"
                    onClick={() => navigate("/dashboard/profile")}
                  >
                    profile
                  </span>{" "}
                  to verify your email or phone Thank you for prioritizing the
                  security of your account.
                </p>
              </Card>
            </div>
          )}
        </Card>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    email: state.user.email,
    phone: state.user.phone,
    emailVerified: state.user.emailVerified,
    phoneVerified: state.user.phoneVerified,
  };
};
export default connect(mapStateToProps)(AuthSettings);
