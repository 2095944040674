import React, { useRef, useState, useEffect, forwardRef } from "react";
import profile from "../../assets/images/profile.jpg";
import StarRating from "../Dashboard/DashboardTabs/StarRating";
import { HiLocationMarker } from "react-icons/hi";
import RatingBar from "../plugins/RatingBar";
import { Card, Col, Row } from "react-bootstrap";
import logo from "../../assets/images/tiip.png";
import usrImg from "../../assets/images/user_Img.jpg";

const ViewShare = forwardRef((props, canvasRef) => {
  const [rating, setRating] = useState("");
  const [location, setLocation] = useState("");

  const rmvMenFormat = (value) => {
    const mentionRegex = /\@\[([^)]+)\]\((\d+)\)/g;
    const transformedComment = value.replace(mentionRegex, "@$1");
    return transformedComment;
  };

  useEffect(() => {
    const userLocation = props.userData.city + ", " + props.userData.state;
    setLocation(userLocation);
    setRating(props.shareData.averageReviewCount ? props.shareData.averageReviewCount : props.userData.averageReviewCount);
  }, [props.userData.averageReviewCount]);

  const renderStars = () => {
    const stars = [];
    const decimalPart = rating - Math.floor(rating);

    for (let index = 1; index <= 5; index++) {
      if (index < rating) {
        stars.push(<span key={index} className="fa fa-star checkedStr"></span>);
      } else if (index === Math.ceil(rating) && decimalPart > 0) {
        stars.push(
          <span key={index} className="fa fa-star-half-o checkedStr"></span>
        );
      } else {
        stars.push(<span key={index} className="fa fa-star"></span>);
      }
    }

    return stars;
  };
  return (
    <>
      <Card
        ref={canvasRef}
        className="shadow"
        style={{ maxWidth: "500px" }}
      >
        <Card.Body className="p-0 cd_bd">
          <div className="d-flex w-100">
            <Col sm={12} md={12} lg={12} xl={12} className="p-0">
              <div className="d-flex w-100">
                <Col
                  sm={12}
                  md={4}
                  lg={4}
                  xl={4}
                  className="pad_shr text-center"
                >
                  <div>
                    <img className="shareLogo" src={logo} />
                  </div>
                  {/* <img className='share_img' src={usrImg}/> */}
                  <div className="fz-15 font-weight-500 mb-3">{props.shareData.userName}</div>
                  <div className="d-flex justify-content-center">
                    <div className="starRtng mr-0">
                      <div id="ratingStars" className="d-flex">
                        <span className="fz-12">{props.userData.averageReviewScore ? props.userData.averageReviewScore.toFixed(1) : (props.shareData.averageReviewScore ? (props.shareData.averageReviewScore.toFixed(1)) : "")}</span>
                        {renderStars()}
                        <span className="fz-12">({props.userData.totalReviewCount ? props.userData.totalReviewCount : props.shareData.totalReviewCount})</span>
                      </div>
                    </div>
                  </div>
                  {props.shareData.reviewerLocation && (
                    <div className="d-flex justify-content-center">
                      <div className="fz_13">
                        <HiLocationMarker />
                      </div>
                      <div className="fz_13">{location}</div>
                    </div>
                  )}
                </Col>
                {/* <div className="sep_dv"></div> */}
                <Col sm={12} md={8} lg={8} xl={8} className="bdr_lt pad_shr">
                  <div className="d-flex">
                    <div className="fol_name text-start text_sty">Reviewed by : </div>
                    <span className="mar_stty text_sty">
                      {props.shareData.reviewerName}
                    </span>
                  </div>
                  <div className="d-flex">
                    <div className="fol_name text-start text_sty">Role : </div>
                    <span className="mar_stty text_sty">{props.shareData.userRole}</span>
                  </div>
                  {props.shareData.comment && (
                    <div className="d-flex">
                      <div className="fol_name text-start text_sty">Comment :</div>
                      <span className="mar_stty text_sty" title={props.shareData.comment}>
                        {props.shareData.comment
                          ? (props.shareData.comment.length >= 20 ? rmvMenFormat(props.shareData.comment).substring(0, 20) + "..." : rmvMenFormat(props.shareData.comment))
                          : ""}
                      </span>
                    </div>
                  )}
                  <div className="cmtSec">
                    <RatingBar
                      disabled={true}
                      likecount={props.shareData.likes}
                      dislikecount={props.shareData.dislikes}
                      labelVAlue={props.shareData.score}
                    />
                  </div>
                </Col>
              </div>
            </Col>
          </div>
        </Card.Body>
      </Card >
    </>
  );
});

export default ViewShare;
