import { Card, Col, Row } from "react-bootstrap";
import "../Register/register.css";
import Header from "../../layout/header";
import Footer from "../../layout/footer";
import { useEffect, useState } from "react";
const PrivacyPolicy = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    fetch("/modckdata/privacyPolicy.json")
      .then((res) => res.json())
      .then((res) => setData(res[0].PrivacyPolicy));
  }, []);

  return (
    <>
      <div className="body-content">
        <Header />
        <div style={{ backgroundColor: "#fff" }}>
          <Row className="justify-content-center">
            <div>
              <h2 className="terms_h2">See what ther're saying…</h2>
            </div>
            <Col lg="11" md="11">
              <Card className="term_card border-0">
                <Card.Body className="px-lg-5 py-lg-5 pt-0">
                  <div className="profileForm">
                    <div className="container about">
                      <div className="mt-4 mb-4 b-btm">
                        <h4 className="terms_h4">tiipstr Privacy Policy</h4>
                      </div>
                      <div className="d-flex justify-content-center">
                        <Col lg="10" md="10">
                          {data.map((item, index) => (
                            <div key={index}>
                              <p className="m-0">
                                <span>{item.content}</span>
                              </p>
                              <br />
                              {item.list && (
                                <ol>
                                  {item.list.map((listItem, idx) => (
                                    <li key={idx}>{listItem}</li>
                                  ))}
                                </ol>
                              )}
                              {item.content1 && (
                                <p className="m-0">
                                  <span>{item.content1}</span>
                                </p>
                              )}
                              {item.list1 && (
                                <ul>
                                  {item.list1.map((listItem, idx) => (
                                    <li key={idx}>{listItem}</li>
                                  ))}
                                </ul>
                              )}
                              {item.content2 && (
                                <p className="m-0">
                                  <span>{item.content2}</span>
                                </p>
                              )}
                              {item.list2 && (
                                <ul>
                                  {item.list2.map((listItem, idx) => (
                                    <li key={idx}>{listItem}</li>
                                  ))}
                                </ul>
                              )}
                              {item.content3 && (
                                <p className="m-0">
                                  <span>{item.content3}</span>
                                </p>
                              )}
                              {item.list3 && (
                                <ul>
                                  {item.list3.map((listItem, idx) => (
                                    <li key={idx}>{listItem}</li>
                                  ))}
                                </ul>
                              )}
                              {item.content4 && (
                                <p className="m-0">
                                  <span>{item.content4}</span>
                                </p>
                              )}
                              {item.list4 && (
                                <ul>
                                  {item.list4.map((listItem, idx) => (
                                    <div>
                                      <li className="m-0">
                                        {listItem.content4_1}
                                      </li>
                                      {listItem.list4_1 && (
                                        <ul>
                                          {listItem.list4_1.map((list, idx) => (
                                            <li key={idx}>{list}</li>
                                          ))}
                                        </ul>
                                      )}
                                    </div>
                                  ))}
                                </ul>
                              )}
                              {item.content5 && (
                                <p className="m-0">
                                  <span>{item.content5}</span>
                                </p>
                              )}
                              {item.list5 && (
                                <ul>
                                  {item.list5.map((listItem, idx) => (
                                    <li key={idx}>{listItem}</li>
                                  ))}
                                </ul>
                              )}
                              {item.title6 && (
                                <h6>
                                  <span className="font-weight-bold">
                                    {item.title6}
                                  </span>
                                </h6>
                              )}
                              {item.content6 && (
                                <p className="m-0">
                                  <span>{item.content6}</span>
                                </p>
                              )}
                              {item.list6 && (
                                <ul>
                                  {item.list6.map((listItem, idx) => (
                                    <li key={idx}>{listItem}</li>
                                  ))}
                                </ul>
                              )}
                              {item.content7 && (
                                <p className="m-0">
                                  <span>{item.content7}</span>
                                </p>
                              )}
                              {item.list7 && (
                                <ul>
                                  {item.list7.map((listItem, idx) => (
                                    <li key={idx}>{listItem}</li>
                                  ))}
                                </ul>
                              )}
                              {item.title8 && (
                                <h6>
                                  <span className="font-weight-bold">
                                    {item.title8}
                                  </span>
                                </h6>
                              )}
                              {item.content8 && (
                                <p className="m-0">
                                  <span>{item.content8}</span>
                                </p>
                              )}
                              {item.list8 && (
                                <ul>
                                  {item.list8.map((listItem, idx) => (
                                    <li key={idx}>{listItem}</li>
                                  ))}
                                </ul>
                              )}
                              {item.content9 && (
                                <p className="m-0">
                                  <span>{item.content9}</span>
                                </p>
                              )}
                              {item.list9 && (
                                <ul>
                                  {item.list9.map((listItem, idx) => (
                                    <div>
                                      <li className="m-0">
                                        {listItem.content9_1}
                                      </li>
                                      {listItem.list9_1 && (
                                        <ul>
                                          {listItem.list9_1.map((list, idx) => (
                                            <li key={idx}>{list}</li>
                                          ))}
                                        </ul>
                                      )}
                                    </div>
                                  ))}
                                </ul>
                              )}

                              {item.title10 && (
                                <h6>
                                  <span className="font-weight-bold">
                                    {item.title10}
                                  </span>
                                </h6>
                              )}
                              {item.content10 && (
                                <p className="m-0">
                                  <span>{item.content10}</span>
                                </p>
                              )}
                              {item.list10 && (
                                <ul>
                                  {item.list10.map((listItem, idx) => (
                                    <li key={idx}>{listItem}</li>
                                  ))}
                                </ul>
                              )}
                              {item.title11 && (
                                <h6>
                                  <span className="font-weight-bold">
                                    {item.title11}
                                  </span>
                                </h6>
                              )}
                              {item.content11 && (
                                <p className="m-0">
                                  <span>{item.content11}</span>
                                </p>
                              )}
                              {item.list11 && (
                                <ul>
                                  {item.list11.map((listItem, idx) => (
                                    <li key={idx}>{listItem}</li>
                                  ))}
                                </ul>
                              )}
                              {item.title12 && (
                                <h6>
                                  <span className="font-weight-bold">
                                    {item.title12}
                                  </span>
                                </h6>
                              )}
                              {item.content12 && (
                                <p className="m-0">
                                  <span>{item.content12}</span>
                                </p>
                              )}
                              {item.list12 && (
                                <ul>
                                  {item.list12.map((listItem, idx) => (
                                    <li key={idx}>{listItem}</li>
                                  ))}
                                </ul>
                              )}
                              {item.content13 && (
                                <p className="m-0">
                                  <span>{item.content13}</span>
                                </p>
                              )}
                              {item.list13 && (
                                <ul>
                                  {item.list13.map((listItem, idx) => (
                                    <li key={idx}>{listItem}</li>
                                  ))}
                                </ul>
                              )}
                              {item.content14 && (
                                <p className="m-0">
                                  <span>{item.content14}</span>
                                </p>
                              )}
                              {item.list14 && (
                                <ul>
                                  {item.list14.map((listItem, idx) => (
                                    <li key={idx}>{listItem}</li>
                                  ))}
                                </ul>
                              )}
                              {item.content15 && (
                                <p className="m-0">
                                  <span>{item.content15}</span>
                                </p>
                              )}
                              {item.list15 && (
                                <ul>
                                  {item.list15.map((listItem, idx) => (
                                    <li key={idx}>{listItem}</li>
                                  ))}
                                </ul>
                              )}
                              {item.content16 && (
                                <p className="m-0">
                                  <span>{item.content16}</span>
                                </p>
                              )}
                              {item.list16 && (
                                <ul>
                                  {item.list16.map((listItem, idx) => (
                                    <div>
                                      <li className="m-0">
                                        {listItem.content16_1}
                                      </li>
                                      {listItem.list16_1 && (
                                        <ul>
                                          {listItem.list16_1.map(
                                            (list, idx) => (
                                              <li key={idx}>{list}</li>
                                            )
                                          )}
                                        </ul>
                                      )}
                                    </div>
                                  ))}
                                </ul>
                              )}
                              {item.content17 && (
                                <p className="m-0">
                                  <span>{item.content17}</span>
                                </p>
                              )}
                              {item.list17 && (
                                <ul>
                                  {item.list17.map((listItem, idx) => (
                                    <li key={idx}>{listItem}</li>
                                  ))}
                                </ul>
                              )}
                              {item.list18 && (
                                <ul className="list-unstyled">
                                  {item.list18.map((listItem, idx) => (
                                    <li key={idx}>{listItem}</li>
                                  ))}
                                </ul>
                              )}
                              {item.content19 && (
                                <p className="m-0">
                                  <span>{item.content19}</span>
                                </p>
                              )}
                              {item.list19 && (
                                <ul>
                                  {item.list19.map((listItem, idx) => (
                                    <li key={idx}>{listItem}</li>
                                  ))}
                                </ul>
                              )}
                              {item.title20 && (
                                <h6>
                                  <span className="font-weight-bold">
                                    {item.title20}
                                  </span>
                                </h6>
                              )}
                              {item.content20 && (
                                <p className="m-0">
                                  <span>{item.content20}</span>
                                </p>
                              )}
                              {item.list20 && (
                                <ul>
                                  {item.list20.map((listItem, idx) => (
                                    <li key={idx}>{listItem}</li>
                                  ))}
                                </ul>
                              )}
                              {item.content21 && (
                                <p className="m-0">
                                  <span>{item.content21}</span>
                                </p>
                              )}
                              {<item className="title21"></item> && (
                                <h6>
                                  <span className="font-weight-bold">
                                    {item.title21}
                                  </span>
                                </h6>
                              )}
                              {item.list21 && (
                                <ul>
                                  {item.list21.map((listItem, idx) => (
                                    <li key={idx}>{listItem}</li>
                                  ))}
                                </ul>
                              )}
                              {item.title22 && (
                                <h6>
                                  <span className="font-weight-bold">
                                    {item.title22}
                                  </span>
                                </h6>
                              )}
                              {item.content22 && (
                                <p className="m-0">
                                  <span>{item.content22}</span>
                                </p>
                              )}
                              {item.list22 && (
                                <ul>
                                  {item.list22.map((listItem, idx) => (
                                    <li key={idx}>{listItem}</li>
                                  ))}
                                </ul>
                              )}
                            </div>
                          ))}
                        </Col>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default PrivacyPolicy;
