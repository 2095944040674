import { Card, Col, Row, Modal, Button } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { useToast } from "../../Toast";
const ProfessionalTypeAccess = (props) => {
  const roleId = props.id;
  const roleName = props.name;
  const [professionalList, setProfessionalList] = useState("");
  const [professionMapping, setProfessionMapping] = useState([]);
  const [checkedItems, setCheckedItems] = useState({});
  const toast = useToast();
  useEffect(() => {
    loadProfessionalList();
  }, [roleId]);
  useEffect(() => {
    loadProfessionMappingList();
    setCheckedItems({});
  }, [roleId && professionalList]);

  const loadProfessionalList = async () => {
    try {
      const response = await window.apiService.professionalList({
        userTypeId: 2,
      });
      setProfessionalList(response.data.recordInfo);
    } catch (error) {
      console.log(error);
    }
  };
  const loadProfessionMappingList = async () => {
    if (roleId) {
      try {
        const response = await window.apiService.profMapList(roleId);
        setProfessionMapping(response.data.recordInfo);
        const defaultIds = professionalList
          .filter((item) =>
            response.data.recordInfo.some(
              (mapping) => mapping.professionalTypeMappingId === item.id
            )
          )
          .map((item) => item.id);
        const arrayIds = defaultIds;
        const objectIds = arrayIds.reduce((acc, id) => {
          acc[id] = true;
          return acc;
        }, {});
        setCheckedItems(objectIds);
      } catch (error) {
        console.log(error);
        toast.error("Error Occured");
      }
    }
  };

  const AddMapping = async () => {
    const selectedIds = Object.keys(checkedItems).filter(
      (key) => checkedItems[key]
    );
    const allIds = [...new Set([...selectedIds])];
    const allObjects = allIds.map((id) => ({
      professionalTypeId: roleId,
      professionalTypeMappingId: id,
    }));
    const params = allObjects.map((obj2) => {
      const matchingObj = professionMapping.find(
        (obj1) =>
          obj1.professionalTypeId === obj2.professionalTypeId &&
          obj1.professionalTypeMappingId ===
          Number(obj2.professionalTypeMappingId)
      );
      return matchingObj
        ? { ...obj2, id: matchingObj.id }
        : { ...obj2, id: "" };
    });
    try {
      const response = await window.apiService.addProfMapList(params);
      if (response.data.status == "OK") {
        toast.success("Professional Type Mapping Updated Successfully");
        loadProfessionMappingList();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleCheckboxChange = (event, itemId) => {
    const { checked } = event.target;
    const updatedCheckedItems = { ...checkedItems, [itemId]: checked };
    setCheckedItems(updatedCheckedItems);
    if (!checked && itemId != roleId) {
      const foundObject = professionMapping.find(
        (item) => item.professionalTypeMappingId === itemId
      );
      const foundId = foundObject ? foundObject.id : null;
      UpdateMapping(foundId);
    }
  };

  const UpdateMapping = async (id) => {
    if (id) {
      if (roleId) {
        try {
          const response = await window.apiService.updateProfMapList(id);
          if (response.data.status == "OK") {
            loadProfessionMappingList();
          }
        } catch (error) {
          console.log(error);
        }
      }
    }
  };

  return (
    <>
      <div className="role_container">
        <Row className="m_b18 title_txt">
          <Col sm={12} md={12} lg={12} xl={12}>
            Selected Profession: {roleName}
          </Col>
        </Row>
        {professionalList &&
          professionalList.map((data) => {
            return (
              <Row key={data.id}>
                <Col sm={8} md={6} lg={6} xl={6}>
                  <div>
                    <input
                      type="checkbox"
                      checked={
                        checkedItems[data.id] ||
                        (data.id == roleId ? true : false)
                      }
                      onChange={(event) => handleCheckboxChange(event, data.id)}
                      style={{ marginRight: "12px" }}
                    />
                    {data.name}
                  </div>
                </Col>
              </Row>
            );
          })}
        <Row className="m_b18 title_txt">
          <Col sm={8} md={6} lg={6} xl={6}>
            <Button variant="primary" className="del_bttn" onClick={AddMapping}>
              Save
            </Button>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ProfessionalTypeAccess;
