import React from "react";
import { Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom"; // Import useNavigate

const UpgradePopup = (props) => {
    const { show, handleClose } = props;
    const navigate = useNavigate(); // Initialize navigate

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Body className="p-3 border text-center">
                <p className="fz_15 font-weight-500 mb-2">Unlock this feature by upgrading your plan.</p>
                <Button
                    onClick={() => navigate("/dashboard/user_settings/plans_billings")}
                    className="mt-2 fz_14"
                >
                    Upgrade
                </Button>
            </Modal.Body>
        </Modal>
    );
};

export default UpgradePopup;
