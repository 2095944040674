import { Card, Col, Row } from "react-bootstrap";
import StarRating from "../Dashboard/DashboardTabs/StarRating";
import logoImg from "../../assets/images/tiipstrlogo.jpg";
import { useLocation } from "react-router-dom";
import { HiLocationMarker } from "react-icons/hi";
import React, { useState, useEffect } from "react";
import { useToast } from "../Toast";
import profile from "../../assets/images/profile.jpg";
import RatingBar from "../plugins/RatingBar";
import { useParams } from "react-router-dom";

const Shareview = () => {
  const { token } = useParams();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const name = params.get("name");
  const rating = params.get("rating");
  const [data, setData] = useState([]);
  const toast = useToast();
  useEffect(() => {
    Decodetoken(token);
  }, [token]);

  const Decodetoken = async () => {
    if (token) {
      try {
        const response = await window.apiService.revDecodeToken(token);
        viewReview(response.data.data.id);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const viewReview = async (id) => {
    if (id) {
      try {
        const response = await window.apiService.viewReview(id);
        setData(response.data.recordInfo);
      } catch (error) {
        toast.error("Error Occured");
      }
    }
  };
  const rmvMenFormat = (value) => {
    const mentionRegex = /\@\[([^)]+)\]\((\d+)\)/g;
    const transformedComment = value.replace(mentionRegex, "@$1");
    return transformedComment;
  };

  return (
    <>
      {/* {data && data.map((review,index) => ( */}
      <Card className="shadow mar_shre">
        <Card.Body className="">
          <Col sm={12} md={12} lg={12} xl={12}>
            <Row>
              <Row className="mb-10">
                <Col sm={12} md={3} lg={2} xl={2} className="text-center">
                  <img
                    className="meImg_profile"
                    src={
                      data.reviewerImagePath ? data.reviewerImagePath : profile
                    }
                  />
                  <Card.Title>
                    {data.reviewerName ? data.reviewerName : name}
                  </Card.Title>
                  <div className="d-flex justify-content-center">
                    <div className="starRtng">
                      <StarRating
                        rating={
                          data.averageReviewCount
                            ? data.averageReviewCount
                            : rating
                        }
                        disabled={true}
                      />
                    </div>
                  </div>
                  {data.reviewerLocation && (
                    <div className="d-flex justify-content-center">
                      <div>
                        <HiLocationMarker />
                      </div>
                      <div className="">{data.reviewerLocation}</div>
                    </div>
                  )}
                </Col>
                <Col sm={12} md={6} lg={6} xl={6} className="bdr_lt pad_ratng">
                  {data.comment && (
                    <div className="">
                      <div className="fol_name">Comment </div>
                      <div style={{ marginLeft: "5px" }} className="">
                        {data.comment ? rmvMenFormat(data.comment) : ""}
                      </div>
                    </div>
                  )}
                </Col>
                <Col sm={12} md={3} lg={3} xl={3} className="pad_ratng">
                  <RatingBar
                    disabled={true}
                    likecount={data.likes}
                    dislikecount={data.dislikes}
                    labelVAlue={data.score}
                  />
                </Col>
              </Row>
            </Row>
          </Col>
        </Card.Body>
      </Card>
      {/* ))} */}
    </>
  );
};

export default Shareview;
