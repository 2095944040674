import { Col, Row, Button, Card, DropdownButton, Dropdown } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { useToast } from "../../../Toast";
import { BsPlusLg } from "react-icons/bs";
import { FaTimes } from "react-icons/fa";
import { TiTick } from "react-icons/ti";
import { Input, Table } from "reactstrap";
import CustomModal from "../../../plugins/CustomModal";
import { HiOutlineTrash } from "react-icons/hi";
import editRole from "../../../../assets/images/usr_edit.png";
const PlanFeatureMapping = () => {
    const [featureList, setFeatureList] = useState([]);
    const [editMode, setEditMode] = useState(false);
    const [newReason, setNewReason] = useState("");
    const [reasonError, setReasonError] = useState("");
    const [editedId, setEditedId] = useState(null);
    const [deletePopupShow, setDeletePopupShow] = useState(false);
    const [toDelReasonId, setToDelReasonId] = useState(null);
    const toast = useToast();
    useEffect(() => {
        loadAllPlanFeatures();
    }, []);
    const showDeletePopup = (id) => {
        setToDelReasonId(id);
        setDeletePopupShow(true);
    };
    const addFeature = async () => {
        setReasonError("");
        if (!newReason) {
            setReasonError("Reason is required.");
            return;
        }
        const params = {
            id: editedId,
            reason: newReason,
        };
        try {
            const response = await window.apiService.addPlanCancelReason(params);
            if (response.data.status === "OK") {
                if (response.data.message === "Reason already exists.") {
                    toast.info(response.data.message);
                } else {
                    toast.success(response.data.message);
                    setNewReason("");
                    setEditedId(null);
                    setEditMode(false);
                    loadAllPlanFeatures();
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    const loadAllPlanFeatures = async () => {
        try {
            const response = await window.apiService.loadPlanCancelReasons();
            const allFeatures = response.data.recordInfo;
            setFeatureList(allFeatures);
        } catch (error) {
            console.log(error);
        }
    };

    const deleteServiceFeature = async () => {
        if (toDelReasonId) {
            try {
                const response = await window.apiService.deletePlanCancelReason(toDelReasonId);
                if (response.data.status == "OK") {
                    toast.success(response.data.message);
                    loadAllPlanFeatures();
                    setDeletePopupShow(false);
                }
            } catch (error) {
                console.log(error);
                setDeletePopupShow(false);
            }
        }
    };

    return (
        <>
            <div className="chat container h-100">
                <Card className="shade h-100">
                    <Row>
                        <Col sm={12} md={12} lg={10} xl={10}>
                            <div className="m-4">
                                <Row className="mb-2 title_txt">
                                    <Col sm={12} md={12} lg={12} xl={12}>
                                        Plan Cancellation Reasons
                                    </Col>
                                </Row>
                                <Row className="m_b8">
                                    <Col sm={12} md={12} lg={8} xl={8}>
                                        <div className="m_b8 d-flex">
                                            <Col sm={10} md={10} lg={8} xl={8} className="pl-0">
                                                <Input
                                                    className="inptRole rle_place"
                                                    placeholder="Reason"
                                                    type="text"
                                                    maxLength={255}
                                                    value={newReason}
                                                    onChange={(e) => {
                                                        setNewReason(e.target.value);
                                                        setReasonError("");
                                                    }}
                                                />
                                                {reasonError && <div className="text-danger fz_13">{reasonError}</div>}
                                            </Col>
                                            {/* <Col sm={8} md={5} lg={5} xl={5}>
                                                <Input
                                                    className="inptRole rle_place"
                                                    placeholder="Description"
                                                    type="text"
                                                    maxLength={500}
                                                    value={newFeatureDesc}
                                                    onChange={(e) => {
                                                        setNewFeatureDesc(e.target.value);
                                                        setFeatureDesError("");
                                                    }}
                                                />
                                                {featureDesError && <div className="text-danger fz_13">{featureDesError}</div>}
                                            </Col> */}
                                            <Col sm={2} md={2} lg={2} xl={2} className="mt-2 custom-col-icn d-flex p-0">
                                                {editMode ? (
                                                    <>
                                                        <TiTick className="c_pointer plus_icn m_r15" title="Update" onClick={() => addFeature()} />
                                                        <FaTimes
                                                            className="c_pointer close_icn" title="Cancel"
                                                            onClick={() => {
                                                                setEditMode(false);
                                                                setNewReason("");
                                                                setEditedId(null);
                                                            }}
                                                        />
                                                    </>
                                                ) : (
                                                    <BsPlusLg onClick={() => addFeature()} className="c_pointer" title="Add" />
                                                )}
                                            </Col>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="m_b8">
                                    <Col sm={12} md={12} lg={2} xl={8}>
                                        <div className="h_218 overflow-y-auto">
                                            <Table
                                                className="align-items-center table-flush brd_solid"
                                            >
                                                <thead className="thead-clr">
                                                    <tr>
                                                        <th scope="col">Reason</th>
                                                        <th scope="col">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {featureList && featureList.length > 0 ?
                                                        featureList.map((data, index) => (
                                                            <React.Fragment key={index}>
                                                                <tr>
                                                                    <td>{data.reason}</td>
                                                                    <td>
                                                                        <div className="d-flex">
                                                                            {/* Edit Icon */}
                                                                            <img
                                                                                src={editRole}
                                                                                alt="Edit"
                                                                                className="mr-2 c_pointer"
                                                                                onClick={() => {
                                                                                    setEditMode(true);
                                                                                    setNewReason(data.reason);
                                                                                    setEditedId(data.id);
                                                                                    setReasonError("");
                                                                                }}
                                                                            />

                                                                            {/* Delete Icon */}
                                                                            <HiOutlineTrash
                                                                                onClick={() => showDeletePopup(data.id)}
                                                                                title="Delete"
                                                                                className="ques_trash c_pointer"
                                                                            />
                                                                        </div>
                                                                    </td>

                                                                </tr>
                                                            </React.Fragment>
                                                        )) : <tr>
                                                            <td colSpan="3" className="text-center">
                                                                No Data Found
                                                            </td>
                                                        </tr>}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Card>
            </div>
            {/* ----------- Delete Confirmation Popup ---------- */}
            < CustomModal
                show={deletePopupShow}
                handleClose={() => setDeletePopupShow(false)}
                performSubmit={() => deleteServiceFeature()}
                title="Delete Confirmation"
                btnText="Yes"
                body={
                    <>
                        <p>Are you sure to delete?</p>
                    </>
                }
            />
        </>
    );
};

export default PlanFeatureMapping;
