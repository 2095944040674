import React from "react";
import Header from "../../layout/header";
function Home() {
  return (
    <>
      <Header />
      <div className="centered-container">
        <h1>Welcome to tiipstr</h1>
      </div>
    </>
  );
}

export default Home;
