import React from "react";
import { useForm } from "react-hook-form";
import { useState} from "react";
import { Button, Card, Col, Row, Form, Container } from "react-bootstrap";
import HeaderMenu from "../headerMenu";
import SideBar from "../SideBar";
import BreadCrumb from "../../plugins/Breadcrumb";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const HelpTab = () => {
  const { register, handleSubmit,watch,formState: { errors ,isValid  }, reset, clearErrors } = useForm();
  const [countryCode, setCountryCode] = useState("+91");

  const onSubmit = async (data, e) => {
    try {
      data.contactNumber = `${data.countryCode}${data.contactNumber}`;

      await window.apiService.addHelp(data);
      toast.success("Help request submitted successfully", {
        theme: "colored",
        position: "top-right",
        autoClose: 1500,
      });
      e.target.reset();
      clearErrors();
    } catch (error) {
      toast.error("Error submitting help request", {
        theme: "colored",
        position: "top-right",
        autoClose: 1500,
      });
    }
  };

  const handleReset = () => {
    reset();
    clearErrors();
  };
  const contactNumberValidation = () => {
    const contactNumber = watch("contactNumber"); // Get the current contact number
    if (countryCode === "+91" && contactNumber.length !== 10) {
      return "Indian numbers must be 10 digits long";
    }
    if (countryCode === "+1" && contactNumber.length !== 10) {
      return "US numbers must be 10 digits long";
    }
    return true; // Valid number
  };

  return (
    <>
      <ToastContainer />
      <HeaderMenu />
      <div className="d-flex">
        <SideBar />
        <div className="main-content ml_200 flex-grow-1 p-4">
          {/* <BreadCrumb /> */}
          {/* <Container className="col-8">
            <Card className="shade card" style={{ height: "517px" }}>
              <Card.Header as="h5" className="text-center" style={{ backgroundColor: '#EFEBE3', color: 'black' }}>
                Help & Support
              </Card.Header>
              <Card.Body className="p-5">
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <Row className="mb-3">
                    <Col md={3}>
                      <Form.Label>Your Name<sup style={{ lineHeight: "2", color: 'red' }}>*</sup></Form.Label>
                    </Col>
                    <Col md={9}>
                      <Form.Control
                        type="text"
                        {...register("name", { 
                          required: true ,
                          pattern: {
                            value: /^[A-Za-z ]+$/,
                            message: "Name should contain only alphabets and spaces"
                          }
                        })}
                        onInput={(e) => {
                          e.target.value = e.target.value.replace(/[^A-Za-z ]/g, '').replace(/^(.)/, (match) => match.toUpperCase());
                        }}
                        className={errors.name ? "is-invalid" : ""}
                        placeholder="Enter your name"
                      />
                      {errors.name && <div className="invalid-feedback">Please enter your name</div>}
                    </Col>
                  </Row>
                  <Row className="mb-3">
  <Col md={3}>
    <Form.Label>
      Contact Number<sup style={{ lineHeight: "2", color: 'red' }}>*</sup>
    </Form.Label>
  </Col>
  <Col md={3}>
    <Form.Select 
      {...register("countryCode", { required: "Country code is required" })} 
      className={`form-control ${errors.countryCode ? "is-invalid" : ""}`}
    >
      <option value="+1">+1</option>
      <option value="+91">+91</option>
    </Form.Select>
    {errors.countryCode && (
      <div className="invalid-feedback">{errors.countryCode.message}</div>
    )}
  </Col>
  <Col md={6}>
  <Form.Control
    type="tel"
    {...register("contactNumber", {
      required: true,
      pattern: {
        value: /^[0-9]*$/,
        message: "Contact number should contain only numbers",
      },
    })}
    onInput={(e) => {
      e.target.value = e.target.value.replace(/[^0-9]/g, ""); // Remove all non-numeric characters
    }}
    className={errors.contactNumber ? "is-invalid" : ""}
    placeholder="Enter your contact number"
  />
  {errors.contactNumber && (
    <div className="invalid-feedback">{errors.contactNumber.message}</div>
  )}
  </Col>
</Row>
                  <Row className="mb-3">
                    <Col md={3}>
                      <Form.Label>Email<sup style={{ lineHeight: "2", color: 'red' }}>*</sup></Form.Label>
                    </Col>
                    <Col md={9}>
                      <Form.Control
                        type="email"
                        {...register("email", { required: true })}
                        className={errors.email ? "is-invalid" : ""}
                        placeholder="Enter your email"
                      />
                      {errors.email && <div className="invalid-feedback">Please enter your email</div>}
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col md={3}>
                      <Form.Label>Message<sup style={{ lineHeight: "2", color: 'red' }}>*</sup></Form.Label>
                    </Col>
                    <Col md={9}>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        {...register("message", { required: true })}
                        className={errors.message ? "is-invalid" : ""}
                        placeholder="Enter your message"
                      />
                      {errors.message && <div className="invalid-feedback">Please enter your message</div>}
                    </Col>
                  </Row>
                  <Row className="justify-content-center">

                    <Col md={3}>
                      <Button
                        type="button"
                        variant="outline-primary"
                        className="w-100"
                        onClick={handleReset}
                        style={{ color: 'red', borderColor: '#e14b4f' }}
                        onMouseEnter={(e) => {
                          e.target.style.backgroundColor = '#e14b4f';
                          e.target.style.color = '#ffffff';
                        }}
                        onMouseLeave={(e) => {
                          e.target.style.backgroundColor = 'transparent';
                          e.target.style.color = '#e14b4f';
                        }}
                      >
                        Reset
                      </Button>
                    </Col>
                    <Col md={3}>
                      <Button
                        type="submit"
                        variant="primary"
                        className="w-100"
                        style={{ backgroundColor: '#0082CA', color: '#FFFFF', borderColor: '#0082CA', boxShadow: '0px 0px 6px 0px rgba(0, 0, 0, 0.25)' }}
                      >
                        Submit
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
          </Container> */}
          <Container className="col-8">
  <Card className="shade card" style={{ height: "517px" }}>
    <Card.Header
      as="h5"
      className="text-center"
      style={{ backgroundColor: '#EFEBE3', color: 'black' }}
    >
      Help & Support
    </Card.Header>
    <Card.Body className="p-5">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row className="mb-3">
          <Col md={3}>
            <Form.Label>
              Your Name<sup style={{ lineHeight: '2', color: 'red' }}>*</sup>
            </Form.Label>
          </Col>
          <Col md={9}>
            <Form.Control
              type="text"
              {...register('name', {
                required: 'Name is required',
                pattern: {
                  value: /^[A-Za-z ]+$/,
                  message: 'Name should contain only alphabets and spaces',
                },
              })}
              onInput={(e) => {
                e.target.value = e.target.value
                  .replace(/[^A-Za-z ]/g, '')
                  .replace(/^(.)/, (match) => match.toUpperCase());
              }}
              className={errors.name ? 'is-invalid' : ''}
              placeholder="Enter your name"
            />
            {errors.name && (
              <div className="invalid-feedback">{errors.name.message}</div>
            )}
          </Col>
        </Row>
        <Row className="mb-3">
        <Col md={3}>
                <Form.Label>
                  Contact Number<sup style={{ lineHeight: "2", color: "red" }}>*</sup>
                </Form.Label>
              </Col>
              <Col md={3}>
                <Form.Select
                  {...register("countryCode", {
                    required: "Country code is required",
                  })}
                  onChange={(e) => setCountryCode(e.target.value)}
                  value={countryCode}
                  className={`form-control ${
                    errors.countryCode ? "is-invalid" : ""
                  }`}
                >
                  <option value="+91">+91</option>
                  <option value="+1">+1 </option>
                </Form.Select>
                {errors.countryCode && (
                  <div className="invalid-feedback">
                    {errors.countryCode.message}
                  </div>
                )}
              </Col>
              <Col md={6}>
                <Form.Control
                  type="tel"
                  {...register("contactNumber", {
                    required: "Contact number is required",
                    validate: contactNumberValidation, // Dynamic validation
                    pattern: {
                      value: /^[0-9]*$/,
                      message: "Contact number should contain only numbers",
                    },
                  })}
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                  }}
                  className={errors.contactNumber ? "is-invalid" : ""}
                  placeholder="Enter your contact number"
                />
                {errors.contactNumber && (
                  <div className="invalid-feedback">
                    {errors.contactNumber.message}
                  </div>
                )}
              </Col>
        </Row>
        <Row className="mb-3">
          <Col md={3}>
            <Form.Label>
              Email<sup style={{ lineHeight: '2', color: 'red' }}>*</sup>
            </Form.Label>
          </Col>
          <Col md={9}>
            <Form.Control
              type="email"
              {...register('email', {
                required: 'Email is required',
                pattern: {
                  value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  message: 'Please enter a valid email address',
                },
              })}
              className={errors.email ? 'is-invalid' : ''}
              placeholder="Enter your email"
            />
            {errors.email && (
              <div className="invalid-feedback">{errors.email.message}</div>
            )}
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={3}>
            <Form.Label>
              Message<sup style={{ lineHeight: '2', color: 'red' }}>*</sup>
            </Form.Label>
          </Col>
          <Col md={9}>
            <Form.Control
              as="textarea"
              rows={3}
              {...register('message', {
                required: 'Message is required',
                minLength: {
                  value: 10,
                  message: 'Message must be at least 10 characters long',
                },
              })}
              className={errors.message ? 'is-invalid' : ''}
              placeholder="Enter your message"
            />
            {errors.message && (
              <div className="invalid-feedback">{errors.message.message}</div>
            )}
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md={3}>
            <Button
              type="button"
              variant="outline-primary"
              className="w-100"
              onClick={handleReset}
              style={{ color: 'red', borderColor: '#e14b4f' }}
              onMouseEnter={(e) => {
                e.target.style.backgroundColor = '#e14b4f';
                e.target.style.color = '#ffffff';
              }}
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = 'transparent';
                e.target.style.color = '#e14b4f';
              }}
            >
              Reset
            </Button>
          </Col>
          <Col md={3}>
            <Button
              type="submit"
              variant="primary"
              className="w-100"
              style={{
                backgroundColor: '#0082CA',
                color: '#FFFFF',
                borderColor: '#0082CA',
                boxShadow: '0px 0px 6px 0px rgba(0, 0, 0, 0.25)',
              }}
            >
              Submit
            </Button>
          </Col>
        </Row>
      </Form>
    </Card.Body>
  </Card>
</Container>
        </div>
      </div>
    </>
  );
};

export default HelpTab;


