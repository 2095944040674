import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
const InactivityDetector = ({ children }) => {
  const [isActive, setIsActive] = useState(true);
  const userId = useSelector((state) => state.user.userId);
  const authToken = useSelector((state) => state.user.token);
  const [isApiCalled, setIsApiCalled] = useState(false);
  useEffect(() => {
    let timeout;
    const resetTimer = () => {
      clearTimeout(timeout);
      setIsActive(false);
      timeout = setTimeout(() => {
        setIsActive(true);
        setIsApiCalled(false);
      }, 10 * 60 * 1000);
    };

    const handleActivity = async () => {
      if (!isApiCalled && userId && authToken) {
        setIsApiCalled(true);
        try {
          await window.apiService.activityUpdate(userId, { isActive: 1 });
        } catch (error) {
          console.error("Failed to update activity status:", error);
        }
      }
    };
    const handleMouseMove = () => {
      resetTimer();
      handleActivity();
    };
    const handleKeyPress = () => {
      handleMouseMove();
    };
    const handleUnload = async () => {
      if (userId && authToken) {
        try {
          await window.apiService.activityUpdate(userId, { isActive: 0 });
        } catch (error) {
          console.error("Failed to update on unload:", error);
        }
      }
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("keypress", handleKeyPress);
    window.addEventListener("beforeunload", handleUnload);

    return () => {
      clearTimeout(timeout);
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("keypress", handleKeyPress);
      window.removeEventListener("beforeunload", handleUnload);
    };
  }, [isApiCalled, userId, authToken]);

  return <div>{children(isActive)}</div>;
};

export default InactivityDetector;
