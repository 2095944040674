import {
  Collapse,
  Form,
  Input,
  InputGroup,
  NavbarBrand,
  Navbar,
  Container,
  Row,
  Col,
} from "reactstrap";
import { Nav, Tab, Modal, Button } from "react-bootstrap";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";
import { MdForwardToInbox, MdLiveHelp } from "react-icons/md";
import { TbLogout } from "react-icons/tb";
import { logoutAction } from "../../Redux/Actions/userAction";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import reportImg from "../../assets/images/report.png";
import inboxImg from "../../assets/images/in_img.jpg";
import inbox from "../../assets/images/inbx.jpg";
import logo from "../../assets/images/tiipstr_lgo.svg";
const SupportSideBar = () => {
  const location = useLocation();
  const [collapseOpen, setCollapseOpen] = useState();
  const [activePath, setActivePath] = useState("/dashboard");
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const userId = useSelector((state) => state.user.userId);
  const dispatch = useDispatch();
  const Logout = async () => {
    dispatch(logoutAction());
    // sessionStorage.removeItem("rememberMeToken");
    localStorage.removeItem("userdetail");
    navigate("/login");
    if (userId) {
      try {
        const response = await window.apiService.logout(userId, {
          isActive: 0,
        });
        console.log("response", response);
      } catch (error) {
        console.log(error);
      }
    }
  };
  const toggleCollapse = () => {
    setCollapseOpen((data) => !data);
  };
  const closeCollapse = () => {
    setCollapseOpen(false);
  };
  const navigate = useNavigate();
  return (
    <>
      <Navbar
        className="navbar-vertical fixed-left navbar-light bg-white"
        expand="md"
        id="sidenav-main"
      >
        <Container fluid className="flx_column">
          {/* Toggler */}
          <button
            className="navbar-toggler"
            type="button"
            onClick={toggleCollapse}
          >
            <span className="navbar-toggler-icon" />
          </button>
          {/* Brand */}
          <NavbarBrand className="pt-0">
            {/* <div className='tiip_logo'/> */}
            <img src={logo} height={80} width={100} />
          </NavbarBrand>

          <Collapse navbar isOpen={collapseOpen}>
            {/* Collapse header */}
            <div className="navbar-collapse-header d-md-none">
              <Row>
                <Col className="collapse-brand" xs="6">
                  <Link to="">
                    <img
                      alt=""
                      src={require("../../assets/images/tiipstrlogo.jpg")}
                    />
                  </Link>
                </Col>
                <Col className="collapse-close" xs="6">
                  <button
                    className="navbar-toggler"
                    type="button"
                    onClick={toggleCollapse}
                  >
                    <span />
                    <span />
                  </button>
                </Col>
              </Row>
            </div>
            {/* Form */}
            {/* <Form className="mt-4 mb-3 d-md-none">
              <InputGroup className="input-group-rounded input-group-merge">
                <Input
                  aria-label="Search"
                  className="form-control-rounded form-control-prepended"
                  placeholder="Search"
                  type="search"
                />
              </InputGroup>
            </Form> */}
            <Nav variant="pills" className="flex-column navStyle">
              {/* <Nav.Item>
                <Nav.Link disabled className={`navTab ${location.pathname === '/dashboard/report' ? 'active' : ''}`} onClick={() => {navigate('/dashboard/report');setActivePath('/dashboard/report');}}>
                <span className='tab_span'><img src={dashbrdImg} className="dashboardTabIcon"/></span>Dashboard
                </Nav.Link>
              </Nav.Item> */}
              <Nav.Item>
                <Nav.Link
                  className={`navTab ${location.pathname === "/dashboard" ? "active" : ""
                    }`}
                  onClick={() => {
                    navigate("/dashboard");
                    setActivePath("/dashboard");
                  }}
                >
                  <span className="tab_span">
                    <img src={reportImg} className="dashboardTabIcon" />
                  </span>
                  Report
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className={`navTab ${location.pathname === "/inbox/2" ? "active" : ""
                    }`}
                  onClick={() => {
                    navigate("/inbox/2");
                    setActivePath("/inbox/2");
                  }}
                >
                  <span className="tab_span">
                    <img
                      src={location.pathname === "/inbox" ? inbox : inboxImg}
                      className="dashboardTabIcon"
                    />
                  </span>
                  <span className="dash_tab_name">Inbox </span>{" "}
                </Nav.Link>
              </Nav.Item>
              <div className="fix_btm">
                <hr className="my-3" />
                <Nav.Item>
                  <Nav.Link className="navTab">
                    <span className="tab_span">
                      <MdLiveHelp className="dashboardTabIcon" />
                    </span>
                    Help
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    className="navTab"
                    onClick={() => setShowConfirmPopup(true)}
                  >
                    <span className="tab_span">
                      <TbLogout className="dashboardTabIcon" />
                    </span>
                    Logout
                  </Nav.Link>
                </Nav.Item>
              </div>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
      {/* ----------- Logout Confirmation Popup ---------- */}
      <Modal
        size="sm"
        show={showConfirmPopup}
        onHide={() => setShowConfirmPopup(false)}
      >
        <Modal.Header>
          <Modal.Title
            id="example-custom-modal-styling-title"
            style={{ fontSize: "15px", fontWeight: 600 }}
          >
            Logout Confirmation
          </Modal.Title>
          <span onClick={() => setShowConfirmPopup(false)} title="Close">
            <AiOutlineClose />
          </span>
        </Modal.Header>
        <Modal.Body className="bordr">
          <span>Are you sure you want to Logout?</span>
        </Modal.Body>
        <Modal.Footer className="bordr pad_13">
          <Button variant="primary" className="del_bttn" onClick={Logout}>
            Yes
          </Button>
          <Button
            variant="danger"
            className="del_bttn"
            onClick={() => setShowConfirmPopup(false)}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SupportSideBar;
