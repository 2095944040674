import { Card, Row, Col, Button } from "react-bootstrap";
import moment from "moment";
import "../../Register/register.css";
import StarRating from "./StarRating";
import profile from "../../../assets/images/profile.jpg";
import React, { useEffect, useState, useRef } from "react";
import { useToast } from "../../Toast";
import HeaderMenu from "../headerMenu";
import SideBar from "../SideBar";
import { GoDotFill } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import BreadCrumb from "../../plugins/Breadcrumb";
import loader from "../../../assets/images/loader.gif";
import { IoMdArrowDropdown, IoMdArrowDropup, IoMdClose } from "react-icons/io";
import { connect } from "react-redux";
import { AiOutlineClose } from "react-icons/ai";
import Modal from "react-bootstrap/Modal";
import RatingBar from "../../plugins/RatingBar";
import { FiUsers, FiUser } from "react-icons/fi";
import Horizontalbar from "../../plugins/Horizontalbar";
import { IoIosArrowDown } from "react-icons/io";
import { Overlay, Popover, OverlayTrigger } from "react-bootstrap";
import { BiSolidCommentDetail } from "react-icons/bi";
import { BsQuestionDiamond } from "react-icons/bs";
import { MdOutlineAttachment } from "react-icons/md";
import fileImg from "../../../assets/images/file.jpg";
import { followId } from "../../../Redux/Actions/userAction";
import mentionInputStyle from "../../../assets/css/MentionStyle";
import { MentionsInput, Mention } from "react-mentions";
import { useDispatch } from "react-redux";
import { commonUtils } from "../../CommonUtils";
import { TiTick } from "react-icons/ti";
import filter from "../../../assets/images/filter.jpg";
import { HiBadgeCheck } from "react-icons/hi";
import { BsFillPinAngleFill } from "react-icons/bs";
import AttachmentModal from "../../plugins/AttachmentModal";
import { useLocation } from "react-router-dom";
import {
  FacebookShareButton,
  TwitterShareButton,
  TwitterIcon,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from "react-share";
import { FaSquareXTwitter, FaLinkedin } from "react-icons/fa6";
import { BsShareFill } from "react-icons/bs";
import { createRoot } from "react-dom/client";
import html2canvas from "html2canvas";
import ViewShare from "../../Home/ViewShare";
import { useSubscription } from '../../SubscriptionContext';
import UpgradePopup from "../../plugins/UpgradePopup";
const ReviewsTab = (props) => {
  const [loading, setLoading] = useState(false);
  const [reviewInfo, setReviewInfo] = useState([]);
  const [reviewList, setReviewList] = useState([]);
  const [showReply, setShowReply] = useState(false);
  const [showAttachment, setShowAttachment] = useState(false);
  const [revCmnt, setRevCmnt] = useState([]);
  const [attachment, setAttachment] = useState([]);
  const [expandedCommentIndex, setExpandedCommentIndex] = useState(-1);
  const [revGlobalRtng, setRevGlobalRtng] = useState([]);
  const [avgCount, setAvgCount] = useState("");
  const [replies, setRevReplies] = useState([]);
  const [showChildReply, setShowChildReply] = useState(false);
  const [comment, setComment] = useState("");
  const [commentError, setCommentError] = useState("");
  const [childcurrentIndex, setchildcurrentIndex] = useState(null);
  const [childrepplycurrentIndex, setchildreplycurrentIndex] = useState([]);
  const [currentIndex, setcurrentIndex] = useState(null);
  const [reviewSts, setReviewSts] = useState("");
  const [cmtError, setCmtError] = useState("");
  const [cmtErrId, setCmtErrId] = useState(null);
  const [users, setUsers] = useState([]);
  const [mentionuserIds, setmentionuserIds] = useState([]);
  const [currentUsers, setCurrentUsers] = useState([]);
  const [selectedRoleId, setSelectedRoleId] = useState("");
  const [showFilterSection, setShowFilterSection] = useState(false);
  const [roleList, setRoleList] = useState("");
  const [filteredList, setFilteredList] = useState([]);
  const [avgRatng, setAvgRatng] = useState(0);
  const [ratngCount, setRatngCount] = useState(0);
  const [duration, setDuration] = useState('');
  const [showPinRev, setShowPinRev] = useState(false);
  const [pinBy, setPinBy] = useState("");
  const [id, setId] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [postBtnDisable, setPostBtnDisable] = useState(false);
  const [urlLink, setUrlLink] = useState("");
  const [isShare, setIsShare] = useState(false);
  const [formData, setFormData] = useState(null);
  const [shareData, setShareData] = useState({});
  const [userData, setUserData] = useState({});
  const location = useLocation();
  const { allFeatures, isFeatureEnabled } = useSubscription();
  const currentPath = location.pathname;
  const [showPopup, setShowPopup] = useState(false);
  const likeFeatureEnable = isFeatureEnabled(allFeatures["Like-Dislike%"]?.key, allFeatures["Like-Dislike%"]?.isDeleted);
  const renderRatingBar = (data, msgreply) => (
    <RatingBar
      likecount={data.likes}
      dislikecount={data.dislikes}
      msgreply={msgreply}
      liked={data.liked}
      handlelike={() => setShowPopup(true)}
      handledislike={() => setShowPopup(true)}
      handleRmvlike={() => setShowPopup(true)}
      labelVAlue={data.score}
    />
  );
  const toggleFilterSection = () => {
    setShowFilterSection(!showFilterSection);
  };
  const dispatch = useDispatch();
  const toast = useToast();
  const expendComment = (index) => {
    setExpandedCommentIndex(index === expandedCommentIndex ? -1 : index);
  };

  useEffect(() => {
    loadRoleList();
  }, [reviewInfo]);

  useEffect(() => {
    handleUserRole(props.selectedRoleId);
  }, [props.selectedRoleId]);

  const handleClosePin = () => {
    setShowPinRev(false);
    setDuration('');
    setErrMsg("");
  }
  // useEffect(() => {
  //   if (isShare && shareData) {
  //     convertToImageAndUpload();
  //   }
  // }, [isShare, shareData]);
  useEffect(() => {
    if (shareData?.userId && shareData?.id) {
      const shareUrl = `https://devapi.tiipstr.app/api/v1/fbshare/view/${shareData.userId}/${shareData.id}`;
      setUrlLink(shareUrl);
    }
  }, [shareData]);


  const CloseShare = () => {
    setIsShare(false);
    setUrlLink("");
    setShareData({});
  };
  const handleShare = (data) => {
    setIsShare(true);
    setShareData(data);
    // convertToImageAndUpload();
    handleImageDownload();
  };

  const dataURLtoBlob = (dataURL) => {
    const arr = dataURL.split(",");
    const mimeMatch = arr[0].match(/:(.*?);/);

    if (!mimeMatch || mimeMatch.length < 2) {
      console.error("Invalid dataURL format");
      return null;
    }

    const mime = mimeMatch[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new Blob([u8arr], { type: mime });
  };


  // const convertToImageAndUpload = async () => {
  //   const container = document.createElement("div");
  //   container.style.position = "absolute";
  //   container.style.left = "-9999px";
  //   container.style.top = "-9999px";
  //   document.body.appendChild(container);
  //   const viewShareComponent = (
  //     <ViewShare shareData={shareData} userData={userData} />
  //   );
  //   createRoot(container).render(viewShareComponent);
  //   await new Promise((resolve) => setTimeout(resolve, 2000));
  //   html2canvas(container).then(async (capturedCanvas) => {
  //     const imageDataUrl = capturedCanvas.toDataURL("image/png", 0.8);
  //     const blob = dataURLtoBlob(imageDataUrl);
  //     const file = new File([blob], "image.png", { type: "image/png" });

  //     console.log("File object:", file);

  //     const newFormData = new FormData();
  //     newFormData.append("id", shareData.id);
  //     newFormData.append("file", file);
  //     newFormData.append("type", "share");

  //     setFormData(newFormData);

  //     if (shareData.id) {
  //       try {
  //         if (!newFormData) {
  //           console.error("No file to upload");
  //           return;
  //         }

  //         const response = await window.apiService.reviewShare(newFormData, {
  //           headers: {
  //             "Content-Type": "multipart/form-data",
  //           },
  //         });

  //         const filepath =
  //           response.data.filePath + "?timestamp=" + new Date().getTime();
  //         setUrlLink(filepath);
  //       } catch (error) {
  //         console.error("API Error:", error);
  //       } finally {
  //         document.body.removeChild(container);
  //       }
  //     }
  //   });
  // };
  const handleImageDownload = async () => {
    const imgSrc =
      "https://tiipstr.s3.amazonaws.com/documents/development/profile/USR_10/tomjerry.jpeg";
    try {
      const response = await fetch(imgSrc);
      const blob = await response.blob();
      const link = document.createElement("a");
      const objectURL = URL.createObjectURL(blob);
      link.href = objectURL;
      link.download = "downloaded_image.jpg";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(objectURL);
    } catch (error) {
      console.error("Error downloading image:", error);
    }
  };

  const handleMouseEnter = (users) => {
    if (users.length > 0) setCurrentUsers(users);
    else setCurrentUsers([]);
  };
  const togglecomment = (id) => {
    setchildcurrentIndex(null);
    setShowChildReply(false);
    if (currentIndex == id) {
      setcurrentIndex(null);
    } else {
      setcurrentIndex(id);
    }
  };
  const getRevQnsRating = (value) => {
    const num =
      value.reduce((total, next) => total + next.rating, 0) / value.length;
    return num.toFixed(1);
  };
  const changeCmtText = (value) => {
    commonUtils.changeSimpleCmtText(
      value,
      setCommentError,
      setmentionuserIds,
      setComment
    );
  };
  const togglechildcomment = (id) => {
    setComment("");
    if (childrepplycurrentIndex == id) {
      setchildreplycurrentIndex(null);
    } else {
      setchildreplycurrentIndex(id);
    }
  };
  const loadRevComments = async (revId) => {
    try {
      const response = await window.apiService.reviewCmtList({ id: revId });
      setRevCmnt(response.data.recordInfo.reviewComments);
      setRevReplies(response.data.recordInfo.reviewReplies);
    } catch (error) {
      toast.error("Error Occured");
    }
  };
  const handlerplylikechange = (id, revId) => {
    updaterplyLikeStatus({
      reviewId: revId,
      commentId: id,
      userId: props.userId,
      liked: 1,
    });
  };
  const handlerplydislikechange = (id, revId) => {
    updaterplyLikeStatus({
      reviewId: revId,
      commentId: id,
      userId: props.userId,
      liked: 0,
    });
  };
  const rplylikecntDecrement = (id, revId) => {
    updaterplyLikeStatus({
      reviewId: revId,
      commentId: id,
      userId: props.userId,
      liked: -1,
    });
  };
  const updaterplyLikeStatus = async (likeParams) => {
    try {
      const response = await window.apiService.addCommentLike(likeParams);
      if (response.data.status == "OK") {
        loadRevComments(likeParams.reviewId);
      }
    } catch (error) {
      toast.error("Error Occured");
    }
  };
  const postComment = async (id, revId) => {
    if (comment.trim().length !== 0) {
      let params = {
        userId: props.userId,
        commentText: comment,
        reviewId: revId,
        parentCommentId: id,
        mentionUserIds: mentionuserIds,
        professionalId:
          props.userType == "Professional" ? props.profileId : null,
        businessId: props.userType == "Business" ? props.profileId : null,
      };
      setPostBtnDisable(true);
      try {
        const response = await window.apiService.addComment(params);
        if (response.data.status == "OK") {
          toast.success(response.data.message);
          setComment("");
          setmentionuserIds([]);
          togglecomment(response.data.id);
          loadRevComments(params.reviewId);
          setPostBtnDisable(false);
        }
      } catch (error) {
        console.log(error);
        toast.error("Error Occured");
        setPostBtnDisable(false);
      }
    } else {
      setCmtError("Comment text is required");
      setCmtErrId(id);
    }
  };
  const navigate = useNavigate();
  useEffect(() => {
    loadReviewList();
    userLists();
  }, [props]);
  const userLists = async () => {
    try {
      const response = await window.apiService.lookupAllUsersList();
      const usersArr = response.data.recordInfo.filter((row) => {
        return (
          row.userId != props.userId &&
          row.userId.split("-")[0] != props.userId &&
          row.userId.split("&")[0] != props.userId
        );
      });
      const users = usersArr.map(function (row) {
        return { display: row.name, id: row.userId };
      });
      setUsers(users);
    } catch (error) {
      console.log(error);
    }
  };

  const loadReviewList = async () => {
    if (props.userId) {
      setLoading(true);

      const params = props.userData
        ? {
          userId: props.userData.id,
          userType: props.userData.userType,
          profileId: props.userData.userType !== "Personal" ? props.userData.profileId : undefined,
        }
        : {
          userId: props.userId,
          userType: props.userType,
          profileId: props.userType !== "Personal" ? props.profileId : undefined,
        };
      try {
        if (params.userId) {
          const response = await window.apiService.latestReview(params);
          setLoading(false);
          if (response.data.status == "OK") {
            let result = response.data.recordInfo;
            setReviewInfo(result);
          }
        }
      } catch (error) {
        setLoading(false);
        toast.error("Error Occured");
      }
    }

  };
  const viewRevComments = (data, replies, sts) => {
    loadRevComments(data[0].reviewId);
    setShowReply(!showReply);
    setReviewSts(sts);
    setShowChildReply(false);
    setcurrentIndex(null);
    setchildreplycurrentIndex(null);
  };
  const viewChildComments = (data, id, revId) => {
    setcurrentIndex(null);
    setShowChildReply(!showChildReply);
    if (childcurrentIndex == id) {
      setchildcurrentIndex(null);
    } else {
      setchildcurrentIndex(id);
    }
    loadRevComments(revId);
  };
  const viewAttachment = (data) => {
    setShowAttachment(true);
    setAttachment(data);
  };
  const handlelikechange = (id, userId) => {
    updateLikeStatus({ reviewId: id, userId: userId, liked: 1 });
  };
  const handledislikechange = (id, userId) => {
    updateLikeStatus({ reviewId: id, userId: userId, liked: 0 });
  };
  const updateLikeStatus = async (likeParams) => {
    try {
      const response = await window.apiService.addLike(likeParams);
      if (response.data.status == "OK") {
        loadReviewList();
      }
    } catch (error) {
      toast.error("Error Occured");
    }
  };
  const likecntDecrement = (id, userId) => {
    updateLikeStatus({ reviewId: id, userId: userId, liked: -1 });
  };
  const handleArrowClick = (rating, avgCount) => {
    setAvgCount(avgCount);
    const result = rating.map((starData) => ({
      label: starData.star,
      value: starData.totalReviewCount,
      userRatings: starData.userRatings,
    }));
    result.sort((a, b) => {
      const numA = parseInt(a.label.split(" ")[0]);
      const numB = parseInt(b.label.split(" ")[0]);
      return numB - numA;
    });
    setRevGlobalRtng(result);
  };
  const checkExt = (file) => {
    let allowedfiletypes = ["jpg", "svg", "jpeg", "png", "bmp", "gif", "jfif"];
    if (
      allowedfiletypes.some(
        (ext) =>
          ext == file.fileName.substring(file.fileName.lastIndexOf(".") + 1)
      )
    ) {
      return true;
    }
    return false;
  };

  const handleMention = (mentionedUser) => {
    const match = mentionedUser.match(/@\[([^\]]+)\](?:\(([^)]+)\))?/);
    if (match) {
      const [, name, userIdPart] = match;
      let userId = userIdPart;
      let busnId = null;
      let profId = null;
      if (userIdPart && userIdPart.includes("&")) {
        [userId, busnId] = userIdPart.split("&");
      } else if (userIdPart && userIdPart.includes("-")) {
        [userId, profId] = userIdPart.split("-");
      }
      if (name && userId) {
        if (userId !== props.userId) {
          dispatch(followId(userId));
          navigate("/dashboard/viewuser", {
            state: {
              userId,
              userType:
                profId || busnId
                  ? profId
                    ? "Professional"
                    : "Business"
                  : "Personal",
              profileId: profId || busnId ? (profId ? profId : busnId) : null,
            },
          });
        }
      }
    }
  };
  const rmvMenFormat = (value) => {
    const mentionRegex = /@\[([^\]]+?)\](?:\(\d+([&-])\d+\))|@([^\s]+)/g;
    const match = mentionRegex.exec(value);
    if (match) {
      if (match[3]) {
        const nestedMatch = match[3].match(/\[([^\]]+)\]\(\d+\)/);
        return nestedMatch ? nestedMatch[1] : "";
      }
      return match[1] || "";
    }
    return null;
  };
  function textWithLinks(text) {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const mentionRegex = /@\[([^\]]+?)\](\((\d+([&-])\d+)?\))|@([^\s]+)/g;
    return text.split(/\s(?![^\[]*])/).map((word, index) => {
      if (urlRegex.test(word)) {
        return (
          <a key={index} href={word} target="_blank" rel="noopener noreferrer">
            {word}
          </a>
        );
      } else if (mentionRegex.test(word)) {
        const mentions = word.match(mentionRegex);
        if (mentions) {
          return mentions.map((mention, mentionIndex) => {
            const match = mention.match(/@\[([^\]]+?)\](\((\d+([&-])\d+)?\))/g);
            const id = match ? match[2] : null;
            return (
              <span
                key={index + mentionIndex}
                className={id == props.userId ? "" : "cmt_icon c_pointer"}
                onClick={() => handleMention(mention)}
              >
                {rmvMenFormat(mention)}
              </span>
            );
          });
        }
      }

      return word;
    });
  }

  const loadRoleList = async () => {
    try {
      const response = await window.apiService.loadUserRoles();
      const userReviewRoleIds = new Set(reviewInfo.map(review => review.roleId));
      const filteredRoleList = response.data.recordInfo.filter(role => userReviewRoleIds.has(role.id));
      setRoleList(filteredRoleList);
    } catch (error) {
      console.log(error);
    }
  };
  const clear = () => {
    setSelectedRoleId("");
    setAvgRatng(0);
  }
  const handleUserRole = (id) => {
    console.log("dsadfad", props)
    const roleId = id;
    setSelectedRoleId(roleId);
    if (roleId) {
      const filtered = reviewInfo.filter(
        (review) => review.roleId === parseInt(props.selectedRoleId ? props.selectedRoleId : roleId, 10)
      );
      setFilteredList(filtered);
      if (filtered.length > 0) {
        const totalRating = filtered.reduce((acc, review) => acc + (review.rating || 0), 0);
        const averageRating = totalRating / filtered.length;
        setAvgRatng(averageRating);
        setRatngCount(filtered.length);
      } else {
        console.log("No reviews available for the selected role.");
      }
    } else {
      setFilteredList(reviewInfo);
    }
  };

  const pinReview = async (id, pinnedBy) => {

    setId(id);
    setPinBy(pinnedBy);
    setShowPinRev(true);

  };

  const handlePinReview = async () => {
    if (!duration && pinBy != null) {
      setErrMsg('Please select a pin duration');
      return;
    }
    const params = {
      id: id,
      pinnedBy: pinBy,
      duration: duration,
      userId: props && props.userId
    };
    if (id) {
      try {
        const response = await window.apiService.pinReview(params);
        if (response.status === "OK") {
          loadReviewList();
          setShowPinRev(false);
          setDuration('');
          setPinBy("");
          setErrMsg("");
        } else {
          toast.error(response.message);
        }
      } catch (error) {
        console.log(error);
        toast.error("Error occurred");
      }
    }
  }


  return (
    <>
      {currentPath == "/dashboard/reviews" && <HeaderMenu />}
      <div className="">
        {currentPath == "/dashboard/reviews" && <SideBar />}
        <div className={currentPath == "/dashboard/reviews" ? "main-content ml_200" : ""}>
          {currentPath == "/dashboard/reviews"}
          <Card className="shade h-500">
            {currentPath == "/dashboard/reviews" && <div>
              <div className="postedReviews">
                <h5>Reviews I've Posted</h5>
                <div>
                  <h5 className="pro_tab_span d-flex" style={{ paddingRight: "0 px" }}>
                    <span className="filImg" title="filter" onClick={toggleFilterSection}>
                      <img src={filter} height={18} className="c_pointer" />
                    </span>
                    {showFilterSection && (
                      <>
                        <div className="rev_fil">
                          <select
                            className="border border-0 fs_12 clr_flter"
                            value={selectedRoleId}
                            onChange={(event) => handleUserRole(event.target.value)}
                          >
                            <option value="">Select Reviewed as</option>
                            {roleList &&
                              roleList.map((role) => (
                                <option key={role.id} value={role.id}>
                                  {role.name}
                                </option>
                              ))}
                          </select>
                          {selectedRoleId && <span className="clr_icn c_pointer" onClick={clear}>
                            <IoMdClose />
                          </span>}</div>
                        {avgRatng != 0 && <div className="rev_fil">
                          <span className="d-flex align-items-center" style={{ fontSize: "12px", height: "24px" }}>
                            <span className="mar_ratng">
                              {Number(avgRatng).toFixed(1)}
                            </span>
                            <StarRating
                              className="avgRat"
                              rating={avgRatng}
                              disabled={true}
                              customClass="special-star"
                            />
                            <span className="mar_cnt" >({ratngCount})</span>
                          </span>
                        </div>}
                      </>
                    )}
                  </h5>
                </div>
              </div>
            </div>}
            <div className={currentPath == "/dashboard/reviews" ? "pad_50" : "m-0 p-0 w-100"}>
              {reviewInfo.length > 0 ? (
                (selectedRoleId ? filteredList : reviewInfo).sort((a, b) => {
                  const userId = props.userId;

                  const isAPinned = a.pinnedBy === userId && new Date(a.pinExpiration) > new Date();
                  const isBPinned = b.pinnedBy === userId && new Date(b.pinExpiration) > new Date();

                  if (isAPinned && !isBPinned) return -1;
                  if (!isAPinned && isBPinned) return 1;

                  if (isAPinned && isBPinned) {
                    return new Date(b.updatedAt) - new Date(a.updatedAt);
                  }

                  return 0;
                }).map((review, i) => (
                  <div key={i}>
                    <Card className="shadow reviewCard">
                      {isFeatureEnabled(allFeatures["Pin-review%"]?.key, allFeatures["Pin-review%"]?.isDeleted) ? <React.Fragment>
                        {currentPath == "/dashboard/reviews" && <span>
                          {review.pinnedBy == props.userId && new Date(review.pinExpiration) > new Date() ? (<div className="pinRev c_pointer" title="Un Pin" onClick={() => pinReview(review.id, null)}><BsFillPinAngleFill /></div>) :
                            (<div className="tr_dot c_pointer" title="Pin a review" onClick={() => pinReview(review.id, props.userId && props.userId)}><BsFillPinAngleFill /></div>)}
                        </span>}</React.Fragment> : <div className="tr_dot c_pointer" title="Pin a review" onClick={() => setShowPopup(true)}><BsFillPinAngleFill /></div>}
                      <Card.Body
                        className={`${review.status == "blocked" ? "blockedCrd" : ""
                          }`}
                        style={{ padding: "15px" }}
                      >
                        <Row>
                          <Col sm={12} md={6} lg={6} xl={6}>
                            <Row>
                              <Col sm={12} md={12} lg={12} xl={11}>
                                <Row>
                                  <Col
                                    sm={4}
                                    md={7}
                                    lg={4}
                                    xl={4}
                                    className="text-center"
                                  >
                                    <div className="kyc_vf">
                                      <img
                                        className="rounded-circle"
                                        style={{ width: "70px", height: "70px" }}
                                        src={
                                          review.userImagePath
                                            ? review.userImagePath
                                            : profile
                                        }
                                      /></div>
                                    {review.status != "blocked" ? (
                                      <div
                                        style={{
                                          marginBottom: "0px",
                                          fontWeight: "500",
                                          color: "#5e72e4",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          dispatch(
                                            followId(
                                              review.userId,
                                              review.professionalId != undefined
                                                ? "Professional"
                                                : review.reviewedBy &&
                                                  review.professionalId ==
                                                  undefined &&
                                                  review.businessId == undefined
                                                  ? "Personal"
                                                  : review.businessId != undefined
                                                    ? "Business"
                                                    : "",
                                              review.professionalId
                                                ? review.professionalId
                                                : review.businessId
                                                  ? review.businessId
                                                  : null
                                            )
                                          );
                                          navigate("/dashboard/viewuser", {
                                            state: {
                                              userId: review.userId,
                                              userType:
                                                review.professionalId !=
                                                  undefined
                                                  ? "Professional"
                                                  : review.reviewedBy &&
                                                    review.professionalId ==
                                                    undefined &&
                                                    review.businessId ==
                                                    undefined
                                                    ? "Personal"
                                                    : review.businessId !=
                                                      undefined
                                                      ? "Business"
                                                      : "",
                                              profileId: review.professionalId
                                                ? review.professionalId
                                                : review.businessId
                                                  ? review.businessId
                                                  : null,
                                            },
                                          });
                                        }}
                                      >
                                        {review.businessId
                                          ? ""
                                          : <>{review.userName}
                                            {isFeatureEnabled(allFeatures["Kyc%"]?.key, allFeatures["Kyc%"]?.isDeleted) && review.isKycVerified ? (
                                              <HiBadgeCheck
                                                style={{
                                                  fontSize: "17px",
                                                  color: "green",
                                                  cursor: "pointer",
                                                  marginLeft: "5px"
                                                }}
                                                title="KYC Verified"
                                                data-toggle="tooltip"
                                                data-placement="top"
                                              />
                                            ) : (
                                              <></>
                                            )}</>}
                                        <div title={review.businessTypeName}>
                                          {review.profTypeName != undefined &&
                                            "(" + review.profTypeName + ")"}
                                          {(review.businessTypeName !=
                                            undefined ||
                                            review.busnShortName !=
                                            undefined) &&
                                            (review.busnShortName
                                              ? review.busnShortName
                                              : review.businessTypeName)}
                                        </div>
                                      </div>
                                    ) : (
                                      <div
                                        style={{
                                          marginBottom: "0px",
                                          fontWeight: "500",
                                          color: "gray",
                                        }}
                                      >
                                        {review.userName}
                                      </div>
                                    )}
                                    <div
                                      className="fs_12 d-flex align-items-center justify-content-center"
                                      style={{ marginBottom: "0px" }}
                                    >
                                      {review.averageReviewScore ? (
                                        <label className="font-weight-bold mar-10">
                                          {review.averageReviewScore.toFixed(1)}
                                          &nbsp;
                                        </label>
                                      ) : (
                                        ""
                                      )}
                                      <StarRating
                                        rating={review.averageReviewCount}
                                        disabled={true}
                                        customClass="ques-star"
                                      />
                                      <span>({review.totalReviewCount})</span>
                                      <OverlayTrigger
                                        trigger="click"
                                        key={review.id}
                                        placement="bottom"
                                        overlay={
                                          <Popover
                                            id={`popover-${review.id}`}
                                            className="pop_rating"
                                          >
                                            <div className="drp_rating">
                                              <StarRating
                                                rating={avgCount}
                                                disabled={true}
                                              />
                                              <span>{avgCount} out of 5</span>{" "}
                                            </div>
                                            {revGlobalRtng.map(
                                              (item, index) => (
                                                <Row key={index}>
                                                  <Col
                                                    sm={12}
                                                    md={3}
                                                    lg={3}
                                                    xl={2}
                                                    className="p-0"
                                                  >
                                                    <span className="prog_span">
                                                      {item.label}
                                                    </span>{" "}
                                                  </Col>
                                                  <Col
                                                    sm={12}
                                                    md={8}
                                                    lg={8}
                                                    xl={7}
                                                    className="p-0"
                                                  >
                                                    <Horizontalbar
                                                      dataSet={item.value}
                                                    />
                                                  </Col>
                                                  <Col
                                                    sm={12}
                                                    md={3}
                                                    lg={3}
                                                    xl={2}
                                                    className="p-0 prog_span"
                                                    style={{
                                                      marginLeft: "10px",
                                                    }}
                                                  >
                                                    <span className="tooltip">
                                                      <span
                                                        className="c_pointer"
                                                        onMouseEnter={() =>
                                                          handleMouseEnter(
                                                            item.userRatings
                                                          )
                                                        }
                                                      >
                                                        {item.value}
                                                        {item.value > 1 ? (
                                                          <FiUsers
                                                            style={{
                                                              marginLeft: "6px",
                                                            }}
                                                          />
                                                        ) : (
                                                          <FiUser
                                                            style={{
                                                              marginLeft: "6px",
                                                            }}
                                                          />
                                                        )}
                                                      </span>
                                                      {commonUtils.ratingTooltip(
                                                        currentUsers
                                                      )}
                                                    </span>
                                                  </Col>
                                                </Row>
                                              )
                                            )}
                                          </Popover>
                                        }
                                        rootClose
                                      >
                                        <span
                                          className="d-flex align-items-center c_pointer"
                                          onClick={() =>
                                            handleArrowClick(
                                              review.starRating,
                                              review.averageReviewCount
                                            )
                                          }
                                        >
                                          <IoIosArrowDown className="clr_gray" />
                                        </span>
                                      </OverlayTrigger>{" "}
                                    </div>
                                  </Col>
                                  <Col
                                    sm={8}
                                    md={5}
                                    lg={8}
                                    xl={8}
                                    className="bdr_lt"
                                  >
                                    <Card.Text
                                      className="fts_15"
                                      style={{ marginBottom: "0px" }}
                                    >
                                      <div
                                        className={` ${expandedCommentIndex === i
                                          ? "show-full"
                                          : ""
                                          }`}
                                        title={review.comment}
                                      >
                                        {review.comment.length >= 75 &&
                                          expandedCommentIndex !== i
                                          ? textWithLinks(
                                            review.comment.substring(0, 75)
                                          )
                                            .map((word, index) => (
                                              <span key={index}>{word} </span>
                                            ))
                                            .concat("...")
                                          : textWithLinks(review.comment).map(
                                            (word, index) => (
                                              <span key={index}>{word} </span>
                                            )
                                          )}
                                      </div>

                                      {review.comment.length >= 75 && (
                                        <button
                                          onClick={() => expendComment(i)}
                                          className="read-more-button"
                                        >
                                          {expandedCommentIndex === i
                                            ? "Read less"
                                            : "Read more"}
                                        </button>
                                      )}
                                    </Card.Text>
                                    {review.reviewComments.length != 0 && (
                                      <Card.Text
                                        className="rply c_pointer"
                                        title="view review comment"
                                        style={{ marginBottom: "0px" }}
                                        onClick={() =>
                                          viewRevComments(
                                            review.reviewComments,
                                            review.reviewReplies,
                                            review.status
                                          )
                                        }
                                      >
                                        {review.reviewComments.length === 1
                                          ? `${review.reviewComments.length} Reply `
                                          : `${review.reviewComments.length} Replies `}
                                      </Card.Text>
                                    )}
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </Col>
                          <Col sm={12} md={6} lg={3} xl={3}>
                            <Row>
                              <Card.Text style={{ marginBottom: "2px" }}>
                                <span className="txt_rev">Reviewed as:</span>{" "}
                                <span className="fts_15">
                                  {review.userRole}
                                </span>
                              </Card.Text>
                              <Card.Text style={{ marginBottom: "0px" }}>
                                <span className="txt_rev">Date of Review:</span>
                                <span className="fts_15">
                                  {" "}
                                  {moment(review.createdAt).format(
                                    "DD-MMM-YYYY"
                                  )}
                                </span>
                              </Card.Text>
                              <Card.Text style={{ marginBottom: "0px" }}>
                                <div className="d-flex">
                                  {review.rating != 0 ? (
                                    <label className="font-weight-bold mar-10">
                                      {review.rating.toFixed(1)}
                                    </label>
                                  ) : (
                                    ""
                                  )}
                                  <StarRating
                                    rating={review.rating}
                                    disabled={true}
                                    customClass="special-star"
                                  />
                                  {review.reviewQuestion.length != 0 && (
                                    <OverlayTrigger
                                      trigger="click"
                                      key={review.id}
                                      placement="bottom"
                                      overlay={
                                        <Popover
                                          id={`popover-${review.id}`}
                                          className="pop_rating"
                                        >
                                          <div className="drp_rating"></div>
                                          {review.reviewQuestion.length ===
                                            0 ? (
                                            <div>No questions found</div>
                                          ) : (
                                            review.reviewQuestion.map(
                                              (item, index) => (
                                                <Row
                                                  className="d-flex"
                                                  key={index}
                                                >
                                                  <Col
                                                    sm={1}
                                                    md={1}
                                                    lg={1}
                                                    xl={1}
                                                    className="p-0 prog_span d-flex align-items-center"
                                                  >
                                                    {index + 1}.
                                                  </Col>
                                                  <Col
                                                    sm={5}
                                                    md={5}
                                                    lg={5}
                                                    xl={5}
                                                    className="p-0 d-flex align-items-center"
                                                  >
                                                    <span className="prog_span">
                                                      {item.question}
                                                    </span>
                                                  </Col>
                                                  <Col
                                                    sm={6}
                                                    md={6}
                                                    lg={6}
                                                    xl={6}
                                                    className="p-0"
                                                  >
                                                    <StarRating
                                                      rating={item.rating}
                                                      disabled={true}
                                                      customClass="ques-star"
                                                    />
                                                  </Col>
                                                </Row>
                                              )
                                            )
                                          )}
                                        </Popover>
                                      }
                                      rootClose
                                    >
                                      <span
                                        style={{
                                          marginTop: "5px",
                                          marginLeft: "5px",
                                        }}
                                        className="d-flex align-items-center c_pointer"
                                        onClick={() =>
                                          handleArrowClick(
                                            review.starRating,
                                            review.averageReviewCount
                                          )
                                        }
                                      >
                                        <BsQuestionDiamond className="clr_gray" />
                                      </span>
                                    </OverlayTrigger>
                                  )}
                                  {review.reviewAttachment.length != 0 && (
                                    <span
                                      title="Attachment"
                                      className="attachmt"
                                      onClick={() =>
                                        viewAttachment(review.reviewAttachment)
                                      }
                                    >
                                      <MdOutlineAttachment />
                                    </span>
                                  )}
                                  {isFeatureEnabled(allFeatures["SM-Share-Feature%"]?.key, allFeatures["SM-Share-Feature%"]?.isDeleted) ? <React.Fragment>
                                    <span className="shre_icn">
                                      {review.status === "reported" ||
                                        review.status == "blocked" ? (
                                        <BsShareFill className="dis_share_icon" title="Share" />
                                      ) : (
                                        <BsShareFill
                                          className="share_icon"
                                          title="Share"
                                          onClick={() => handleShare(review)}
                                        />
                                      )}
                                    </span></React.Fragment> : <BsShareFill
                                    className="share_icon"
                                    title="Share"
                                    onClick={() => setShowPopup(true)}
                                  />}
                                </div>
                              </Card.Text>
                              {review.status == "blocked" && (
                                <Card.Text
                                  style={{ marginBottom: "0px", color: "red" }}
                                >
                                  Blocked
                                </Card.Text>
                              )}
                            </Row>
                          </Col>
                          <Col sm={12} md={12} lg={3} xl={3}>
                            {likeFeatureEnable ? (<RatingBar
                              disabled={true}
                              likecount={review.likes}
                              dislikecount={review.dislikes}
                              liked={review.liked}
                              handlelike={() =>
                                handlelikechange(review.id, review.userId)
                              }
                              handledislike={() =>
                                handledislikechange(review.id, review.userId)
                              }
                              handleRmvlike={() =>
                                likecntDecrement(review.id, review.userId)
                              }
                              labelVAlue={review.score}
                            />) : (
                              <>
                                {renderRatingBar(review, false)}
                              </>
                            )
                            }
                          </Col>
                          {/* <Col
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            className="more_rev c_pointer fts_15"
                          >
                            {review.count > 1 && (
                              <span
                                onClick={() => {
                                  navigate("/dashboard/reviews/more", {
                                    state: {
                                      userIdRev: review.userId,
                                      review: review,
                                    },
                                  });
                                }}
                              >
                                More...{" "}
                              </span>
                            )}
                          </Col> */}
                        </Row>
                      </Card.Body>
                    </Card>
                  </div>
                ))
              ) : loading ? (
                <div className="text-center">
                  <img className="loader-image" src={loader} alt="Loading..." />
                </div>
              ) : (
                <div className="row col-md-12 d-align mx-0 w-100 p-0">
                  <div className="no-data w-100 text-center font-weight-600 fz_14">
                    Sorry, there is no data to be displayed
                  </div>
                </div>
              )}
            </div>
          </Card>
        </div>
        <Modal
          size="md"
          className="report_modal"
          show={showReply}
          onHide={() => setShowReply(false)}
        >
          <Modal.Header
            className="border_rst__btm"
            style={{ padding: "15px 0px!important" }}
          >
            <Modal.Title
              id="example-custom-modal-styling-title"
              style={{ fontSize: "15px", fontWeight: 600 }}
            >
              <h6 className="font-weight-bold">Review Comments</h6>
            </Modal.Title>
            <span
              onClick={() => {
                setShowReply(false);
              }}
              title="Close"
              style={{ cursor: "pointer" }}
            >
              <AiOutlineClose />
            </span>
          </Modal.Header>
          <Modal.Body style={{ fontSize: "14px" }}>
            {revCmnt &&
              revCmnt.map((data, i) => (
                <Row>
                  <Col
                    className={`revCmtPop pb-0 ${showChildReply && replies.length != 0 ? "revcmt_bg" : ""
                      }`}
                  >
                    <div className="d-flex">
                      <div>
                        <img
                          className="brd_rad h_w_25"
                          src={
                            data.userImagePath ? data.userImagePath : profile
                          }
                        />
                      </div>
                      <div style={{ marginLeft: "8px" }}>{data.userName}</div>
                      <span className="dat_icn">
                        <GoDotFill />
                      </span>{" "}
                      <div
                        className="fz_11 dat_icn"
                        style={{ color: "#959595" }}
                      >
                        {" "}
                        {moment
                          .utc(data.createdAt)
                          .local()
                          .startOf("seconds")
                          .fromNow()}
                      </div>
                    </div>
                    <div className="ctTxt">
                      {textWithLinks(data.commentText).map((word, index) => (
                        <span key={index}>{word} </span>
                      ))}
                    </div>
                    <div class="send d-flex gap_20 mb-2 mt-2">
                      {likeFeatureEnable ? (<RatingBar
                        disabled={
                          reviewSts == "reported" ||
                            data.userId === props.userId
                            ? true
                            : false
                        }
                        msgreply={true}
                        liked={data.liked}
                        likecount={data.likes}
                        dislikecount={data.dislikes}
                        handlelike={() =>
                          handlerplylikechange(data.id, data.reviewId)
                        }
                        handledislike={() =>
                          handlerplydislikechange(data.id, data.reviewId)
                        }
                        handleRmvlike={() =>
                          rplylikecntDecrement(data.id, data.reviewId)
                        }
                        labelVAlue={data.score}
                      />) : (
                        <>
                          {renderRatingBar(data, true)}</>
                      )}
                      {reviewSts == "reported" ||
                        data.userId === props.userId ? (
                        <BiSolidCommentDetail className="cmt_icn clr_gray" />
                      ) : (
                        <BiSolidCommentDetail
                          className="cmt_icon c_pointer"
                          title="Add comment"
                          onClick={() => togglecomment(data.id)}
                        />
                      )}
                      <Row>
                        {replies.filter(
                          (obj) =>
                            obj.id == data.id || obj.parentCommentId == data.id
                        ).length != 0 && (
                            <span
                              className="rply c_pointer"
                              onClick={() =>
                                viewChildComments(replies, data.id, data.reviewId)
                              }
                            >
                              {replies.filter(
                                (obj) =>
                                  obj.id == data.id ||
                                  obj.parentCommentId == data.id
                              ).length === 1
                                ? `${replies.filter(
                                  (obj) =>
                                    obj.id == data.id ||
                                    obj.parentCommentId == data.id
                                ).length
                                } Reply `
                                : `${replies.filter(
                                  (obj) =>
                                    obj.id == data.id ||
                                    obj.parentCommentId == data.id
                                ).length
                                } Replies `}
                              <span>
                                {childcurrentIndex == data.id ? (
                                  <IoMdArrowDropup />
                                ) : (
                                  <IoMdArrowDropdown />
                                )}
                              </span>
                            </span>
                          )}
                      </Row>
                    </div>
                    {currentIndex == data.id ? (
                      <Row>
                        <div className="position-relative p-0">
                          {cmtError && cmtErrId == data.id ? (
                            <div className="text-danger ml-2 fz-12">
                              {cmtError}
                            </div>
                          ) : (
                            ""
                          )}
                          <MentionsInput
                            style={mentionInputStyle}
                            className="form-control Cmt_box"
                            maxLength={288}
                            onChange={(e) => {
                              changeCmtText(e.target.value);
                            }}
                            value={comment}
                            placeholder="type someting..."
                          >
                            <Mention
                              style={{ backgroundColor: "#efebe3" }}
                              trigger="@"
                              data={users}
                            />
                          </MentionsInput>
                          {/* <textarea className="form-control Cmt_box" placeholder="type someting..." onChange={(e) => setchildComment(e.target.value)} value={childComment}/> */}
                          <button disabled={postBtnDisable}
                            className="btn btn-primary cmt_btn"
                            onClick={() => {
                              if (isFeatureEnabled(allFeatures["Add-Comment%"]?.key, allFeatures["Add-Comment%"]?.isDeleted)) {
                                postComment(data.id, data.reviewId)
                              }
                              else {
                                setShowPopup(true);
                              }
                            }}
                          >
                            Post
                          </button>
                        </div>
                      </Row>
                    ) : (
                      ""
                    )}
                    {childcurrentIndex == data.id && replies.length != 0
                      ? replies.map((item, i) =>
                        item.id == data.id ||
                          item.parentCommentId == data.id ? (
                          <Row>
                            <Col className="revchdcmt pb-0 p-2">
                              <div className="d-flex">
                                <div>
                                  <img
                                    className="brd_rad h_w_25"
                                    src={
                                      item.replyUserImagePath
                                        ? item.replyUserImagePath
                                        : profile
                                    }
                                  />
                                </div>
                                <div
                                  className="d-flex"
                                  style={{
                                    marginLeft: "8px",
                                    alignItems: "center",
                                  }}
                                >
                                  {item.replyUserName}
                                  <span className="dat_icn">
                                    <GoDotFill />
                                  </span>{" "}
                                  <div
                                    style={{
                                      fontSize: "11px",
                                      color: "#959595",
                                    }}
                                  >
                                    {" "}
                                    {moment
                                      .utc(item.createdAt)
                                      .local()
                                      .startOf("seconds")
                                      .fromNow()}
                                  </div>
                                </div>
                              </div>
                              <div className="ctTxt">
                                <span className="rep_men">
                                  {"@" + item.userName + " "}
                                </span>
                                {textWithLinks(item.replyCommentText).map(
                                  (word, index) => (
                                    <span key={index}>{word} </span>
                                  )
                                )}
                              </div>
                              <div class="send d-flex gap_20 mb-2 mt-2">
                                {likeFeatureEnable ? (<RatingBar
                                  disabled={
                                    reviewSts == "reported" ||
                                      item.replyUserId === props.userId
                                      ? true
                                      : false
                                  }
                                  msgreply={true}
                                  liked={item.liked}
                                  likecount={item.likes}
                                  dislikecount={item.dislikes}
                                  handlelike={() =>
                                    handlerplylikechange(
                                      item.id,
                                      data.reviewId
                                    )
                                  }
                                  handledislike={() =>
                                    handlerplydislikechange(
                                      item.id,
                                      data.reviewId
                                    )
                                  }
                                  handleRmvlike={() =>
                                    rplylikecntDecrement(
                                      item.id,
                                      data.reviewId
                                    )
                                  }
                                  labelVAlue={item.score}
                                />) : (
                                  <>
                                    {renderRatingBar(item, true)}</>
                                )}
                                {reviewSts == "reported" ||
                                  item.replyUserId === props.userId ? (
                                  <BiSolidCommentDetail className="cmt_icn clr_gray" />
                                ) : (
                                  <BiSolidCommentDetail
                                    className="cmt_icon c_pointer"
                                    title="Add comment"
                                    onClick={() =>
                                      togglechildcomment(item.replyCommentId)
                                    }
                                  />
                                )}
                              </div>
                              {childrepplycurrentIndex ==
                                item.replyCommentId ? (
                                <Row>
                                  <div className="position-relative p-0">
                                    {cmtError && cmtErrId == data.id ? (
                                      <div className="text-danger ml-2 fz-12">
                                        {cmtError}
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                    <MentionsInput
                                      style={mentionInputStyle}
                                      className="form-control Cmt_box"
                                      maxLength={288}
                                      onChange={(e) => {
                                        changeCmtText(e.target.value);
                                      }}
                                      value={comment}
                                      placeholder="type someting..."
                                    >
                                      <Mention
                                        style={{ backgroundColor: "#efebe3" }}
                                        trigger="@"
                                        data={users}
                                      />
                                    </MentionsInput>
                                    {/* <textarea className="form-control Cmt_box" placeholder="type someting..." onChange={(e) => setchildComment(e.target.value)} value={childComment}/> */}
                                    <button disabled={postBtnDisable}
                                      className="btn btn-primary cmt_btn"
                                      onClick={() => {
                                        if (isFeatureEnabled(allFeatures["Add-Comment%"]?.key, allFeatures["Add-Comment%"]?.isDeleted)) {
                                          postComment(item.replyCommentId, data.reviewId)
                                        }
                                        else {
                                          setShowPopup(true);
                                        }
                                      }}
                                    >
                                      Post
                                    </button>
                                  </div>
                                </Row>
                              ) : (
                                ""
                              )}
                            </Col>
                          </Row>
                        ) : (
                          ""
                        )
                      )
                      : ""}
                  </Col>
                </Row>
              ))}
          </Modal.Body>
        </Modal>

        {/* Attachment popup */}
        <AttachmentModal
          show={showAttachment}
          onClose={() => setShowAttachment(false)}
          attachment={attachment}
          checkExt={checkExt}
          fileImg={fileImg}
        />
        {/* ----------- Pin a review popup ---------- */}
        <UpgradePopup
          show={showPopup}
          handleClose={() => setShowPopup(false)}
        />
        <Modal
          size="sm"
          show={showPinRev}
          onHide={handleClosePin}
        >
          <Modal.Header>
            <Modal.Title
              id="example-custom-modal-styling-title"
              style={{ fontSize: "15px", fontWeight: 600 }}
            >
              {pinBy != null ? "How long do you want to pin the review?" : "Unpin Review Confirmation"}
            </Modal.Title>
            <span onClick={handleClosePin} className="c_pointer" title="Close">
              <AiOutlineClose />
            </span>
          </Modal.Header>
          <Modal.Body className="bordr padpin">
            {pinBy != null ? <div>
              <label htmlFor="pin-duration">you can un pin at any time</label>
              <div>
                <input
                  className="c_pointer"
                  type="radio"
                  id="24hours"
                  name="duration"
                  value="24 hours"
                  checked={duration === '24 hours'}
                  onChange={(e) => { setDuration(e.target.value); setErrMsg(""); }}
                />
                <label htmlFor="24hours" className="marLf">24 hours</label>
              </div>
              <div>
                <input
                  className="c_pointer"
                  type="radio"
                  id="7days"
                  name="duration"
                  value="7 days"
                  checked={duration === '7 days'}
                  onChange={(e) => { setDuration(e.target.value); setErrMsg(""); }}
                />
                <label htmlFor="7days" className="marLf">7 days</label>
              </div>
              <div>
                <input
                  className="c_pointer"
                  type="radio"
                  id="30days"
                  name="duration"
                  value="30 days"
                  checked={duration === '30 days'}
                  onChange={(e) => { setDuration(e.target.value); setErrMsg(""); }}
                />
                <label htmlFor="30days" className="marLf">30 days</label>
                <div className="clr_red">{errMsg}</div>
              </div>
            </div> : "Are you sure you want to unpin this review?"}
          </Modal.Body>
          <Modal.Footer className="bordr pinBtm">
            <Button
              variant="primary"
              className="del_bttn"
              onClick={handlePinReview}
            >
              {pinBy != null ? "Pin Review" : "Yes"}
            </Button>
            <Button
              variant="danger"
              className="del_bttn"
              onClick={handleClosePin}
            >
              {pinBy != null ? "Cancel" : "No"}
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Review share popup */}
        <Modal size="sm" show={isShare} onHide={CloseShare}>
          <Modal.Header style={{ padding: "15px 0px!important" }}>
            <Modal.Title
              id="example-custom-modal-styling-title"
              style={{ fontSize: "15px", fontWeight: 600 }}
            >
              Share
            </Modal.Title>
            <span
              onClick={CloseShare}
              title="Close"
              style={{ cursor: "pointer" }}
            >
              <AiOutlineClose />
            </span>
          </Modal.Header>
          <Modal.Body className="text-center p-2">
            {/* <div><img style={{ width: "100%" }} src={urlLink && urlLink} /></div> */}
            {shareData && <ViewShare shareData={shareData} userData={userData} />}
            {/* {!urlLink && (
              <img className="loader-image" src={loader} alt="Loading..." />
            )} */}
            {urlLink && (
              <FacebookShareButton
                url={urlLink}
                title="tiipstr"
                // hashtag={`${process.env.REACT_APP_URL}/login`}
                className="m-2"
              >
                <FacebookIcon className="c_pointer" size={32} />
              </FacebookShareButton>
            )}
            {urlLink && (
              <TwitterShareButton
                url={urlLink}
                title="SEE WHAT THEY'RE SAYING . . ."
                className="m-2"
              >
                <FaSquareXTwitter size={36} />
              </TwitterShareButton>
            )}
            {urlLink && (
              <LinkedinShareButton
                url={urlLink}
                title="tiipstr"
                className="m-2"
              >
                <LinkedinIcon size={31} />
              </LinkedinShareButton>
            )}
          </Modal.Body>
        </Modal>
      </div>
      <UpgradePopup
        show={showPopup}
        handleClose={() => setShowPopup(false)}
      />
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    userType: state.user.userType,
    profileId: state.user.profileId,
  };
};

export default connect(mapStateToProps)(ReviewsTab);
