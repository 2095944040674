import { Card, Col, Row, Modal, Button } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { useToast } from "../../Toast";
import { BsPlusLg } from "react-icons/bs";
import { FaTimes } from "react-icons/fa";
import { TiTick } from "react-icons/ti";
import HeaderMenu from "../headerMenu";
import SideBar from "../SideBar";
import BreadCrumb from "../../plugins/Breadcrumb";
import { Input } from "reactstrap";
import editCategory from "../../../assets/images/usr_edit.png";
import { HiOutlineTrash } from "react-icons/hi";
import { AiOutlineClose } from "react-icons/ai";

const CategorySettings = () => {
  const [categoryList, setCategoryList] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [editedCategory, setEditedCategory] = useState(null);
  const [newCategory, setNewCategory] = useState("");
  const [categoryError, setCategoryError] = useState("");
  const [deletePopupShow, setDeletePopupShow] = useState(false);
  const [id, setId] = useState(null);
  const handleClose = () => setDeletePopupShow(false);
  const toast = useToast();

  useEffect(() => {
    loadCategoryList();
  }, []);

  const loadCategoryList = async () => {
    try {
      const response = await window.apiService.getCategories();
      setCategoryList(response.data);
      console.log("data", response.data);
    } catch (error) {
      console.log(error);
    }
  };
  const isDuplicateCategory = (name) => {
    return categoryList.some(
      (category) => category.name.toLowerCase() === name.toLowerCase()
    );
  };

  const addCategory = async () => {
    setCategoryError("");
    if (!newCategory) {
      setCategoryError("Category is required.");
      return;
    }
    if (isDuplicateCategory(newCategory)) {
      setCategoryError("Category already exists.");
      return;
    }
    setTimeout(() => {
      loadCategoryList();
    }, 1000);

    const params = { name: newCategory };
    try {
      const response = await window.apiService.addCategory(params);
      if (response.status === "OK") {
        toast.success(response.message);
        console.log("ok", response.message);
        setNewCategory("");
        loadCategoryList();
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.log(error);
      toast.error("Error occurred");
    }
  };

  const updateCategory = async () => {
    if (!editedCategory || !editedCategory.name) {
      setCategoryError("Category name is required.");
      return;
    }

    setTimeout(() => {
      loadCategoryList();
    }, 1000);

    const params = { name: editedCategory.name };
    try {
      const response = await window.apiService.updateCategory(
        editedCategory.id,
        params
      );
      if (response.status === "OK") {
        toast.success(response.message);
        setEditMode(false);
        setEditedCategory(null);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.log(error);
      toast.error("Error occurred");
    }
  };

  const showDeletePopup = (id) => {
    setId(id);
    setDeletePopupShow(true);
  };

  const deleteCategory = async () => {
    if (id) {
      setTimeout(() => {
        loadCategoryList();
      }, 1000);
      try {
        const response = await window.apiService.deleteCategory(id);
        if (response.status === "OK") {
          toast.success(response.message);
          loadCategoryList();
          setDeletePopupShow(false);
        } else {
          toast.error(response.message);
        }
      } catch (error) {
        console.log(error);
        // toast.error("Error occurred");
        setDeletePopupShow(false);
      }
    }
  };

  return (
    <>
      <HeaderMenu />
      <SideBar />
      <div className="main-content ml_200">
        {/* <BreadCrumb /> */}
        <Card className="shade h-500">
          <Row style={{ marginLeft: "1px" }}>
            <Col sm={12} md={12} lg={5} xl={5}>
              <div className="category_container">
                <Row className="m_b18 title_txt">
                  <Col sm={12} md={12} lg={12} xl={12}>
                    Manage Categories
                  </Col>
                </Row>
                <Row className="m_b8">
                  <Col sm={6} md={9} lg={9} xl={9} className="custom-col">
                    <Input
                      className="inptCategory category_place"
                      placeholder={
                        editMode ? "Edit Category" : "Enter a new Category"
                      }
                      type="text"
                      value={
                        editMode && editedCategory
                          ? editedCategory.name
                          : newCategory
                      }
                      onChange={(e) => {
                        if (editMode && editedCategory) {
                          setEditedCategory({
                            ...editedCategory,
                            name: e.target.value,
                          });
                        } else {
                          setNewCategory(e.target.value);
                          setCategoryError("");
                        }
                      }}
                    />
                    {categoryError && (
                      <div className="text-danger">{categoryError}</div>
                    )}
                  </Col>
                  <Col
                    sm={6}
                    md={3}
                    lg={3}
                    xl={3}
                    className="align-items-center custom-col-icn d-flex p-0"
                  >
                    {editMode ? (
                      <>
                        <TiTick
                          title="Submit"
                          className="c_pointer plus_icn m_r15"
                          onClick={updateCategory}
                        />
                        <FaTimes
                          title="Cancel"
                          className="c_pointer close_icn"
                          onClick={() => {
                            setEditMode(false);
                            setEditedCategory(null);
                          }}
                        />
                      </>
                    ) : (
                      <BsPlusLg onClick={addCategory} className="c_pointer " />
                    )}
                  </Col>
                </Row>
                {categoryList &&
                  categoryList.map((category) => (
                    <Row key={category.id}>
                      <Col sm={6} md={9} lg={9} xl={9} className="custom-col">
                        <Input
                          placeholder="Enter a new Category"
                          className="category_place m_b8 inptCategory"
                          type="text"
                          value={category.name}
                          readOnly
                        />
                      </Col>
                      <Col
                        sm={6}
                        md={3}
                        lg={3}
                        xl={3}
                        className="align-items-center custom-col-icn p-0"
                      >
                        <div className="d-flex">
                          <div className="m_r15">
                            <span
                              onClick={() => {
                                setEditMode(true);
                                setEditedCategory(category);
                                setCategoryError("");
                              }}
                              title="Edit"
                              className="c_pointer"
                            >
                              <img src={editCategory} alt="Edit" />
                            </span>
                          </div>
                          <div className="m_r15">
                            <HiOutlineTrash
                              onClick={() => showDeletePopup(category.id)}
                              title="Delete"
                              className="category_trash c_pointer"
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  ))}
              </div>
            </Col>
          </Row>
        </Card>
      </div>
      {/* ----------- Delete Confirmation Popup ---------- */}
      <Modal
        size="sm"
        show={deletePopupShow}
        onHide={() => setDeletePopupShow(false)}
      >
        <Modal.Header>
          <Modal.Title
            id="example-custom-modal-styling-title"
            style={{ fontSize: "15px", fontWeight: 600 }}
          >
            Delete Confirmation
          </Modal.Title>
          <span onClick={handleClose} title="Close">
            <AiOutlineClose />
          </span>
        </Modal.Header>
        <Modal.Body className="bordr">
          <span>Are you sure to delete?</span>
        </Modal.Body>
        <Modal.Footer className="bordr">
          <Button
            variant="primary"
            className="del_bttn"
            onClick={deleteCategory}
          >
            Yes
          </Button>
          <Button
            variant="danger"
            className="del_bttn"
            onClick={() => setDeletePopupShow(false)}
          >
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CategorySettings;
