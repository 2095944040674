import React from 'react';
import { Modal } from 'react-bootstrap';
import { AiOutlineClose } from 'react-icons/ai';

const AttachmentModal = ({ show, onClose, attachment, checkExt, fileImg }) => {
  return (
    <Modal
      size="md"
      className="report_modal"
      show={show}
      onHide={onClose}
    >
      <Modal.Header
        className="border_rst__btm"
        style={{ padding: "15px 0px!important" }}
      >
        <Modal.Title
          id="example-custom-modal-styling-title"
          style={{ fontSize: "15px", fontWeight: 600 }}
        >
          <h6 className="font-weight-bold">Attachments</h6>
        </Modal.Title>
        <span
          onClick={onClose}
          title="Close"
          style={{ cursor: "pointer" }}
        >
          <AiOutlineClose />
        </span>
      </Modal.Header>
      <Modal.Body className="d-flex overflow-auto">
        {attachment && attachment.map((file, index) => (
          <div key={index} className="col-3 col-md-3 col-lg-3 mrt_5">
            {checkExt(file) ? (
              <img
                width="70"
                height="70"
                src={file.filePath || ""}
                className="br_5px border_att"
                alt={file.fileName}
              />
            ) : (
              <img
                width="70"
                height="70"
                src={fileImg}
                className="br_5px border_att"
                alt="File icon"
              />
            )}
            <div className="attch_imgview" title={file.fileName}>
              <a
                title={file.fileName}
                href={file.filePath}
                className="color-black"
                style={{ fontSize: "13px" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                {file.fileName.substring(0, 9) + "..."}
              </a>
            </div>
          </div>
        ))}
      </Modal.Body>
    </Modal>
  );
};

export default AttachmentModal;
