import React from "react";
import { Modal, Button } from "react-bootstrap";

const CustomModal = (props) => {
  const { show, handleClose, title, body, performSubmit, post, btnText } = props;

  return (
    <Modal show={show} onHide={handleClose} backdrop={post ? "static" : true}>
      <Modal.Header closeButton className="p-3">
        <Modal.Title
          className="mb-1"
          style={{ fontSize: "15px", fontWeight: 600 }}
        >
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-3 bordr">{body}</Modal.Body>
      {props.post == true ? (
        <></>
      ) : (
        <Modal.Footer className="bordr pad_13">
          <Button
            variant="primary"
            className="del_bttn"
            onClick={performSubmit}
          >
            {btnText}
          </Button>
          <Button variant="danger" className="del_bttn" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default CustomModal;
