import { Card, Col, Row, Modal, Button } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { useToast } from "../../Toast";
import { BsPlusLg } from "react-icons/bs";
import { FaTimes } from "react-icons/fa";
import { TiTick } from "react-icons/ti";
import SideBar from "../AdminSideBar";
import HeaderMenu from "../HeaderMenu";
import BreadCrumb from "../../plugins/Breadcrumb";
import { Input } from "reactstrap";
import editRole from "../../../assets/images/usr_edit.png";
import { HiOutlineTrash } from "react-icons/hi";
import { AiOutlineClose } from "react-icons/ai";

const CompositeScore = () => {
  const toast = useToast();
  const [scoreFactorList, setScoreFactorList] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [editedType, setEditedType] = useState("");
  const [newScoreFactor, setNewScoreFactor] = useState("");
  const [newScorePoints, setNewScorePoints] = useState("");
  const [scoreFacErr, setScoreFacErr] = useState("");
  const [scorePointError, setScorePointError] = useState("");
  const [deletePopupShow, setDeletePopupShow] = useState(false);
  const [id, setId] = useState(null);
  const handleClose = () => setDeletePopupShow(false);
  const [isActive, setIsActive] = useState(false);
  useEffect(() => {
    loadScoreFactorList();
  }, []);

  const loadScoreFactorList = async () => {
    try {
      const response = await window.apiService.profileScoreList();
      setScoreFactorList(response.data.recordInfo);
      setNewScoreFactor("");
    } catch (error) {
      console.log(error);
    }
  };
  const handleCheckboxChange = () => { };
  const handleEditCheckboxChange = (e) => {
    setEditedType({ ...editedType, isActive: e.target.checked });
  };
  const addUserRole = async () => {
    setScoreFacErr("");
    setScorePointError("");
    if (!newScoreFactor) {
      setScoreFacErr("Score Factor Name is Required.");
      return;
    }
    if (!newScorePoints) {
      setScoreFacErr("Score Factor Weightage is Required.");
      return;
    }
    const params = {
      name: newScoreFactor,
      point: newScorePoints,
      isActive: 1,
    };
    try {
      const response = await window.apiService.addProfileScore(params);
      if (
        response.data.status == "OK" &&
        response.data.message != "Composite score factor already exists."
      ) {
        toast.success(response.data.message);
        setNewScoreFactor("");
        setNewScorePoints("");
        loadScoreFactorList();
      } else if (
        response.data.message == "Composite score factor already exists."
      ) {
        toast.info(response.data.message);
        setNewScoreFactor("");
        setNewScorePoints("");
        loadScoreFactorList();
      } else {
        toast.error("Error Occured");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const updateUserRole = async () => {
    const params = {
      name: editedType.name,
      point: editedType.point,
      isActive: editedType.isActive,
    };
    try {
      const response = await window.apiService.updateProfileScore(
        editedType.id,
        params
      );
      if (
        response.data.status == "OK" &&
        response.data.message != "Composite score factor already exists."
      ) {
        toast.success(response.data.message);
        setEditMode(false);
        setEditedType("");
        loadScoreFactorList();
      } else if (
        response.data.message == "Composite score factor already exists."
      ) {
        toast.info(response.data.message);
        setEditMode(false);
        setEditedType("");
        loadScoreFactorList();
      } else {
        toast.error("Error Occured");
      }
    } catch (error) {
      console.log(error);
      toast.error("Error Occured");
    }
  };
  const showDeletePopup = (id) => {
    setId(id);
    setDeletePopupShow(true);
  };
  const deleteCompositeScoreFactor = async () => {
    if (id) {
      try {
        const response = await window.apiService.deleteProfileScore(id);
        if (response.data.status == "OK") {
          toast.success(response.data.message);
          loadScoreFactorList();
          setDeletePopupShow(false);
        }
      } catch (error) {
        console.log(error);
        setDeletePopupShow(false);
      }
    }
  };

  return (
    <>
      <HeaderMenu />
      <SideBar />
      <div className="main-content ml_200">
        {/* <BreadCrumb /> */}
        <Card className="shade h-500">
          <Row>
            <Col sm={12} md={12} lg={7} xl={7}>
              <div className="role_container">
                <Row className="m_b18 title_txt">
                  <Col sm={12} md={12} lg={12} xl={12}>
                    Manage Composite Score Factors
                  </Col>
                </Row>
                <Row className="m_b8">
                  <Col sm={6} md={5} lg={5} xl={5} className="custom-col">
                    <Input
                      className="inptRole rle_place"
                      placeholder="Score Factor Name"
                      disabled={editMode ? true : false}
                      type="text"
                      value={editMode ? editedType.name : newScoreFactor}
                      onChange={(e) => {
                        if (editMode) {
                          setEditedType({
                            ...editedType,
                            name: e.target.value,
                          });
                        } else {
                          setNewScoreFactor(e.target.value);
                          setScoreFacErr("");
                        }
                      }}
                    />
                    {scoreFacErr && (
                      <div className="text-danger">{scoreFacErr}</div>
                    )}
                  </Col>
                  <Col sm={6} md={3} lg={3} xl={3} className="custom-col">
                    <Input
                      className="inptRole rle_place"
                      placeholder="Weightage"
                      type="text"
                      onKeyPress={(e) =>
                        !/[0-9.]/.test(e.key) && e.preventDefault()
                      }
                      value={editMode ? editedType.point : newScorePoints}
                      onChange={(e) => {
                        if (editMode) {
                          setEditedType({
                            ...editedType,
                            point: e.target.value,
                          });
                        } else {
                          setNewScorePoints(e.target.value);
                          setScoreFacErr("");
                        }
                      }}
                    />
                    {scorePointError && (
                      <div className="text-danger">{scorePointError}</div>
                    )}
                  </Col>
                  <Col
                    sm={6}
                    md={3}
                    lg={3}
                    xl={3}
                    className="align-items-center custom-col-icn d-flex p-0"
                  >
                    {editMode ? (
                      <>
                        {editedType.isActive}
                        <input
                          type="checkbox"
                          className="ad_chkbox m_r15"
                          title={editedType.isActive ? "Active" : "Inactive"}
                          checked={editedType.isActive}
                          onChange={handleEditCheckboxChange}
                        />
                        <TiTick
                          title="Update"
                          className="c_pointer plus_icn m_r15"
                          onClick={updateUserRole}
                        />
                        <FaTimes
                          title="Cancel"
                          className="c_pointer close_icn"
                          onClick={() => {
                            setEditMode(false);
                            setEditedType("");
                          }}
                        />
                      </>
                    ) : (
                      <BsPlusLg
                        onClick={addUserRole}
                        className="c_pointer "
                        title="Add"
                      />
                    )}
                  </Col>
                </Row>
                {scoreFactorList &&
                  scoreFactorList.map((role) => (
                    <Row>
                      <Col sm={6} md={5} lg={5} xl={5} className="custom-col">
                        <label className="rle_place form-control m_b8 inptRole">
                          {role.name}
                        </label>
                      </Col>
                      <Col sm={6} md={3} lg={3} xl={3} className="custom-col">
                        <label className="rle_place form-control m_b8 inptRole">
                          {role.point}
                        </label>
                      </Col>
                      <Col
                        sm={6}
                        md={3}
                        lg={3}
                        xl={3}
                        className="align-items-center custom-col-icn p-0"
                      >
                        <div className="d-flex">
                          <div className="m_r15">
                            <input
                              type="checkbox"
                              className="ad_chkbox"
                              title={role.isActive ? "Active" : "Inactive"}
                              checked={role.isActive}
                              onChange={handleCheckboxChange}
                            />
                          </div>
                          <div className="m_r15">
                            <span
                              onClick={() => {
                                setEditMode(true);
                                setEditedType(role);
                                setScoreFacErr("");
                                setIsActive(role.isActive);
                              }}
                              title="Edit"
                              className="c_pointer"
                            >
                              <img src={editRole} />
                            </span>
                          </div>
                          <div className="m_r15">
                            <HiOutlineTrash
                              onClick={() => showDeletePopup(role.id)}
                              title="Delete"
                              className="ques_trash c_pointer"
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  ))}
              </div>
            </Col>
            <Col sm={12} md={12} lg={7} xl={7} className="mt_53 p-0"></Col>
          </Row>
        </Card>
      </div>
      {/* ----------- Delete Confirmation Popup ---------- */}
      <Modal
        size="sm"
        show={deletePopupShow}
        onHide={() => setDeletePopupShow(false)}
      >
        <Modal.Header>
          <Modal.Title
            id="example-custom-modal-styling-title"
            style={{ fontSize: "15px", fontWeight: 600 }}
          >
            Delete Confirmation
          </Modal.Title>
          <span onClick={handleClose} title="Close">
            <AiOutlineClose />
          </span>
        </Modal.Header>
        <Modal.Body className="bordr">
          <span>Are you sure to delete?</span>
        </Modal.Body>
        <Modal.Footer className="bordr">
          <Button
            variant="primary"
            className="del_bttn"
            onClick={deleteCompositeScoreFactor}
          >
            Yes
          </Button>
          <Button
            variant="danger"
            className="del_bttn"
            onClick={() => setDeletePopupShow(false)}
          >
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CompositeScore;
