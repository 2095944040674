import React, { useState, useEffect } from "react";

const StarRating = (props) => {
  const [rating, setRating] = useState(props.rating);

  useEffect(() => {
    setRating(props.rating);
  }, [props.rating]);

  const handleRatingClick = (selectedRating) => {
    if (!props.disabled) {
      if (props.roleId && props.id) {
        if (selectedRating === rating) {
          setRating(0);
          props.onRatingChange(0, props.roleId, props.id, props.idx);
        } else {
          setRating(selectedRating);
          props.onRatingChange(
            selectedRating,
            props.roleId,
            props.id,
            props.idx
          );
        }
      } else {
        if (selectedRating === rating) {
          setRating(0);
          props.onRatingChange(0);
        } else {
          setRating(selectedRating);
          props.onRatingChange(selectedRating);
        }
      }
    }
  };

  const renderStars = () => {
    const stars = [];
    const integerPart = Math.floor(rating);
    const decimalPart = rating - integerPart;

    const maxStars = 5;

    for (let i = 1; i <= Math.min(integerPart, maxStars); i++) {
      stars.push(
        <span
          key={i}
          className={`star selected ${props.disabled ? "disabled" : ""} ${props.isReview ? "isreview" : ""
            } ${props.customClass || ""} ${props.lineH || ""}`}
          onClick={() => handleRatingClick(i)}
        >
          &#9733;
        </span>
      );
    }

    if (decimalPart >= 0.25 && decimalPart < 0.75 && stars.length < maxStars) {
      stars.push(
        <span
          key={integerPart + 1}
          className={`star selected-half ${props.disabled ? "disabled" : ""} ${props.isReview ? "isreview" : ""
            } ${props.customClass || ""} ${props.lineH || ""}`}
          onClick={() => handleRatingClick(integerPart + 0.5)}
        >
          &#9733;
        </span>
      );
    } else if (decimalPart >= 0.75 && stars.length < maxStars) {
      stars.push(
        <span
          key={integerPart + 1}
          className={`star selected ${props.disabled ? "disabled" : ""} ${props.isReview ? "isreview" : ""
            } ${props.customClass || ""} ${props.lineH || ""}`}
          onClick={() => handleRatingClick(integerPart + 1)}
        >
          &#9733;
        </span>
      );
    }

    for (let i = stars.length + 1; i <= maxStars; i++) {
      stars.push(
        <span
          key={i}
          className={`star ${props.disabled ? "disabled" : ""} ${props.isReview ? "isreview" : ""
            } ${props.customClass || ""} ${props.lineH || ""}`}
          onClick={() => handleRatingClick(i)}
        >
          &#9733;
        </span>
      );
    }

    return stars;
  };

  return <div className="star-rating">{renderStars()}</div>;
};

export default StarRating;
