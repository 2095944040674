import React, { createContext, useContext } from 'react';
import { ToastContainer, toast as showToast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ToastContext = createContext();

export const useToast = () => useContext(ToastContext);

export const ToastProvider = ({ children }) => {
  const toast = {
    success: (message) => showToast.success(message),
    error: (message) => showToast.error(message),
    warning: (message) => showToast.warning(message),
    info: (message) => showToast.info(message),
  };

  return (
    <ToastContext.Provider value={toast}>
      {children}
      <ToastContainer
        theme="colored"
        position="top-right"
        autoClose={1500}
        hideProgressBar={false}
        closeOnClick
        pauseOnHover
        draggable
      />
    </ToastContext.Provider>
  );
};
