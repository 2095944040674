import React, { useState, useEffect } from "react";
import { useToast } from "../../Toast";
import HeaderMenu from "../headerMenu";
import SideBar from "../SideBar";
import { Button, Card, Container, Row, Col, Table, Modal, Form } from "react-bootstrap";
import { connect } from "react-redux";
import { BiChevronDownCircle, BiChevronUpCircle } from "react-icons/bi";
import { format, parseISO } from "date-fns";
import { FaCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { TiTick } from "react-icons/ti";
import { isBefore } from "date-fns";
import { useSubscription } from '../../SubscriptionContext';
const SubscriptionPlanDetails = (props) => {
    const [isBillingHistoryVisible, setIsBillingHistoryVisible] = useState(false);
    const [planDetails, setPlanDetails] = useState({});
    const [planList, setPlanList] = useState([]);
    const [activePlanId, setActivePlanId] = useState(null);
    const [validity, setValidity] = useState(null);
    const [showCancelModal, setShowCancelModal] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState([]);
    const [cancelReason, setCancelReason] = useState("");
    const [cancelReasons, setCancelReasons] = useState("");
    const [reasonMissing, setReasonMissing] = useState(false);
    const [trialStatus, setTrialStatus] = useState("");
    const [trialEndDate, setTrialEndDate] = useState("");
    const [comment, setComment] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const toast = useToast();
    const { refreshSubscriptions } = useSubscription();
    const [userPlanHistory, setUserPlanHistory] = useState([]);
    const billingHistoryData = [
        { slNo: 1, date: "2024-01-01", planName: "Basic Plan", amount: "$4.99" },
    ];

    useEffect(() => {
        getPlansById();
        loadUserPlanHistory();
    }, [props]);
    useEffect(() => {
        loadPlans();
        loadCancelReason();
    }, []);


    const loadPlans = async () => {
        try {
            const response = await window.apiService.loadPlans();
            setPlanList(response.data.recordInfo);
            refreshSubscriptions();
        } catch (error) {
            console.log(error);
        }
    };
    const loadCancelReason = async () => {
        try {
            const response = await window.apiService.listCancelreason();
            setCancelReasons(response.data.recordInfo);
        } catch (error) {
            console.log(error);
        }
    };
    const toggleBillingHistory = () => {
        setIsBillingHistoryVisible(!isBillingHistoryVisible);
    };

    const getPlansById = async () => {
        if (props) {
            try {
                const response = await window.apiService.getPlanById(props.userId);
                if (response.data.status === "OK") {
                    setActivePlanId(response.data.recordInfo.planId);
                    setValidity(response.data.recordInfo.endDate);
                    setTrialStatus(response.data.recordInfo.trialStatus);
                    setTrialEndDate(response.data.recordInfo.trialEndDate);
                }
            } catch (error) {
                console.log(error);
            }
        }
    };
    const filteredPlans = planList.filter(plan => plan.id === activePlanId);
    const handleCancel = (plan) => {
        setSelectedPlan(plan);
        setShowCancelModal(true);
    };

    const handleRenew = async (plan) => {
        const startDate = new Date();
        const endDate = new Date(startDate);
        endDate.setDate(startDate.getDate() + plan.planPeriod);

        const formattedStartDate = format(startDate, "yyyy-MM-dd");
        const formattedEndDate = format(endDate, "yyyy-MM-dd");

        const params = {
            userId: props.userId,
            planId: plan.id,
            startDate: formattedStartDate,
            endDate: formattedEndDate,
            status: "active",
            action: "renewed",
        }
        try {
            const response = await window.apiService.addUserPlan(params);
            if (response.status === "OK") {
                toast.success("Plan renewed successfully");
                getPlansById();
                loadPlans();
                loadUserPlanHistory();
            }
        } catch (error) {
            console.log(error);
        }
    };


    const confirmCancellation = async () => {
        setIsLoading(true);
        if (!cancelReason) {
            setReasonMissing(true);
            setIsLoading(false);
            return;
        }
        const params = {
            userId: props.userId,
            planId: selectedPlan.id,
            cancellationReasonId: cancelReason,
            additionalComments: comment,
            action: "canceled"
        };
        try {
            const response = await window.apiService.addCancelPlan(params);
            if (response.status === "OK") {
                setIsLoading(false);
                toast.success(response.message);
                setShowCancelModal(false);
                getPlansById();
                loadPlans();
                setCancelReason("");
                setComment("");
                loadUserPlanHistory();
            }
        } catch (error) {
            console.log(error);
            setIsLoading(false);
        }
    };

    const loadAllPlanFeatures = async () => {
        try {
            const response = await window.apiService.loadPlanCancelReasons();
            const cancelReason = response.data.recordInfo;
            setCancelReasons(cancelReason);
        } catch (error) {
            console.log(error);
        }
    };

    const loadUserPlanHistory = async () => {
        if (props) {
            try {
                const response = await window.apiService.getUserPlanHistory(props.userId);
                if (response.data.status === "OK") {
                    setUserPlanHistory(response.data.recordInfo);
                }
            } catch (error) {
                console.log(error);
            }
        }
    };

    const hasPlanExpired = () => {
        const validityDate = new Date(validity);
        const today = new Date();
        validityDate.setHours(0, 0, 0, 0);
        today.setHours(0, 0, 0, 0);

        return validityDate < today;
    };

    return (
        <>
            <HeaderMenu />
            <SideBar />
            <div className="main-content ml_200">
                <Card className="shade h-500">
                    <Container fluid>
                        <div className="header-body pt-3">
                            <Row>
                                <Col lg="12" xl="12">
                                    <div className="d-flex mt-2 toggle_gap">
                                        <label className="font-weight-500 fz_14">
                                            Plan and Billing
                                        </label>
                                    </div>
                                </Col>
                                <hr />
                                <Col lg="12" xl="12">
                                    <div className="d-flex mt-2 toggle_gap">
                                        <label className="font-weight-500 fz_14 a_clr">
                                            Plan Details
                                        </label>
                                    </div>
                                </Col>
                                {filteredPlans && filteredPlans.length > 0 ? (
                                    filteredPlans.map((plan, idx) => (
                                        <React.Fragment key={idx}>
                                            <Col xs="12" sm="6" md="6" lg="4" xl="3" className="mb-4 mt-4">
                                                <Card className="plan-card">
                                                    <div className="ribbon">{hasPlanExpired() && plan.isTrial == 0 ? "Expired" : "Active"}</div>
                                                    <Card.Header className="text-primary" style={{ backgroundColor: "#ece9e2" }}>Current Plan
                                                        {plan.isTrial == 1 && <span className="freePln">Free</span>}
                                                    </Card.Header>
                                                    <Card.Body>
                                                        <div>
                                                            <span><FaCircle className="icn_blur text-basic" /></span> {plan.servicePlan}
                                                            {trialStatus == "active" && <span className="freePln">Trial Activated</span>}
                                                            {trialStatus == "none" && new Date(validity).toDateString() === new Date().toDateString() && <span className="freePln fs_12">Expires today</span>}
                                                        </div>
                                                        <div>{plan.description}</div>
                                                        {plan.isTrial == 1 && <ul className="list-unstyled mt-3 mb-4">
                                                            {plan.features.map((feature, featureIdx) => (
                                                                <li key={featureIdx}>
                                                                    <TiTick className="tck" /> {feature.featureName}
                                                                    <span className="text-muted"> ({feature.description})</span>
                                                                </li>
                                                            ))}
                                                        </ul>}
                                                        {plan.isTrial === 0 && (
                                                            <>
                                                                <h5 className="mt-2">
                                                                    ${plan.price}
                                                                    <small> / {plan.billingCycle === "monthly" ? "month" : "year"}</small>
                                                                </h5>
                                                                <div className="mb-2">
                                                                    {trialStatus === "active" ? (
                                                                        new Date(trialEndDate).toDateString() === new Date().toDateString() ? (
                                                                            "Trial expires today"
                                                                        ) : (
                                                                            `Trial expires on ${format(new Date(trialEndDate), "dd MMM yyyy")}`
                                                                        )
                                                                    ) : (
                                                                        <span>Renew at {format(new Date(validity), "dd MMM yyyy")}</span>
                                                                    )}
                                                                </div>
                                                            </>
                                                        )}
                                                        {hasPlanExpired() && plan.isTrial == 0 ? (
                                                            <Button className="btn_plan w_100" onClick={() => handleRenew(plan)}>
                                                                Renew Plan
                                                            </Button>
                                                        ) : plan.isTrial == 0 && trialStatus != "active" && (<><div className="d-flex">
                                                            <Button className="btn_plan w_100" onClick={() => handleCancel(plan)}>
                                                                Cancel Plan
                                                            </Button>
                                                            <Button className="btn_plan w_100" onClick={() => handleRenew(plan)}>
                                                                Renew Plan
                                                            </Button></div>
                                                        </>
                                                        )}
                                                    </Card.Body>
                                                </Card>
                                            </Col>

                                            {plan.isTrial == 0 && <Col xs="12" sm="6" md="6" lg="4" xl="3" className="mb-4 mt-4">
                                                <Card className="plan-card">
                                                    <Card.Header className="text-primary" style={{ backgroundColor: "#ece9e2" }}>Current Plan Features</Card.Header>
                                                    <Card.Body>
                                                        <ul className="list-unstyled mt-3 mb-4 lst_ft">
                                                            {plan.features.map((feature, featureIdx) => (
                                                                <li key={featureIdx}>
                                                                    <TiTick className="tck" /> {feature.featureName}
                                                                    <span className="text-muted"> ({feature.description})</span>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </Card.Body>
                                                </Card>
                                            </Col>}

                                            <Col xs="12" sm="6" md="6" lg="4" xl="3" className="mb-4 mt-4">
                                                <Card className="plan-card">
                                                    <Card.Header className="text-primary" style={{ backgroundColor: "#ece9e2" }}>Upgrade Plan</Card.Header>
                                                    <Card.Body>
                                                        <div>
                                                            <span><FaCircle className="icn_blur text-basic" /></span> {plan.servicePlan}
                                                        </div>
                                                        {plan.isTrial == 1 && <div>{plan.description}</div>}
                                                        {plan.isTrial == 0 && <h5>
                                                            ${plan.price}
                                                            <small> / {plan.billingCycle === "monthly" ? "month" : "year"}</small>
                                                        </h5>}
                                                        <Button
                                                            className="btn_plan w_100"
                                                            onClick={() => navigate("/dashboard/profile", { state: true })}
                                                        >
                                                            Upgrade Plan
                                                        </Button>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        </React.Fragment>
                                    ))
                                ) : (
                                    <div className="row d-align mx-0 pb-4">
                                        <div className="no-data font-weight-600 w-100 text-center fz_14">
                                            Sorry, there is no data to be displayed
                                        </div>
                                    </div>
                                )}

                                <Col lg="12" xl="12">
                                    <div className="d-flex mt-2 toggle_gap align-items-center">
                                        <label className="font-weight-500 fz_14 mr-2 a_clr">
                                            Plan History <span onClick={toggleBillingHistory} className="p-0">
                                                {isBillingHistoryVisible ? <BiChevronUpCircle /> : <BiChevronDownCircle />}
                                            </span>
                                        </label>
                                    </div>
                                    {isBillingHistoryVisible && (
                                        <Table className="align-items-center table-flush brd_solid"
                                            responsive>
                                            <thead className="thead-clr">
                                                <tr>
                                                    <th>Sl. No</th>
                                                    <th>Plan Name</th>


                                                    {/* <th>Amount</th> */}
                                                    <th>Status</th>
                                                    <th>Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {userPlanHistory.length > 0 ? (
                                                    userPlanHistory.map((history, index) => (
                                                        <tr key={history.id}>
                                                            <td>{index + 1}</td>
                                                            <td>{
                                                                planList.find(plan => plan.id === history.planId)?.servicePlan || ""
                                                            }</td>


                                                            <td>{history.action}</td>
                                                            <td>{format(new Date(history.startDate), "dd MMM yyyy")}</td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td colSpan="6" className="text-center">
                                                            No History Found
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </Table>
                                    )}
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </Card>

                {/* Cancel Plan Modal */}
                <Modal show={showCancelModal} onHide={() => setShowCancelModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Cancel Plan</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group controlId="cancelReasonSelect">
                            <Form.Label>Reason for cancellation <span className="text-danger">*</span></Form.Label>
                            <Form.Select
                                value={cancelReason}
                                onChange={(e) => {
                                    setCancelReason(e.target.value);
                                    setReasonMissing(false);
                                }}
                                aria-label="Select cancellation reason"
                            >
                                <option value="">Select a reason</option>
                                {cancelReasons && cancelReasons.map((reasonObj) => (
                                    <option key={reasonObj.id} value={reasonObj.id}>
                                        {reasonObj.reason}
                                    </option>
                                ))}
                            </Form.Select>
                            {reasonMissing && (
                                <div className="text-danger mt-2">Please select a reason for cancellation.</div>
                            )}
                        </Form.Group>
                        <Form.Group controlId="cancelReason" className="mt-3">
                            <Form.Label>Comment</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                value={comment}
                                onChange={(e) => setComment(e.target.value)}
                                placeholder="Enter additional comments"
                            />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-danger" onClick={() => setShowCancelModal(false)}>
                            Close
                        </Button>
                        <Button
                            className="u_review_btn"
                            variant="primary"
                            onClick={confirmCancellation}
                        >
                            Confirm Cancellation
                        </Button>
                    </Modal.Footer>
                </Modal>

            </div>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        userId: state.user.userId,
        userType: state.user.userType,
        profileId: state.user.profileId,
    };
};

export default connect(mapStateToProps)(SubscriptionPlanDetails);

