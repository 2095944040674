import React from "react";
import {
  Chart as ChartJS,
  BarElement,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Legend,
  Tooltip,
  BarController,
  LineController,
} from "chart.js";
import { Chart } from "react-chartjs-2";

ChartJS.register(
  BarController,
  LineController,
  BarElement,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Legend,
  Tooltip
);

const LineChart = (props) => {
  const data = {
    labels: props.labels,
    datasets: [
      {
        type: "bar",
        label: "Rating",
        data: props.data,
        fill: false,
        borderColor: "rgb(129 207 207)",
        backgroundColor: "rgb(129 207 207)",
        yAxisID: "y1",
        order: 2,
      },
      {
        type: "line",
        label: "Count",
        data: props.additionalData,
        borderColor: "#c88200",
        backgroundColor: "#c88200",
        borderWidth: 1,
        yAxisID: "y2",
        order: 1,
      },
    ],
  };

  const options = {
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          autoSkip: false,
        },
        offset: true,
      },
      y1: {
        beginAtZero: true,
        min: 0,
        max: 5,
        grid: {
          display: false,
        },
        ticks: {
          autoSkip: true,
          stepSize: 1,
          font: {
            weight: "bold",
          },
          callback: function (value) {
            if (value <= 5) {
              return value + "  ";
            }
            return null;
          },
        },
        position: "left",
        title: {
          display: true,
          text: "Rating",
          color: "rgba(75, 192, 192, 1)",
          font: {
            weight: "bold",
          },
        },
      },
      y2: {
        beginAtZero: true,
        min: 0,
        grid: {
          display: false,
        },
        ticks: {
          autoSkip: true,
          font: {
            weight: "bold",
          },
          callback: function (value) {
            if (value >= 1000) {
              return value / 1000 + "k";
            }
            return value;
          },
          stepSize: 1,
        },
        position: "right",
        title: {
          display: true,
          text: "Count",
          color: "#c88200",
          font: {
            weight: "bold",
          },
        },
      },
    },
    plugins: {
      customTicksPlugin: {
        id: "customTicksPlugin",
        afterDraw: (chart) => {
          const ctx = chart.ctx;
          const yAxis = chart.scales.y1;
          ctx.save();

          yAxis.ticks.forEach((tick, index) => {
            const y = yAxis.getPixelForValue(tick.value);
            ctx.fillStyle = "#fea500";
            ctx.fillText("★", yAxis.left + 35, y + 1);
          });

          ctx.restore();
        },
      },
      legend: {
        labels: {
          generateLabels: (chart) => {
            const original = ChartJS.defaults.plugins.legend.labels.generateLabels;
            const labelsOriginal = original.call(this, chart);

            labelsOriginal.forEach((label) => {
              if (label.text === 'Count') {
                label.fontColor = '#c88200';
              }
            });

            labelsOriginal.forEach((label) => {
              if (label.text === 'Rating') {
                label.fontColor = '#41abab';
              }
            });

            return labelsOriginal;
          },
        },
      },
    },
  };

  return (
    <div className="d-flex justify-content-center">
      <Chart
        type="bar"
        data={data}
        options={options}
        plugins={[options.plugins.customTicksPlugin]}
      />
    </div>
  );
};

export default LineChart;
