import "./inbox.css";
import "../../Register/register.css";
import React, { useState, useRef } from "react";
import Searchbar from "./searchbar";
import Chats from "./chats";
import Chat from "./chat";
import Groupchat from "./groupchat";
import { Collapse, Form, Navbar, Container, Row, Col } from "reactstrap";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";

export function Sidebar() {
  const childCompRef = useRef();
  const childGrpChatRef = useRef();
  const [collapseOpen, setCollapseOpen] = useState();
  const [id, setId] = useState("");
  const [userName, setUserName] = useState("");
  const [userImg, setUserImg] = useState("");
  const [city, setUserCity] = useState("");
  const [state, setUserState] = useState("");
  const [chatType, setChatType] = useState("direct");
  const [description, setDescription] = useState("");
  const [notShow, setNotShow] = useState(false);

  const toggleCollapse = () => {
    setCollapseOpen((data) => !data);
  };

  const closeCollapse = () => {
    setCollapseOpen(false);
  };
  const handleEventKey = (id, name, img, city, state, description, chattype) => {
    if (id) {
      setId(id);
      setUserName(name);
      setUserImg(img);
      setUserCity(city);
      setUserState(state);
      setDescription(description);
      console.log("description", description)
      setChatType(chattype);
      if (chatType === 'direct')
        childCompRef.current.reloadMsg();
      else
        childGrpChatRef.current.reloadMsg();
    } else {
      setNotShow(true);
    }
  };

  return (
    <>
      <Navbar
        className="navbar-vertical fixed-left navbar-light bg-white ml_200"
        expand="md"
        id="chatnav-main"
      >
        <Container className="flx_column" fluid>
          <button
            className="navbar-toggler"
            type="button"
            onClick={toggleCollapse}
          >
            <span className="navbar-toggler-icon" />
          </button>
          <Collapse navbar isOpen={collapseOpen} className="cht_mr w-100 position-relative">
            <div className="navbar-collapse-header d-md-none">
              <Row>
                <Col className="collapse-brand" xs="6">
                  <Link to="/dashboard">
                    <h2 style={{ color: "#11cdef" }}>tiipstr</h2>
                  </Link>
                </Col>
                <Col className="collapse-close" xs="6">
                  <button
                    className="navbar-toggler"
                    type="button"
                    onClick={toggleCollapse}
                  >
                    <span />
                    <span />
                  </button>
                </Col>
              </Row>
            </div>
            {/* <Form className="mt-4 mb-3 d-md-none">
              <Searchbar />
            </Form> */}
            <Chats handleEventKey={handleEventKey} />
          </Collapse>
        </Container>
      </Navbar>
      <div className="chat_ml">
        {chatType === 'direct' ? <Chat
          id={id}
          name={userName}
          img={userImg}
          city={city}
          state={state}
          ref={childCompRef}
          notShow={notShow}
        /> : <Groupchat
          id={id}
          name={userName}
          img={userImg}
          description={description}
          ref={childGrpChatRef}
          notShow={notShow}
          chatType={chatType}
        />}
      </div>
    </>
  );
}

export default Sidebar;
