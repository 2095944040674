import { Button, Card, Form, Container, Row, Col, ButtonGroup } from "react-bootstrap";
import { Table } from "reactstrap";
import PhoneControl from "../CustomPhone/PhoneControl";
import profile from "../../assets/images/profile.jpg";
import Modal from "react-bootstrap/Modal";
import "./Profile.css";
import "../Register/register.css";
import BusinessUser from "./BusinessUser";
import React, { useState, useEffect, useRef } from "react";
import { FaCirclePlus, FaGear, FaPlus } from "react-icons/fa6";
import { FiEdit, FiUpload } from "react-icons/fi";
import { FaAddressCard, FaCheck, FaEye } from "react-icons/fa";
import { HiBadgeCheck, HiCheckCircle } from "react-icons/hi";
import { RiCloseLine } from "react-icons/ri";
import { MdMiscellaneousServices } from "react-icons/md";
import { AiOutlineClose } from "react-icons/ai";
import { IoPersonCircleSharp } from "react-icons/io5";
import { FaUserTie, FaUserPlus, FaUserCheck } from "react-icons/fa";
import { MdBusinessCenter } from "react-icons/md";
import { TiCamera } from "react-icons/ti";
import { BsExclamationCircle } from "react-icons/bs";
import { useToast } from "../Toast";
import HeaderMenu from "../Dashboard/headerMenu";
import SideBar from "../Dashboard/SideBar";
import "../../assets/css/ToggleSwitch.css";
import { connect } from "react-redux";
import { useDispatch, useSelector } from "react-redux";
import loader from "../../assets/images/loader.gif";
import { phoneNumber } from "../../Redux/Actions/userAction";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import CustomModal from "../plugins/CustomModal";
import { IoCloseCircleSharp, IoQrCode } from "react-icons/io5";
import { userAction } from "../../Redux/Actions/userAction";
import { GrCircleQuestion } from "react-icons/gr";
import { RiDeleteBin6Line } from "react-icons/ri";
import { BsEnvelopePlus } from "react-icons/bs";
import { BiNotepad } from "react-icons/bi";
import { PiCaretCircleDownBold, PiCaretCircleUpBold } from "react-icons/pi";
import capturegiude from "../../assets/images/Capture Guidance.jpg";
import { Dropdown, DropdownButton } from "react-bootstrap";
import uploadfile from "../../assets/images/Upload File.png";
import { GrEdit } from "react-icons/gr";
import { HiOutlineTrash } from "react-icons/hi";
import { TiTick } from "react-icons/ti";
import editRole from "../../assets/images/usr_edit.png";
import { IoMdClose } from "react-icons/io";
import cscore from "../../assets/images/score.jpg";
import PlanDetails from "../../components/PlanSubscriptions/PlanDetails";
import { useLocation } from "react-router-dom";
import { MdDoubleArrow } from "react-icons/md";
import moment from "moment";
import { useSubscription } from '../SubscriptionContext';
import UpgradePopup from "../plugins/UpgradePopup";
const STATUS = {
  start: 1,
  default: 2,
};
const Profile = (props) => {
  const [status, setStatus] = useState(STATUS.default);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const intervalRef = useRef();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [state, setState] = useState("");
  const [year, setYear] = useState("");
  const [city, setCity] = useState("");
  const [tiipstrSince, setTiipstrSince] = useState("");
  const [profTypeList, setProfTypeList] = useState([]);
  const [busnsTypeList, setBusnsTypeList] = useState([]);
  const [professionInfo, setprofessionInfo] = useState([]);
  const [businessInfo, setbusinessInfo] = useState([]);
  const [businessUserInfo, setbusinessUserInfo] = useState([]);
  const [cmpyName, setcmpyName] = useState("");
  const [cmpyShortName, setcmpyShortName] = useState("");
  const [itiNo, setItiNo] = useState("");
  const [cmpyPhone, setcmpyPhone] = useState("");
  const [cmpyEmail, setcmpyEmail] = useState("");
  const [cmpyState, setcmpyState] = useState("");
  const [cmpyCountry, setcmpyCountry] = useState("");
  const [cmpyZipCode, setcmpyZipCode] = useState("");
  const [cmpyWebsite, setcmpyWebsite] = useState("");
  const [cmpyFbUrl, setcmpyFbUrl] = useState("");
  const [cmpyTwUrl, setcmpyTwUrl] = useState("");
  const [cmpyLnUrl, setcmpyLnUrl] = useState("");
  const [cmpyIgUrl, setcmpyIgUrl] = useState("");
  const [cmpySince, setcmpySince] = useState("");
  const [cmpyCity, setcmpyCity] = useState("");
  const [cmpyDescription, setcmpyDescription] = useState("");
  const [officePhone, setofficePhone] = useState("");
  const [officeAddress, setofficeAddress] = useState("");
  const [officeEmail, setofficeEmail] = useState("");
  const [editedProvalue, setEditedProvalue] = useState("");
  const [profBio, setprofBio] = useState("");
  const [busnBio, setbusnBio] = useState("");
  const [professionType, setprofessionType] = useState("");
  const [professionTypeId, setprofessionTypeId] = useState("");
  const [newProfessionType, setNewProfessionType] = useState("");
  const [emptyErr, setemptyErr] = useState(false);
  const [mobileVerified, setmobileVerified] = useState(false);
  const [isEmailCodeSend, setIsEmailCodeSend] = useState(false);
  const [isMobileCodeSend, setIsMobileCodeSend] = useState(false);
  const [hideEdit, setHideEdit] = useState(true);
  const [hideProEdit, setHideProEdit] = useState(true);
  const [mobilePopupShow, setMobilePopupShow] = useState(false);
  const [emailPopupShow, setEmailPopupShow] = useState(false);
  const [profDeleteShow, setProfDeleteShow] = useState(false);
  const [deleteProfessionId, setDeleteProfessionId] = useState("");
  const [editProfessionType, setEditProfessionType] = useState(false);
  const [imagePath, setImagePath] = useState("");
  const [profCity, setProfCity] = useState("");
  const [profState, setProfState] = useState("");
  const [editedValue, setEditedValue] = useState("");
  const [documentErr, setDocumetErr] = useState(false);
  const [editedIdx, setEditedIdx] = useState("");
  const [editedProIdx, setEditedProIdx] = useState("");
  const [verificationCode, setVerifcationCode] = useState("");
  const [minutes, setMinutes] = useState(5);
  const [seconds, setSeconds] = useState(0);
  const [config, setConfig] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isPhoneChanged, setisPhoneChanged] = useState(false);
  const [emailVerified, setemailVerified] = useState(false);
  const [isPublic, setIsPublic] = useState(false);
  const dispatch = useDispatch();
  const toast = useToast();
  const [displayContent, setdisplayContent] = useState(false);
  const [collapseOpen, setCollapseOpen] = useState();
  const timerMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const timerSeconds = seconds < 10 ? `0${seconds}` : seconds;
  const [isLoading, setIsLoading] = useState(false);
  const [eyespopup, setEyepop] = useState(false);
  const handleClose = () => setDeletePopup(false);
  const [shrtNameErr, setShrtNameErr] = useState("");
  const [showScore, setShowScore] = useState(false);
  const [shortName, setShortName] = useState(null);
  const [proLoading, setProLoading] = useState(false);
  const [validation, setValidation] = useState({
    cmpyName: "",
    cmpyEmail: "",
    professionType: "",
    newProfType: "",
  });
  const [isValid, setIsValid] = useState({
    facebookUrl: "",
    twitterUrl: "",
    linkedInUrl: "",
    instagramUrl: "",
  });
  const [busnUrlValid, setBusnUrlValid] = useState({
    facebookUrl: "",
    twitterUrl: "",
    linkedInUrl: "",
    instagramUrl: "",
    website: "",
  });
  const [cmpyEmailErr, setcmpyEmailErr] = useState("");
  const [profEmailErr, setprofEmailErr] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [phoneValidation, setPhoneValidation] = useState("");
  const [officephoneValidation, setofficePhoneValidation] = useState("");
  const [businessPhoneValidation, setbusinessPhoneValidation] = useState("");
  const [deletePopupShow, setDeletePopupShow] = useState(false);
  const [logoimagePath, setLogoimagePath] = useState([]);
  const [logoPreview, setLogoPreview] = useState("");
  const [imagePreview, setImagePreview] = useState("");
  const [facebookUrl, setFacebookUrl] = useState("");
  const [twitterUrl, setTwitterUrl] = useState("");
  const [linkedInUrl, setLinkedInUrl] = useState("");
  const [instagramUrl, setInstagramUrl] = useState("");
  const [bio, setBio] = useState("");
  const [Qrcode, setQrCode] = useState("");
  const [profileScore, setProfileScore] = useState("");
  const childCompRef = useRef();
  const childCmpyPhRef = useRef();
  const userId = useSelector((state) => state.user.userId);
  const authToken = useSelector((state) => state.user.token);
  const oldPhoneNo = useSelector((state) => state.user.phNumber);
  const newReviewCount = useSelector((state) => state.user.newReviewCount);
  const newInboxCount = useSelector((state) => state.user.newInboxCount);
  const groupNotify = useSelector((state) => state.user.groupNotify);
  const newMentionCount = useSelector((state) => state.user.newMentionCount);
  const [showQrCode, setShowQrCode] = useState(false);
  const [blockbtn, setBlockbtn] = useState(false);
  const [mobBlockbtn, setMobBlockbtn] = useState(false);
  const [popupData, setpopupData] = useState("");
  const [publicCompositeScore, setPublicCompositeScore] = useState("");
  const [userNameExts, setUserNameExts] = useState(false);
  const [toggleQues, settoggleQUes] = useState(false);
  const [toggletext, settoggletext] = useState(false);
  const [isToggled, setIsToggled] = useState(false);
  const [hideDiv, setHideDiv] = useState(false);
  const [activeKey, setActiveKey] = useState("Profile");
  const [subActiveKey, setSubActiveKey] = useState("Business_details");
  const [businessList, setbusinessList] = useState("");
  const [itiNoErr, setItiNoErr] = useState("");
  const [itiNoInvalid, setItiNoInvalid] = useState(false);
  const [defaultCmpy, setDefaultCmpy] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showServiceModal, setShowServiceModal] = useState(false);
  const [noteText, setNoteText] = useState("");
  const [noteCmtErr, setNoteCmtErr] = useState("");
  const [currentIdxId, setCurrentIdxId] = useState("");
  const [error, setError] = useState("");
  const [logoErr, setlogoErr] = useState("");
  const [errortype, setErrorType] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [serviceName, setServiceName] = useState("");
  const [serviceDescription, setServiceDescription] = useState("");
  const [currentIdx, setCurrentIdx] = useState("");
  const [serviceType, setServiceType] = useState("");
  const [expandedRowIndex, setExpandedRowIndex] = useState(null);
  const [businessNotes, setbusinessNotes] = useState([]);
  const [profImagePath, setProfImagePath] = useState("");
  const [serviceProviders, setServiceProviders] = useState([]);
  const [imageBase64, setImageBase64] = useState("");
  const [docimageback64, setDocimageBack64] = useState("");
  const [imageName, setImageName] = useState("");
  const [verificationResult, setVerificationResult] = useState("");
  const [currentStep, setCurrentStep] = useState("documentSelection");
  const [imagePreviewdoc, setImagePreviewdoc] = useState(null);
  const [imagePreviewBack, setImagePrewBack] = useState(null);
  const [identityNumber, setIdentityNumber] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [kucfirstName, setKycFirstName] = useState("");
  const [kyclastName, setKycLastName] = useState("");
  const [kycdateOfBirth, setKycDateOfBirth] = useState("");
  const [verificationstatus, setVerificationstatus] = useState(false);

  const [imageNameFront, setImageNameFront] = useState("");
  const [imageNameBack, setImageNameBack] = useState("");

  const [selectedDocument, setSelectedDocument] = useState(null);

  const [kycpopupshow, setKycpopupShow] = useState(false);
  // const [kycFirstName, setKycFirstname] = useState('');
  // const [kycLastName, setKycLastname] = useState('');

  const [documentId, setDocumentId] = useState("");

  const [imageSizeError, setImageSizeError] = useState("");

  const [kycFirstNameError, setKycFirstNameError] = useState("");
  const [kycLastNameError, setKycLastNameError] = useState("");
  const [kycDateOfBirthError, setKycDateOfBirthError] = useState("");
  const [documentTypeError, setDocumentTypeError] = useState("");
  const [documentIdError, setDocumentIdError] = useState("");

  const [failedMessage, setFailedMessage] = useState("");
  const [kycStatus, setKycStatus] = useState("");

  const [kybVerification, setKybverification] = useState("");
  const [kybBussinessid, setKybBusinessId] = useState('')
  const [kybpopup, setKybPopup] = useState(false);
  const [kybCompanyname, setKybCompanyname] = useState('');
  const [KybCountry, setKybCountry] = useState('');
  const [kybSuccess, setKybSuccess] = useState(false)
  const [kybfailes, setKybfailed] = useState(false);
  const [kybform, setKybForm] = useState(false);
  const [kybStatus, setKybStatus] = useState(null);
  const [kybErrors, setKybErrors] = useState({
    companyName: '',
    incorporationCountry: ''
  });

  const [editIndex, setEditIndex] = useState(-1);
  const [editedType, setEditedType] = useState("");
  const [editedName, setEditedName] = useState("");
  const [deletePopup, setDeletePopup] = useState(false);
  const [questionId, setQuestionId] = useState(null);
  const [errorIndex, setErrorIndex] = useState(null);
  const [typeError, setTypeError] = useState("");
  const [nameError, setNameError] = useState("");
  const [score, setScore] = useState(0);
  const [busId, setBusId] = useState('');
  const [viewPlan, setViewPlan] = useState(false);
  const location = useLocation();
  const { isFeatureEnabled, allFeatures } = useSubscription();
  const [showPopup, setShowPopup] = useState(false);
  const data = location.state;
  useEffect(() => {
    handlePlan(data);
  }, [data]);
  const handlePlan = (data) => {
    setViewPlan(data);
  };
  const validateAlphabetInput = (value) => {
    return /^[A-Za-z\s]*$/.test(value);
  };

  const handleIncrement = () => {
    if (score < 5) {
      setScore(score + 1);
    }
  };

  const handleDecrement = () => {
    if (score > 0) {
      setScore(score - 1);
    }
  };

  const updateScore = async () => {
    if (score != 0) {
      const params = {
        businessId: busId,
        userId: props.userId,
        score: score,
      };
      try {
        const response = await window.apiService.updateCultureScore(params);
        if (response.data.status === "OK") {
          toast.success(response.data.message);
          setShowScore(false);
          inviteUserBusnList(props.userId);
        }
      } catch (error) {
        console.error("Error adding service:", error);
      }
    } else {
      toast.info("Culture score should not be 0.");
    }
  };

  const renderAction = (item, i) => {
    // if (item.roleId !== 1) return null;

    return (
      <DropdownButton
        as={ButtonGroup}
        title="Actions"
        id={`dropdown-button-drop-${i}`}
        key={i}
      >
        {item.roleId === 1 && <Dropdown.Item onClick={(e) => editBusiness(item, i, item.id)}>
          <FiEdit style={{ color: "#144689", fontSize: "17px", marginRight: "10px", }} />
          Edit
        </Dropdown.Item>}
        {item.isVerified === 1 && item.roleId === 1 && (
          <Dropdown.Item onClick={() => { setShowModal(true); setCurrentIdxId(item.id); }}>
            <BiNotepad className="c_pointer" style={{ color: "#144689", fontSize: "18px", marginRight: "10px", }} />
            Add Notes
          </Dropdown.Item>
        )}
        {item.isVerified === 1 && item.roleId !== 3 &&
          (isFeatureEnabled(allFeatures["Add-Users-for-Business%"]?.key, allFeatures["Add-Users-for-Business%"]?.isDeleted) ? (
            <Dropdown.Item onClick={() => inviteUserAdd(item.id)}>
              <BsEnvelopePlus className="fz_18 c_pointer" style={{ marginRight: "10px", }} />
              Invite Users
            </Dropdown.Item>) : (<Dropdown.Item onClick={() => setShowPopup(true)}>
              <BsEnvelopePlus className="fz_18 c_pointer" style={{ marginRight: "10px", }} />
              Invite Users
            </Dropdown.Item>))
        }
        {item.isVerified === 1 && item.roleId === 1 && (
          <Dropdown.Item onClick={() => { handleEyeClick(item.id); setCurrentIdxId(item.id); }}>
            <MdMiscellaneousServices className="c_pointer" style={{ color: "#144689", fontSize: "18px", marginRight: "10px" }} />
            Add and View Services
          </Dropdown.Item>
        )}
        {item.isVerified === 1 &&
          (isFeatureEnabled(allFeatures["Culture-Score%"]?.key, allFeatures["Culture-Score%"]?.isDeleted) ?
            <Dropdown.Item onClick={() => { AddCultureScore(item.id, item.shortName, item.companyName, item.cultureScore) }}>
              <img src={cscore} height="20" style={{ marginRight: "10px" }} />
              Add Culture Score
            </Dropdown.Item> : <Dropdown.Item onClick={() => setShowPopup(true)}>
              <img src={cscore} height="20" style={{ marginRight: "10px" }} />
              Add Culture Score
            </Dropdown.Item>
          )
        }
        {/* {item.roleId === 1 && (
          <Dropdown.Item onClick={() => { handleEyeClick(item.id); setCurrentIdxId(item.id); }}>
            <MdMiscellaneousServices className="c_pointer" style={{ color: "#144689", fontSize: "18px", marginRight: "10px" }} />
            Delete
          </Dropdown.Item>
        )} */}
      </DropdownButton>
    );
  };

  const AddCultureScore = (id, name, cName, score) => {
    setShortName((name ? name : cName));
    setcmpyName(cName);
    setBusId(id);
    setShowScore(true);
    // setScore((score?score:0));
  }


  const handleEditClick = (index, type, name) => {
    setEditIndex(index);
    setEditedType(type);
    setEditedName(name);
  };

  const handleCancelEdit = () => {
    setEditIndex(-1);
    setEditedType("");
    setEditedName("");
    setTypeError("");
    setNameError("");
  };

  const changeType = (value) => {
    if (value || value === "") {
      setTypeError("");
      setEditedType(value);
    }
  };

  const changeName = (value) => {
    if (value || value === "") {
      setNameError("");
      setEditedName(value);
    }
  };

  const handleSaveEdit = async (id, index) => {
    let isValid = true;
    if (!editedType) {
      setErrorIndex(index);
      setTypeError("Please select a Service Type");
      isValid = false;
    }
    if (!editedName.trim()) {
      setErrorIndex(index);
      setNameError("Please enter the Product/ServiceProvider name");
      isValid = false;
    }
    if (!isValid) {
      return;
    }
    const params = {
      question: editedName,
      userRoleId: editedType,
      businessId: currentIdxId,
    };
    const isServiceExists = serviceProviders.some(
      (busn) =>
        busn.question === editedName &&
        busn.userRoleId === +editedType &&
        busn.id != id
    );
    if (isServiceExists) {
      toast.error("Service/Product already exists");
      return;
    }
    try {
      const response = await window.apiService.editRoleQns(id, params);
      if (response.data.status == "OK") {
        toast.success("Service/Product updated successfully");
        setEditIndex(-1);
        setEditedType("");
        setEditedName("");
        loadBusinessServiceProviders(currentIdxId);
        setErrorIndex(null);
        setTypeError("");
        setNameError("");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const toggleCollapse = () => {
    setCollapseOpen((data) => !data);
  };
  const closeCollapse = () => {
    setCollapseOpen(false);
  };
  const handleRowClick = (id, index) => {
    setExpandedRowIndex(index === expandedRowIndex ? null : index);
    loadBusinessNote(id);
  };
  const handleNotes = (event) => {
    console.log("event.target.value", event.target.value);
    setNoteText(event.target.value);
    if (event.target.value) {
      setNoteCmtErr("");
    }
  };
  useEffect(() => {
    loadBusinessList();
    loadsprofileInfo();
    loadProfessionalList();
    loadBusnList();
    if (props.userId) {
      inviteUserBusnList(props.userId);
    }
    fetch("/config.json")
      .then((res) => res.json())
      .then((res) => {
        setConfig(res);
      });
  }, [props.userId]);
  useEffect(() => {
    if (status === STATUS.start) {
      intervalRef.current = setInterval(() => {
        countDown();
      }, 1000);
    }
    return () => {
      clearInterval(intervalRef.current);
    };
  }, [minutes, seconds, status]);
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: "2-digit", month: "short", year: "numeric" };
    return new Intl.DateTimeFormat("en-GB", options).format(date);
  };
  const onIspublicToggle = () => {
    setIsPublic(!isPublic);
    if (isPublic && profileScore >= publicCompositeScore)
      setProfileScore(profileScore - publicCompositeScore);
    if (!isPublic) setProfileScore(profileScore + publicCompositeScore);
  };
  const loadBusnList = async () => {
    try {
      const response = await window.apiService.allBusnList();
      let result = response.data.data;
      if (result) {
        setbusinessList(result);
        // if (!defaultCmpy) {
        //   setDefaultCmpy(
        //     result.find(
        //       (user) => user.isVerified === 1 && user.userId === props.userId
        //     )?.id ?? null
        //   );
        // }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const loadBusinessNote = async (id) => {
    try {
      const response = await window.apiService.loadBusinessNote(id);
      let result = response.data.data;
      if (result) {
        setbusinessNotes(result);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleEyeClick = (id) => {
    setEyepop(true);
    loadBusinessServiceProviders(id);
  };

  const loadBusinessServiceProviders = async (id) => {
    try {
      const response = await window.apiService.revQnsById({ businessId: id });
      let result = response.data.recordInfo;
      if (result) {
        setServiceProviders(result);
      }
    } catch (error) {
      console.error("Error loading business service providers:", error);
    }
  };

  const inviteUserBusnList = async (id) => {
    try {
      const response = await window.apiService.inviteUserBusnList(id);
      let result = response.data.data;
      if (result) {
        setbusinessUserInfo(result);
        // if (!defaultCmpy) {
        //   setDefaultCmpy(
        //     result.find((user) => user.isVerified === 1)?.id ?? null
        //   );
        // }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleServiceNameChange = (e) => {
    setServiceName(e.target.value);
    if (e.target.value) {
      setError("");
    }
  };
  const handleServiceDescriptionChange = (e) => {
    setServiceDescription(e.target.value);
  };
  const handleServiceTypeChange = (event) => {
    setServiceType(event.target.value);
    if (event.target.value) {
      setErrorType("");
    }
  };

  const addService = async () => {
    let isValid = true;
    if (serviceName.trim() === "") {
      setError("Please enter the Product/ServiceProvider name");
      isValid = false;
    } else {
      setError("");
    }

    if (serviceType.trim() === "") {
      setErrorType("Please select a Service Type");
      isValid = false;
    } else {
      setErrorType("");
    }

    if (!isValid) {
      setIsSaving(false);
      return;
    }
    const params = {
      businessId: currentIdxId,
      question: serviceName,
      userRoleId: serviceType,
    };
    const isServiceExists = serviceProviders.some(
      (busn) =>
        busn.question === serviceName && busn.userRoleId === +serviceType
    );
    if (isServiceExists) {
      toast.error("Service/Product already exists");
      return;
    }
    try {
      const response = await window.apiService.userRoleQuesAdd(params);
      if (response.data.status === "OK") {
        toast.success("Service added successfully");
        setShowServiceModal(false);
        setServiceName("");
        setServiceDescription("");
        setServiceType("");
        loadBusinessServiceProviders(currentIdxId);
      }
    } catch (error) {
      console.error("Error adding service:", error);
    }

    setIsSaving(false);
  };

  const addNotes = async () => {
    console.log("noteText", noteText);
    if (noteText.trim()) {
      const params = {
        note: noteText,
        userId: props.userId,
        businessId: currentIdxId,
      };
      try {
        const response = await window.apiService.addNotes(params);
        // let result = response.data.data;
        if (response.data.status == "OK") {
          toast.success(response.data.message);
          setShowModal(false);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setNoteCmtErr("Note Text is required.");
    }
    setExpandedRowIndex(null);
  };
  const start = () => {
    setMinutes(5);
    setSeconds(0);
    setStatus(STATUS.start);
  };
  const Refresh = async () => {
    if (props.authToken) {
      const switchAccount = localStorage.getItem("switchAccount");
      const params = {
        profileId: props.profileId,
        authToken: props.authToken,
        userType: props.userType ? props.userType : "Personal",
        switchAccount: switchAccount ? switchAccount : false,
      };
      try {
        const data = await window.apiService.refresh(params);
        let result = data.data;
        if (data) {
          dispatch(userAction(result));
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  const inviteUserAdd = (id) => {
    if (id) {
      setDefaultCmpy(id);
      setSubActiveKey("Business_users");
    } else {
      setDefaultCmpy(id);
      setSubActiveKey("Business_details");
    }
  };
  const loadsprofileInfo = async () => {
    setdisplayContent(false);
    if (props.userId) {
      const params = {
        id: props.userId,
        userType: props.userType,
        profileId: props.profileId,
      };
      try {
        const data = await window.apiService.loadprofileInfo(params);
        let result = data.data;
        if (data.status == "OK") {
          setFirstName(result.firstName);
          setPhone(result.phone);
          dispatch(phoneNumber(result.phone));
          setLastName(result.lastName);
          setEmail(result.email);
          setState(result.state);
          setYear(result.birthYear);
          setCity(result.city);
          setImagePath(result.imagePath);
          setbusinessInfo(result.businessList);
          setprofessionInfo(result.professionalList);
          setBio(result.bio);
          setTwitterUrl(result.twitterUrl);
          setFacebookUrl(result.facebookUrl);
          setLinkedInUrl(result.linkedinUrl);
          setInstagramUrl(result.instagramUrl);
          setemailVerified(result.emailVerified);
          setmobileVerified(result.phoneVerified);
          setVerificationstatus(result.isKycVerified);
          // setVerificationstatus(false);
          setIsPublic(result.isPublic);
          setProfileScore(
            result.profileScore != undefined ? result.profileScore : 0.0
          );
          if (result.qrCode != undefined)
            setQrCode("data:image/jpeg;base64," + result.qrCode);
          else setQrCode("");
          setTiipstrSince(
            new Date(result.createdAt).toISOString().split("T")[0]
          );
          setPublicCompositeScore(result.publicScore);
          dispatch({
            type: "SAVE_USER_DATA",
            payload: {
              token: authToken,
              userId: result.id,
              userType: result.userType,
              userName: result.name,
              imagePath: result.imagePath != null ? result.imagePath : "",
              newReviewCount: newReviewCount,
              newInboxCount: newInboxCount,
              groupNotify: groupNotify,
              newMentionCount: newMentionCount,
              emailVerified: result.emailVerified,
              phoneVerified: result.phoneVerified,
              userTypeInfo: result.userTypeInfo,
              email: result.email,
              phone: result.phone != undefined ? result.phone : "",
              profileId: result.profileId,
            },
          });
          setdisplayContent(true);
        }
      } catch (error) {
        setdisplayContent(true);
        toast.error("Error Occured");
      }
    }
  };

  function countDown() {
    if (seconds === 0) {
      if (minutes !== 0) {
        setSeconds(59);
        setMinutes((min) => min - 1);
      } else {
        let mins = 0;
        let sec = 0;
        setSeconds(sec);
        setMinutes(mins);
      }
    } else {
      setSeconds((sec) => sec - 1);
    }
  }
  const loadProfessionalList = async () => {
    try {
      const response = await window.apiService.professionalList({
        userTypeId: 2,
      });
      setProfTypeList(response.data.recordInfo);
    } catch (error) {
      console.log(error);
    }
  };
  const loadBusinessList = async () => {
    try {
      const response = await window.apiService.professionalList({
        userTypeId: 3,
      });
      setBusnsTypeList(response.data.recordInfo);
    } catch (error) {
      console.log(error);
    }
  };
  const addNewProfessionType = async () => {
    if (!newProfessionType.trim()) {
      setValidation({ newProfType: "Profession type is required" });
    } else {
      if (proLoading) return;
      const params = {
        userRole: newProfessionType,
        userTypeId: 2,
      };
      setProLoading(true);
      try {
        const response = await window.apiService.userRoleAdd(params);
        if (
          response.data.status == "OK" &&
          response.data.message == "Profession Type already exists."
        ) {
          toast.error(response.data.message);
          setNewProfessionType("");
          setEditProfessionType(false);
        } else if (response.data.status == "OK") {
          toast.success(response.data.message);
          let result = response.data.recordInfo;
          setProfTypeList(result);
          setValidation({ newProfType: "" });
          setNewProfessionType("");
          setEditProfessionType(false);
          loadProfessionalList();
        }
      } catch (error) {
        console.log(error);
      }
      finally {
        setProLoading(false);
      }
    }
  };
  const phoneValidate = () => {
    const result = officePhone.indexOf(" ");
    const ph = officePhone.substring(result + 1);
    if (officePhone != "" && ph != undefined && ph.length === 14) {
      setofficePhoneValidation("");
    } else {
      setofficePhoneValidation("Phone No is required");
    }
  };
  const businessPhoneValidate = async () => {
    // Validate business phone number and update businessPhoneValidation state
    const result = cmpyPhone.indexOf(" ");
    const ph = cmpyPhone.substring(result + 1);
    if (cmpyPhone != "" && ph != undefined) {
      if (ph.length === 14) setbusinessPhoneValidation("");
      else setbusinessPhoneValidation("Invalid Phone no");
    } else setbusinessPhoneValidation("Phone No is required");
  };
  const rmvFile = (index, file) => {
    const removIdx = selectedFiles.filter(function (filePrev, idx) {
      if (idx != index) {
        return filePrev;
      }
    });
    setSelectedFiles(removIdx);
    if (editedValue.id != undefined)
      deleteAttachment(editedValue.id, file.name);
  };
  const deleteAttachment = async (id, name) => {
    setLoading(true);
    try {
      const response = await window.apiService.deleteAttachment(id, name);
      if (response.data.status == "OK") {
        setLoading(false);
        toast.success(response.data.message);
      }
    } catch (error) {
      setLoading(false);
      console.log("error", error);
    }
  };
  const businessPhoneChange = (value) => {
    setcmpyPhone(value);
  };
  const officephoneChange = (value) => {
    const result = value.indexOf(" ");
    const ph = value.substring(result + 1);
    if (value != "" && ph.length === 14) {
      setofficePhoneValidation("");
    } else {
      setofficePhoneValidation("Phone No is required");
    }
    setofficePhone(value);
  };
  const onchangeprofessionType = (value) => {
    const arr = profTypeList.filter((st) => st.name === value);
    if (!value.trim()) {
      setValidation({ professionType: "profession type is required" });
    } else {
      setValidation({ professionType: "" });
    }
    setprofessionType(value);
    if (value) {
      setprofessionTypeId(arr[0].id);
    }
  };
  const phoneChange = (value) => {
    const result = value.indexOf(" ");
    const ph = value.substring(result + 1);
    if (value != "" && ph.length === 14) {
      setPhoneValidation("");
    } else {
      setPhoneValidation("Phone No is required");
    }
    setPhone(value);
    if (value == oldPhoneNo) {
      setisPhoneChanged(false);
      setmobileVerified(true);
    } else {
      setisPhoneChanged(true);
      setmobileVerified(false);
    }
  };
  const checkfbUrlValidity = (value, type) => {
    if (type == "Personal") {
      if (value) {
        try {
          new URL(value);
          setIsValid((prevUrls) => ({ ...prevUrls, facebookUrl: "" }));
        } catch (error) {
          setIsValid((prevUrls) => ({
            ...prevUrls,
            facebookUrl: "Invalid Url",
          }));
        }
      } else setIsValid((prevUrls) => ({ ...prevUrls, facebookUrl: "" }));
      setFacebookUrl(value);
    } else {
      if (value) {
        try {
          new URL(value);
          setBusnUrlValid((prevUrls) => ({ ...prevUrls, facebookUrl: "" }));
        } catch (error) {
          setBusnUrlValid((prevUrls) => ({
            ...prevUrls,
            facebookUrl: "Invalid Url",
          }));
        }
      } else setBusnUrlValid((prevUrls) => ({ ...prevUrls, facebookUrl: "" }));
      setcmpyFbUrl(value);
    }
  };
  const checktwUrlValidity = (value, type) => {
    if (type == "Personal") {
      if (value) {
        try {
          new URL(value);
          setIsValid((prevUrls) => ({ ...prevUrls, twitterUrl: "" }));
        } catch (error) {
          setIsValid((prevUrls) => ({
            ...prevUrls,
            twitterUrl: "Invalid Url",
          }));
        }
      } else setIsValid((prevUrls) => ({ ...prevUrls, twitterUrl: "" }));
      setTwitterUrl(value);
    } else {
      if (value) {
        try {
          new URL(value);
          setBusnUrlValid((prevUrls) => ({ ...prevUrls, twitterUrl: "" }));
        } catch (error) {
          setBusnUrlValid((prevUrls) => ({
            ...prevUrls,
            twitterUrl: "Invalid Url",
          }));
        }
      } else setBusnUrlValid((prevUrls) => ({ ...prevUrls, twitterUrl: "" }));
      setcmpyTwUrl(value);
    }
  };
  const checklInUrlValidity = (value, type) => {
    if (type == "Personal") {
      if (value) {
        try {
          new URL(value);
          setIsValid((prevUrls) => ({ ...prevUrls, linkedInUrl: "" }));
        } catch (error) {
          setIsValid((prevUrls) => ({
            ...prevUrls,
            linkedInUrl: "Invalid Url",
          }));
        }
      } else setIsValid((prevUrls) => ({ ...prevUrls, linkedInUrl: "" }));
      setLinkedInUrl(value);
    } else {
      if (value) {
        try {
          new URL(value);
          setBusnUrlValid((prevUrls) => ({ ...prevUrls, linkedInUrl: "" }));
        } catch (error) {
          setBusnUrlValid((prevUrls) => ({
            ...prevUrls,
            linkedInUrl: "Invalid Url",
          }));
        }
      } else setBusnUrlValid((prevUrls) => ({ ...prevUrls, linkedInUrl: "" }));
      setcmpyLnUrl(value);
    }
  };
  const checkIgUrlValidity = (value, type) => {
    if (type == "Personal") {
      if (value) {
        try {
          new URL(value);
          setIsValid((prevUrls) => ({ ...prevUrls, instagramUrl: "" }));
        } catch (error) {
          setIsValid((prevUrls) => ({
            ...prevUrls,
            instagramUrl: "Invalid Url",
          }));
        }
      } else setIsValid((prevUrls) => ({ ...prevUrls, instagramUrl: "" }));
      setInstagramUrl(value);
    } else {
      if (value) {
        try {
          new URL(value);
          setBusnUrlValid((prevUrls) => ({ ...prevUrls, instagramUrl: "" }));
        } catch (error) {
          setBusnUrlValid((prevUrls) => ({
            ...prevUrls,
            instagramUrl: "Invalid Url",
          }));
        }
      } else setBusnUrlValid((prevUrls) => ({ ...prevUrls, instagramUrl: "" }));
      setcmpyIgUrl(value);
    }
  };
  const checkWebUrlValid = (value) => {
    if (value) {
      try {
        new URL(value);
        setBusnUrlValid((prevUrls) => ({ ...prevUrls, website: "" }));
      } catch (error) {
        setBusnUrlValid((prevUrls) => ({
          ...prevUrls,
          website: "Invalid Url",
        }));
      }
    } else setBusnUrlValid((prevUrls) => ({ ...prevUrls, website: "" }));
    setcmpyWebsite(value);
  };
  const mobileverifyset = (value) => {
    setMobilePopupShow(value);
  };
  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    const maxFileSize = 2 * 1024 * 1024;
    const validFiles = [];
    files.forEach((file) => {
      if (file.size > maxFileSize) {
        toast.error(`${file.name} exceeds the maximum size limit`);
      } else {
        validFiles.push(file);
      }
    });
    setSelectedFiles(validFiles);
  };
  const rmvLogo = () => {
    setLogoPreview("");
    setLogoimagePath(null);
    if (editedValue.id != undefined) deleteLogoImage(editedValue.id);
  };
  const deleteLogoImage = async (id) => {
    setLoading(true);
    try {
      const response = await window.apiService.deleteLogoImage(id);
      if (response.data.status == "OK") {
        setLoading(false);
        toast.success(response.data.message);
        loadsprofileInfo();
      }
    } catch (error) {
      setLoading(false);
      console.log("error", error);
    }
  };
  const uploadLogoFile = async (id) => {
    if (logoPreview) {
      let formData = new FormData();
      formData.append("file", logoPreview);
      formData.append("id", id);
      formData.append("type", "file");
      try {
        const response = await window.apiService.uploadLogoFile(formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        if (response.data.status == "OK") {
          setLoading(false);
        } else if (response.data.status == "EXPECTATION_FAILED") {
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  const handleLogoChange = (event) => {
    let allowedfiletypes = ["jpg", "svg", "jpeg", "png", "bmp", "gif", "jfif"];
    if (event.target.files.length > 0) {
      let ext = event.target.files[0].name.substring(
        event.target.files[0].name.lastIndexOf(".") + 1
      );
      const isnotFound = allowedfiletypes.some((element) => {
        if (element === ext) {
          return true;
        }
        return false;
      });
      if (isnotFound) {
        setLogoPreview(event.target.files[0]);
      } else {
        toast.error(config.FileFormatErr);
      }
    }
  };
  const handleProfImage = (event) => {
    let allowedfiletypes = ["jpg", "svg", "jpeg", "png", "bmp", "gif", "jfif"];
    if (event.target.files.length > 0) {
      let ext = event.target.files[0].name.substring(
        event.target.files[0].name.lastIndexOf(".") + 1
      );
      const isnotFound = allowedfiletypes.some((element) => {
        if (element === ext) {
          return true;
        }
        return false;
      });
      if (isnotFound) {
        setImagePreview(event.target.files[0]);
      } else {
        toast.error(config.FileFormatErr);
      }
    }
  };
  const updatebusdata = async (busnInfo) => {
    setLoading(true);
    try {
      const response = await window.apiService.businessUpdate(busnInfo);
      if (response.status == "OK") {
        console.log("response.data.businessInfo", busnInfo)
        await uploadBusinessFile(busnInfo.businessInfo?.id);
        await uploadLogoFile(busnInfo.businessInfo?.id);
        loadsprofileInfo();
        inviteUserBusnList(props.userId);
        loadBusnList();
        setActiveKey("Business");
        Refresh();
        setLoading(false);
        toast.success(response.message);
      } else {
        toast.error(response.message);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Error Occured");
    }
  };
  const updateProfInfo = async (profInfo) => {
    try {
      const response = await window.apiService.professionUpdate(profInfo);
      if (response.status === "OK") {
        toast.success(response.message);
        setActiveKey("Professional");
        Refresh();
        setLoading(false);
        setHideDiv(false);

        if (imagePreview instanceof File || imagePreview.constructor === File) {
          const res = await uploadProfFile(response.id);
        }
        loadsprofileInfo();
      } else {
        toast.error(response.message);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  const updateInfo = async (userInfo) => {
    try {
      const data = await window.apiService.profileUpdate(
        props.userId,
        userInfo
      );
      if (data.status == "OK") {
        toast.success(data.message);
        loadsprofileInfo();
        setLoading(false);
      } else {
        toast.error(data.message);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Error Occured");
    }
  };
  const checkNameExists = async (name) => {
    try {
      const response = await window.apiService.nameExists({ firstName: name });
      if (response.data.status == true) setUserNameExts(true);
      else setUserNameExts(false);
    } catch (error) {
      toast.error("Error Occured");
    }
  };
  const onSubmit = (type) => {
    let userInfo = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      phone: phone,
      state: state,
      city: city,
      birthYear: year,
      imagePath: imagePath,
      professional: professionInfo,
      isPublic: isPublic,
      isPhoneModified: isPhoneChanged,
      bio: bio,
      facebookUrl: facebookUrl,
      twitterUrl: twitterUrl,
      linkedinUrl: linkedInUrl,
      instagramUrl: instagramUrl,
      profileScore: profileScore,
    };
    if (type == "personal") {
      if (year < new Date().getFullYear() && year >= 1900) {
        if (
          firstName &&
          city &&
          state &&
          year &&
          lastName &&
          phone != "" &&
          phone != "+1 undefined" &&
          phone != "+91 undefined" &&
          !phoneValidation &&
          !userNameExts &&
          !isValid.facebookUrl &&
          !isValid.twitterUrl &&
          !isValid.linkedInUrl &&
          !isValid.instagramUrl
        ) {
          setLoading(true);
          updateInfo(userInfo);
        } else {
          if (
            isValid.facebookUrl ||
            isValid.twitterUrl ||
            isValid.linkedInUrl ||
            isValid.instagramUrl
          ) {
            toast.error("Invalid Url");
          } else {
            toast.error("Please fill out all required fields.");
          }
        }
      } else {
        toast.error("Invalid Birth Year");
      }
    } else if (type == "professional") {
      let userProfInfo = {
        id: props.userId,
        firstName: firstName,
        lastName: lastName,
        email: email,
        professionalInfo: professionInfo,
      };
      setLoading(true);
      updateProfInfo(userProfInfo);
    }
  };
  const editCancel = () => {
    removeFill();
    setHideEdit(true);
  };
  const editProCancel = () => {
    removeproFill();
    setHideProEdit(true);
    setHideDiv(false);
  };
  const validateForm = () => {
    let isValid = true;
    if (!professionType.trim()) {
      setValidation({ professionType: "Profession type is required" });
      isValid = false;
    } else {
      setValidation({ professionType: "" });
    }

    if (!officeEmail.trim()) {
      setprofEmailErr("Office Mail ID is required");
      isValid = false;
    } else if (profEmailErr) {
      setprofEmailErr("Office email is invalid");
      isValid = false;
    } else {
      setprofEmailErr("");
    }

    return isValid;
  };
  const updateProfessionInfo = () => {
    phoneValidate();
    if (validateForm()) {
      const isFound = professionInfo.some((element, idx) => {
        if (element.professionType == professionType && idx != editedProIdx) {
          return true;
        }
        return false;
      });
      if (!isFound) {
        const profInfo = {
          id: editedProvalue.id,
          professionType: professionType,
          officePhone: officePhone,
          officeAddress: officeAddress,
          officeCity: profCity,
          officeState: profState,
          professionTypeId: professionTypeId,
          email: officeEmail,
          imagePath: profImagePath,
          bio: profBio,
        };
        const userProfInfo = {
          id: props.userId,
          firstName: firstName,
          lastName: lastName,
          professionalInfo: profInfo,
        };
        updateProfInfo(userProfInfo);
        removeproFill();
        setHideProEdit(true);
      } else {
        toast.error("Profession already exists.");
      }
    } else {
      toast.error("Please fill out required fields.");
    }
    // setprofessionInfo(profData);
  };

  const updateBusinessInfo = () => {
    console.log("validateBusinessInfo()", validateBusinessInfo());
    if (validateBusinessInfo()) {
      const isFound = businessUserInfo.some((element, idx) => {
        if (element.companyName == cmpyName && idx != editedIdx) {
          return true;
        }
        return false;
      });
      if (!isFound) {
        let busnInfo = {
          id: editedValue.id,
          companyName: cmpyName,
          phone: cmpyPhone,
          email: cmpyEmail,
          shortName: cmpyShortName,
          city: cmpyCity,
          bio: busnBio,
          state: cmpyState,
          description: cmpyDescription,
          country: cmpyCountry,
          zipcode: cmpyZipCode,
          website: cmpyWebsite,
          facebookUrl: cmpyFbUrl,
          twitterUrl: cmpyTwUrl,
          instagramUrl: cmpyIgUrl,
          linkedinUrl: cmpyLnUrl,
          yearStarted: cmpySince,
          itiNo: itiNo,
          isVerified: editedValue.isVerified,
          logo: logoimagePath,
        };
        setHideEdit(true);
        let userBusiInfo = {
          id: props.userId,
          firstName: firstName,
          lastName: lastName,
          email: email,
          phone: phone,
          businessInfo: busnInfo,
        };
        setLoading(true);
        updatebusdata(userBusiInfo);
        setValidation({ cmpyName: "" });
        removeFill();
      } else {
        console.log("isfound");
        toast.error("Company Name already exists");
      }
    } else {
      if (
        busnUrlValid.facebookUrl ||
        busnUrlValid.twitterUrl ||
        busnUrlValid.linkedInUrl ||
        busnUrlValid.instagramUrl ||
        busnUrlValid.website
      ) {
        toast.error("Invalid Url");
      }
    }
  };
  async function convertJsonToFiles(jsonData) {
    const files = [];
    if (!Array.isArray(jsonData)) {
      console.error("jsonData is not an array");
      return files;
    }
    for (const item of jsonData) {
      const corsProxyUrl = "https://cors-anywhere.herokuapp.com/";
      // Fetch file from URL
      const response = await fetch(corsProxyUrl + item.filePath);
      const blob = await response.blob();

      // Create File object
      const file = new File([blob], item.fileName, {
        lastModified: new Date(item.updatedAt).getTime(),
        type: blob.type,
      });

      files.push(file);
    }

    return files;
  }
  const rmvProfFile = (file) => {
    setImagePreview("");
    setProfImagePath(null);
    if (editedProvalue.id != undefined) deleteProfImage(editedProvalue.id);
  };
  const deleteProfImage = async (id) => {
    setLoading(true);
    try {
      const response = await window.apiService.deleteProfImage(id);
      if (response.data.status == "OK") {
        setLoading(false);
        toast.success(response.data.message);
        loadsprofileInfo();
      }
    } catch (error) {
      setLoading(false);
      console.log("error", error);
    }
  };
  const getImageName = (path) => {
    const url = new URL(path);
    const pathname = url.pathname;
    const filename = pathname.split("/").pop();
    return filename;
  };
  const removeFill = () => {
    childCmpyPhRef.current.clrField();
    setcmpyName("");
    setcmpyShortName("");
    setShrtNameErr("");
    setcmpyPhone("");
    setcmpyEmail("");
    setcmpyState("");
    setcmpyCity("");
    setbusnBio("");
    setcmpyDescription("");
    setbusinessPhoneValidation("");
    setValidation({ cmpyName: "" });
    setcmpyEmailErr("");
    setItiNoErr("");
    setcmpyCountry("");
    setcmpyZipCode("");
    setcmpyWebsite("");
    setcmpyFbUrl("");
    setcmpyTwUrl("");
    setcmpyLnUrl("");
    setcmpyIgUrl("");
    setcmpySince("");
    setItiNo("");
    setDocumetErr(false);
    setItiNoInvalid(false);
    setSelectedFiles([]);
    setLogoPreview("");
  };
  const removeproFill = () => {
    childCompRef.current.clrField();
    setofficePhone("");
    setofficeAddress("");
    setprofessionType("");
    setProfCity("");
    setProfState("");
    setofficePhoneValidation("");
    setImagePreview("");
    setofficeEmail("");
    setprofBio("");
  };
  const editBusiness = (value, idx, id) => {
    setKybverification(true);
    setIsToggled(true);
    setEditedValue(value);
    setEditedIdx(idx);
    // setKybBusinessId(id)
    setHideEdit(false);
    convertJsonToFiles(value.businessFiles).then((result) => {
      setSelectedFiles(result);
    });
    // let kybstatus = value.isKybVerified;
    // if (kybstatus === 1) {
    //   setKybSuccess(true)
    // } else if (kybstatus === 0) {
    //   setKybSuccess(false)
    // }

    console.log("proffection deto", businessUserInfo)

    // console.log("aaaa", value.isKybVerified);
    console.log("hbibb", value)
    console.log("cccccc", editedValue)

    setcmpyName(value.companyName);
    setcmpyShortName(value.shortName);
    setcmpyPhone(value.phone);
    setcmpyEmail(value.email);
    setcmpyState(value.state);
    setcmpyCity(value.city);
    setbusnBio(value.bio);
    setcmpyDescription(value.description);
    setcmpyCountry(value.country);
    setcmpyZipCode(value.zipCode);
    setcmpyWebsite(value.website);
    setcmpyFbUrl(value.facebookUrl);
    setcmpyTwUrl(value.twitterUrl);
    setcmpyLnUrl(value.linkedinUrl);
    setcmpyIgUrl(value.instagramUrl);
    setcmpySince(value.yearStarted);
    setItiNo(value.itiNo);
    if (isToggled) childCmpyPhRef.current.populate();
    setLogoPreview(value.logo);
    setLogoimagePath(value.logo);
  };
  const editProfession = (value, idx) => {
    setEditedProIdx(idx);
    setEditedProvalue(value);
    setHideProEdit(false);
    setofficePhone(value.officePhone);
    setofficeAddress(value.officeAddress);
    setprofessionType(value.professionType);
    setProfCity(value.officeCity);
    setofficeEmail(value.email);
    setprofBio(value.bio);
    setProfState(value.officeState);
    setprofessionTypeId(value.professionTypeId);
    setHideDiv(true);
    if (hideDiv) childCompRef.current.populate();
    setImagePreview(value.imagePath);
    setProfImagePath(value.imagePath);
  };
  const deleteProfession = async () => {
    if (deleteProfessionId) {
      try {
        const response = await window.apiService.deleteProfession({
          id: deleteProfessionId,
          userId: userId,
        });
        if (response.data.status == "OK") {
          toast.success(response.data.message);
          setProfDeleteShow(false);
          loadsprofileInfo();
          Refresh();
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  const verifyMobileCode = async () => {
    if (verificationCode) {
      try {
        const response = await window.apiService.verifyCode({
          id: props.userId,
          type: "phone",
          verificationCode: verificationCode,
        });
        if (response.data.status == "OK") {
          toast.success(response.data.message);
          loadsprofileInfo();
          setVerifcationCode("");
          setMobilePopupShow(false);
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        toast.error("Invalid OTP");
        setVerifcationCode("");
      }
    } else {
      setemptyErr(true);
    }
  };
  const verifyEmailCode = async () => {
    if (verificationCode) {
      try {
        const response = await window.apiService.verifyCode({
          id: props.userId,
          type: "email",
          verificationCode: verificationCode,
        });
        if (response.data.status == "OK") {
          toast.success(response.data.message);
          loadsprofileInfo();
          setVerifcationCode("");
          setEmailPopupShow(false);
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        toast.error("Invalid OTP");
        setVerifcationCode("");
        console.log(error);
      }
    } else {
      setemptyErr(true);
    }
  };
  const handleProfEmailChange = (value) => {
    if (emailPattern.test(officeEmail)) {
      setprofEmailErr("");
    } else setprofEmailErr("Office email inValid");
    setofficeEmail(value);
  };
  const handleEmailChange = (value) => {
    if (emailPattern.test(cmpyEmail)) {
      setcmpyEmailErr("");
    } else setcmpyEmailErr("Invalid email address");
    setcmpyEmail(value);
  };
  const uploadProfFile = async (id) => {
    if (imagePreview) {
      let formData = new FormData();
      formData.append("file", imagePreview);
      formData.append("id", id);
      formData.append("type", "file");
      try {
        const response = await window.apiService.profUploadFile(formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        if (response.data.status == "OK") {
          setLoading(false);
        } else if (response.data.status == "EXPECTATION_FAILED") {
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  const uploadBusinessFile = async (id) => {
    if (selectedFiles.length > 0) {
      let formData = new FormData();
      for (const row of Object.keys(selectedFiles)) {
        formData.append("files", selectedFiles[row]);
      }
      formData.append("id", id);
      formData.append("type", "file");
      try {
        const response = await window.apiService.busnUploadFile(formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        if (response.data.status == "OK") {
          setLoading(false);
        } else if (response.data.status == "EXPECTATION_FAILED") {
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  const validateBusinessInfo = () => {
    businessPhoneValidate();
    if (!cmpyName.trim())
      setValidation({ cmpyName: "Company name is required" });
    else setValidation({ cmpyName: "" });
    console.log("cmpyShortName", cmpyShortName);
    if (cmpyShortName === undefined || !cmpyShortName.trim())
      setShrtNameErr("Short name is required");
    else setShrtNameErr("");
    if (itiNo == null || !itiNo.trim()) {
      setItiNoErr("Company ITI No is required");
      setItiNoInvalid(false);
    } else if (itiNo.length !== 9) {
      setItiNoErr("");
      setItiNoInvalid(true);
    } else {
      setItiNoErr("");
      setItiNoInvalid(false);
    }
    if (!cmpyEmail.trim()) setcmpyEmailErr("Company email is required");
    else {
      if (emailPattern.test(cmpyEmail)) {
        setcmpyEmailErr("");
      } else setcmpyEmailErr("Invalid email address");
    }
    if (selectedFiles.length > 0) setDocumetErr(false);
    else setDocumetErr(true);
    const result = cmpyPhone.indexOf(" ");
    const ph = cmpyPhone.substring(result + 1);
    if (
      cmpyName.trim() &&
      cmpyShortName !== undefined &&
      cmpyShortName.trim() &&
      cmpyEmail.trim() &&
      itiNo.trim() &&
      itiNo !== null &&
      itiNo.length === 9 &&
      !cmpyEmailErr &&
      emailPattern.test(cmpyEmail) &&
      cmpyPhone.trim() &&
      ph != undefined &&
      ph.length === 14 &&
      !busnUrlValid.facebookUrl &&
      !busnUrlValid.twitterUrl &&
      !busnUrlValid.linkedInUrl &&
      !busnUrlValid.instagramUrl &&
      !busnUrlValid.website &&
      selectedFiles.length > 0
    ) {
      return true;
    } else {
      return false;
    }
  };

  const addUserBusinessInfo = () => {
    if (validateBusinessInfo()) {
      const isFound = businessUserInfo.some((element) => {
        if (element.companyName == cmpyName) {
          return true;
        }
        return false;
      });
      const checkBusAllow = businessUserInfo.length == 0 ?
        true : isFeatureEnabled(allFeatures["2-Business-Profiles%"]?.key, allFeatures["2-Business-Profiles%"]?.isDeleted) ? true : false;
      if (!isFound && checkBusAllow) {
        let busnInfo = {
          companyName: cmpyName,
          phone: cmpyPhone,
          email: cmpyEmail,
          shortName: cmpyShortName,
          city: cmpyCity,
          bio: busnBio,
          state: cmpyState,
          description: cmpyDescription,
          country: cmpyCountry,
          zipcode: cmpyZipCode,
          website: cmpyWebsite,
          facebookUrl: cmpyFbUrl,
          twitterUrl: cmpyTwUrl,
          instagramUrl: cmpyIgUrl,
          linkedinUrl: cmpyLnUrl,
          yearStarted: cmpySince,
          itiNo: itiNo,
          logo: null,
        };
        let userBusiInfo = {
          id: props.userId,
          firstName: firstName,
          lastName: lastName,
          email: email,
          phone: phone,
          businessInfo: busnInfo,
        };
        setLoading(true);
        updatebusdata(userBusiInfo);
        removeFill();
        setIsToggled(false);
        // loadBusnList();
      }
      else {
        if (!checkBusAllow) {
          setShowPopup(true);
        }
        else {
          toast.error("Company Name already exists");
        }
      }
    } else {
      if (
        busnUrlValid.facebookUrl ||
        busnUrlValid.twitterUrl ||
        busnUrlValid.linkedInUrl ||
        busnUrlValid.instagramUrl ||
        busnUrlValid.website
      ) {
        toast.error("Invalid Url");
      }
    }
  };
  const addUserProfessionInfo = () => {
    phoneValidate();
    if (validateForm()) {
      const isFound = professionInfo.some((element) => {
        if (element.professionType == professionType) {
          return true;
        }
        return false;
      });
      if (!isFound) {
        var result = {
          professionType: professionType,
          professionTypeId: professionTypeId,
          officePhone: officePhone,
          officeAddress: officeAddress,
          officeCity: profCity,
          officeState: profState,
          email: officeEmail,
          bio: profBio,
          imagePath: null,
        };
        let userProfInfo = {
          id: props.userId,
          firstName: firstName,
          lastName: lastName,
          professionalInfo: result,
        };
        // setprofessionInfo(result);
        updateProfInfo(userProfInfo);
        removeproFill();
      } else {
        toast.error("Profession already exists");
      }
    } else {
      toast.error("Please fill out required fields.");
    }
  };
  const verifyMobile = async () => {
    setLoading(true);
    try {
      const response = await window.apiService.sendCode({
        id: props.userId,
        type: "phone",
      });
      if (response.data.status == "OK") {
        toast.success(response.data.message);
        start();
        setIsMobileCodeSend(true);
        setisPhoneChanged(false);
        setLoading(false);
        setVerifcationCode("");
      } else {
        setLoading(false);
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log(error);
      toast.error("Error Occured");
    }
  };
  const verifyEmail = async () => {
    setLoading(true);
    try {
      const response = await window.apiService.sendCode({
        id: props.userId,
        type: "email",
      });
      if (response.data.status == "OK") {
        toast.success(response.data.message);
        start();
        setLoading(false);
        setIsEmailCodeSend(true);
        setVerifcationCode("");
      } else {
        setLoading(false);
        toast.error(response.data.message);
      }
    } catch (error) {
      if (error) {
        toast.error("Invalid otp");
      }
    }
  };
  const createImage = (image) => {
    var reader = new FileReader();
    reader.onload = (e) => {
      setImagePath(e.target.result);
    };
    reader.readAsDataURL(image);
  };
  const uploadImage = async (event) => {
    if (event.target.files[0]) {
      const file = event.target.files[0];
      const validFormats = /\.(jpg|jpeg|png|svg)$/i;

      if (!validFormats.test(file.name)) {
        toast.error(config.ImgFormatErr);
        return false;
      }

      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = async () => {
        // if (img.width !== 320 || img.height !== 320) {
        //   toast.error(config.ImgDimErr);
        //   return false;
        // }
        setLoading(true);
        const myNewFile = new File(
          [event.target.files[0]],
          event.target.files[0].name.replace(/\s/g, ""),
          { type: event.target.files[0].type }
        );
        createImage(myNewFile);
        let formData = new FormData();
        formData.append("file", myNewFile);
        formData.append("id", userId);
        try {
          const response = await window.apiService.imgUpload(formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
          if (response.data.status == "OK") {
            loadsprofileInfo();
            setLoading(false);
            toast.success(response.data.message);
          } else if (response.data.status == "EXPECTATION_FAILED") {
            setLoading(false);
            toast.error(response.data.message);
          }
        } catch (error) {
          console.log(error);
          setLoading(false);
        }
      };
    }
  };
  const deleteImage = async () => {
    if (imagePath) {
      setLoading(true);
      setDeletePopupShow(false);
      try {
        const response = await window.apiService.deleteImage(userId);
        if (response.data.status == "OK") {
          loadsprofileInfo();
          setLoading(false);
          toast.success(response.data.message);
        } else if (response.data.status == "EXPECTATION_FAILED") {
          setLoading(false);
          toast.error(response.data.message);
        }
      } catch (error) {
        setLoading(false);
        setDeletePopupShow(false);
        console.log(error);
      }
    } else {
      toast.warning(config.DelImageErr);
    }
  };
  const handleSelect = (key) => {
    setActiveKey(key);
  };
  const handleFirstNameChange = (e) => {
    const value = e.target.value;

    // Only update if input contains only alphabets
    if (validateAlphabetInput(value)) {
      setKycFirstName(value);
      setKycFirstNameError('');
    } else {
      setKycFirstNameError('First name should contain only alphabets');
    }
  };

  // Handle last name input
  const handleLastNameChange = (e) => {
    const value = e.target.value;

    // Only update if input contains only alphabets
    if (validateAlphabetInput(value)) {
      setKycLastName(value);
      setKycLastNameError('');
    } else {
      setKycLastNameError('Last name should contain only alphabets');
    }
  };

  const handelKyc = async (e) => {
    e.preventDefault();

    if (!kucfirstName.trim()) {
      setKycFirstNameError("Please enter your first name");
      return;
    } else {
      setKycFirstNameError("");
    }

    if (!kyclastName.trim()) {
      setKycLastNameError("Please enter your last name");
      return;
    } else {
      setKycLastNameError("");
    }

    if (!kycdateOfBirth.trim()) {
      setKycDateOfBirthError("Please enter your date of birth");
      return;
    } else {
      setKycDateOfBirthError("");
    }

    if (!selectedDocument) {
      setDocumentTypeError("Please select a document type");
      return;
    } else {
      setDocumentTypeError("");
    }

    if (!documentId.trim()) {
      setDocumentIdError("Please enter a document ID");
      return;
    } else {
      setDocumentIdError("");
    }

    console.log("verrrr", verificationstatus);

    // const kycdata = {
    //   "email": email,
    //   "firstName": kucfirstName,
    //   "lastName": kyclastName,
    //   "userId": userId,
    //   "documentType": selectedDocument,
    //   "dateOfBirth": kycdateOfBirth,
    //   "documentId": documentId,

    // }

    const kycdata = {
      email: email,
      type: "person",
      userId: userId,
      documentType: selectedDocument,
      dateOfBirth: kycdateOfBirth,
      documentId: documentId,
      personDetails: {
        firstName: kucfirstName,
        lastName: kyclastName,
      },
    };
    setLoading(true);
    try {
      const response = await window.apiService.kycfirstapi(kycdata);
      if (response.status === 200) {
        setLoading(false);
        setCurrentStep("uploadForm");
      } else {
        setLoading(false);
      }
    } catch (error) {
      setCurrentStep('failed');
      setFailedMessage("Detials upload Failed Please try again");
      setLoading(false);
      console.log(error);
    }
  };

  // const handeldocconvert = (e) => {

  //   e.preventDefault();
  //   const file = e.target.files[0];
  //   const reader = new FileReader();
  //   reader.onloadend = () => {
  //     setImageBase64(reader.result);
  //     setImagePreviewdoc(reader.result);

  //   };
  //   if (file) {
  //     setImageName(file.name);
  //     reader.readAsDataURL(file);
  //   }
  // };

  const handeldocconvert = (e, type) => {
    e.preventDefault();

    const file = e.target.files[0];
    const reader = new FileReader();

    if (file.size > 4 * 1024 * 1024) {
      setImageSizeError("Error: Image size must be less than 4MB");
      return;
    } else {
      setImageSizeError("");
    }

    reader.onloadend = () => {
      if (type === "front") {
        setImageBase64(reader.result);
        setImagePreviewdoc(reader.result);
      } else if (type === "back") {
        setDocimageBack64(reader.result);
        setImagePrewBack(reader.result);
      }
      setImageName(file.name);
    };

    if (file) {
      if (type === "front") {
        setImageNameFront(file.name);
      } else if (type === "back") {
        setImageNameBack(file.name);
      }
      reader.readAsDataURL(file);
    }
    // setImageName(file.name)
    // alert(`Uploaded ${type === 'front' ? 'Front' : 'Back'} Image: ${file.name}`);
  };

  const uploadKycdocument = async (e) => {
    // console.log(docimageback64);
    // console.log(imageBase64)

    // console.log( `${imageBase64},${docimageback64}`)
    const documentdata = {
      fileName: imageName,
      data: `${imageBase64},${docimageback64}`,
    };
    setLoading(true);

    try {
      e.preventDefault();

      const response = await window.apiService.kycdocumentupload(documentdata);
      if (response.status === 200) {
        setTimeout(() => {
          verifyDocument(e);
        }, 20000);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setCurrentStep('failed');
      setFailedMessage("Verification Failed Please try again");
      setLoading(false);
      console.log(error);
      setLoading(false);
      setTimeout(() => {
        window.location.reload()
      }, 7000);
    }
  };

  const verifyDocument = async (e) => {
    e.preventDefault();
    try {
      const response = await window.apiService.verifyKyc();
      let kycdata = response.data;
      let status = kycdata.status;
      setKycStatus(kycdata.status)

      if (kycdata.result && kycdata.result.breakdown && kycdata.result.breakdown.extractedData && kycdata.result.breakdown.extractedData.documentDetails) {
        let documentNumber = kycdata.result.breakdown.extractedData.documentDetails.documentNumber;
        console.log("Document Number:", documentNumber);
      } else {
        console.error("Document details are not available in the provided data.");
      }


      if (status === "failed") {
        setCurrentStep('failed');
        setFailedMessage("Please Give the Valid Document to Verify");
        setLoading(false);
        setTimeout(() => {
          updatedKycVerification(e, false);
        }, 2000);
        setTimeout(() => {
          window.location.reload()
        }, 7000);
      }
      else if (status === "pending") {
        setLoading(true);
        setTimeout(() => {
          kycPendingVerification(e);
        }, 10000)
      } else if (status === "complete") {
        setLoading(false);
        const parts = kycdateOfBirth.split('-');
        const formattedDateOfBirth = `${parts[0]}-${parseInt(parts[1], 10)}-${parseInt(parts[2], 10)}`;
        console.log("date of birth" + formattedDateOfBirth)

        let firstnamecheck =
          response.data.result.breakdown.extractedData.holderDetails
            .firstName[0];
        let lastnamecheck =
          response.data.result.breakdown.extractedData.holderDetails
            .lastName[0];
        let dob =
          response.data.result.breakdown.extractedData.holderDetails.dob;
        let dateofbirthcheck = `${dob.year}-${dob.month}-${dob.day}`;
        // let agecheck = response.data.result.breakdown.extractedData.holderDetails.age;
        let documentNumber =
          kycdata.result.breakdown.extractedData.documentDetails.documentNumber;

        console.log(
          "firstname" +
          firstnamecheck +
          "\n" +
          "lastname" +
          lastnamecheck +
          "\n" +
          "date of birth" +
          dateofbirthcheck
        );

        if (firstnamecheck.toLowerCase() === kucfirstName.toLowerCase() &&
          lastnamecheck.toLowerCase() === kyclastName.toLowerCase() && dateofbirthcheck === formattedDateOfBirth && documentNumber === documentId) {
          setVerificationResult("success");
          setCurrentStep("successful");
          setLoading(false);
          setVerificationstatus(true);
          setTimeout(() => {
            updatedKycVerification(e, true);
            setKycpopupShow(false);
          }, 2000);

        } else {
          let failedMessage = "";
          if (firstnamecheck.toLowerCase() !== kucfirstName.toLowerCase()) {
            failedMessage += `First name does not match the provided document. Expected: ${kucfirstName}, Actual: ${firstnamecheck}\n`;
          }
          if (lastnamecheck.toLowerCase() !== kyclastName.toLowerCase()) {
            failedMessage += `Last name does not match the provided document. Expected: ${kyclastName}, Actual: ${lastnamecheck}\n`;
          }
          if (dateofbirthcheck !== formattedDateOfBirth) {
            failedMessage += `Date of birth does not match the provided document. Expected: ${formattedDateOfBirth}, Actual: ${dateofbirthcheck}\n`;
          }
          if (documentNumber !== documentId) {
            failedMessage += `Document number does not match the provided document. Expected: ${documentId}, Actual: ${documentNumber}\n`;
          }
          if (failedMessage) {
            setCurrentStep('failed');
            setLoading(false);
            setFailedMessage(failedMessage.trim());
            setTimeout(() => {
              updatedKycVerification(e, false);
              setKycpopupShow(false);
              setCurrentStep("documentSelection");
              window.location.reload()
            }, 7000);
            // setTimeout(() => {
            //   setCurrentStep("documentSelection");
            // }, 5000);
            // setTimeout(() => {
            //   window.location.reload()
            // }, 7000);
          }
        }

      }
      else {
        setCurrentStep('failed');
        setFailedMessage("Please Give the Valid Document to Verify");
        setLoading(false);
        setTimeout(() => {
          updatedKycVerification(e, false);
        }, 2000);
        setTimeout(() => {
          window.location.reload()
        }, 7000);
      }
      console.log("status", status);
    } catch (error) {
      setLoading(false);
      setCurrentStep('failed');
      setFailedMessage("Verification failed Please try again");
      console.log(error);
      setTimeout(() => {
        window.location.reload()
      }, 7000);
    }
  };

  const kycPendingVerification = async (e) => {
    e.preventDefault();

    try {
      const response = await window.apiService.verifyKyc();
      let kycdata = response.data;
      let status = kycdata.status;
      setKycStatus(kycdata.status)


      if (kycdata.result && kycdata.result.breakdown && kycdata.result.breakdown.extractedData && kycdata.result.breakdown.extractedData.documentDetails) {
        let documentNumber = kycdata.result.breakdown.extractedData.documentDetails.documentNumber;
        console.log("Document Number:", documentNumber);
      } else {
        console.error("Document details are not available in the provided data.");
      }

      console.log("Staqtusshvjhv" + status)


      if (status === "failed") {
        setCurrentStep('failed');
        setFailedMessage("Please Give the Valid Document to Verify");
        setLoading(false);
        setTimeout(() => {
          updatedKycVerification(e, false);
        }, 2000);
        setTimeout(() => {
          window.location.reload()
        }, 7000);
      }
      else if (status === "pending") {
        verifyDocument(e);
      } else if (status === "complete") {
        const parts = kycdateOfBirth.split('-');
        const formattedDateOfBirth = `${parts[0]}-${parseInt(parts[1], 10)}-${parseInt(parts[2], 10)}`;
        console.log("date of birth" + formattedDateOfBirth)

        let firstnamecheck = response.data.result.breakdown.extractedData.holderDetails.firstName[0];
        let lastnamecheck = response.data.result.breakdown.extractedData.holderDetails.lastName[0];
        let dob = response.data.result.breakdown.extractedData.holderDetails.dob;
        let dateofbirthcheck = `${dob.year}-${dob.month}-${dob.day}`;
        // let agecheck = response.data.result.breakdown.extractedData.holderDetails.age;
        let documentNumber = kycdata.result.breakdown.extractedData.documentDetails.documentNumber;

        console.log("firstname" + firstnamecheck + "\n" + "lastname" + lastnamecheck + "\n" + "date of birth" + dateofbirthcheck)

        if (firstnamecheck.toLowerCase() === kucfirstName.toLowerCase() &&
          lastnamecheck.toLowerCase() === kyclastName.toLowerCase() && dateofbirthcheck === formattedDateOfBirth && documentNumber === documentId) {
          setVerificationResult("success");
          setCurrentStep("successful");
          setLoading(false);
          setVerificationstatus(true);
          setTimeout(() => {
            updatedKycVerification(e, true);
            setKycpopupShow(false);
          }, 2000);
        } else {
          let failedMessage = "";
          if (firstnamecheck.toLowerCase() !== kucfirstName.toLowerCase()) {
            failedMessage += `First name does not match the provided document. Expected: ${kucfirstName}, Actual: ${firstnamecheck}\n`;
          }
          if (lastnamecheck.toLowerCase() !== kyclastName.toLowerCase()) {
            failedMessage += `Last name does not match the provided document. Expected: ${kyclastName}, Actual: ${lastnamecheck}\n`;
          }
          if (dateofbirthcheck !== formattedDateOfBirth) {
            failedMessage += `Date of birth does not match the provided document. Expected: ${formattedDateOfBirth}, Actual: ${dateofbirthcheck}\n`;
          }
          if (documentNumber !== documentId) {
            failedMessage += `Document number does not match the provided document. Expected: ${documentId}, Actual: ${documentNumber}\n`;
          }
          if (failedMessage) {
            setCurrentStep('failed');
            setLoading(false);
            setFailedMessage(failedMessage.trim());
            setTimeout(() => {
              updatedKycVerification(e, false);
              setKycpopupShow(true);
              setCurrentStep("documentSelection");
              window.location.reload()
            }, 2000);

          }
        }

      }
      else {
        setCurrentStep('failed');
        setFailedMessage("Please Give the Valid Document to Verify");
        setLoading(false);
        setTimeout(() => {
          updatedKycVerification(e, false);
          window.location.reload()
        }, 5000);

      }
      console.log("status", status);
    } catch (error) {
      console.log(error);
      setLoading(false);
      setTimeout(() => {
        window.location.reload()
      }, 5000);
    }

  }

  const uploaddoc = () => {
    setCurrentStep("confirmation");
  };
  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  const updatedKycVerification = async (e, verificationstatus) => {
    const verificationdata = {
      verified: verificationstatus,
    };

    console.log("verificationstatus" + verificationstatus);
    // setLoading(true);

    let id = userId;

    try {
      e.preventDefault();

      const response = await window.apiService.updateKycVerification(
        id,
        verificationdata
      );
      if (response.status === 200) {
        console.log("status oke");
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };
  const showDeletePopup = (questionId) => {
    setQuestionId(questionId);
    setDeletePopup(true);
  };

  const deleteQuestion = async () => {
    if (questionId) {
      try {
        const response = await window.apiService.deleteRoleQns(questionId);
        if (response.data.status == "OK") {
          toast.success(response.data.message);
          setDeletePopup(false);
          loadBusinessServiceProviders(currentIdxId);
        }
      } catch (error) {
        console.log(error);
        setDeletePopup(false);
      }
    }
  };

  const Kybfun = async (e) => {
    e.preventDefault();
    setLoading(true);

    const validate = () => {
      let valid = true;
      const newErrors = {
        companyName: '',
        incorporationCountry: ''
      };

      if (!kybCompanyname) {
        valid = false;
        newErrors.companyName = 'Company Name is required';
      }

      if (!KybCountry) {
        valid = false;
        newErrors.incorporationCountry = 'Incorporation Country is required';
      }

      setKybErrors(newErrors);
      return valid;
    };

    if (!validate()) {
      setLoading(false);
      return;
    }

    const kybdata = {
      "companyName": kybCompanyname,
      "incorporationCountry": KybCountry
    };
    try {
      const response = await window.apiService.kybApicall(kybdata
      );
      if (response.status === 200) {
        setLoading(false)
        setKybSuccess(true)
        setKybForm(false)
        console.log("status oke ");
        console.log(response)
        setKybStatus(true);
        setTimeout(() => {
          kybVerificationStatus(e, true);
        }, 1000);

        setTimeout(() => {
          setKybPopup(false)
        }, 5000);

      } else {
        setKybStatus(false);
        setLoading(false)
        setKybForm(false)
        setKybfailed(true)
        setTimeout(() => {
          setKybPopup(false)
        }, 5000);
      }
    } catch (error) {
      setLoading(false)
      setKybForm(false)
      setKybfailed(true)
      console.log(error);
      setTimeout(() => {
        setKybPopup(false)
      }, 5000);
    }
  }

  const kybVerificationStatus = async (e, kybverifiystatus) => {
    e.preventDefault();

    console.log("kybStatus", kybStatus)

    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().split('T')[0]; // Format to YYYY-MM-DD

    const kybverifydata = {
      "id": kybBussinessid,
      "kybStatus": kybverifiystatus,
      "kybVerificationdate": formattedDate
    }
    try {
      const response = await window.apiService.kybverificationApi(kybverifydata
      );
      if (response.status === 200) {
        // setLoading(false)
        // setKybSuccess(true)
        // setKybForm(false)
        console.log("status oke ");
        console.log(response)
      } else {
        // setLoading(false)
        // setKybForm(false)
        // setKybfailed(true)
      }
    } catch (error) {
      // setLoading(false)
      // setKybForm(false)
      // setKybfailed(true)
      console.log(error);
    }
  }

  const closeScore = () => {
    setShowScore(false);
    setScore(0);
  }
  return (
    <>
      <HeaderMenu />
      <div className="">
        <SideBar />
        {isLoading ? (
          <div className="pro-loader-center">
            <img className="loader-image" src={loader} alt="Loading..." />
          </div>
        ) : (
          ""
        )}
        {displayContent ? (
          <div className="main-content ml_200">
            <div className="pro_tab_div">
              <Tabs
                defaultActiveKey="Profile"
                activeKey={activeKey}
                onSelect={handleSelect}
                id="profile_tab"
                className="mb-2"
              >
                <Tab
                  eventKey="Profile"
                  title={
                    <span className="pro_tab_span">
                      <IoPersonCircleSharp className="dashboardTabIcon" />
                      Personal
                    </span>
                  }
                >
                  <div className="pro_container mt-2">
                    <div className="profile_ml">
                      {loading ? (
                        <div className="pro-loader-center">
                          <img
                            className="loader-image"
                            src={loader}
                            alt="Loading..."
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {!viewPlan ? <div className="profile_div">
                        <Container className="pb-0" fluid>
                          <Row>
                            <Col className="order-xl-1" xl="12">
                              <Card className="bg-secondary profile_card">
                                <Card.Header className="bg-white border-0">
                                  <Row>
                                    <Col lg={12} md={12} sm={12}>
                                      <h3 className="mb-0 fz-22 text-center alert-link">
                                        Profile Information
                                      </h3>
                                    </Col>
                                    <Col lg={12} md={12} sm={12}>
                                      {tiipstrSince ? (
                                        <div className="float-right fz-15 font-weight-500">
                                          tiipstr since -&nbsp;
                                          <span className="f13_clr">
                                            {formatDate(tiipstrSince)}
                                          </span>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </Col>
                                  </Row>
                                </Card.Header>
                                <Card.Body>
                                  <Row>
                                    <Col lg="4" md="4" sm="12">
                                      <Row className="justify-content-center">
                                        <div
                                          className="card-profile-image"
                                        >
                                          <img
                                            className="imagePreview"
                                            src={
                                              imagePath ? imagePath : profile
                                            }
                                          />
                                          <label className="upload-icon" title="Logo size should be 320 x 320.Acceptable file formats are jpeg, png or svg.">
                                            <TiCamera className="uploadfont" />
                                            <input
                                              id="interviewer_profile_photo"
                                              type="file"
                                              accept="image/jpeg, image/png, image/svg+xml"
                                              class="uploadFile img"
                                              style={{
                                                width: "0px",
                                                height: "0px",
                                                overflow: "hidden",
                                              }}
                                              onChange={uploadImage}
                                            />
                                          </label>
                                          <label className="delete-icon" title="Delete profile image">
                                            <AiOutlineClose
                                              className="closefont"
                                              onClick={() =>
                                                setDeletePopupShow(true)
                                              }
                                            />
                                          </label>
                                        </div>
                                      </Row>
                                      {/* <Row className="justify-content-center">
                                  <div className="card-profile-image">
                                    <CircularProgressbarWithChildren value={profileScore*100/1.2} strokeWidth={5} maxValue={100}>
                                      <img className='imagePreview' src={imagePath ? imagePath : profile}/>
                                        <label className="upload-icon"><TiCamera className="uploadfont"/><input id="interviewer_profile_photo" type="file" accept="image/*" class="uploadFile img" style={{width: '0px',height: '0px',overflow: 'hidden'}} onChange={uploadImage}/></label>
                                      <label className="delete-icon"><AiOutlineClose className="closefont" onClick={()=>setDeletePopupShow(true)} /></label>
                                    </CircularProgressbarWithChildren>
                                    <span className="f13_clr">{Math.trunc(profileScore*100/1.2)+'%'}</span>
                                    </div>
                                  </Row> */}
                                      <Row className="justify-content-center mb-2">
                                        <div className="text-center fz-22 alert-link">
                                          {firstName} {lastName}{" "}
                                          {isFeatureEnabled(allFeatures["Kyc%"]?.key, allFeatures["Kyc%"]?.isDeleted) && verificationstatus === true ? (
                                            <HiBadgeCheck
                                              style={{
                                                fontSize: "22px",
                                                color: "green",
                                                cursor: "pointer",
                                              }}
                                              title="KYC Verified"
                                              data-toggle="tooltip"
                                              data-placement="top"
                                            />
                                          ) : (
                                            <></>
                                          )}
                                        </div>
                                      </Row>
                                      {isFeatureEnabled(allFeatures["QR-Code%"]?.key, allFeatures["QR-Code%"]?.isDeleted) ? (Qrcode ? (
                                        <Row className="justify-content-center">
                                          <div className="qr_image">
                                            <img
                                              src={Qrcode}
                                              alt="Base64 Image"
                                            />
                                          </div>
                                        </Row>
                                      ) : (
                                        <Row className="justify-content-center">
                                          <Button
                                            onClick={() => onSubmit("personal")}
                                            className="btn btn-default qr_btn btn-primary"
                                          >
                                            <IoQrCode />
                                            &nbsp;Generate QR Code
                                          </Button>
                                        </Row>
                                      )) : <div className="text-center">
                                        <p className="c_pointer font-weight-400 text-blue mb-2 fz_14" onClick={() => setShowPopup(true)}>View QR Code</p></div>}

                                      <Row className="justify-content-center">
                                        <Col lg="6" className="text-center">
                                          <Form.Group class="form-group required-field-block">
                                            {verificationstatus === true ? (
                                              <></>
                                            ) : (
                                              // <HiCheckCircle
                                              //   style={{ fontSize: "20px", color: "green", cursor: 'pointer' }}
                                              // // onClick={() => setKycpopupShow(true)}
                                              // />
                                              <Form.Label className="mb-1 profile_label font-weight-500 text-danger">
                                                KYC Pending &nbsp;
                                              </Form.Label>
                                            )}

                                            {verificationstatus === true ? (
                                              <></>
                                            ) : (
                                              // <HiCheckCircle
                                              //   style={{ fontSize: "20px", color: "green", cursor: 'pointer' }}
                                              // // onClick={() => setKycpopupShow(true)}
                                              // />
                                              <BsExclamationCircle
                                                className={"text-danger"}
                                                style={{
                                                  fontSize: "20px",
                                                  cursor: "pointer",
                                                }}
                                                onClick={() => {
                                                  if (isFeatureEnabled(allFeatures["Kyc%"]?.key, allFeatures["Kyc%"]?.isDeleted)) {
                                                    setKycpopupShow(true);
                                                  } else {
                                                    setShowPopup(true);
                                                  }
                                                }}
                                              />
                                            )}
                                          </Form.Group>
                                        </Col>
                                      </Row>
                                    </Col>
                                    <Col lg="8" md="8" sm="12">
                                      <Form>
                                        <div className="">
                                          <Row>
                                            <Col lg="6">
                                              <Form.Group className="form-group">
                                                <Form.Label className="mb-1 profile_label">
                                                  First Name
                                                </Form.Label>
                                                <Form.Control
                                                  type="text"
                                                  className={
                                                    !firstName
                                                      ? "form-control input_ctrl error"
                                                      : "form-control input_ctrl"
                                                  }
                                                  onChange={(e) => {
                                                    const value = e.target.value;
                                                    const regex = /^[a-zA-Z\s]*$/;
                                                    if (regex.test(value)) {
                                                      checkNameExists(value);
                                                      setFirstName(value);
                                                    }
                                                  }}
                                                  value={firstName}
                                                  placeholder="first name"
                                                />
                                                {!firstName && (
                                                  <p className="fz-13">
                                                    First Name is required
                                                  </p>
                                                )}
                                                {/* {userNameExts && (
                                                  <p className="fz-13">
                                                    Name already Exists
                                                  </p>
                                                )} */}
                                              </Form.Group>
                                            </Col>
                                            <Col lg="6">
                                              <Form.Group className="form-group">
                                                <Form.Label className="mb-1 profile_label">
                                                  Last Name
                                                </Form.Label>
                                                <Form.Control
                                                  type="text"
                                                  className={
                                                    !lastName
                                                      ? "form-control input_ctrl error"
                                                      : "form-control input_ctrl"
                                                  }
                                                  onChange={(e) => {
                                                    const value = e.target.value;
                                                    const regex = /^[a-zA-Z\s]*$/;
                                                    if (regex.test(value)) {
                                                      setLastName(value);
                                                    }
                                                  }}
                                                  value={lastName}
                                                  placeholder="last name"
                                                />
                                                {!lastName && (
                                                  <p className="fz-13">
                                                    Last Name is required
                                                  </p>
                                                )}
                                              </Form.Group>
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col lg="6">
                                              <PhoneControl
                                                phoneNo={phone}
                                                unmounted={true}
                                                isProfile={true}
                                                mandatory={true}
                                                mobileVerify={true}
                                                PopupShow={mobileverifyset}
                                                isverified={mobileVerified}
                                                isPhoneChanged={isPhoneChanged}
                                                onPhoneChange={phoneChange}
                                                phoneValidation={
                                                  phoneValidation
                                                }
                                              />
                                            </Col>
                                            <Col lg="6">
                                              <Form.Group class="form-group required-field-block">
                                                <Form.Label className="mb-1 profile_label">
                                                  Email Address
                                                </Form.Label>
                                                <Form.Control
                                                  type="email"
                                                  className="form-control input_ctrl"
                                                  title={
                                                    email.length > 30
                                                      ? email
                                                      : ""
                                                  }
                                                  value={
                                                    email.length > 30
                                                      ? email
                                                        .substring(0, 30)
                                                        .concat("...")
                                                      : email
                                                  }
                                                  disabled
                                                  placeholder="email"
                                                  name="email"
                                                  maxlength="80"
                                                />
                                                {email != "" && (
                                                  <span class="required-icon">
                                                    {!emailVerified ? (
                                                      <a
                                                        href="#"
                                                        onClick={() =>
                                                          setEmailPopupShow(
                                                            true
                                                          )
                                                        }
                                                      >
                                                        <BsExclamationCircle
                                                          className={
                                                            email != "" &&
                                                              emailVerified
                                                              ? ""
                                                              : "text-danger"
                                                          }
                                                          style={{
                                                            fontSize: "20px",
                                                          }}
                                                        />
                                                      </a>
                                                    ) : (
                                                      <a href="#">
                                                        <HiCheckCircle
                                                          style={{
                                                            fontSize: "20px",
                                                            color: "green",
                                                          }}
                                                        />
                                                      </a>
                                                    )}
                                                  </span>
                                                )}
                                              </Form.Group>
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col lg="6">
                                              <Form.Group className="form-group">
                                                <Form.Label className="mb-1 profile_label">
                                                  City
                                                </Form.Label>
                                                <Form.Control
                                                  type="text"
                                                  className={
                                                    !city
                                                      ? "form-control input_ctrl error"
                                                      : "form-control input_ctrl"
                                                  }
                                                  onChange={(e) =>
                                                    setCity(e.target.value)
                                                  }
                                                  value={city}
                                                  placeholder="city"
                                                />
                                                {!city && (
                                                  <p className="fz-13">
                                                    City is required
                                                  </p>
                                                )}
                                              </Form.Group>
                                            </Col>
                                            <Col lg="6">
                                              <Form.Group className="form-group">
                                                <Form.Label className="mb-1 profile_label">
                                                  State
                                                </Form.Label>
                                                <Form.Control
                                                  type="text"
                                                  className={
                                                    !state
                                                      ? "form-control input_ctrl error"
                                                      : "form-control input_ctrl"
                                                  }
                                                  onChange={(e) =>
                                                    setState(e.target.value)
                                                  }
                                                  value={state}
                                                  placeholder="state"
                                                />
                                                {!state && (
                                                  <p className="fz-13">
                                                    State is required
                                                  </p>
                                                )}
                                              </Form.Group>
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col lg="6">
                                              <Form.Group
                                                className="form-group"
                                                onKeyPress={(e) =>
                                                  !/[0-9]/.test(e.key) &&
                                                  e.preventDefault()
                                                }
                                              >
                                                <Form.Label className="mb-1 profile_label">
                                                  Birth Year
                                                </Form.Label>
                                                <Form.Control
                                                  type="text"
                                                  className={
                                                    !year
                                                      ? "form-control input_ctrl error"
                                                      : "form-control input_ctrl"
                                                  }
                                                  onChange={(e) =>
                                                    setYear(e.target.value)
                                                  }
                                                  value={year}
                                                  placeholder="birth year"
                                                  maxLength={4}
                                                />
                                                {!year && (
                                                  <p className="fz-13">
                                                    Birth Year is required
                                                  </p>
                                                )}
                                              </Form.Group>
                                            </Col>
                                            <Col lg="6">
                                              <Form.Group className="form-group">
                                                <Form.Label className="mb-1 profile_label">
                                                  Bio
                                                </Form.Label>
                                                <textarea
                                                  type="text"
                                                  className="form-control text-area input_ctrl"
                                                  maxLength={72}
                                                  onChange={(e) =>
                                                    setBio(e.target.value)
                                                  }
                                                  value={bio}
                                                />
                                                <p className="f13_clr float-right">
                                                  72 characters only *
                                                </p>
                                              </Form.Group>
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col lg="6">
                                              <div className="d-flex">
                                                <div className="d-flex">
                                                  <label className="font-weight-500 fz_14 mr-1">
                                                    {isPublic
                                                      ? "Public Account"
                                                      : "Private Account"}
                                                  </label>
                                                  <sup>
                                                    <GrCircleQuestion
                                                      onMouseEnter={() =>
                                                        settoggleQUes(true)
                                                      }
                                                      onMouseLeave={() =>
                                                        settoggleQUes(false)
                                                      }
                                                      className=" c_pointer font-weight-500 text-blue fz_15 mr-2"
                                                    />
                                                  </sup>
                                                  <label className="toggle-switch">
                                                    <input
                                                      type="checkbox"
                                                      checked={isPublic}
                                                      onChange={() => {
                                                        if (!isPublic && isFeatureEnabled(allFeatures["Public-acccount%"]?.key, allFeatures["Public-acccount%"]?.isDeleted)) {
                                                          onIspublicToggle();
                                                        } else if (!isPublic) {
                                                          setShowPopup(true);
                                                        } else {
                                                          onIspublicToggle();
                                                        }
                                                      }}
                                                    />;
                                                    <span className="switch" />
                                                  </label>
                                                </div>
                                              </div>
                                            </Col>
                                            <Col lg="6"><Button
                                              onClick={() => setViewPlan(true)}
                                              className="btn btn-danger"
                                              style={{ borderRadius: "20px" }}
                                            >
                                              Subscribe <MdDoubleArrow />
                                            </Button></Col>
                                          </Row>
                                          <div>
                                            {toggleQues && (
                                              <div className="hover_text">
                                                Making account as public will
                                                increase the overall score
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      </Form>
                                    </Col>
                                  </Row>
                                  {isPublic && (
                                    <Row>
                                      <Col lg="4" md="4" sm="12"></Col>
                                      <Col lg="8" md="8" sm="12">
                                        <div className="div_br">
                                          <Col lg={12} md={12} sm={12}>
                                            <h3 className="mb-0 fz-22 text-center alert-link">
                                              <div className="social_hr">
                                                <h5>
                                                  Social{" "}
                                                  <sup>
                                                    <GrCircleQuestion
                                                      onMouseEnter={() =>
                                                        settoggletext(true)
                                                      }
                                                      onMouseLeave={() =>
                                                        settoggletext(false)
                                                      }
                                                      className="font-weight-500 c_pointer text-blue fz_15 mt-1"
                                                    />
                                                  </sup>
                                                </h5>
                                                {toggletext && (
                                                  <div className="hover_text">
                                                    Boost your Overall Score by
                                                    adding your Social Media
                                                    accounts - Additional score
                                                    increase for each account
                                                    added!
                                                  </div>
                                                )}
                                              </div>
                                            </h3>
                                            <Form>
                                              <div className="">
                                                <Row>
                                                  <Col lg="6">
                                                    <Form.Group className="form-group">
                                                      <Form.Label className="mb-1 profile_label">
                                                        Facebook Url
                                                      </Form.Label>
                                                      <Form.Control
                                                        type="text"
                                                        className="form-control input_ctrl"
                                                        onChange={(e) => {
                                                          if (isFeatureEnabled(allFeatures["Add-social-media-urls%"]?.key, allFeatures["Add-social-media-urls%"]?.isDeleted)) {
                                                            checkfbUrlValidity(
                                                              e.target.value,
                                                              "Personal"
                                                            )
                                                          }
                                                          else {
                                                            setShowPopup(true);
                                                          }
                                                        }
                                                        }
                                                        value={facebookUrl}
                                                      />
                                                      {isValid.facebookUrl !==
                                                        "" && (
                                                          <p className="fz-13">
                                                            {isValid.facebookUrl}
                                                          </p>
                                                        )}
                                                    </Form.Group>
                                                  </Col>
                                                  <Col lg="6">
                                                    <Form.Group className="form-group">
                                                      <Form.Label className="mb-1 profile_label">
                                                        X Url
                                                      </Form.Label>
                                                      <Form.Control
                                                        type="text"
                                                        className="form-control input_ctrl"
                                                        onChange={(e) => {
                                                          if (isFeatureEnabled(allFeatures["Add-social-media-urls%"]?.key, allFeatures["Add-social-media-urls%"]?.isDeleted)) {
                                                            checktwUrlValidity(
                                                              e.target.value,
                                                              "Personal"
                                                            )
                                                          }
                                                          else {
                                                            setShowPopup(true);
                                                          }
                                                        }}
                                                        value={twitterUrl}
                                                      />
                                                      {isValid.twitterUrl !==
                                                        "" && (
                                                          <p className="fz-13">
                                                            {isValid.twitterUrl}
                                                          </p>
                                                        )}
                                                    </Form.Group>
                                                  </Col>
                                                </Row>
                                                <Row>
                                                  <Col lg="6">
                                                    <Form.Group className="form-group">
                                                      <Form.Label className="mb-1 profile_label">
                                                        LinkedIn Url
                                                      </Form.Label>
                                                      <Form.Control
                                                        type="text"
                                                        className="form-control input_ctrl"
                                                        onChange={(e) => {
                                                          if (isFeatureEnabled(allFeatures["Add-social-media-urls%"]?.key, allFeatures["Add-social-media-urls%"]?.isDeleted)) {
                                                            checklInUrlValidity(
                                                              e.target.value,
                                                              "Personal"
                                                            )
                                                          }
                                                          else {
                                                            setShowPopup(true);
                                                          }
                                                        }}
                                                        value={linkedInUrl}
                                                      />
                                                      {isValid.linkedInUrl !==
                                                        "" && (
                                                          <p className="fz-13">
                                                            {isValid.linkedInUrl}
                                                          </p>
                                                        )}
                                                    </Form.Group>
                                                  </Col>
                                                  <Col lg="6">
                                                    <Form.Group className="form-group">
                                                      <Form.Label className="mb-1 profile_label">
                                                        Instagram Url
                                                      </Form.Label>
                                                      <Form.Control
                                                        type="text"
                                                        className="form-control input_ctrl"
                                                        onChange={(e) => {
                                                          if (isFeatureEnabled(allFeatures["Add-social-media-urls%"]?.key, allFeatures["Add-social-media-urls%"]?.isDeleted)) {
                                                            checkIgUrlValidity(
                                                              e.target.value,
                                                              "Personal"
                                                            )
                                                          }
                                                          else {
                                                            setShowPopup(true);
                                                          }
                                                        }}
                                                        value={instagramUrl}
                                                      />
                                                      {isValid.instagramUrl !==
                                                        "" && (
                                                          <p className="fz-13">
                                                            {isValid.instagramUrl}
                                                          </p>
                                                        )}
                                                    </Form.Group>
                                                  </Col>
                                                </Row>
                                              </div>
                                            </Form>
                                          </Col>
                                        </div>
                                      </Col>
                                    </Row>
                                  )}
                                  <div className="pl-lg-4 pt-3 text-center">
                                    <Button
                                      onClick={() => onSubmit("personal")}
                                      className="btn btn-default update_btn btn-primary"
                                    >
                                      Update
                                    </Button>
                                  </div>
                                </Card.Body>
                              </Card>
                            </Col>
                          </Row>
                        </Container>
                      </div> : <PlanDetails back={handlePlan} />}
                    </div>
                  </div>
                </Tab>
                {isFeatureEnabled(allFeatures["Professional-Profile%"]?.key, allFeatures["Professional-Profile%"]?.isDeleted) && <Tab
                  eventKey="Professional"
                  title={
                    <span className="pro_tab_span">
                      <FaUserTie className="dashboardTabIcon" />
                      Professional
                    </span>
                  }
                >
                  <div className="pro_container">
                    <div className="profile_ml">
                      {loading ? (
                        <div className="pro-loader-center">
                          <img
                            className="loader-image"
                            src={loader}
                            alt="Loading..."
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="profile_div">
                        <Container className="pb-0" fluid>
                          <Row>
                            <Col className="order-xl-1" xl="12">
                              <Card className="bg-secondary profile_card">
                                <Card.Header className="bg-white crd_hd_pad">
                                  <Row className="align-items-center">
                                    <Col xs="12">
                                      <div className="d-flex justify-content-between">
                                        <h5 className="mb-0 text-center alert-link">
                                          Professional Details
                                        </h5>
                                        <div>
                                          <a>
                                            {!hideDiv ? (
                                              <FaCirclePlus
                                                className="fz_18 c_pointer"
                                                onClick={() => setHideDiv(true)}
                                              />
                                            ) : (
                                              <IoCloseCircleSharp
                                                className="fz-22 c_pointer"
                                                onClick={() =>
                                                  setHideDiv(false)
                                                }
                                              />
                                            )}
                                          </a>
                                        </div>
                                      </div>
                                    </Col>
                                    <Col className="text-right" xs="4"></Col>
                                  </Row>
                                </Card.Header>
                                <Card.Body
                                  className={`${professionInfo && professionInfo.length > 0
                                    ? ""
                                    : hideDiv
                                      ? ""
                                      : "p-0"
                                    }`}
                                >
                                  <Row className="justify-content-center">
                                    {hideDiv && (
                                      <Col lg="12" md="12" sm="12">
                                        <Form>
                                          <div className="mb-3">
                                            <Row>
                                              <Col
                                                lg="2"
                                                className="mx_wd_18 pr-0"
                                              >
                                                <Form.Group className="form-group">
                                                  <Form.Label className="mb-1 profile_label">
                                                    Profession Type
                                                  </Form.Label>
                                                  <div className="d-flex">
                                                    <select
                                                      disabled={!hideProEdit}
                                                      className={
                                                        validation.professionType
                                                          ? "form-control input_ctrl error"
                                                          : "form-control input_ctrl"
                                                      }
                                                      value={professionType}
                                                      onChange={(e) =>
                                                        onchangeprofessionType(
                                                          e.target.value
                                                        )
                                                      }
                                                    >
                                                      <option id="" value="">
                                                        Select
                                                      </option>
                                                      {profTypeList &&
                                                        profTypeList.map(
                                                          (profType) => (
                                                            <option
                                                              value={
                                                                profType.name
                                                              }
                                                            >
                                                              {profType.name}
                                                            </option>
                                                          )
                                                        )}
                                                    </select>
                                                    &nbsp;
                                                    {hideProEdit ? <FaGear
                                                      className="mr-2 mt-2 c_pointer theme_clr"
                                                      title="Add"
                                                      onClick={() =>
                                                        setEditProfessionType(
                                                          true
                                                        )
                                                      }
                                                    /> : <FaGear className="mr-2 mt-2 clr_gray" />}
                                                  </div>
                                                  {validation.professionType && (
                                                    <p className="fz-13">
                                                      {
                                                        validation.professionType
                                                      }
                                                    </p>
                                                  )}
                                                </Form.Group>
                                              </Col>
                                              <Col lg="2">
                                                <PhoneControl
                                                  phoneNo={officePhone}
                                                  unmounted={true}
                                                  mandatory={true}
                                                  onPhoneChange={
                                                    officephoneChange
                                                  }
                                                  phoneValidation={
                                                    officephoneValidation
                                                  }
                                                  ref={childCompRef}
                                                />
                                              </Col>
                                              <Col lg="2">
                                                <Form.Group className="form-group">
                                                  <Form.Label className="mb-1 profile_label">
                                                    Mail id
                                                  </Form.Label>
                                                  <Form.Control
                                                    type="text"
                                                    className={
                                                      profEmailErr &&
                                                        !officeEmail.trim()
                                                        ? "form-control input_ctrl error"
                                                        : "form-control input_ctrl"
                                                    }
                                                    onChange={(e) =>
                                                      handleProfEmailChange(
                                                        e.target.value
                                                      )
                                                    }
                                                    value={officeEmail}
                                                  />
                                                  {profEmailErr && (
                                                    <p className="fz-13">
                                                      {profEmailErr}
                                                    </p>
                                                  )}
                                                </Form.Group>
                                              </Col>
                                              <Col lg="2">
                                                <Form.Group className="form-group">
                                                  <Form.Label className="mb-1 profile_label">
                                                    Office Address
                                                  </Form.Label>
                                                  <textarea
                                                    type="text"
                                                    className="form-control text-area input_ctrl"
                                                    onChange={(e) =>
                                                      setofficeAddress(
                                                        e.target.value
                                                      )
                                                    }
                                                    value={officeAddress}
                                                  />
                                                </Form.Group>
                                              </Col>
                                              <Col lg="2">
                                                <Form.Group className="form-group">
                                                  <Form.Label className="mb-1 profile_label">
                                                    City
                                                  </Form.Label>
                                                  <Form.Control
                                                    type="text"
                                                    className="form-control input_ctrl"
                                                    onChange={(e) =>
                                                      setProfCity(
                                                        e.target.value
                                                      )
                                                    }
                                                    value={profCity}
                                                  ></Form.Control>
                                                </Form.Group>
                                              </Col>
                                              <Col lg="2">
                                                <Form.Group className="form-group">
                                                  <Form.Label className="mb-1 profile_label">
                                                    State
                                                  </Form.Label>
                                                  <Form.Control
                                                    type="text"
                                                    className="form-control input_ctrl"
                                                    onChange={(e) =>
                                                      setProfState(
                                                        e.target.value
                                                      )
                                                    }
                                                    value={profState}
                                                  ></Form.Control>
                                                </Form.Group>
                                              </Col>
                                            </Row>
                                            <Row>
                                              <Col lg="4">
                                                <Form.Group className="form-group">
                                                  <Form.Label className="mb-1 profile_label">
                                                    Bio
                                                  </Form.Label>
                                                  <textarea
                                                    type="text"
                                                    className="form-control text-area input_ctrl"
                                                    maxLength={72}
                                                    onChange={(e) =>
                                                      setprofBio(e.target.value)
                                                    }
                                                    value={profBio}
                                                  />
                                                  <p className="f13_clr">
                                                    72 characters only *
                                                  </p>
                                                </Form.Group>
                                              </Col>
                                              <Col lg="2">
                                                <Form.Group className="form-group">
                                                  <Form.Label className="mb-1 profile_label">
                                                    Image
                                                  </Form.Label>
                                                  <Form.Control
                                                    className="form-control input_ctrl rounded-0"
                                                    type="file"
                                                    onChange={handleProfImage}
                                                    accept="image/*"
                                                  />
                                                </Form.Group>
                                                {imagePreview && (
                                                  <Card className="file_pad mt-1">
                                                    <div className="row m-1">
                                                      {imagePreview &&
                                                        (imagePreview instanceof
                                                          File ||
                                                          imagePreview.constructor ===
                                                          File) ? (
                                                        <div className="col-10 preview_ttle">
                                                          {imagePreview.name && (
                                                            <a
                                                              href={
                                                                imagePreview.url
                                                              }
                                                              target="_blank"
                                                              title={
                                                                imagePreview.name
                                                              }
                                                              className="fz-10"
                                                            >
                                                              {
                                                                imagePreview.name
                                                              }
                                                            </a>
                                                          )}
                                                        </div>
                                                      ) : (
                                                        <div className="col-10 preview_ttle">
                                                          <a
                                                            href={imagePreview}
                                                            target="_blank"
                                                            title={getImageName(
                                                              imagePreview
                                                            )}
                                                            className="fz-10"
                                                          >
                                                            {getImageName(
                                                              imagePreview
                                                            )}
                                                          </a>
                                                        </div>
                                                      )}
                                                      {!hideProEdit && (
                                                        <div className="col-2 p-1">
                                                          <span className="pl-1 c_pointer">
                                                            <AiOutlineClose
                                                              className="drop_header"
                                                              onClick={() =>
                                                                rmvProfFile(
                                                                  imagePreview
                                                                )
                                                              }
                                                            />
                                                          </span>
                                                        </div>
                                                      )}
                                                    </div>
                                                  </Card>
                                                )}
                                              </Col>
                                            </Row>
                                            <Row className="justify-content-center">
                                              {hideProEdit ? (
                                                <div className="d-flex justify-content-center">
                                                  <Button
                                                    className="btn-default"
                                                    onClick={
                                                      addUserProfessionInfo
                                                    }
                                                  >
                                                    Add
                                                  </Button>
                                                </div>
                                              ) : (
                                                <div className="d-flex justify-content-center mt-4">
                                                  <Button
                                                    className="btn-default mr-2"
                                                    onClick={
                                                      updateProfessionInfo
                                                    }
                                                  >
                                                    Update
                                                  </Button>
                                                  <Button
                                                    className="btn-default"
                                                    onClick={editProCancel}
                                                  >
                                                    Cancel
                                                  </Button>
                                                </div>
                                              )}
                                            </Row>
                                          </div>
                                        </Form>
                                      </Col>
                                    )}
                                    <Col lg="12" md="12" sm="12">
                                      {professionInfo.length > 0 && (
                                        <Table
                                          className="align-items-center table-flush brd_solid"
                                          responsive
                                        >
                                          <thead className="thead-clr">
                                            <tr>
                                              <th scope="col">Sl.No</th>
                                              {/* <th scope="col">Image</th> */}
                                              <th scope="col">
                                                Profession Type
                                              </th>
                                              <th scope="col">Phone No</th>
                                              <th scope="col">Address</th>
                                              <th scope="col">City</th>
                                              <th scope="col">State</th>
                                              <th scope="col" colSpan="2">
                                                Action
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {professionInfo.length > 0 &&
                                              professionInfo.map((item, i) => (
                                                <tr>
                                                  <th scope="col">{i + 1}</th>
                                                  <th scope="col">
                                                    {item.professionType}
                                                  </th>
                                                  <th scope="col">
                                                    {item.officePhone}
                                                  </th>
                                                  <th scope="col">
                                                    {item.officeAddress}
                                                  </th>
                                                  <th scope="col">
                                                    {item.officeCity}
                                                  </th>
                                                  <th scope="col">
                                                    {item.officeState}
                                                  </th>
                                                  <th scope="col">
                                                    {" "}
                                                    <a
                                                      href="#"
                                                      onClick={(e) =>
                                                        editProfession(item, i)
                                                      }
                                                    >
                                                      <FiEdit
                                                        title="Edit"
                                                        style={{
                                                          color: "#144689",
                                                          fontSize: "17px",
                                                          marginRight: "20px",
                                                        }}
                                                      />
                                                    </a>
                                                    {item.id &&
                                                      props.profileId ==
                                                      item.id ? (
                                                      <RiDeleteBin6Line
                                                        title="Delete"
                                                        style={{
                                                          color: "gray",
                                                          fontSize: "18px",
                                                        }}
                                                      />
                                                    ) : (
                                                      <a
                                                        href="#"
                                                        onClick={(e) => {
                                                          setProfDeleteShow(
                                                            true
                                                          );
                                                          setDeleteProfessionId(
                                                            item.id
                                                          );
                                                        }}
                                                      >
                                                        <RiDeleteBin6Line
                                                          title="Delete"
                                                          style={{
                                                            color: "#144689",
                                                            fontSize: "18px",
                                                          }}
                                                        />
                                                      </a>
                                                    )}
                                                  </th>
                                                </tr>
                                              ))}
                                          </tbody>
                                        </Table>
                                      )}
                                      {/* {professionInfo.length > 0 && (
                                        <Row>
                                          <Col
                                            lg="12"
                                            md="12"
                                            sm="12"
                                            className="d-flex justify-content-center pt-4"
                                          >
                                            <Button
                                              className="btn btn-default update_btn btn-primary"
                                              onClick={() =>
                                                onSubmit("professional")
                                              }
                                            >
                                              Save
                                            </Button>
                                          </Col>
                                        </Row>
                                      )} */}
                                    </Col>
                                  </Row>
                                </Card.Body>
                              </Card>
                            </Col>
                          </Row>
                        </Container>
                      </div>
                    </div>
                  </div>
                </Tab>}

                {isFeatureEnabled(allFeatures["Business-Profile%"]?.key, allFeatures["Business-Profile%"]?.isDeleted) && <Tab
                  eventKey="Business"
                  title={
                    <span className="pro_tab_span">
                      <MdBusinessCenter className="dashboardTabIcon" />
                      Business
                    </span>
                  }
                >
                  <div className="busn_tab_div">
                    <Tabs
                      defaultActiveKey="Business_details"
                      activeKey={subActiveKey}
                      onSelect={(key) => setSubActiveKey(key)}
                      id="business_tab"
                      className="mb-2"
                    >
                      <Tab
                        eventKey="Business_details"
                        title={
                          <h6 className="pro_tab_span">Add Business Details</h6>
                        }
                      >
                        <div className="pro_container">
                          <div className="profile_ml">
                            {loading ? (
                              <div className="pro-loader-center">
                                <img
                                  className="loader-image"
                                  src={loader}
                                  alt="Loading..."
                                />
                              </div>
                            ) : (
                              ""
                            )}
                            <div className="profile_div rounded-0">
                              <Container className="pb-0" fluid>
                                <Row>
                                  <Col className="order-xl-1" xl="12">
                                    <Card className="bg-secondary profile_card">
                                      <Card.Header className="bg-white crd_hd_pad">
                                        <div className="d-flex justify-content-between">
                                          <h6 className="mb-0">
                                            Business Information
                                          </h6>
                                          <a>
                                            {!isToggled ? (
                                              <FaCirclePlus
                                                className="fz_18 c_pointer"
                                                onClick={() => {
                                                  setIsToggled(true);
                                                  setKybverification(false);
                                                }}
                                              />
                                            ) : (
                                              <IoCloseCircleSharp
                                                className="fz-22 c_pointer"
                                                onClick={() => {
                                                  setIsToggled(false);
                                                  editCancel();
                                                }}
                                              />
                                            )}
                                          </a>
                                        </div>
                                      </Card.Header>
                                      <Card.Body
                                        className={`${businessUserInfo &&
                                          businessUserInfo.length > 0
                                          ? ""
                                          : isToggled
                                            ? ""
                                            : "p-0"
                                          }`}
                                      >
                                        {isToggled && (
                                          <Row>
                                            <Col lg="12" md="12" sm="12">
                                              <Form>
                                                <div className="">
                                                  <Row>
                                                    <Col lg="2">
                                                      <Form.Group className="form-group">
                                                        <Form.Label className="mb-1 profile_label">
                                                          Company Name
                                                        </Form.Label>
                                                        <Form.Control
                                                          disabled={
                                                            !hideEdit &&
                                                            editedValue.isVerified ==
                                                            1
                                                          }
                                                          type="text"
                                                          className={
                                                            validation.cmpyName
                                                              ? "form-control input_ctrl error"
                                                              : "form-control input_ctrl"
                                                          }
                                                          onChange={(e) =>
                                                            setcmpyName(
                                                              e.target.value
                                                            )
                                                          }
                                                          value={cmpyName}
                                                        />
                                                        {validation.cmpyName && (
                                                          <p className="fz-13">
                                                            Company Name is
                                                            required
                                                          </p>
                                                        )}
                                                      </Form.Group>
                                                    </Col>
                                                    <Col lg="2">
                                                      <Form.Group
                                                        className="form-group"
                                                        onKeyPress={(e) =>
                                                          !/[0-9]/.test(
                                                            e.key
                                                          ) &&
                                                          e.preventDefault()
                                                        }
                                                      >
                                                        <Form.Label className="mb-1 profile_label">
                                                          AIN / TIN Number
                                                        </Form.Label>
                                                        <Form.Control
                                                          disabled={
                                                            !hideEdit &&
                                                            editedValue.isVerified ==
                                                            1
                                                          }
                                                          type="text"
                                                          maxLength={9}
                                                          className={
                                                            itiNoErr ||
                                                              itiNoInvalid
                                                              ? "form-control input_ctrl error"
                                                              : "form-control input_ctrl"
                                                          }
                                                          onChange={(e) =>
                                                            setItiNo(
                                                              e.target.value
                                                            )
                                                          }
                                                          value={itiNo}
                                                        />
                                                        {itiNoInvalid && (
                                                          <p className="fz-13">
                                                            Minimum 9 characters
                                                            required
                                                          </p>
                                                        )}
                                                        {itiNoErr && (
                                                          <p className="fz-13">
                                                            AIN / TIN No is
                                                            required
                                                          </p>
                                                        )}
                                                      </Form.Group>
                                                    </Col>
                                                    <Col lg="2">
                                                      <PhoneControl
                                                        phoneNo={cmpyPhone}
                                                        isProfile={false}
                                                        unmounted={true}
                                                        mandatory={true}
                                                        onPhoneChange={
                                                          businessPhoneChange
                                                        }
                                                        phoneValidation={
                                                          businessPhoneValidation
                                                        }
                                                        ref={childCmpyPhRef}
                                                      />
                                                    </Col>
                                                    <Col lg="2">
                                                      <Form.Group class="form-group required-field-block">
                                                        <Form.Label className="mb-1 profile_label">
                                                          Email Address
                                                        </Form.Label>
                                                        <Form.Control
                                                          type="text"
                                                          className={
                                                            cmpyEmailErr
                                                              ? "form-control input_ctrl error"
                                                              : "form-control input_ctrl"
                                                          }
                                                          onChange={(e) =>
                                                            handleEmailChange(
                                                              e.target.value
                                                            )
                                                          }
                                                          value={cmpyEmail}
                                                        />
                                                        {cmpyEmailErr && (
                                                          <p className="fz-13">
                                                            {cmpyEmailErr}
                                                          </p>
                                                        )}
                                                      </Form.Group>
                                                    </Col>
                                                    <Col lg="2">
                                                      <Form.Group className="form-group">
                                                        <Form.Label className="mb-1 profile_label">
                                                          City
                                                        </Form.Label>
                                                        <Form.Control
                                                          type="text"
                                                          className="form-control input_ctrl"
                                                          onChange={(e) => {
                                                            const value = e.target.value;
                                                            if (/^[a-zA-Z\s]*$/.test(value)) {
                                                              setcmpyCity(value);
                                                            }
                                                          }}
                                                          value={cmpyCity}
                                                        />
                                                      </Form.Group>
                                                    </Col>

                                                    <Col lg="2">
                                                      <Form.Group className="form-group">
                                                        <Form.Label className="mb-1 profile_label">
                                                          State
                                                        </Form.Label>
                                                        <Form.Control
                                                          type="text"
                                                          className="form-control input_ctrl"
                                                          onChange={(e) => {
                                                            const value = e.target.value;
                                                            if (/^[a-zA-Z\s]*$/.test(value)) {
                                                              setcmpyState(value);
                                                            }
                                                          }}
                                                          value={cmpyState}
                                                        />
                                                      </Form.Group>
                                                    </Col>
                                                  </Row>
                                                  <Row>
                                                    <Col lg="2">
                                                      <Form.Group className="form-group">
                                                        <Form.Label className="mb-1 profile_label">
                                                          Country
                                                        </Form.Label>
                                                        <Form.Control
                                                          type="text"
                                                          className="form-control input_ctrl"
                                                          onChange={(e) => {
                                                            const value = e.target.value;
                                                            if (/^[a-zA-Z\s]*$/.test(value)) {
                                                              setcmpyCountry(value);
                                                            }
                                                          }}
                                                          value={cmpyCountry}
                                                        />
                                                      </Form.Group>
                                                    </Col>

                                                    <Col lg="2">
                                                      <Form.Group
                                                        className="form-group"
                                                        onKeyPress={(e) =>
                                                          !/[0-9]/.test(
                                                            e.key
                                                          ) &&
                                                          e.preventDefault()
                                                        }
                                                      >
                                                        <Form.Label className="mb-1 profile_label">
                                                          Zip Code
                                                        </Form.Label>
                                                        <Form.Control
                                                          type="text"
                                                          className="form-control text-area input_ctrl"
                                                          onChange={(e) =>
                                                            setcmpyZipCode(
                                                              e.target.value
                                                            )
                                                          }
                                                          value={cmpyZipCode}
                                                          maxLength={6}
                                                        />
                                                      </Form.Group>
                                                    </Col>
                                                    <Col lg="2">
                                                      <Form.Group className="form-group">
                                                        <Form.Label className="mb-1 profile_label">
                                                          Description
                                                        </Form.Label>
                                                        <textarea
                                                          type="text"
                                                          className="form-control text-area input_ctrl"
                                                          onChange={(e) =>
                                                            setcmpyDescription(
                                                              e.target.value
                                                            )
                                                          }
                                                          value={
                                                            cmpyDescription
                                                          }
                                                        />
                                                      </Form.Group>
                                                    </Col>
                                                    <Col lg="2">
                                                      <Form.Group className="form-group">
                                                        <Form.Label className="mb-1 profile_label">
                                                          Website
                                                        </Form.Label>
                                                        <Form.Control
                                                          type="text"
                                                          className="form-control text-area input_ctrl"
                                                          onChange={(e) =>
                                                            checkWebUrlValid(
                                                              e.target.value
                                                            )
                                                          }
                                                          value={cmpyWebsite}
                                                        />
                                                        {busnUrlValid.website !==
                                                          "" && (
                                                            <p className="fz-13">
                                                              {
                                                                busnUrlValid.website
                                                              }
                                                            </p>
                                                          )}
                                                      </Form.Group>
                                                    </Col>
                                                    <Col lg="2">
                                                      <Form.Group className="form-group">
                                                        <Form.Label className="mb-1 profile_label">
                                                          Facebook Url
                                                        </Form.Label>
                                                        <Form.Control
                                                          type="text"
                                                          className="form-control text-area input_ctrl"
                                                          onChange={(e) =>
                                                            checkfbUrlValidity(
                                                              e.target.value,
                                                              "Business"
                                                            )
                                                          }
                                                          value={cmpyFbUrl}
                                                        />
                                                        {busnUrlValid.facebookUrl !==
                                                          "" && (
                                                            <p className="fz-13">
                                                              {
                                                                busnUrlValid.facebookUrl
                                                              }
                                                            </p>
                                                          )}
                                                      </Form.Group>
                                                    </Col>
                                                    <Col lg="2">
                                                      <Form.Group className="form-group">
                                                        <Form.Label className="mb-1 profile_label">
                                                          X Url
                                                        </Form.Label>
                                                        <Form.Control
                                                          type="text"
                                                          className="form-control text-area input_ctrl"
                                                          onChange={(e) =>
                                                            checktwUrlValidity(
                                                              e.target.value,
                                                              "Business"
                                                            )
                                                          }
                                                          value={cmpyTwUrl}
                                                        />
                                                        {busnUrlValid.twitterUrl !==
                                                          "" && (
                                                            <p className="fz-13">
                                                              {
                                                                busnUrlValid.twitterUrl
                                                              }
                                                            </p>
                                                          )}
                                                      </Form.Group>
                                                    </Col>
                                                  </Row>
                                                  <Row>
                                                    <Col lg="2">
                                                      <Form.Group className="form-group">
                                                        <Form.Label className="mb-1 profile_label">
                                                          LinkedIn Url
                                                        </Form.Label>
                                                        <Form.Control
                                                          type="text"
                                                          className="form-control text-area input_ctrl"
                                                          onChange={(e) =>
                                                            checklInUrlValidity(
                                                              e.target.value,
                                                              "Business"
                                                            )
                                                          }
                                                          value={cmpyLnUrl}
                                                        />
                                                        {busnUrlValid.linkedInUrl !==
                                                          "" && (
                                                            <p className="fz-13">
                                                              {
                                                                busnUrlValid.linkedInUrl
                                                              }
                                                            </p>
                                                          )}
                                                      </Form.Group>
                                                    </Col>
                                                    <Col lg="2">
                                                      <Form.Group className="form-group">
                                                        <Form.Label className="mb-1 profile_label">
                                                          Instagram Url
                                                        </Form.Label>
                                                        <Form.Control
                                                          type="text"
                                                          className="form-control text-area input_ctrl"
                                                          onChange={(e) =>
                                                            checkIgUrlValidity(
                                                              e.target.value,
                                                              "Business"
                                                            )
                                                          }
                                                          value={cmpyIgUrl}
                                                        />
                                                        {busnUrlValid.instagramUrl !==
                                                          "" && (
                                                            <p className="fz-13">
                                                              {
                                                                busnUrlValid.instagramUrl
                                                              }
                                                            </p>
                                                          )}
                                                      </Form.Group>
                                                    </Col>
                                                    <Col lg="2">
                                                      <Form.Group
                                                        className="form-group"
                                                        onKeyPress={(e) =>
                                                          !/[0-9]/.test(
                                                            e.key
                                                          ) &&
                                                          e.preventDefault()
                                                        }
                                                      >
                                                        <Form.Label className="mb-1 profile_label">
                                                          Since
                                                        </Form.Label>
                                                        <Form.Control
                                                          type="text"
                                                          className="form-control text-area input_ctrl"
                                                          onChange={(e) =>
                                                            setcmpySince(
                                                              e.target.value
                                                            )
                                                          }
                                                          value={cmpySince}
                                                          maxLength={4}
                                                        />
                                                      </Form.Group>
                                                    </Col>
                                                    <Col lg="2">
                                                      <Form.Group className="form-group">
                                                        <Form.Label className="mb-1 profile_label">
                                                          Short Name
                                                        </Form.Label>
                                                        <Form.Control
                                                          type="text"
                                                          className={
                                                            shrtNameErr
                                                              ? "form-control input_ctrl error"
                                                              : "form-control input_ctrl"
                                                          }
                                                          onChange={(e) =>
                                                            setcmpyShortName(
                                                              e.target.value
                                                            )
                                                          }
                                                          value={cmpyShortName}
                                                        />
                                                        {shrtNameErr && (
                                                          <p className="fz-13">
                                                            {shrtNameErr}
                                                          </p>
                                                        )}
                                                      </Form.Group>
                                                    </Col>
                                                    <Col lg="4">
                                                      <Form.Group className="form-group">
                                                        <Form.Label className="mb-1 profile_label">
                                                          Bio
                                                        </Form.Label>
                                                        <textarea
                                                          type="text"
                                                          className="form-control text-area input_ctrl"
                                                          maxLength={72}
                                                          onChange={(e) =>
                                                            setbusnBio(
                                                              e.target.value
                                                            )
                                                          }
                                                          value={busnBio}
                                                        />
                                                        <p className="f13_clr mb-0">
                                                          72 characters only *
                                                        </p>
                                                      </Form.Group>
                                                    </Col>
                                                  </Row>
                                                  <Row>
                                                    <Col lg="3">
                                                      <Form.Group className="form-group">
                                                        <Form.Label className="mb-1 profile_label">
                                                          Logo
                                                        </Form.Label>
                                                        <Form.Control
                                                          className={`form-control input_ctrl ${logoErr
                                                            ? "error"
                                                            : ""
                                                            }`}
                                                          type="file"
                                                          onChange={
                                                            handleLogoChange
                                                          }
                                                          accept=".jpg,.png"
                                                        />
                                                        {logoErr && (
                                                          <p className="fz-13 mb-0">
                                                            Document is required
                                                          </p>
                                                        )}
                                                      </Form.Group>
                                                      {logoPreview && (
                                                        <Card className="file_pad mt-1">
                                                          <div className="row m-1">
                                                            {logoPreview &&
                                                              (logoPreview instanceof
                                                                File ||
                                                                logoPreview.constructor ===
                                                                File) ? (
                                                              <div className="col-10 preview_ttle">
                                                                {logoPreview.name && (
                                                                  <a
                                                                    href={
                                                                      logoPreview.url
                                                                    }
                                                                    target="_blank"
                                                                    title={
                                                                      logoPreview.name
                                                                    }
                                                                    className="fz-10"
                                                                  >
                                                                    {
                                                                      logoPreview.name
                                                                    }
                                                                  </a>
                                                                )}
                                                              </div>
                                                            ) : (
                                                              <div className="col-10 preview_ttle">
                                                                <a
                                                                  href={
                                                                    logoPreview
                                                                  }
                                                                  target="_blank"
                                                                  title={getImageName(
                                                                    logoPreview
                                                                  )}
                                                                  className="fz-10"
                                                                >
                                                                  {getImageName(
                                                                    logoPreview
                                                                  )}
                                                                </a>
                                                              </div>
                                                            )}
                                                            {!hideEdit && (
                                                              <div className="col-2 p-1">
                                                                <span className="pl-1 c_pointer">
                                                                  <AiOutlineClose
                                                                    className="drop_header"
                                                                    onClick={() =>
                                                                      rmvLogo()
                                                                    }
                                                                  />
                                                                </span>
                                                              </div>
                                                            )}
                                                          </div>
                                                        </Card>
                                                      )}
                                                    </Col>
                                                    <Col lg="3">
                                                      <Form.Group className="form-group">
                                                        <Form.Label className="mb-1 profile_label">
                                                          Browse Documents
                                                        </Form.Label>
                                                        <Form.Control
                                                          disabled={
                                                            !hideEdit &&
                                                            editedValue.isVerified ==
                                                            1
                                                          }
                                                          className={`form-control input_ctrl ${documentErr
                                                            ? "error"
                                                            : ""
                                                            }`}
                                                          type="file"
                                                          multiple
                                                          onChange={
                                                            handleFileChange
                                                          }
                                                          accept=".pdf,.jpg,.png,.doc,.docx,.txt"
                                                        />
                                                        {documentErr && (
                                                          <p className="fz-13 mb-0">
                                                            Document is required
                                                          </p>
                                                        )}
                                                      </Form.Group>
                                                      {selectedFiles.length >
                                                        0 ? (
                                                        <Card className="file_pad mt-1">
                                                          {selectedFiles.map(
                                                            (file, i) => {
                                                              return (
                                                                <div className="row m-1">
                                                                  <div className="col-10 preview_ttle">
                                                                    {file.name && (
                                                                      <a
                                                                        href={
                                                                          file.url
                                                                        }
                                                                        target="_blank"
                                                                        title={
                                                                          file.name
                                                                        }
                                                                        className="fz-10"
                                                                      >
                                                                        {
                                                                          file.name
                                                                        }
                                                                      </a>
                                                                    )}
                                                                  </div>
                                                                  {!hideEdit &&
                                                                    editedValue.isVerified ==
                                                                    0 && (
                                                                      <div className="col-2 p-1">
                                                                        <span className="pl-1 c_pointer">
                                                                          <AiOutlineClose
                                                                            className="drop_header"
                                                                            onClick={() => {
                                                                              rmvFile(
                                                                                i,
                                                                                file
                                                                              );
                                                                            }}
                                                                          />
                                                                        </span>
                                                                      </div>
                                                                    )}
                                                                </div>
                                                              );
                                                            }
                                                          )}
                                                        </Card>
                                                      ) : (
                                                        ""
                                                      )}
                                                    </Col>
                                                    {/* 
                                                    <Col lg="3">
                                                      {kybVerification ? (
                                                        <Row>
                                                          <Col lg="10" >
                                                            <Form.Group className="form-group mt-3" >
                                                              {kybSuccess ? (
                                                                <Form.Label className="mb-1 profile_label font-weight-bold text-success " >
                                                                  KYB Verified <HiBadgeCheck
                                                                    style={{
                                                                      fontSize: "22px",
                                                                      color: "green",
                                                                      cursor: "pointer",
                                                                    }}
                                                                    title="KYB Verified"
                                                                    data-toggle="tooltip"
                                                                    data-placement="top"
                                                                  />
                                                                </Form.Label>
                                                              ) : (
                                                                <Form.Label className="mb-1 profile_label font-weight-bold text-danger" >
                                                                  KYB Verification <BsExclamationCircle
                                                                    className={"text-danger"}
                                                                    style={{
                                                                      fontSize: "20px",
                                                                      cursor: "pointer",
                                                                    }}
                                                                    onClick={() => setKybPopup(true) || setKybForm(true)}
                                                                  />
                                                                </Form.Label>
                                                              )}

                                                            </Form.Group>
                                                          </Col>
                                                        </Row>
                                                      ) : (
                                                        <></>
                                                      )}

                                                    </Col> */}


                                                    {hideEdit ? (
                                                      <div className="d-flex justify-content-center mt-2 mb-3">
                                                        <Button
                                                          className="btn-default"
                                                          onClick={
                                                            addUserBusinessInfo
                                                          }
                                                        >
                                                          Add Business
                                                        </Button>
                                                      </div>
                                                    ) : (
                                                      <div className="d-flex justify-content-center mt-2 mb-3">
                                                        <Button
                                                          className="btn-default"
                                                          onClick={
                                                            updateBusinessInfo
                                                          }
                                                        >
                                                          Update
                                                        </Button>
                                                        <Button
                                                          className="btn-default"
                                                          onClick={editCancel}
                                                        >
                                                          Cancel
                                                        </Button>
                                                      </div>
                                                    )}
                                                  </Row>
                                                </div>
                                              </Form>
                                            </Col>
                                          </Row>
                                        )}
                                        {businessUserInfo &&
                                          businessUserInfo.length > 0 && (
                                            <Table
                                              className="align-items-center table-flush brd_solid"
                                              responsive
                                            >
                                              <thead className="thead-clr" >
                                                <tr >
                                                  <th className="pl-0 pr-0"></th>
                                                  <th className="ml-3 pl-0 pr-0" style={{ textTransform: 'none' }}>
                                                    Company Name
                                                  </th>
                                                  <th className="pl-0 pr-0" style={{ textTransform: 'none' }}>
                                                    Role
                                                  </th>
                                                  <th className="pl-0 pr-0" style={{ textTransform: 'none' }}>
                                                    Email Id
                                                  </th>
                                                  <th className="pl-0 pr-0" style={{ textTransform: 'none' }}>
                                                    Phone No
                                                  </th>
                                                  <th className="pl-0 pr-0" style={{ textTransform: 'none' }}>
                                                    City
                                                  </th>
                                                  <th className="pl-0 pr-0" style={{ textTransform: 'none' }}>
                                                    State
                                                  </th>
                                                  {/* <th className="pl-0 pr-0" style={{textTransform:'none'}}>
                                                    Description
                                                  </th> */}
                                                  <th className="pl-0 pr-0" style={{ textTransform: 'none' }}>
                                                    Status
                                                  </th>
                                                  <th className="pl-0 pr-0" style={{ textTransform: 'none' }}>
                                                    Action
                                                  </th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {businessUserInfo &&
                                                  businessUserInfo.length > 0 &&
                                                  businessUserInfo.map(
                                                    (item, i) => (
                                                      <React.Fragment key={i}>
                                                        <tr>
                                                          <th className="p-0">
                                                            {item.roleId == 1 &&
                                                              (expandedRowIndex ==
                                                                i ? (
                                                                <PiCaretCircleUpBold
                                                                  className="fz_18 mr-3 c_pointer"
                                                                  onClick={() =>
                                                                    handleRowClick(
                                                                      item.id,
                                                                      i
                                                                    )
                                                                  }
                                                                />
                                                              ) : (
                                                                <PiCaretCircleDownBold
                                                                  className="fz_18 mr-3 c_pointer"
                                                                  onClick={() =>
                                                                    handleRowClick(
                                                                      item.id,
                                                                      i
                                                                    )
                                                                  }
                                                                />
                                                              ))}
                                                          </th>
                                                          <th className="p-0 ">
                                                            <div className="d-flex" title={item.companyName}>
                                                              {/* {item.companyName} */}
                                                              {item.companyName.length >
                                                                13
                                                                ? item.companyName
                                                                  .substring(
                                                                    0,
                                                                    13
                                                                  )
                                                                  .concat("...")
                                                                : item.companyName}
                                                              {/* <div style={{
                                                                fontSize: "14px",
                                                                cursor: "pointer",
                                                                fontWeight: '600',
                                                                padding: '0 5px 0 10px'
                                                              }}>KYB</div> */}
                                                              {/* {kybStatus || item.isKybVerified ? (
                                                                <HiBadgeCheck
                                                                  style={{
                                                                    fontSize: "15px",
                                                                    color: "green",
                                                                    cursor: "pointer",
                                                                  }}
                                                                  className="mt-1"
                                                                  title="KYB Verified"
                                                                  data-toggle="tooltip"
                                                                  data-placement="top"
                                                                />
                                                              ) : (
                                                                <BsExclamationCircle
                                                                  className="text-danger mt-1"
                                                                  style={{
                                                                    fontSize: "15px",
                                                                    cursor: "pointer",
                                                                  }}
                                                                  title="Verify KYB"
                                                                  data-toggle="tooltip"
                                                                  data-placement="top"
                                                                  onClick={() => {
                                                                    setKybPopup(true);
                                                                    setKybForm(true);
                                                                    setKybBusinessId(item.id)
                                                                  }}
                                                                />
                                                              )} */}
                                                            </div>
                                                          </th>

                                                          <th className="p-0">
                                                            {item.roleName}

                                                          </th>
                                                          <th
                                                            title={item.email}
                                                          >
                                                            {item.email.length >
                                                              13
                                                              ? item.email
                                                                .substring(
                                                                  0,
                                                                  13
                                                                )
                                                                .concat("...")
                                                              : item.email}
                                                          </th>
                                                          <th className="p-0">
                                                            {item.phone}
                                                          </th>
                                                          <th className="p-0">
                                                            {item.city}
                                                          </th>
                                                          <th className="p-0">
                                                            {item.state}
                                                          </th>
                                                          {/* <th className="p-0">
                                                            {item.description}
                                                          </th> */}
                                                          <th className="p-0">
                                                            {item.isVerified ==
                                                              1 ? (
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "#08b108",
                                                                }}
                                                              >
                                                                Verified
                                                              </span>
                                                            ) : (
                                                              "Pending"
                                                            )}
                                                          </th>
                                                          <th className="p-0">
                                                            {" "}
                                                            {renderAction(item, i)}
                                                          </th>
                                                        </tr>
                                                        {expandedRowIndex ===
                                                          i && (
                                                            <tr
                                                              colSpan="12"
                                                              key={`${i}-expanded`}
                                                            >
                                                              <td
                                                                colSpan="12"
                                                                className="p-2"
                                                              >
                                                                {businessNotes &&
                                                                  businessNotes.length >
                                                                  0 ? (
                                                                  <Table>
                                                                    <thead className="thead-clr">
                                                                      <tr>
                                                                        <th>
                                                                          Note
                                                                        </th>
                                                                        <th>
                                                                          Added On
                                                                        </th>
                                                                        <th>
                                                                          Added By
                                                                        </th>
                                                                      </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                      {businessNotes.map(
                                                                        (
                                                                          data,
                                                                          index
                                                                        ) => (
                                                                          <tr
                                                                            key={
                                                                              index
                                                                            }
                                                                          >
                                                                            <th>
                                                                              {
                                                                                data.note
                                                                              }
                                                                            </th>
                                                                            <th>
                                                                              <p className="mb-0 font-weight-400 fz_13">
                                                                                {moment(
                                                                                  data.createdAt
                                                                                ).format(
                                                                                  "D MMM YYYY"
                                                                                )}
                                                                              </p>
                                                                            </th>
                                                                            <th>
                                                                              <div className="d-flex align-items-center">
                                                                                {data.userId ==
                                                                                  props.userId ? (
                                                                                  "You"
                                                                                ) : (
                                                                                  <>
                                                                                    <img
                                                                                      className="brd_rad"
                                                                                      width="20"
                                                                                      height="20"
                                                                                      src={
                                                                                        data.imagePath
                                                                                          ? data.imagePath
                                                                                          : profile
                                                                                      }
                                                                                    />
                                                                                    &nbsp;
                                                                                    <p className="mb-0 font-weight-400 fz_14">
                                                                                      {
                                                                                        data.name
                                                                                      }
                                                                                    </p>
                                                                                  </>
                                                                                )}
                                                                              </div>
                                                                            </th>
                                                                          </tr>
                                                                        )
                                                                      )}
                                                                    </tbody>
                                                                  </Table>
                                                                ) : (
                                                                  <div className="busn_no_data w-100 text-center font-weight-400 fz-12">
                                                                    Sorry, there
                                                                    is no data to
                                                                    be displayed
                                                                  </div>
                                                                )}
                                                              </td>
                                                            </tr>
                                                          )}
                                                      </React.Fragment>
                                                    )
                                                  )}
                                              </tbody>
                                            </Table>
                                          )}
                                      </Card.Body>
                                    </Card>
                                  </Col>
                                </Row>
                              </Container>
                            </div>
                          </div>
                        </div>
                      </Tab>
                      {defaultCmpy && (
                        <Tab
                          eventKey="Business_users"
                          title={
                            <h6 className="pro_tab_span">Business Users</h6>
                          }
                        >
                          <BusinessUser
                            userId={props.userId}
                            defaultCmpy={defaultCmpy}
                            onSelectCompany={inviteUserAdd}
                            activeKey={subActiveKey}
                          />
                        </Tab>
                      )}
                    </Tabs>
                  </div>
                </Tab>}
              </Tabs>
            </div>
            {/* ----------- Mobile verification Popup ---------- */}
            {!isMobileCodeSend || isPhoneChanged ? (
              <Modal
                size="sm"
                show={mobilePopupShow}
                onHide={() => setMobilePopupShow(false)}
              >
                <Modal.Header>
                  <Modal.Title
                    id="example-custom-modal-styling-title"
                    style={{ fontSize: "15px", fontWeight: 600 }}
                  >
                    Verify Mobile Number
                  </Modal.Title>
                  <span
                    onClick={() => {
                      setMobilePopupShow(false);
                    }}
                    title="Close"
                  >
                    <AiOutlineClose />
                  </span>
                </Modal.Header>
                <Modal.Body>
                  <Button
                    onClick={() => {
                      verifyMobile();
                      setMobBlockbtn(true);
                    }}
                    disabled={mobBlockbtn}
                  >
                    Send Verification Code
                  </Button>
                </Modal.Body>
              </Modal>
            ) : (
              <Modal
                size="sm"
                show={mobilePopupShow}
                onHide={() => setMobilePopupShow(false)}
              >
                <Modal.Header>
                  <Modal.Title
                    id="example-custom-modal-styling-title"
                    style={{ fontSize: "15px", fontWeight: 600 }}
                  >
                    Verify Mobile Number
                  </Modal.Title>
                  <span
                    onClick={() => {
                      setMobilePopupShow(false);
                    }}
                    title="Close"
                  >
                    <AiOutlineClose />
                  </span>
                </Modal.Header>
                <Modal.Body>
                  <div className="fz_11">
                    Enter the OTP send to Mobile No {phone}
                  </div>
                  <div className="input-group code_Input">
                    <input
                      className="form-control py-2 border border-top-0 fz_13"
                      placeholder="Enter OTP"
                      onChange={(e) => setVerifcationCode(e.target.value)}
                      value={verificationCode}
                      maxLength="6"
                      onKeyPress={(e) =>
                        !/[0-9]/.test(e.key) && e.preventDefault()
                      }
                    />
                  </div>
                  {emptyErr ? <span className="fz-13">Enter OTP</span> : ""}
                  <div className="d-flex justify-content-between mb-2">
                    <div>
                      <span className="fz-12">
                        Didn't receive an OTP?&nbsp;
                        <a
                          href="#"
                          classsName="a_clr fz-12"
                          onClick={verifyMobile}
                        >
                          Resend Code
                        </a>
                      </span>
                    </div>
                    {timerMinutes === "00" && timerSeconds === "00" ? (
                      ""
                    ) : (
                      <div className="float-right fz_13">
                        {timerMinutes === "00" ? (
                          <span className="">
                            {timerMinutes}:{timerSeconds}
                          </span>
                        ) : (
                          <span>
                            {timerMinutes}:{timerSeconds}
                          </span>
                        )}
                      </div>
                    )}
                    {timerMinutes === "00" && timerSeconds === "00" ? (
                      <div className="float-right text-warning fz-12">
                        Code Expired
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <Button onClick={verifyMobileCode} className="fz-12">
                    Verify
                  </Button>
                </Modal.Body>
              </Modal>
            )}
            <Modal
              size="sm"
              show={deletePopupShow}
              onHide={() => setDeletePopupShow(false)}
            >
              <Modal.Header>
                <Modal.Title
                  id="example-custom-modal-styling-title"
                  style={{ fontSize: "15px", fontWeight: 600 }}
                >
                  Delete Confirmation
                </Modal.Title>
                <span onClick={() => setDeletePopupShow(false)} title="Close">
                  <AiOutlineClose />
                </span>
              </Modal.Header>
              <Modal.Body className="bordr">
                <span>Are you sure to delete this profile image?</span>
              </Modal.Body>
              <Modal.Footer className="bordr">
                <Button
                  variant="primary"
                  className="del_bttn"
                  onClick={() => deleteImage()}
                >
                  Yes
                </Button>
                <Button
                  variant="danger"
                  className="del_bttn"
                  onClick={() => setDeletePopupShow(false)}
                >
                  No
                </Button>
              </Modal.Footer>
            </Modal>
            <Modal
              size="sm"
              show={profDeleteShow}
              onHide={() => setProfDeleteShow(false)}
            >
              <Modal.Header>
                <Modal.Title
                  id="example-custom-modal-styling-title"
                  style={{ fontSize: "15px", fontWeight: 600 }}
                >
                  Delete Confirmation
                </Modal.Title>
                <span onClick={() => setProfDeleteShow(false)} title="Close">
                  <AiOutlineClose />
                </span>
              </Modal.Header>
              <Modal.Body className="bordr">
                <span>Are you sure to delete this profession?</span>
              </Modal.Body>
              <Modal.Footer className="bordr">
                <Button
                  variant="primary"
                  className="del_bttn"
                  onClick={deleteProfession}
                >
                  Yes
                </Button>
                <Button
                  variant="danger"
                  className="del_bttn"
                  onClick={() => setProfDeleteShow(false)}
                >
                  No
                </Button>
              </Modal.Footer>
            </Modal>
            {/* -----------Email verification Popup ---------- */}
            {!isEmailCodeSend ? (
              <Modal
                size="sm"
                show={emailPopupShow}
                onHide={() => setEmailPopupShow(false)}
              >
                <Modal.Header>
                  <Modal.Title
                    id="example-custom-modal-styling-title"
                    style={{ fontSize: "15px", fontWeight: 600 }}
                  >
                    Verify Email Id
                  </Modal.Title>
                  <span
                    onClick={() => {
                      setEmailPopupShow(false);
                    }}
                    title="Close"
                  >
                    <AiOutlineClose />
                  </span>
                </Modal.Header>
                <Modal.Body>
                  <Button
                    onClick={() => {
                      verifyEmail();
                      setBlockbtn(true);
                    }}
                    disabled={blockbtn}
                  >
                    Send Verification Code
                  </Button>
                </Modal.Body>
              </Modal>
            ) : (
              <Modal
                size="sm"
                show={emailPopupShow}
                onHide={() => setEmailPopupShow(false)}
              >
                <Modal.Header className="p-3">
                  <Modal.Title
                    id="example-custom-modal-styling-title"
                    className="mb-4"
                    style={{ fontSize: "15px", fontWeight: 600 }}
                  >
                    Verify Email Id
                  </Modal.Title>
                  <span
                    onClick={() => {
                      setEmailPopupShow(false);
                    }}
                    title="Close"
                  >
                    <AiOutlineClose />
                  </span>
                </Modal.Header>
                <Modal.Body className="p-3">
                  <div className="fz_11">
                    Enter the OTP send to Email id {email}
                  </div>
                  <div className="input-group code_Input">
                    <input
                      className="form-control py-2 border border-top-0 fz_13"
                      placeholder="Enter OTP"
                      onChange={(e) => setVerifcationCode(e.target.value)}
                      value={verificationCode}
                      maxLength="6"
                      onKeyPress={(e) =>
                        !/[0-9]/.test(e.key) && e.preventDefault()
                      }
                    />
                  </div>
                  {emptyErr ? <span className="fz-13">Enter OTP</span> : ""}
                  <div className="d-flex justify-content-between mb-2">
                    <div>
                      <span className="fz-12">
                        Didn't receive an OTP?&nbsp;
                        <a
                          href="#"
                          classsName="a_clr fz-12"
                          onClick={verifyEmail}
                        >
                          Resend Code
                        </a>
                      </span>
                    </div>
                    {timerMinutes === "00" && timerSeconds === "00" ? (
                      ""
                    ) : (
                      <div className="float-right fz_13">
                        {timerMinutes === "00" ? (
                          <span className="">
                            {timerMinutes}:{timerSeconds}
                          </span>
                        ) : (
                          <span>
                            {timerMinutes}:{timerSeconds}
                          </span>
                        )}
                      </div>
                    )}
                    {timerMinutes === "00" && timerSeconds === "00" ? (
                      <div className="float-right text-warning fz-12">
                        Code Expired
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <Button onClick={verifyEmailCode} className="fz-12">
                    Verify
                  </Button>
                </Modal.Body>
              </Modal>
            )}
            <Modal
              size="sm"
              show={editProfessionType}
              onHide={() => setEditProfessionType(false)}
            >
              <Modal.Header className="p-3">
                <Modal.Title
                  id="example-custom-modal-styling-title"
                  className="mb-4"
                  style={{ fontSize: "15px", fontWeight: 600 }}
                >
                  Add
                </Modal.Title>
                <span
                  onClick={() => {
                    setEditProfessionType(false);
                  }}
                  title="Close"
                >
                  <AiOutlineClose className="c_pointer" />
                </span>
              </Modal.Header>
              <Modal.Body className="p-3">
                <div className="d-flex">
                  <Col lg={11} md={11} sm={11} className="pl-2 pr-2">
                    <Form.Group className="form-group">
                      <input
                        type="text"
                        className={
                          validation.newProfType
                            ? "form-control input_ctrl error"
                            : "form-control input_ctrl"
                        }
                        onChange={(e) => {
                          const value = e.target.value;
                          if (/^[a-zA-Z\s]*$/.test(value)) {
                            setNewProfessionType(value);
                          }
                        }}
                        value={newProfessionType}
                      />
                      {validation.newProfType && (
                        <p className="fz-13 mb-2">
                          {
                            validation.newProfType
                          }
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                  <Col lg={1} md={1} sm={1} className="pl-2 pr-2 mr-2">
                    <a href="#">
                      <FaCirclePlus
                        className="circle_plus"
                        onClick={() => {
                          if (!proLoading) addNewProfessionType();
                        }}
                      />
                    </a>
                  </Col>
                </div>
              </Modal.Body>
            </Modal>

            {/* Kyc model */}

            <div>
              <Modal
                size="lg"
                show={kycpopupshow}
                // onHide={() => setKycpopupShow(false)}
                centered
              >
                {loading ? (
                  <div className="pro-loader-center">
                    <img className="loader-image" src={loader} alt="Loading..." />
                  </div>
                ) : (
                  ""
                )}
                {currentStep === "documentSelection" && (
                  <Modal.Header
                    style={{
                      padding: "15px 50px 5px 60px",
                      borderBottom: "none",
                    }}
                  >
                    <h3 className="text-center">KYC Verification</h3>
                    <span
                      onClick={() => {
                        setKycpopupShow(false);
                      }}
                      title="Close"
                    >
                      <AiOutlineClose className="c_pointer" />
                    </span>
                  </Modal.Header>
                )}

                {currentStep === "confirmation" && (
                  <Modal.Header
                    style={{
                      padding: "5px 50px 5px 60px",
                      borderBottom: "none",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <h3 className="mt-3">Upload Document</h3>
                  </Modal.Header>
                )}

                {currentStep === "successful" && (
                  <Modal.Header
                    style={{
                      padding: "15px 50px 5px 60px",
                      borderBottom: "none",
                    }}
                  >
                    <h3 className="text-center">KYC Status</h3>
                  </Modal.Header>
                )}

                {currentStep === "failed" && (
                  <Modal.Header

                    style={{
                      padding: "15px 50px 5px 60px",
                      borderBottom: "none",
                    }}
                  >
                    <h3 className="text-center">KYC Status</h3>
                  </Modal.Header>
                )}

                <Modal.Body
                  style={{
                    padding: "0px 50px 50px 50px",
                    borderTop: "1px solid #dfdada",
                  }}
                >
                  {currentStep === "documentSelection" && (
                    <div className="container">
                      <form onSubmit={handelKyc}>
                        <div className="form-row mt-3">
                          <div className="form-group col-xl-6">
                            <label htmlFor="exampleInputEmail1">
                              First Name
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter first name"
                              // onChange={(e) => setKycFirstName(e.target.value)}
                              value={kucfirstName}
                              onChange={handleFirstNameChange}
                            />
                            {kycFirstNameError && (
                              <p className="text-danger">{kycFirstNameError}</p>
                            )}
                          </div>
                          <div className="form-group col-xl-6">
                            <label htmlFor="exampleInputPassword1">
                              Last Name
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter last name"
                              // onChange={(e) => setKycLastName(e.target.value)}
                              value={kyclastName}
                              onChange={handleLastNameChange}
                            />
                            {kycLastNameError && (
                              <p className="text-danger">{kycLastNameError}</p>
                            )}
                          </div>
                        </div>
                        <div className="form-group">
                          <label htmlFor="exampleInputPassword1">
                            Date Of Birth
                          </label>
                          <input
                            type="date"
                            className="form-control w-100"
                            placeholder="Date Of Birth"
                            onChange={(e) => setKycDateOfBirth(e.target.value)}
                            max={new Date().toISOString().split("T")[0]}
                          />
                          {kycDateOfBirthError && (
                            <p className="text-danger">{kycDateOfBirthError}</p>
                          )}
                        </div>
                        <div>
                          <div className="form-group">
                            <label htmlFor="documentType">Document Type</label>
                            <select
                              id="documentType"
                              className="form-control"
                              value={selectedDocument}
                              onChange={(e) =>
                                setSelectedDocument(e.target.value)
                              }
                            >
                              <option value="">Select Document Type</option>
                              <option value="passport">Passport</option>
                              <option value="driving_license">
                                Driving Licence
                              </option>
                              <option value="national_identity_card">
                                National Identity Card
                              </option>
                              <option value="residence_permit">
                                Residence Permit
                              </option>
                            </select>
                            {documentTypeError && (
                              <p className="text-danger">{documentTypeError}</p>
                            )}
                          </div>

                          {selectedDocument && (
                            <div className="form-group">
                              <label htmlFor="documentId">
                                Enter Document ID
                              </label>
                              <input
                                type="text"
                                id="documentId"
                                className="form-control"
                                value={documentId}
                                onChange={(e) => setDocumentId(e.target.value)}
                              />
                              {documentIdError && (
                                <p className="text-danger">{documentIdError}</p>
                              )}
                            </div>
                          )}
                        </div>
                        <div>
                          <li style={{ color: "#757373", fontSize: "12px" }}>
                            {" "}
                            Your information is encrypted and securely stored.
                            We adhere to strict privacy policies and
                            regulations.
                          </li>
                        </div>
                        <button
                          type="submit"
                          className="btn btn-primary w-100 mt-3"
                        >
                          Continue
                        </button>
                      </form>
                    </div>
                  )}

                  {currentStep === "uploadForm" && (
                    <div
                      style={{
                        padding: "10px 100px",
                        borderTop: "1px solid #dfdada",
                      }}
                    >
                      <h3 className="text-center">Capture Guidness</h3>
                      <h6 className="text-center">
                        Please follow the instruction given below
                      </h6>

                      <div className="d-flex">
                        <img
                          src={capturegiude}
                          alt="captureimg"
                          width={700}
                          height={450}
                        />
                      </div>
                      <div className="d-flex justify-content-center">
                        <button
                          className="btn btn-primary w-50 mt-3"
                          onClick={uploaddoc}
                        >
                          Click to Continue
                        </button>
                      </div>
                    </div>
                  )}

                  {currentStep === "confirmation" && (
                    <div
                      style={{
                        padding: "0px 50px",
                        borderTop: "1px solid #dfdada",
                      }}
                    >
                      <h5 className="mt-3">{selectedDocument}</h5>
                      <p style={{ fontSize: "13px" }}>
                        Please upload a clear photo of your valid
                        government-issued ID
                      </p>

                      <>
                        <div className="d-flex flex-column" style={{ gap: "20px" }}>
                          {imagePreviewdoc && (
                            <div className="file-upload">
                              <div className="d-flex justify-content-between mb-3">
                                <h5>Front Photo</h5>
                                <IoMdClose onClick={() => setImagePreviewdoc(null)} style={{ fontSize: '25px' }} />
                              </div>

                              <img
                                src={imagePreviewdoc}
                                alt="Front"
                                style={{ height: "300px", width: "100%" }}
                              />

                            </div>
                          )}

                          {imagePreviewBack && (
                            <div className=" file-upload">
                              <div className="d-flex justify-content-between mb-3">
                                <h5>Back Photo</h5>
                                <IoMdClose onClick={() => setImagePrewBack(null)} style={{ fontSize: '25px' }} />
                              </div>
                              <img
                                src={
                                  imagePreviewBack ||
                                  "default-back-image-url.jpg"
                                }
                                alt="Back"
                                style={{ height: "300px", width: "100%" }}
                              />

                            </div>
                          )}
                        </div>
                        <div className="d-flex " style={{ gap: "20px" }}>
                          <label
                            htmlFor="front-file-upload"
                            className="file-upload "
                            style={{
                              display: imagePreviewdoc ? "none" : "block",
                            }}
                          >
                            <input
                              id="front-file-upload"
                              type="file"
                              onChange={(e) => handeldocconvert(e, "front")}
                            />
                            <div className="d-flex justify-content-between">
                              <div className="upload-text">
                                Upload a Front Photo
                              </div>
                              <div className="upload-icon-next">
                                <img src={uploadfile} alt="" />
                              </div>
                            </div>
                          </label>

                          <label
                            htmlFor="back-file-upload"
                            className="file-upload "
                            style={{
                              display: imagePreviewBack ? "none" : "block",
                            }}
                          >
                            <input
                              id="back-file-upload"
                              type="file"
                              onChange={(e) => handeldocconvert(e, "back")}
                            />
                            <div className="d-flex justify-content-between">
                              <div className="upload-text">
                                Upload a Back Photo
                              </div>
                              <div className="upload-icon-next">
                                <img src={uploadfile} alt="" />
                              </div>
                            </div>
                          </label>
                        </div>
                        {imageSizeError && (
                          <p className="text-danger font-weight-bold">
                            {imageSizeError}
                          </p>
                        )}
                      </>

                      <p
                        className="font-weight-bold"
                        style={{ fontSize: "13px" }}
                      >
                        File formats accepted: JPG, PNG
                        <br />
                        Image size: 100KB to less than 4MB
                      </p>

                      <li className="font-weight-bold">
                        Please review your information carefully. If everything
                        looks correct
                      </li>
                      <div className="form-group mt-2">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="invalidCheck"
                            required
                            onChange={handleCheckboxChange}
                          />
                          <label
                            className="form-check-label font-weight-bold"
                            htmlFor="invalidCheck"
                          >
                            By submitting this information, you agree to our
                            terms of service and privacy policy.
                          </label>
                          <div className="invalid-feedback">
                            You must agree before submitting.
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-center">
                        <button
                          className="btn btn-primary w-100"
                          type="submit"
                          disabled={!isChecked}
                          onClick={uploadKycdocument}
                        >
                          Submit Verification
                        </button>
                      </div>
                    </div>
                  )}

                  {currentStep === "successful" && (
                    <div
                      className="d-flex  justify-content-center"
                      style={{
                        padding: "20px 50px",
                        borderTop: "1px solid #dfdada",
                      }}
                    >
                      <h2 className="d-flex justify-content-center text-success">
                        KYC verified
                      </h2>
                      <HiBadgeCheck
                        style={{
                          fontSize: "30px",
                          color: "green",
                          marginTop: "5px",
                        }}
                      />
                    </div>
                  )}

                  {currentStep === "failed" && (
                    <div className="d-flex justify-content-center">
                      <div>
                        <div className="d-flex justify-content-center">
                          <h2 className="text-danger">
                            KYC Verification Failed
                          </h2>
                          <BsExclamationCircle
                            className={"text-danger"}
                            style={{ fontSize: "30px", marginTop: "5px" }}
                          />
                        </div>

                        {/* <p className="text-danger font-weight-500">{failedMessage}</p> */}
                        <div
                          className="text-danger font-weight-500"
                          dangerouslySetInnerHTML={{
                            __html: `<ul>${failedMessage
                              .split("\n")
                              .filter((msg) => msg.trim() !== "")
                              .map((msg) => `<li>${msg}</li>`)
                              .join("")}</ul>`,
                          }}
                        />
                      </div>
                    </div>
                  )}
                </Modal.Body>
              </Modal>
            </div>

            <CustomModal
              show={showModal}
              handleClose={() => setShowModal(false)}
              performSubmit={() => addNotes()}
              btnText="Save"
              title="Add Notes"
              body={
                <>
                  <textarea
                    className="form-control Comment_sty h_25"
                    placeholder="Type Something…"
                    onChange={handleNotes}
                  ></textarea>
                  {noteCmtErr && (
                    <div className="text-danger">{noteCmtErr}</div>
                  )}
                </>
              }
            />
            {/* <CustomModal
              show={showServiceModal}
              handleClose={() => setShowServiceModal(false)}
              performSubmit={addService}
              title="Add Service"
              body={
                <>
                  <select
                    value={serviceType}
                    onChange={handleServiceTypeChange}
                    className={`form-control mb-2 ${
                      isSaving && serviceType.trim() === "" ? "mandatory" : ""
                    }`}
                  >
                    <option value="">Select Type *</option>
                    {busnsTypeList &&
                      busnsTypeList.map((busn) => (
                        <option key={busn.id} value={busn.id}>
                          {busn.name}
                        </option>
                      ))}
                  </select>
                  {errortype && <div style={{ color: "red" }}>{errortype}</div>}
                  <input
                    type="text"
                    value={serviceName}
                    onChange={handleServiceNameChange}
                    placeholder="Product/ServiceProvider Name *"
                    className={`form-control mb-2 ${
                      isSaving && serviceName.trim() === "" ? "mandatory" : ""
                    }`}
                  />
                  {error && <div style={{ color: "red" }}>{error}</div>}
                  <textarea
                    value={serviceDescription}
                    onChange={handleServiceDescriptionChange}
                    placeholder=" Description"
                    className="form-control mb-2"
                  ></textarea>
                  <style jsx>{`
                    .mandatory {
                      border-color: red;
                    }
                  `}</style>
                </>
              }
            /> */}
            <Modal
              size="md"
              show={eyespopup}
              onHide={() => setEyepop(false)}
              centered
            >
              <Modal.Header>
                <Modal.Title
                  id="example-modal-sizes-title-lg"
                  style={{ fontSize: "15px", fontWeight: 600 }}
                >
                  Add Services/Products
                </Modal.Title>
                <span
                  onClick={() => {
                    setEyepop(false);
                    setError("");
                    setErrorType("");
                    setServiceName("");
                    setServiceType("");
                    setServiceDescription("");
                  }}
                  title="Close"
                >
                  <AiOutlineClose />
                </span>
              </Modal.Header>
              <Modal.Body>
                <select
                  value={serviceType}
                  onChange={handleServiceTypeChange}
                  className={`form-control mb-2 ${isSaving && serviceType.trim() === "" ? "mandatory" : ""
                    }`}
                >
                  <option value="">Select Type *</option>
                  {busnsTypeList &&
                    busnsTypeList
                      .filter((busn) => busn.isBusiness)
                      .map((busn) => (
                        <option key={busn.id} value={busn.id}>
                          {busn.name}
                        </option>
                      ))}
                </select>
                {errortype && <div style={{ color: "red" }}>{errortype}</div>}
                <input
                  type="text"
                  value={serviceName}
                  onChange={handleServiceNameChange}
                  placeholder="Product/ServiceProvider Name *"
                  className={`form-control mb-2 ${isSaving && serviceName.trim() === "" ? "mandatory" : ""
                    }`}
                />
                {error && <div style={{ color: "red" }}>{error}</div>}
                {/* <textarea
                    value={serviceDescription}
                    onChange={handleServiceDescriptionChange}
                    placeholder=" Description"
                    className="form-control mb-2"
                  ></textarea> */}
                <style jsx>{`
                  .mandatory {
                    border-color: red;
                  }
                `}</style>
                <Modal.Footer className="bordr pad_13">
                  <Button
                    variant="primary"
                    className="del_bttn"
                    onClick={addService}
                  >
                    Save
                  </Button>
                  <Button
                    variant="danger"
                    className="del_bttn"
                    onClick={() => {
                      setEyepop(false);
                      // Clear all errors and reset form fields
                      setError("");
                      setErrorType("");
                      setServiceName("");
                      setServiceType("");
                      setServiceDescription("");
                    }}
                  >
                    Cancel
                  </Button>
                </Modal.Footer>

                <div
                  style={{
                    fontSize: "15px",
                    fontWeight: 600,
                    marginBottom: "20px",
                  }}
                >
                  List of Service/Product Providers
                </div>

                {serviceProviders.length === 0 ? (
                  <p>No service providers found.</p>
                ) : (
                  <table className="table">
                    <thead class="thead-clr">
                      <tr>
                        <th>Type</th>
                        <th>Name</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {serviceProviders.map((data, index) => (
                        <tr key={data.id}>
                          <td className="pad_7">
                            {editIndex === index ? (
                              <select
                                value={editedType}
                                onChange={(e) => {
                                  changeType(e.target.value);
                                }}
                                className="form-control"
                              >
                                <option value="">Select Type *</option>
                                {busnsTypeList &&
                                  busnsTypeList
                                    .filter((busn) => busn.isBusiness)
                                    .map((busn) => (
                                      <option key={busn.id} value={busn.id}>
                                        {busn.name}
                                      </option>
                                    ))}
                              </select>
                            ) : (
                              busnsTypeList.find(
                                (item) => item.id === data.userRoleId
                              )?.name || "Unknown"
                            )}
                            {errorIndex === index && typeError && (
                              <p style={{ color: "red" }}>{typeError}</p>
                            )}
                          </td>
                          <td className="pad_7">
                            {editIndex === index ? (
                              <input
                                type="text"
                                value={editedName}
                                onChange={(e) => {
                                  changeName(e.target.value);
                                }}
                                placeholder="Product/ServiceProvider Name *"
                                className="form-control"
                              />
                            ) : (
                              data.question
                            )}
                            {errorIndex === index && nameError && (
                              <p style={{ color: "red" }}>{nameError}</p>
                            )}
                          </td>
                          <td
                            className={
                              editIndex === index ? "pad_7" : "d-flex pad_7"
                            }
                          >
                            {editIndex !== index && (
                              <div
                                className="c_pointer"
                                style={{ color: "blue", marginRight: "10px" }}
                                onClick={() =>
                                  handleEditClick(
                                    index,
                                    data.userRoleId,
                                    data.question
                                  )
                                }
                              >
                                <img title="Edit" src={editRole} />
                              </div>
                            )}
                            {editIndex !== index && (
                              <div>
                                <HiOutlineTrash
                                  onClick={() => showDeletePopup(data.id)}
                                  title="Delete"
                                  className="ques_trash c_pointer"
                                />
                              </div>
                            )}
                            {editIndex === index && (
                              <>
                                <span
                                  className="c_pointer plus_icn"
                                  title="Update"
                                  onClick={() => handleSaveEdit(data.id, index)}
                                >
                                  <TiTick />
                                </span>
                                <span
                                  className="c_pointer plus_icn"
                                  title="Cancel"
                                  onClick={handleCancelEdit}
                                >
                                  <RiCloseLine />
                                </span>
                              </>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </Modal.Body>
            </Modal>
            <UpgradePopup
              show={showPopup}
              handleClose={() => setShowPopup(false)}
            />
            {/* <Modal size="sm" show={showQrCode} onHide={() => setShowQrCode(false)}>
            <Modal.Body className="p-3">
                <div className="d-flex align-items-center">
                <Col sm={6} md={6} lg={6} xl={6} className="card_bdr_right">
                  <div className="user text-center d-flex justify-content-center">
                          <div className="h80_w80 rounded-circle">
                              <img src={popupData.imagePath ? popupData.imagePath : profile} className="rounded-circle h80_w80"/>
                          </div>
                    </div>
                    <div className="mt-2 text-center">
                      <h6 className="mb-0">{popupData.professionType}</h6>
                    </div>
                    </Col>
                <Col sm={6} md={6} lg={6} xl={6}>
                    <div className="d-flex align-items-center justify-content-center">
                    <img className="qr_img_bdr" src={shsds(popupData.qrCode)}/>
                  </div>
                </Col>
                </div>
            </Modal.Body>
            </Modal> */}
          </div>
        ) : (
          <div className="pro-loader-center">
            <img className="loader-image" src={loader} alt="Loading..." />
          </div>
        )}
      </div>
      {/* ----------- Delete Confirmation Popup ---------- */}
      <Modal size="sm" show={deletePopup} onHide={() => setDeletePopup(false)}>
        <Modal.Header>
          <Modal.Title
            id="example-custom-modal-styling-title"
            style={{ fontSize: "15px", fontWeight: 600 }}
          >
            Delete Confirmation
          </Modal.Title>
          <span onClick={handleClose} title="Close" className="c_pointer">
            <AiOutlineClose />
          </span>
        </Modal.Header>
        <Modal.Body className="bordr">
          <span>Are you sure to delete?</span>
        </Modal.Body>
        <Modal.Footer className="bordr">
          <Button
            variant="primary"
            className="del_bttn"
            onClick={deleteQuestion}
          >
            Yes
          </Button>
          <Button
            variant="danger"
            className="del_bttn"
            onClick={() => setDeletePopup(false)}
          >
            No
          </Button>
        </Modal.Footer>
      </Modal>

      {/* kyb verification popup */}

      <Modal
        size="sm"
        show={kybpopup}
      // onHide={() => setKybPopup(false)}
      >
        {loading ? (
          <div className="pro-loader-center">
            <img className="loader-image" src={loader} alt="Loading..." />
          </div>
        ) : (
          ""
        )}
        <Modal.Header>
          <Modal.Title
            id="example-custom-modal-styling-title"
            style={{ fontSize: "15px", fontWeight: 600 }}
          >
            KYB Verification
          </Modal.Title>
          <span onClick={() => setKybPopup(false)} title="Close" className="c_pointer">
            <AiOutlineClose />
          </span>
        </Modal.Header>
        <Modal.Body className="bordr">
          {kybform === true && (

            <form onSubmit={Kybfun}>
              <div class="form-group">
                <label for="company name">Company Name</label>
                <input type="text" class="form-control mt-1" id="companyname" placeholder="Enter Company Name" onChange={(e) => setKybCompanyname(e.target.value)} value={kybCompanyname} />
                {kybErrors.companyName && <div className="text-danger mt-1">{kybErrors.companyName}</div>}
              </div>
              <div class="form-group mt-2">
                <label for="incorporationCountry">Incorporation Country</label>
                <input type="text" class="form-control mt-1" id="incorporationCountry" placeholder="Enter Incorporation Country" onChange={(e) => setKybCountry(e.target.value)} value={KybCountry} />
                {kybErrors.incorporationCountry && <div className="text-danger mt-1">{kybErrors.incorporationCountry}</div>}
              </div>
              <button type="submit" className="btn btn-primary w-100 mt-5" disabled={loading}>
                {loading ? 'Loading...' : 'Submit'}
              </button>
            </form>
          )}
          {kybSuccess === true && (
            <div className="d-flex justify-content-center">
              <h2 className="  text-success">
                Kyb Verifieded
              </h2>
              <HiBadgeCheck
                style={{
                  fontSize: "30px",
                  color: "green",
                  marginTop: "5px",
                }}
              />
            </div>




          )}
          {kybfailes === true && (

            <div className="d-flex justify-content-center">
              <h2 className="text-danger">
                Kyb verification failed
              </h2>
              <BsExclamationCircle
                className={"text-danger"}
                style={{ fontSize: "30px", marginTop: "5px" }}
              />
            </div>

          )}
        </Modal.Body>
      </Modal>
      {/* Culture score popup */}
      <Modal
        size="sm"
        show={showScore}
        onHide={closeScore}
      >
        <Modal.Header>
          <Modal.Title
            id="example-custom-modal-styling-title"
            className="cul_hdr"
          >
            Add Culture Score
          </Modal.Title>
          <span className="c_pointer" onClick={closeScore} title="Close">
            <AiOutlineClose />
          </span>
        </Modal.Header>
        <Modal.Body className="bordr d-flex justify-content-center">
          <div className="culture-score-container">
            <div className="c_title cmy_hglt" title={cmpyName}>{shortName && shortName}</div>
            <div className="c_subtitle">Culture Score</div>
            <div onClick={handleIncrement} className="inc_dec_scr bdr_btm">+</div>
            <div className="score c_subtitle">{score}</div>
            <div onClick={handleDecrement} className="inc_dec_scr">-</div>
          </div>
        </Modal.Body>
        <Modal.Footer className="bordr">
          <Button
            variant="danger"
            className="del_bttn"
            onClick={() => updateScore()}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    userTypeInfo: state.user.userTypeInfo,
    email: state.user.email,
    userType: state.user.userType,
    profileId: state.user.profileId,
    authToken: state.user.token,
  };
};
export default connect(mapStateToProps)(Profile);
