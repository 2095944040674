import React from "react";
import SideBar from "./SupportSideBar";
import HeaderMenu from "./HeaderMenu";
import { Card, CardHeader, Container, Row, Col, Table } from "reactstrap";

const Dashboard = () => {
  return (
    <>
      <HeaderMenu />
      <div className="">
        <SideBar />
        <div className="main-content ml_200">
          <Container fluid>
            <div className="header-body pt-3"></div>
          </Container>
          <Container className="mt_18">
            <Row>
              <Col xl="12">
                <Card className="shadow">
                  <CardHeader className="border-0 social_pad">
                    <Row className="align-items-center">
                      <div className="col">
                        <h3 className="mb-0 fs_20">TO DO</h3>
                      </div>
                      <div className="col text-right"></div>
                    </Row>
                  </CardHeader>
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th scope="col"></th>
                        <th scope="col">TO DO</th>
                        <th scope="col" />
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row"></th>
                        <td></td>
                        <td>
                          <div className="d-flex align-items-center">
                            <span className="mr-2"></span>
                            <div></div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row"></th>
                        <td></td>
                        <td>
                          <div className="d-flex align-items-center">
                            <span className="mr-2"></span>
                            <div></div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
