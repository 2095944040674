export default {
    control: {
      backgroundColor: '#fff',
      fontSize: 14,
      width:'100%',
      // fontWeight: 'normal',
    },
    '&multiLine': {
      highlighter: {
        paddingTop:5,
        border: '1px solid transparent',
      },
      input: {
        border: '0px',
      },
    },
    '&singleLine': {
      display: 'inline-block',
      highlighter: {
        padding: 1,
        border: '2px inset transparent',
      }
    },
    suggestions: {
      list: {
        position:'absolute',
        backgroundColor: 'white',
        border: '1px solid rgba(0,0,0,0.15)',
        fontSize: 14,
        overflow: 'scroll',
        height: '40vh',
        width: '50vh',
        top:'-50vh'
      },
      item: {
        padding: '5px 15px',
        '&focused': {
          backgroundColor: '#cee4e5',
        },
      },
    },
  }