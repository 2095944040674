import { Button, Card, Col, Row, Tab, Tabs } from "react-bootstrap";
import profile from "../../../assets/images/profile.jpg";
import React, { useState, useEffect, useRef } from "react";
import { useToast } from "../../Toast";
import HeaderMenu from "../headerMenu";
import SideBar from "../SideBar";
import { useNavigate } from "react-router-dom";
import BreadCrumb from "../../plugins/Breadcrumb";
import loader from "../../../assets/images/loader.gif";
import { connect } from "react-redux";
import StarRating from "./StarRating";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { OverlayTrigger, Overlay, Popover } from "react-bootstrap";
import { FiUsers, FiUser } from "react-icons/fi";
import { HiLocationMarker } from "react-icons/hi";
import { PiPhoneFill } from "react-icons/pi";
import { GrMail } from "react-icons/gr";
import Horizontalbar from "../../plugins/Horizontalbar";
import { IoPersonCircleSharp } from "react-icons/io5";
import { FaUserTie } from "react-icons/fa";
import { MdBusinessCenter } from "react-icons/md";
import { useDispatch } from "react-redux";
import { followId, userTypeId } from "../../../Redux/Actions/userAction";
import { commonUtils } from "../../CommonUtils";
import { TiTick } from "react-icons/ti";
import { HiBadgeCheck, HiCheckCircle } from "react-icons/hi";
import { BiBell } from "react-icons/bi";
import { useSubscription } from '../../SubscriptionContext';
import UpgradePopup from "../../plugins/UpgradePopup";
const FollowingTab = (props) => {
  const [loading, setLoading] = useState(false);
  const [followingList, setFollowingList] = useState([]);
  const [showPopover, setShowPopover] = useState(false);
  const navigate = useNavigate();
  const [activeCard, setActiveCard] = useState(null);
  const [globalRating, setGlobalRating] = useState([]);
  const [currentUsers, setCurrentUsers] = useState([]);
  const [eventKey, setEventKey] = useState("Personal");
  const [loadQuestion, setLoadQuestion] = useState(true);
  const { isFeatureEnabled, allFeatures } = useSubscription();
  const toast = useToast();
  const dispatch = useDispatch();
  const [showPopup, setShowPopup] = useState(false);
  useEffect(() => {
    FollowingList();
  }, [props]);
  useEffect(() => {
    const reloadFollowUserHandler = () => {
      FollowingList();
    };
    window.addEventListener("reloadFollowingUsers", reloadFollowUserHandler);
    return () => {
      window.removeEventListener(
        "reloadFollowingUsers",
        reloadFollowUserHandler
      );
    };
  }, []);
  const handleMouseEnter = (users) => {
    if (users.length > 0) setCurrentUsers(users);
    else setCurrentUsers([]);
  };
  const handleReviewClick = (
    userId,
    professionalList,
    businessList,
    followedTypeId,
    followedUserType,
    list,
    professionalId,
    loadQuestion
  ) => {
    const filteredData = list.professional;
    const filterBusData = list.business;
    navigate("/dashboard/following/postreview", {
      state: {
        userId,
        professionalList,
        businessList,
        followedTypeId,
        followedUserType,
        filteredData,
        filterBusData,
        professionalId,
        loadQuestion
      },
    });
  };
  const FollowingList = async () => {
    if (props.busnAdminId || props.userId) {
      setLoading(true);
      const params = {
        followingUserId:
          props.userType === "Business" ? props.busnAdminId : props.userId,
        followingUserType: props.userType,
        followingTypeId: props.userType == "Personal" ? null : props.profileId,
      };
      try {
        const response = await window.apiService.followingList(params);
        setFollowingList(response.data.recordInfo);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.error("Error Occured");
      }
    }
  };
  const updateRevNotifyStatus = async (list) => {
    setLoading(true);
    const params = {
      followingUserId: list.id,
      followingUserType: list.followedUserType,
      followingTypeId: list.followedTypeId,
      userId:
        props.userType === "Business" ? props.busnAdminId : props.userId,
      userType: props.userType,
      userTypeId: props.userType == "Personal" ? null : props.profileId,
      status: 'new'
    };
    try {
      const response = await window.apiService.updateRevNotifyStatus(params);
      if (response.data.status == "OK") {
        toast.success(response.data.message);
        FollowingList();
        navigate("/dashboard/viewuser", {
          state: {
            userId: list.id,
            userType: list.followedUserType,
            profileId: null,
          },
        });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Error Occured");
    }
  }
  const getAccTypeLabel = (type) => {
    return (
      <div className="d-flex justify-content-end">
        <div
          className={`usr_brnd ${type == "Business"
            ? "usr_brnd_bus"
            : type == "Professional"
              ? "usr_brnd_pro"
              : "usr_brnd_psnl"
            }`}
        >
          <span className="m-1">{type}</span>
        </div>
      </div>
    );
  };
  const handleArrowClick = (rating) => {
    const result = rating.map((starData) => ({
      label: starData.star,
      value: starData.totalReviewCount,
      userRatings: starData.userRatings,
    }));
    result.sort((a, b) => {
      const numA = parseInt(a.label.split(" ")[0]);
      const numB = parseInt(b.label.split(" ")[0]);
      return numB - numA;
    });
    setGlobalRating(result);
  };
  const handleButtonClick = (key) => {
    setActiveCard(key);
  };
  const handleOverlayClose = (key) => {
    setActiveCard(null);
  };

  return (
    <>
      <HeaderMenu />
      <div className="">
        <SideBar />
        <div className="main-content ml_200">
          {/* <BreadCrumb /> */}
          <Card className="shade">
            <Row>
              <Col sm={12}>
                <div className="postedReviews">
                  <h5>tiipstrs I am following</h5>
                </div>
                {/* <Tabs
                  defaultActiveKey="Personal"
                  className="follower_tab w-100 mt_6"
                  onSelect={(key) => setEventKey(key)}
                >
                  <Tab
                    eventKey="Personal"
                    title={
                      <span className="pro_tab_span">
                        <IoPersonCircleSharp
                          className={`dashboardTabIcon ${
                            eventKey == "Personal" ? "clr_darkblue" : ""
                          }`}
                        />
                        Personal
                      </span>
                    }
                  > */}
                <Row className="pt-2 ml-2 mr-2 mt-5 pad_50">
                  {followingList.length > 0 ? (
                    followingList.map((list) =>
                      list.followedUserType == "Personal" ? (
                        <Col sm={12} md={4} lg={3} xl={2} className="user_card">
                          <Card className="usr_crd_bdr mb-10 rounded-0">
                            {getAccTypeLabel(list.followedUserType)}
                            <div className="user text-center d-flex justify-content-center mt-2">
                              <div className="follow_profile kyc_vf">
                                <img
                                  className="rounded-circle"
                                  src={
                                    list.imagePath ? list.imagePath : profile
                                  }
                                  width="60"
                                  height="60"
                                />
                              </div>
                              {list.status === 'new' && <div className="fol_bell fs_20">
                                <BiBell onClick={() => updateRevNotifyStatus(list)} /></div>}
                            </div>
                            <div className="mt-2 text-center">
                              <span
                                className="fol_name c_pointer"
                                onClick={() => {
                                  dispatch(
                                    followId(
                                      list.id,
                                      list.followedUserType,
                                      null
                                    )
                                  );
                                  navigate("/dashboard/viewuser", {
                                    state: {
                                      userId: list.id,
                                      userType: list.followedUserType,
                                      profileId: null,
                                    },
                                  });
                                }}
                              >
                                <h6 className="mb-0">{list.name}
                                  {list.isKycVerified ? (
                                    <HiBadgeCheck
                                      style={{
                                        fontSize: "17px",
                                        color: "green",
                                        cursor: "pointer",
                                        marginLeft: "5px"
                                      }}
                                      title="KYC Verified"
                                      data-toggle="tooltip"
                                      data-placement="top"
                                    />
                                  ) : (
                                    <></>
                                  )}
                                </h6>
                              </span>
                              <div className="d-flex justify-content-center">
                                {list.averageReviewScore > 0.0 ? (
                                  <span className="font-weight-bold total_fnt mar_9">
                                    {list.averageReviewScore.toFixed(1)}
                                  </span>
                                ) : (
                                  ""
                                )}
                                <StarRating
                                  isReview={true}
                                  rating={list.averageReviewCount}
                                  disabled={true}
                                />
                                <span className="d-flex align-items-center total_fnt">
                                  ({list.totalReviewCount})
                                </span>
                                <OverlayTrigger
                                  trigger="click"
                                  key={list.id}
                                  placement="bottom"
                                  overlay={
                                    <Popover
                                      id={`popover-${list.id}`}
                                      className="pop_rating"
                                    >
                                      <div className="drp_rating">
                                        <StarRating
                                          rating={list.averageReviewCount}
                                          disabled={true}
                                        />
                                        <span>
                                          {list.averageReviewCount} out of 5
                                        </span>
                                      </div>
                                      {globalRating.map((item, index) => (
                                        <Row>
                                          <Col
                                            sm={12}
                                            md={3}
                                            lg={3}
                                            xl={2}
                                            className="p-0"
                                          >
                                            <span className="prog_span">
                                              {item.label}
                                            </span>{" "}
                                          </Col>
                                          <Col
                                            sm={12}
                                            md={8}
                                            lg={8}
                                            xl={7}
                                            className="p-0"
                                          >
                                            <Horizontalbar
                                              dataSet={item.value}
                                            />
                                          </Col>
                                          <Col
                                            sm={12}
                                            md={3}
                                            lg={3}
                                            xl={2}
                                            className="p-0 prog_span"
                                            style={{ marginLeft: "10px" }}
                                          >
                                            <span className="tooltip">
                                              <span
                                                className="c_pointer"
                                                onMouseEnter={() =>
                                                  handleMouseEnter(
                                                    item.userRatings
                                                  )
                                                }
                                              >
                                                {item.value}
                                                {item.value > 1 ? (
                                                  <FiUsers
                                                    style={{
                                                      marginLeft: "6px",
                                                    }}
                                                  />
                                                ) : (
                                                  <FiUser
                                                    style={{
                                                      marginLeft: "6px",
                                                    }}
                                                  />
                                                )}
                                              </span>
                                              {commonUtils.ratingTooltip(
                                                currentUsers
                                              )}
                                            </span>
                                          </Col>
                                        </Row>
                                      ))}
                                    </Popover>
                                  }
                                  rootClose
                                >
                                  <span
                                    className="d-flex align-items-center c_pointer"
                                    onClick={() =>
                                      handleArrowClick(list.starRating)
                                    }
                                  >
                                    <IoIosArrowDown />
                                  </span>
                                </OverlayTrigger>
                              </div>
                              <div
                                style={{ overflow: "visible" }}
                                className="card_txt text-center fnt_11 mail_icn d-flex justify-content-center"
                              >
                                <span className="d-flex">
                                  <GrMail style={{ marginTop: "3px" }} />
                                  &nbsp;
                                  {list.email ? (
                                    list.isPublic ? (
                                      <span title={list.email}>
                                        {list.email.length > 19
                                          ? `${list.email.slice(0, 19)}...`
                                          : list.email}
                                      </span>
                                    ) : (
                                      list.email.slice(0, 4) +
                                      "x".repeat(
                                        Math.max(0, list.email.length - 11)
                                      )
                                    )
                                  ) : (
                                    "-"
                                  )}
                                </span>
                              </div>

                              <div className="card_txt text-center fnt_11">
                                {list.phone ? <PiPhoneFill /> : ""}
                                &nbsp;
                                {list.phone
                                  ? list.isPublic
                                    ? list.phone
                                    : "x".repeat(
                                      Math.max(0, list.phone.length - 4)
                                    ) + list.phone.slice(-4)
                                  : "-"}
                              </div>
                              <div className="card_txt text-center fnt_11">
                                {list.location ? <HiLocationMarker /> : ""}
                                &nbsp;{list.location ? list.location : "-"}{" "}
                              </div>
                              <div className="h_23"></div>
                              <div className="btn_wd mt-2">
                                {isFeatureEnabled(allFeatures["Add-Review%"]?.key, allFeatures["Add-Review%"]?.isDeleted) ? <button
                                  className="btn btn-primary w-100 rev_btn"
                                  onClick={() =>
                                    handleReviewClick(
                                      list.id,
                                      list.professional,
                                      list.business,
                                      list.followedTypeId,
                                      list.followedUserType,
                                      list,
                                      null,
                                      loadQuestion
                                    )
                                  }
                                >
                                  Review
                                </button> : <button
                                  className="btn btn-primary w-100 rev_btn"
                                  onClick={() => setShowPopup(true)
                                  }
                                >
                                  Review
                                </button>}
                              </div>
                            </div>
                          </Card>
                        </Col>
                      ) : list.followedUserType == "Professional" &&
                        list.professional != null ? (
                        <Col sm={12} md={4} lg={3} xl={2} className="user_card">
                          <Card className="usr_crd_bdr mb-10 rounded-0">
                            {getAccTypeLabel(list.followedUserType)}
                            <div className="user text-center d-flex justify-content-center mt-2">
                              <div className="follow_profile">
                                <img
                                  className="rounded-circle"
                                  src={
                                    list.professional.imagePath
                                      ? list.professional.imagePath
                                      : profile
                                  }
                                  width="60"
                                  height="60"
                                />
                              </div>
                              {list.status === 'new' && <div className="fol_bell fs_20">
                                <BiBell onClick={() => updateRevNotifyStatus(list)} /></div>}
                            </div>
                            <div className="mt-2 text-center">
                              <span
                                className="fol_name c_pointer"
                                onClick={() => {
                                  dispatch(
                                    followId(
                                      list.id,
                                      list.followedUserType,
                                      list.professional.id
                                    )
                                  );
                                  navigate("/dashboard/viewuser", {
                                    state: {
                                      userId: list.id,
                                      userType: list.followedUserType,
                                      profileId: list.professional.id,
                                    },
                                  });
                                }}
                              // onClick={() => {
                              //   navigate("/dashboard/following/view", {
                              //     state: {
                              //       userIdFollow: list.id,
                              //       userTypeFollow: list.followedUserType,
                              //       ProfileId: list.professional.id,
                              //     },
                              //   });
                              // }}
                              >
                                <h6 className="mb-0">{list.name}&nbsp;</h6>
                                <p className="mb-0 fz_14 font-weight-400">
                                  {"(" + list.professional.professionType + ")"}
                                </p>
                              </span>
                              <div className="d-flex justify-content-center">
                                {list.professional.averageReviewScore > 0.0 ? (
                                  <span className="font-weight-bold total_fnt mar_9">
                                    {list.professional.averageReviewScore.toFixed(
                                      1
                                    )}
                                  </span>
                                ) : (
                                  ""
                                )}
                                <StarRating
                                  isReview={true}
                                  rating={list.professional.averageReviewCount}
                                  disabled={true}
                                />
                                <span className="d-flex align-items-center total_fnt">
                                  ({list.professional.totalReviewCount})
                                </span>
                                <OverlayTrigger
                                  trigger="click"
                                  key={list.id}
                                  placement="bottom"
                                  overlay={
                                    <Popover
                                      id={`popover-${list.id}`}
                                      className="pop_rating"
                                    >
                                      <div className="drp_rating">
                                        <StarRating
                                          rating={
                                            list.professional.averageReviewCount
                                          }
                                          disabled={true}
                                        />
                                        <span>
                                          {list.professional.averageReviewCount}{" "}
                                          out of 5
                                        </span>
                                      </div>
                                      {globalRating.map((item, index) => (
                                        <Row>
                                          <Col
                                            sm={12}
                                            md={3}
                                            lg={3}
                                            xl={2}
                                            className="p-0"
                                          >
                                            <span className="prog_span">
                                              {item.label}
                                            </span>{" "}
                                          </Col>
                                          <Col
                                            sm={12}
                                            md={8}
                                            lg={8}
                                            xl={7}
                                            className="p-0"
                                          >
                                            <Horizontalbar
                                              dataSet={item.value}
                                            />
                                          </Col>
                                          <Col
                                            sm={12}
                                            md={3}
                                            lg={3}
                                            xl={2}
                                            className="p-0 prog_span"
                                            style={{
                                              marginLeft: "10px",
                                            }}
                                          >
                                            <span className="tooltip">
                                              <span
                                                className="c_pointer"
                                                onMouseEnter={() =>
                                                  handleMouseEnter(
                                                    item.userRatings
                                                  )
                                                }
                                              >
                                                {item.value}
                                                {item.value > 1 ? (
                                                  <FiUsers
                                                    style={{
                                                      marginLeft: "6px",
                                                    }}
                                                  />
                                                ) : (
                                                  <FiUser
                                                    style={{
                                                      marginLeft: "6px",
                                                    }}
                                                  />
                                                )}
                                              </span>
                                              {commonUtils.ratingTooltip(
                                                currentUsers
                                              )}
                                            </span>
                                          </Col>
                                        </Row>
                                      ))}
                                    </Popover>
                                  }
                                  rootClose
                                >
                                  <span
                                    className="d-flex align-items-center c_pointer"
                                    onClick={() =>
                                      handleArrowClick(
                                        list.professional.starRating
                                      )
                                    }
                                  >
                                    <IoIosArrowDown />
                                  </span>
                                </OverlayTrigger>
                              </div>
                              <div
                                style={{ overflow: "visible" }}
                                className="card_txt text-center fnt_11 mail_icn d-flex justify-content-center"
                              >
                                <span className="d-flex">
                                  <GrMail style={{ marginTop: "3px" }} />
                                  &nbsp;
                                  {list.email ? (
                                    list.isPublic ? (
                                      <span title={list.email}>
                                        {list.email.length > 19
                                          ? `${list.email.slice(0, 19)}...`
                                          : list.email}
                                      </span>
                                    ) : (
                                      list.email.slice(0, 4) +
                                      "x".repeat(
                                        Math.max(0, list.email.length - 11)
                                      )
                                    )
                                  ) : (
                                    "-"
                                  )}
                                </span>
                              </div>

                              <div className="card_txt text-center fnt_11">
                                {list.professional.officePhone ? (
                                  <PiPhoneFill />
                                ) : (
                                  ""
                                )}
                                &nbsp;
                                {list.professional.officePhone
                                  ? list.isPublic
                                    ? list.professional.officePhone
                                    : "x".repeat(
                                      Math.max(
                                        0,
                                        list.professional.officePhone.length -
                                        4
                                      )
                                    ) +
                                    list.professional.officePhone.slice(-4)
                                  : "-"}
                              </div>
                              <div className="card_txt text-center fnt_11">
                                {list.professional.location ? (
                                  <HiLocationMarker />
                                ) : (
                                  ""
                                )}
                                &nbsp;
                                {list.professional.location
                                  ? list.professional.location
                                  : "-"}{" "}
                              </div>
                              <div className="btn_wd mt-2">
                                {isFeatureEnabled(allFeatures["Add-Review%"]?.key, allFeatures["Add-Review%"]?.isDeleted) ? <button
                                  className="btn btn-primary w-100 rev_btn"
                                  onClick={() =>
                                    handleReviewClick(
                                      list.id,
                                      list.professional,
                                      list.business,
                                      list.professional.professionTypeId,
                                      list.followedUserType,
                                      list,
                                      list.professional.id,
                                      (props.userType == "Business" ? loadQuestion : false)
                                    )
                                  }
                                >
                                  Review
                                </button> : <button
                                  className="btn btn-primary w-100 rev_btn"
                                  onClick={() => setShowPopup(true)
                                  }
                                >
                                  Review
                                </button>}
                              </div>
                            </div>
                          </Card>
                        </Col>
                      ) : list.business != null ? (
                        <Col sm={12} md={4} lg={3} xl={2} className="user_card">
                          <Card className="usr_crd_bdr mb-10 rounded-0">
                            {getAccTypeLabel(list.followedUserType)}
                            <div className="user text-center d-flex justify-content-center mt-2">
                              <div className="follow_profile kyc_vf">
                                <img
                                  className="rounded-circle"
                                  src={
                                    list.business.logo
                                      ? list.business.logo
                                      : profile
                                  }
                                  width="60"
                                  height="60"
                                />
                              </div>
                              {list.status === 'new' && <div className="fol_bell fs_20">
                                <BiBell onClick={() => updateRevNotifyStatus(list)} /></div>}
                            </div>
                            <div className="mt-2 text-center">
                              <span
                                className="fol_name c_pointer"
                                // onClick={() => {
                                //   navigate("/dashboard/following/view", {
                                //     state: {
                                //       userIdFollow: list.id,
                                //       userTypeFollow: list.followedUserType,
                                //       ProfileId: list.business.id,
                                //     },
                                //   });
                                // }}
                                onClick={() => {
                                  dispatch(
                                    followId(
                                      list.id,
                                      list.followedUserType,
                                      list.business.id
                                    )
                                  );
                                  navigate("/dashboard/viewuser", {
                                    state: {
                                      userId: list.id,
                                      userType: list.followedUserType,
                                      profileId: list.business.id,
                                    },
                                  });
                                }}
                              >
                                {/* <h6 className="mb-0">{list.name}&nbsp;</h6> */}
                                <h6
                                  className="mb-0"
                                  title={list.business.companyName}
                                >
                                  {list.business.shortName
                                    ? list.business.shortName
                                    : list.business.companyName}
                                </h6>
                              </span>
                              <div className="d-flex justify-content-center">
                                {list.business.averageReviewScore > 0.0 ? (
                                  <span className="font-weight-bold total_fnt mar_9">
                                    {list.business.averageReviewScore.toFixed(
                                      1
                                    )}
                                  </span>
                                ) : (
                                  ""
                                )}
                                <StarRating
                                  isReview={true}
                                  rating={list.business.averageReviewCount}
                                  disabled={true}
                                />
                                <span className="d-flex align-items-center total_fnt">
                                  ({list.business.totalReviewCount})
                                </span>
                                <OverlayTrigger
                                  trigger="click"
                                  key={list.id}
                                  placement="bottom"
                                  overlay={
                                    <Popover
                                      id={`popover-${list.id}`}
                                      className="pop_rating"
                                    >
                                      <div className="drp_rating">
                                        <StarRating
                                          rating={
                                            list.business.averageReviewCount
                                          }
                                          disabled={true}
                                        />
                                        <span>
                                          {list.business.averageReviewCount} out
                                          of 5
                                        </span>
                                      </div>
                                      {globalRating.map((item, index) => (
                                        <Row>
                                          <Col
                                            sm={12}
                                            md={3}
                                            lg={3}
                                            xl={2}
                                            className="p-0"
                                          >
                                            <span className="prog_span">
                                              {item.label}
                                            </span>{" "}
                                          </Col>
                                          <Col
                                            sm={12}
                                            md={8}
                                            lg={8}
                                            xl={7}
                                            className="p-0"
                                          >
                                            <Horizontalbar
                                              dataSet={item.value}
                                            />
                                          </Col>
                                          <Col
                                            sm={12}
                                            md={3}
                                            lg={3}
                                            xl={2}
                                            className="p-0 prog_span"
                                            style={{
                                              marginLeft: "10px",
                                            }}
                                          >
                                            <span className="tooltip">
                                              <span
                                                className="c_pointer"
                                                onMouseEnter={() =>
                                                  handleMouseEnter(
                                                    item.userRatings
                                                  )
                                                }
                                              >
                                                {item.value}
                                                {item.value > 1 ? (
                                                  <FiUsers
                                                    style={{
                                                      marginLeft: "6px",
                                                    }}
                                                  />
                                                ) : (
                                                  <FiUser
                                                    style={{
                                                      marginLeft: "6px",
                                                    }}
                                                  />
                                                )}
                                              </span>
                                              {commonUtils.ratingTooltip(
                                                currentUsers
                                              )}
                                            </span>
                                          </Col>
                                        </Row>
                                      ))}
                                    </Popover>
                                  }
                                  rootClose
                                >
                                  <span
                                    className="d-flex align-items-center c_pointer"
                                    onClick={() =>
                                      handleArrowClick(list.business.starRating)
                                    }
                                  >
                                    <IoIosArrowDown />
                                  </span>
                                </OverlayTrigger>
                              </div>
                              <div
                                style={{ overflow: "visible" }}
                                className="card_txt text-center fnt_11 mail_icn d-flex justify-content-center"
                              >
                                <span className="d-flex">
                                  <GrMail style={{ marginTop: "3px" }} />
                                  &nbsp;
                                  {list.email ? (
                                    list.isPublic ? (
                                      <span title={list.email}>
                                        {list.email.length > 19
                                          ? `${list.email.slice(0, 19)}...`
                                          : list.email}
                                      </span>
                                    ) : (
                                      list.email.slice(0, 4) +
                                      "x".repeat(
                                        Math.max(0, list.email.length - 11)
                                      )
                                    )
                                  ) : (
                                    "-"
                                  )}
                                </span>
                              </div>

                              <div className="card_txt text-center fnt_11">
                                {list.business.phone ? <PiPhoneFill /> : ""}
                                &nbsp;
                                {list.business.phone
                                  ? list.isPublic
                                    ? list.business.phone
                                    : "x".repeat(
                                      Math.max(
                                        0,
                                        list.business.phone.length - 4
                                      )
                                    ) + list.business.phone.slice(-4)
                                  : "-"}
                              </div>
                              <div className="card_txt text-center fnt_11">
                                {list.business.location ? (
                                  <HiLocationMarker />
                                ) : (
                                  ""
                                )}
                                &nbsp;
                                {list.business.location
                                  ? list.business.location
                                  : "-"}{" "}
                              </div>
                              <div className="h_23"></div>
                              <div className="btn_wd mt-2">
                                {isFeatureEnabled(allFeatures["Add-Review%"]?.key, allFeatures["Add-Review%"]?.isDeleted) ? <button
                                  className="btn btn-primary w-100 rev_btn"
                                  onClick={() =>
                                    handleReviewClick(
                                      list.id,
                                      list.professional,
                                      list.business,
                                      list.business.id,
                                      list.followedUserType,
                                      list,
                                      null,
                                      loadQuestion
                                    )
                                  }
                                >
                                  Review
                                </button> : <button
                                  className="btn btn-primary w-100 rev_btn"
                                  onClick={() => setShowPopup(true)
                                  }
                                >
                                  Review
                                </button>}
                              </div>
                            </div>
                          </Card>
                        </Col>
                      ) : (
                        ""
                      )
                    )
                  ) : loading ? (
                    <div className="text-center">
                      <img
                        className="loader-image"
                        src={loader}
                        alt="Loading..."
                      />
                    </div>
                  ) : (
                    <div className="row col-md-12 d-align mx-0 w-100 p-0">
                      <div className="no-data font-weight-600 w-100 text-center fz_14">
                        Sorry, there is no data to be displayed
                      </div>
                    </div>
                  )}
                </Row>
              </Col>
            </Row>
          </Card>
        </div>
      </div>
      <UpgradePopup
        show={showPopup}
        handleClose={() => setShowPopup(false)}
      />
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    userId: state.user.userId,
    userType: state.user.userType,
    profileId: state.user.profileId,
    userTypeInfo: state.user.userTypeInfo,
    busnAdminId: state.user.busnAdminId,
  };
};
export default connect(mapStateToProps)(FollowingTab);
