import ProgressBar from "react-bootstrap/ProgressBar";
import { MdThumbUp, MdThumbDown } from "react-icons/md";
import {
  BsFillEmojiFrownFill,
  BsFillEmojiNeutralFill,
  BsFillEmojiSmileFill,
} from "react-icons/bs";
import { Row } from "react-bootstrap";
import { useState } from "react";
const RatingBar = (props) => {
  const score =
    (props.dislikecount / (props.likecount + props.dislikecount)) * 100;
  const handlelike = () => {
    if (props.liked != 1) {
      props.handlelike();
    } else {
      props.handleRmvlike();
    }
  };
  const handledislike = () => {
    if (props.liked != 0) {
      props.handledislike();
    } else {
      props.handleRmvlike();
    }
  };
  return (
    <>
      {!props.msgreply ? (
        <div className="rating">
          {props.disabled ? (
            <div className="disabled_like grow">
              <MdThumbUp className="disabled-icon-sty" />
            </div>
          ) : (
            <div className="like grow">
              <MdThumbUp
                onClick={handlelike}
                className={
                  props.likecount && props.liked == 1
                    ? "active-icon-sty"
                    : "like-icon-sty"
                }
              />
            </div>
          )}
          {props.dislikecount == 0 && props.likecount == 0 ? (
            <div className="fa_meh">
              <BsFillEmojiNeutralFill className="ety-icon-sty" />
            </div>
          ) : (
            ""
          )}
          {props.labelVAlue * 100 >= 0 &&
            props.labelVAlue * 100 <= 66.6 &&
            score >= 0 &&
            score <= 66.6 ? (
            <div className="fa_meh">
              <BsFillEmojiNeutralFill className="meh-icon-sty" />
            </div>
          ) : (
            ""
          )}
          {score >= 66.7 && score <= 100 ? (
            <div className="fa_meh">
              <BsFillEmojiFrownFill className="meh-icon-sty" />
            </div>
          ) : (
            ""
          )}
          {props.labelVAlue * 100 >= 66.7 && props.labelVAlue * 100 <= 100 ? (
            <div className="fa_meh">
              <BsFillEmojiSmileFill className="meh-icon-sty" />
            </div>
          ) : (
            ""
          )}
          {props.disabled ? (
            <div className="disabled_dislike grow">
              <MdThumbDown className="disabled-icon-sty" />
            </div>
          ) : (
            <div className="dislike grow">
              <MdThumbDown
                onClick={handledislike}
                className={
                  props.dislikecount && props.liked == 0
                    ? "active-icon-sty"
                    : "like-icon-sty"
                }
              />
            </div>
          )}
          <div className="count_sty">
            <span className="like_count m-1">{props.likecount}</span>
            <span className="dislike_count m-1">{props.dislikecount}</span>
          </div>
          <Row className="justify-content-center">
            <ProgressBar className="p-0 m-0 meProgress">
              <ProgressBar
                className="progress_red"
                min={0}
                max={100}
                now={17}
                label={
                  props.dislikecount > props.likecount
                    ? score >= 66.8 && score <= 100
                      ? score + "%"
                      : ""
                    : ""
                }
              />
              <ProgressBar
                className="progress_org"
                min={0}
                max={100}
                now={16.6}
                label={
                  props.dislikecount > props.likecount
                    ? score >= 33.4 && score <= 66.7
                      ? score + "%"
                      : ""
                    : ""
                }
              />
              <ProgressBar
                className="progress_porg"
                min={0}
                max={100}
                now={16.6}
                label={
                  props.dislikecount > props.likecount
                    ? score >= 1 && score <= 33.3
                      ? score + "%"
                      : ""
                    : ""
                }
              />
              <ProgressBar
                className="progress_ygren"
                min={0}
                max={100}
                now={17}
                label={
                  props.likecount > props.dislikecount
                    ? props.labelVAlue * 100 >= 1 &&
                      props.labelVAlue * 100 <= 33.3
                      ? props.labelVAlue * 100 + "%"
                      : ""
                    : ""
                }
              />
              {props.likecount != 0 &&
                props.dislikecount != 0 &&
                props.likecount == props.dislikecount ? (
                <ProgressBar
                  className="progress_pgren"
                  min={0}
                  max={100}
                  now={16.6}
                  label={"50%"}
                />
              ) : (
                <ProgressBar
                  className="progress_pgren"
                  min={0}
                  max={100}
                  now={16.6}
                  label={
                    props.likecount > props.dislikecount
                      ? props.labelVAlue * 100 >= 33.4 &&
                        props.labelVAlue * 100 <= 66.7
                        ? props.labelVAlue * 100 + "%"
                        : ""
                      : ""
                  }
                />
              )}
              <ProgressBar
                className="progress_gren"
                min={0}
                max={100}
                now={17}
                label={
                  props.likecount > props.dislikecount
                    ? props.labelVAlue * 100 >= 66.8 &&
                      props.labelVAlue * 100 <= 100
                      ? props.labelVAlue * 100 + "%"
                      : ""
                    : ""
                }
              />
            </ProgressBar>
          </Row>
        </div>
      ) : (
        <div className="d-flex mb-2 mt-2">
          {props.disabled ? (
            <div className="disabled_like grow">
              <span className="like_count m-1">{props.likecount}</span>
              <MdThumbUp className="disabled-icon-sty fz_18 mb-1" />
            </div>
          ) : (
            <div className="like grow">
              <span className="dislike_count m-1">{props.likecount}</span>
              <MdThumbUp
                onClick={handlelike}
                className={
                  props.likecount && props.liked == 1
                    ? "active-icon-sty fz_18 mb-1"
                    : "like-icon-sty fz_18  mb-1"
                }
              />
            </div>
          )}
          {props.disabled ? (
            <div className="disabled_dislike grow">
              <MdThumbDown className="disabled-icon-sty fz_18" />
              <span className="dislike_count m-1 mb-1">
                {props.dislikecount}
              </span>
            </div>
          ) : (
            <div className="dislike grow">
              <MdThumbDown
                onClick={handledislike}
                className={
                  props.dislikecount && props.liked == 0
                    ? "active-icon-sty fz_18"
                    : "like-icon-sty fz_18"
                }
              />
              <span className="dislike_count m-1 mb-1">
                {props.dislikecount}
              </span>
            </div>
          )}
          <div className="count_sty"></div>
        </div>
      )}
    </>
  );
};

export default RatingBar;
