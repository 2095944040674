import React, { useState, useEffect } from "react";
import HeaderMenu from "../headerMenu";
import SideBar from "../SideBar";
import { Card, Col, Button } from "react-bootstrap";
import BreadCrumb from "../../plugins/Breadcrumb";
import profile from "../../../assets/images/profile.jpg";
import loader from "../../../assets/images/loader.gif";
import { useLocation } from "react-router-dom";
import { useToast } from "../../Toast";
import { connect } from "react-redux";
import { FaRegWindowClose } from "react-icons/fa";
import { FaRegCheckSquare } from "react-icons/fa";
const UserPreference = (props) => {
    const [follList, setFollList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [upLoading, setUpLoading] = useState(false);
    const [checkedList, setCheckedList] = useState([]);
    const [above3Checked, setAbove3Checked] = useState(false);
    const [threeStarChecked, setThreeStarChecked] = useState(false);
    const [below3Checked, setBelow3Checked] = useState(false);
    const [gloRevStarList, setGloRevStarList] = useState([]);
    const [preference, setPreference] = useState("");
    const toast = useToast();
    const location = useLocation();
    // const userId = location.state?.userId;
    // const userType = location.state?.userType;
    // const profileId = location.state?.profileId;
    const [checkedStars, setCheckedStars] = useState([]);

    const handleCheckChange = (star) => {
        setCheckedStars((prevCheckedStars) =>
            prevCheckedStars.includes(star)
                ? prevCheckedStars.filter((item) => item !== star)
                : [...prevCheckedStars, star]
        );
    };
    useEffect(() => {
        FollowingList();
        listFollowNotify();
        listRevNotify();
    }, [props]);

    const checkToShowCard = (follower) => {
        return (
            (follower.followedUserType === "Business" && follower.business != null) ||
            follower.followedUserType === "Personal" ||
            (follower.followedUserType === "Professional" && follower.professional != null)
        );
    };

    // const generateRecords = () => {
    //     let records = [];
    //     if (preference === 'above') {
    //         if (checkedStars.contains('1')) {
    //             records = records.concat([
    //                 { userId: props.userId, userType: props.userType, userTypeId: props.profileId, rating: 2 },
    //                 { userId: props.userId, userType: props.userType, userTypeId: props.profileId, rating: 3 },
    //                 { userId: props.userId, userType: props.userType, userTypeId: props.profileId, rating: 4 },
    //                 { userId: props.userId, userType: props.userType, userTypeId: props.profileId, rating: 5 }
    //             ]);
    //         }
    //         else if(checkedStars.contains('2')){
    //             records = records.concat([
    //                 { userId: props.userId, userType: props.userType, userTypeId: props.profileId, rating: 3 },
    //                 { userId: props.userId, userType: props.userType, userTypeId: props.profileId, rating: 4 },
    //                 { userId: props.userId, userType: props.userType, userTypeId: props.profileId, rating: 5 }
    //             ]);
    //         }
    //         else if(checkedStars.contains('3')){
    //             records = records.concat([
    //                 { userId: props.userId, userType: props.userType, userTypeId: props.profileId, rating: 4 },
    //                 { userId: props.userId, userType: props.userType, userTypeId: props.profileId, rating: 5 }
    //             ]);
    //         }
    //     }
    //     else if (preference === 'below') {

    //     }
    //     else if (preference === 'equal') {

    //     }
    //     if (newAbove3Checked) {
    //         records = records.concat([
    //             { userId: props.userId, userType: props.userType, userTypeId: props.profileId, rating: 4 },
    //             { userId: props.userId, userType: props.userType, userTypeId: props.profileId, rating: 5 }
    //         ]);
    //     }
    //     if (newThreeStarChecked) {
    //         records = records.concat([
    //             { userId: props.userId, userType: props.userType, userTypeId: props.profileId, rating: 3 }
    //         ]);
    //     }
    //     if (newBelow3Checked) {
    //         records = records.concat([
    //             { userId: props.userId, userType: props.userType, userTypeId: props.profileId, rating: 1 },
    //             { userId: props.userId, userType: props.userType, userTypeId: props.profileId, rating: 2 }
    //         ]);
    //     }
    //     console.log("records", records)
    //     setGloRevStarList(records);
    //     // updateRevNotify(records);
    //     return records;
    // };
    // const handleRevChange = (type) => {
    //     let newAbove3Checked = above3Checked;
    //     let newThreeStarChecked = threeStarChecked;
    //     let newBelow3Checked = below3Checked;

    //     if (type === 'above3') {
    //         newAbove3Checked = !above3Checked;
    //         setAbove3Checked(newAbove3Checked);
    //     } else if (type === 'threeStar') {
    //         newThreeStarChecked = !threeStarChecked;
    //         setThreeStarChecked(newThreeStarChecked);
    //     } else if (type === 'below3') {
    //         newBelow3Checked = !below3Checked;
    //         setBelow3Checked(newBelow3Checked);
    //     }

    //     generateRecords(newAbove3Checked, newThreeStarChecked, newBelow3Checked);
    // };

    const generateRecords = () => {
        const { userId, userType, profileId } = props;
        let records = [];
        const createRecord = (rating) => ({
            userId,
            userType,
            userTypeId: profileId,
            rating,
        });
        const addRecordIfNotExists = (rating) => {
            const record = createRecord(rating);
            const exists = records.some(
                r => r.userId === record.userId &&
                    r.userType === record.userType &&
                    r.userTypeId === record.userTypeId &&
                    r.rating === record.rating
            );
            if (!exists) {
                record.filter = preference;
                records.push(record);
            }
        };

        if (preference === 'All') {
            [1, 2, 3, 4, 5].forEach(addRecordIfNotExists);
        } else if (preference === 'Above') {
            checkedStars.forEach(star => {
                const possibleRatings = [];
                if (star === 1) {
                    possibleRatings.push(2, 3, 4, 5);
                } else if (star === 2) {
                    possibleRatings.push(3, 4, 5);
                } else if (star === 3) {
                    possibleRatings.push(4, 5);
                } else if (star === 4) {
                    possibleRatings.push(5);
                }
                possibleRatings.forEach(addRecordIfNotExists);
            });
        } else if (preference === 'Below') {
            checkedStars.forEach(star => {
                const possibleRatings = [];
                if (star === 2) {
                    possibleRatings.push(1);
                } else if (star === 3) {
                    possibleRatings.push(1, 2);
                } else if (star === 4) {
                    possibleRatings.push(1, 2, 3);
                } else if (star === 5) {
                    possibleRatings.push(1, 2, 3, 4);
                }
                possibleRatings.forEach(addRecordIfNotExists);
            });
        } else if (preference === 'Equal') {
            checkedStars.forEach(addRecordIfNotExists);
        }

        console.log(records);
        setGloRevStarList(records);
    };

    useEffect(() => {
        generateRecords();
    }, [checkedStars, preference]);


    const addCheckedList = (fol) => {
        const profId = fol.professional?.id || fol.business?.id || null;
        setCheckedList((prevList) => [
            ...prevList,
            {
                userId: props.userId,
                userType: props.userType,
                userTypeId: props.profileId ? props.profileId : null,
                followingUserId: fol.id,
                followingTypeId: profId,
                followingUserType: fol.professional
                    ? 'Professional'
                    : fol.business
                        ? 'Business'
                        : 'Personal',
            },
        ]);
    };

    const removeById = (id, profileId) => {
        setCheckedList((prevList) =>
            prevList.filter(
                (item) => item.followingUserId !== id || item.followingTypeId !== profileId
            )
        );
    };

    const handleCheckboxChange = (follower) => {
        const profId = follower.professional?.id || follower.business?.id || null;
        const isChecked = checkedList.some(
            (item) => {
                if (follower.followedUserType === 'Personal') {
                    return item.followingUserId === follower.id && item.followingTypeId === null;
                } else if (follower.followedUserType === 'Professional') {
                    return item.followingUserId === follower.id && item.followingTypeId === follower.professional?.id;
                } else if (follower.followedUserType === 'Business') {
                    return item.followingUserId === follower.id && item.followingTypeId === follower.business?.id;
                }
                return false;
            }
        );
        if (isChecked) {
            removeById(follower.id, profId);
        } else {
            addCheckedList(follower);
        }
    };

    const FollowingList = async () => {
        console.log("profileId", props.profileId)
        const params = {
            followingUserId: props.userId,
            followingUserType: props.userType,
            followingTypeId: props.profileId,
        };
        setLoading(true);
        try {
            const response = await window.apiService.userFollowingList(params);
            setFollList(response.data.recordInfo);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            toast.error("Error Occurred");
        }
    };
    // const updateReviewPref = (data) => {
    //     addFollowNotify();
    //     updateRevNotify(data)
    // }
    const updateReviewPref = async (data) => {
        setUpLoading(true);
        let isFollowNotifySuccess = false;
        let isRevNotifySuccess = false;

        try {
            const followNotifyResponse = await window.apiService.addFollowNotify(checkedList);
            if (followNotifyResponse.data.status == 'OK') {
                isFollowNotifySuccess = true;
                listFollowNotify();
            }
            const revNotifyResponse = await window.apiService.updateRevNotify(data);
            if (revNotifyResponse.data.status == 'OK') {
                isRevNotifySuccess = true;
                listRevNotify();
            }
            if (isFollowNotifySuccess && isRevNotifySuccess) {
                toast.success("Updated successfully");
            }
        } catch (error) {
            toast.error("Error Occurred");
        } finally {
            setUpLoading(false);
        }
    };

    // const updateRevNotify = async (data) => {
    //     let params = data;
    //     setLoading(true);
    //     try {
    //         const response = await window.apiService.updateRevNotify(params);
    //         if (response.data.status == 'OK') {
    //             toast.success(response.data.message);
    //             setLoading(false);
    //             listRevNotify();
    //         }
    //     } catch (error) {
    //         setLoading(false);
    //         toast.error("Error Occurred");
    //     }
    // };


    const listFollowNotify = async () => {
        const params = {
            userId: props.userId,
            userType: props.userType,
            userTypeId: props.profileId ? props.profileId : null,
        };
        setLoading(true);
        try {
            if (params.userId) {
                const response = await window.apiService.listFollowNotify({ params });
                setCheckedList(response.data.data);
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            toast.error("Error Occurred");
        }
    };
    const handlePreferenceChange = (e) => {
        const newPreference = e.target.value;
        setPreference(newPreference);
        setCheckedStars([]);
    };
    const getOptionsBasedOnPreference = () => {
        switch (preference) {
            case 'Above':
                return [1, 2, 3, 4];
            case 'Below':
                return [2, 3, 4, 5];
            case 'Equal':
                return [1, 2, 3, 4, 5];
            case 'All':
                return [1, 2, 3, 4, 5];
            default:
                return [];
        }
    };
    const StarRatingOptions = ({ options, checkedStars, handleCheckboxChange }) => (
        <div>
            {options.map((star) => (
                <div key={star}>
                    <input
                        className="c_pointer"
                        type="checkbox"
                        checked={checkedStars.includes(star)}
                        onChange={() => handleCheckboxChange(star)}
                    />
                    &nbsp;
                    <label className="fz_14">{star} star</label>
                </div>
            ))}
        </div>
    );

    const listRevNotify = async () => {
        const params = {
            userId: props.userId,
            userType: props.userType,
            userTypeId: props.profileId ? props.profileId : null,
        };
        setLoading(true);
        try {
            const response = await window.apiService.listRevNotify(params);
            let result = response.data.data;
            setGloRevStarList(result);
            let filterType = null;
            let maxRating = 0;
            let minRating = 5;
            for (const review of result) {
                if (review.filter === 'Above') {
                    filterType = 'Above';
                    if (review.rating < minRating) {
                        minRating = review.rating;
                    }
                } else if (review.filter === 'Below') {
                    filterType = 'Below';
                    if (review.rating > maxRating) {
                        maxRating = review.rating;
                    }
                } else if (review.filter === 'Equal') {
                    filterType = 'Equal';
                } else if (review.filter === 'All') {
                    filterType = 'All';
                }
            }

            if (filterType === 'Below') {
                if (maxRating === 1) {
                    setCheckedStars([2]);
                } else if (maxRating === 2) {
                    setCheckedStars([3]);
                } else if (maxRating === 3) {
                    setCheckedStars([4]);
                } else if (maxRating === 4) {
                    setCheckedStars([5]);
                }
            } else if (filterType === 'Above') {
                if (minRating === 1) {
                    setCheckedStars([1]);
                } else if (minRating === 2) {
                    setCheckedStars([1]);
                } else if (minRating === 3) {
                    setCheckedStars([2]);
                } else if (minRating === 4) {
                    setCheckedStars([3]);
                }
            } else if (filterType === 'Equal') {
                setCheckedStars(result.map(review => review.rating));
            }
            else if (filterType === 'All') {
                setCheckedStars(result.map(review => review.rating));
            } else {
                setCheckedStars([]);
            }

            setPreference(filterType);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            toast.error("Error Occurred");
        }
    };
    // const addFollowNotify = async () => {
    //     setLoading(true);
    //     try {
    //         const response = await window.apiService.addFollowNotify(checkedList);
    //         if (response.data.status == 'OK') {
    //             toast.success("Updated successfully");
    //             listFollowNotify();
    //             setLoading(false);
    //         }
    //     } catch (error) {
    //         setLoading(false);
    //         toast.error("Error Occurred");
    //     }
    // };

    const renderImage = (follower) => {
        return follower?.followedUserType === "Professional"
            ? follower?.professional?.imagePath
                ? follower.professional.imagePath
                : profile
            : follower?.followedUserType === "Business"
                ? follower?.business?.logo
                    ? follower.business.logo
                    : profile
                : follower?.imagePath
                    ? follower.imagePath
                    : profile;
    };

    return (
        <>
            <HeaderMenu />
            <SideBar />
            <div className="main-content ml_200">
                {/* <BreadCrumb /> */}
                <Card className="shade h-500">
                    <div className="row mx-2 my-2">
                        <Col lg="5" xl="5" md="5" sm="12">
                            <h6>Following user's</h6>
                            <p className="fz_14 clr_gray mb-0 font-weight-400">Choose users to receive new review notification</p>
                            <div className="me_revList mx_h_55vh p-1">
                                {follList && follList.length > 0 ? (
                                    follList.map(
                                        (follower, index) =>
                                            checkToShowCard(follower) && (
                                                <div key={index} className="foll_lst py-1 rounded-0">
                                                    <img
                                                        src={renderImage(follower)}
                                                        height={30}
                                                        width={30}
                                                        className="rounded-circle"
                                                    />
                                                    <div className="fz_14">
                                                        {follower.followedUserType === 'Business'
                                                            ? follower.business?.companyName
                                                            : follower.name}
                                                        &nbsp;
                                                        {follower.followedUserType === 'Professional'
                                                            ? `(${follower.professional?.professionType})`
                                                            : ''}
                                                    </div>

                                                    <div className="ml-auto">
                                                        <input
                                                            className="c_pointer"
                                                            title="Receive notification"
                                                            type="checkbox"
                                                            checked={checkedList.some(
                                                                (item) => {
                                                                    if (follower.followedUserType === 'Personal') {
                                                                        return item.followingUserId === follower.id && item.followingTypeId === null;
                                                                    } else if (follower.followedUserType === 'Professional') {
                                                                        return item.followingUserId === follower.id && item.followingTypeId === follower.professional?.id;
                                                                    } else if (follower.followedUserType === 'Business') {
                                                                        return item.followingUserId === follower.id && item.followingTypeId === follower.business?.id;
                                                                    }
                                                                    return false;
                                                                }
                                                            )}
                                                            onChange={() => handleCheckboxChange(follower)}
                                                        />
                                                    </div>
                                                </div>
                                            )
                                    )
                                ) : loading ? (
                                    <div className="pro-loader-center">
                                        <img className="loader-image" src={loader} alt="Loading..." />
                                    </div>
                                ) : (
                                    <div className="text-center">No followers found</div>
                                )}
                            </div>
                            {/* {follList && follList.length > 0 ? <div className="d-flex justify-content-end mt-2">
                                <Button className="btn btn-default cancel_btn pad_btn" onClick={() => setCheckedList([])}>Cancel</Button>
                                <Button className="btn btn-default pad_btn" disabled={checkedList.length === 0} onClick={() => addFollowNotify()}>Add</Button>
                            </div> : ''} */}
                        </Col>
                        {checkedList && checkedList.length > 0 ? <Col lg="4" xl="3" md="5" sm="12">
                            <h6 className="mb-4">Filter by star rating</h6>
                            <select
                                className="rle_place form-control input_select mb-2"
                                value={preference}
                                onChange={handlePreferenceChange}
                            >
                                <option value="">Select</option>
                                <option value="Above">Above</option>
                                <option value="Below">Below</option>
                                <option value="Equal">Equal</option>
                                {/* <option value="All">All</option> */}
                            </select>
                            {preference && preference !== 'All' && (
                                <StarRatingOptions
                                    options={getOptionsBasedOnPreference()}
                                    checkedStars={checkedStars}
                                    handleCheckboxChange={handleCheckChange}
                                />
                            )}
                            {/* <div>
                                <input
                                    className="c_pointer"
                                    type="checkbox"
                                    checked={above3Checked}
                                    onChange={() => handleRevChange('above3')}
                                />
                                &nbsp;
                                <label className="fz_14">Above 3 stars</label>
                            </div>
                            <div>
                                <input
                                    className="c_pointer"
                                    type="checkbox"
                                    checked={threeStarChecked}
                                    onChange={() => handleRevChange('threeStar')}
                                />
                                &nbsp;
                                <label className="fz_14">3 Stars</label>
                            </div>
                            <div>
                                <input
                                    className="c_pointer"
                                    type="checkbox"
                                    checked={below3Checked}
                                    onChange={() => handleRevChange('below3')}
                                />
                                &nbsp;
                                <label className="fz_14">Below 3 stars</label>
                            </div> */}
                            {/* {preference && <div className="d-flex justify-content-end">
                                <FaRegWindowClose title="Update" className="fz_22 mr-2 text-red" onClick={() => updateRevNotify(gloRevStarList)} />
                                <FaRegCheckSquare title="Cancel" className="fz_22 text-green" onClick={() => listRevNotify()} /></div>} */}
                            {preference && <div className="d-flex justify-content-end"> <Button className="btn btn-default cancel_btn pad_btn" onClick={() => { listRevNotify(); setPreference(''); }}>Cancel</Button>
                                <Button className="btn btn-default pad_btn" disabled={upLoading || gloRevStarList.length === 0 || checkedList.length === 0} onClick={() => updateReviewPref(gloRevStarList)}>Update</Button>

                            </div>}
                        </Col> : ''}
                    </div>
                </Card >
            </div >
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        userId: state.user.userId,
        userType: state.user.userType,
        profileId: state.user.profileId,
    };
};
export default connect(mapStateToProps)(UserPreference);
