import React, { useState, useRef } from "react";
import { Collapse, Form, Navbar, Container } from "reactstrap";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
import "../../../Dashboard/inbox/inbox.css";
import { Nav, Col, Row, Button, Card } from "react-bootstrap";
import HeaderMenu from "../../HeaderMenu";
import SideBar from "../../../Admin/AdminSideBar";
import BreadCrumb from "../../../plugins/Breadcrumb";
import ServicePlan from "./ServicePlan";
import PlanFeature from "./PlanFeature";
import PlanFeatureMapping from "./PlanFeatureMapping";
import ServicePlanDiscount from "./ServicePlanDiscount";
import CancellationReason from "./CancellationReason";
export function Sidebar() {
    const [collapseOpen, setCollapseOpen] = useState();
    const [chatType, setChatType] = useState("plans");

    const toggleCollapse = () => {
        setCollapseOpen((data) => !data);
    };
    const renderComponent = (chatType) => {
        switch (chatType) {
            case 'plans':
                return <ServicePlan />;
            case 'Features':
                return <PlanFeature />;
            case 'discount':
                return <ServicePlanDiscount />;
            case 'reason':
                return <CancellationReason />;
            default:
                return <PlanFeatureMapping />;
        }
    };

    return (
        <>
            <HeaderMenu />
            <div className="">
                <SideBar />
                <div className="ml_200">
                    <div className="inbox">
                        <div className="container pl-0 pr-0">
                            <Navbar
                                className="navbar-vertical fixed-left navbar-light bg-white ml_200"
                                expand="md"
                                id="chatnav-main"
                            >
                                <Container className="flx_column" fluid>
                                    <button
                                        className="navbar-toggler"
                                        type="button"
                                        onClick={toggleCollapse}
                                    >
                                        <span className="navbar-toggler-icon" />
                                    </button>
                                    <Collapse navbar isOpen={collapseOpen} className="cht_mr w-100 position-relative">
                                        <div className="navbar-collapse-header d-md-none">
                                            <Row>
                                                <Col className="collapse-brand" xs="6">
                                                    <Link to="/dashboard">
                                                        <h2 style={{ color: "#11cdef" }}>tiipstr</h2>
                                                    </Link>
                                                </Col>
                                                <Col className="collapse-close" xs="6">
                                                    <button
                                                        className="navbar-toggler"
                                                        type="button"
                                                        onClick={toggleCollapse}
                                                    >
                                                        <span />
                                                        <span />
                                                    </button>
                                                </Col>
                                            </Row>
                                        </div>
                                        <Nav variant="pills" className="flex-column navStyle">
                                            <div className="set_chat_div">
                                                <Nav.Item className={chatType === 'plans' ? 'bg_lt_gray' : ''}
                                                >
                                                    <Nav.Link className="blk_clr"
                                                        onClick={(event) => {
                                                            setChatType('plans')
                                                        }}
                                                    >Service Plans
                                                    </Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item className={chatType === 'Features' ? 'bg_lt_gray' : ''}
                                                >
                                                    <Nav.Link className="blk_clr"
                                                        onClick={(event) => {
                                                            setChatType('Features')
                                                        }}
                                                    >Features
                                                    </Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item className={chatType === 'Mapping' ? 'bg_lt_gray' : ''}
                                                >
                                                    <Nav.Link className="blk_clr"
                                                        onClick={(event) => {
                                                            setChatType('Mapping')
                                                        }}
                                                    >Plan Features
                                                    </Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item className={chatType === 'discount' ? 'bg_lt_gray' : ''}
                                                >
                                                    <Nav.Link className="blk_clr"
                                                        onClick={(event) => {
                                                            setChatType('discount')
                                                        }}
                                                    >Service Plan Discounts
                                                    </Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item className={chatType === 'reason' ? 'bg_lt_gray' : ''}
                                                >
                                                    <Nav.Link className="blk_clr"
                                                        onClick={(event) => {
                                                            setChatType('reason')
                                                        }}
                                                    >Cancellation Reasons
                                                    </Nav.Link>
                                                </Nav.Item>
                                            </div>
                                        </Nav>
                                    </Collapse>
                                </Container>
                            </Navbar>
                            <div className="chat_ml">
                                {renderComponent(chatType)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Sidebar;
